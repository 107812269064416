import { createContext, Dispatch, SetStateAction } from 'react'
import { NormalizedShippingMethods } from '../../lib/commercetools/normalizers/normalizeShippingMethods'

export type ShippingContextProps = {
  error: Error | undefined
  pendingRequests: number
  shippingMethodId: string | undefined
  shippingMethods: NormalizedShippingMethods | []
  shippingMethodCount: number
  hasCartShipping: boolean
  isPending: boolean
  isReadOnly: boolean
  setIsReadOnly: Dispatch<SetStateAction<boolean>>
}

export const defaultProps: ShippingContextProps = {
  error: undefined,
  pendingRequests: 0,
  shippingMethodId: undefined,
  shippingMethods: [],
  shippingMethodCount: 0,
  hasCartShipping: false,
  isPending: false,
  isReadOnly: false,
  setIsReadOnly: () => undefined,
}

export const ShippingContext = createContext<ShippingContextProps>(defaultProps)
