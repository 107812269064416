import makeDebug from 'debug'

const debug = makeDebug('lib:utils:lrucache')

/**
 * A Least Recently Used (LRU) Cache implementation.
 * @template KeyType The type of the cache keys.
 * @template ValueType The type of the cache values.
 */
export class LRUCache<KeyType, ValueType> {
  private maxSize
  private cache = new Map<KeyType, ValueType>()
  private disableCache = false

  /**
   * Creates a new LruCache instance.
   * @param maxSize The maximum size of the cache. Defaults to 100.
   */
  constructor(maxSize = 100) {
    this.maxSize = maxSize
  }

  /**
   * Retrieves the value associated with the specified key from the LRUCache.
   * If caching is disabled, this method returns undefined.
   * If the key exists in the cache, it is moved to the front of the cache to indicate it was recently accessed.
   *
   * @param key - The key of the value to retrieve.
   * @returns The value associated with the key, or undefined if caching is disabled or the key does not exist in the cache.
   */
  get(key: KeyType) {
    if (this.disableCache) {
      return
    }

    const value = this.cache.get(key)

    if (value) {
      this.cache.delete(key)
      this.cache.set(key, value)

      debug('📗 LRUCache get ' + key)
    }

    return value
  }

  /**
   * Adds a key-value pair to the cache.
   * If the cache is disabled, this method does nothing.
   * If the cache already contains the key, it is removed before adding the new key-value pair.
   * If the cache has reached its maximum size, the least recently used key-value pair is removed before adding the new key-value pair.
   * @param key - The key to be added to the cache.
   * @param value - The value to be associated with the key in the cache.
   */
  put(key: KeyType, value: ValueType) {
    if (this.disableCache) {
      return
    }

    if (this.cache.has(key)) {
      this.cache.delete(key)
    } else if (this.cache.size >= this.maxSize) {
      const firstKey = this.cache.keys().next().value

      this.cache.delete(firstKey)
    }

    this.cache.set(key, value)

    debug('📕 LRUCache put ' + key)
  }

  /**
   * Checks if the cache contains a specific key.
   *
   * @example const cachedResponse = cache.has(url) ? cache.get(url) : undefined
   * @param key - The key to check for in the cache.
   * @returns `true` if the cache contains the key, `false` otherwise.
   */
  has(key: KeyType) {
    if (this.disableCache) {
      return false
    }

    return this.cache.has(key)
  }

  /**
   * Clears the cache.
   */
  clear() {
    this.cache.clear()
  }

  /**
   * Returns the number of items currently stored in the cache.
   *
   * @returns The number of items in the cache.
   */
  size() {
    return this.cache.size
  }

  /**
   * Disables the cache and clears it if it's not empty.
   */
  disable() {
    this.disableCache = true

    if (this.cache.size > 0) {
      this.clear()
    }
  }
}
