import { normalizeProduct } from './normalizeProduct'
import { BxLineItem, BxProduct } from '../requests/bxTypes'
import { EcomLocale, Currency } from '../../../config/shopAPI/types'

export const normalizeLineItem = (
  {
    id,
    bxProductUrl,
    bxProduct: bxProductWithoutMasterVariant,
    bxVariantIsAvailable,
    variant: masterVariant,
    price,
    discountedPricePerQuantity,
    lineItemMode,
    quantity,
  }: BxLineItem,
  lcCC: EcomLocale
): NormalizedLineItem => {
  if (!bxProductWithoutMasterVariant.masterData.current) {
    throw new Error(
      'Required field <LineItem>.bxProduct.masterVariant.masterData.current is missing'
    )
  } else if (!masterVariant) {
    throw new Error('Required field <LineItem>.variant is missing')
  }

  // Assemble a full BxProduct from <LineItem>.variant and the other fields in
  // <LineItem>.bxProduct (no master variant data there to avoid)
  const p: BxProduct = {
    ...bxProductWithoutMasterVariant,
    bxProductUrl,
    masterData: {
      ...bxProductWithoutMasterVariant.masterData,
      current: {
        ...bxProductWithoutMasterVariant.masterData.current,
        masterVariant: {
          ...masterVariant,
          bxVariantIsAvailable,
          price,
        },
      },
    },
  }

  return {
    id,
    audioLibraryAssignUrl: masterVariant.bxAudioLibraryAssignUrl,
    isFreeGift: lineItemMode === 'GiftLineItem',
    product: normalizeProduct(p, lcCC),
    quantity,
    total: {
      price: price.bxValue && {
        amount: price.bxValue.centAmount / 100,
        centAmount: price.bxValue.centAmount,
        currency: price.bxValue.currencyCode as Currency,
      },
      strikePrice: price.bxStrikeValue && {
        amount: price.bxStrikeValue.centAmount / 100,
        centAmount: price.bxStrikeValue.centAmount,
        currency: price.bxStrikeValue.currencyCode as Currency,
      },

      /**
       * discountedPricePerQuantity may include multiple prices if a division by
       * rounding up or down produces multiple results. E.g. 5 / 3 would result in
       * 1: 1.66
       * 2: 1.67
       * 3: 1.67
       * As the gtmV4Price is for Tracking only, we agreed to only use the first
       * discounted price here.
       */
      gtmV4Price: discountedPricePerQuantity[0] && {
        amount:
          discountedPricePerQuantity[0].discountedPrice.value.centAmount / 100,
        centAmount:
          discountedPricePerQuantity[0].discountedPrice.value.centAmount,
        currency: discountedPricePerQuantity[0].discountedPrice.value
          .currencyCode as Currency,
      },
    },
  }
}
