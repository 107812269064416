import { Currency, EcomLocale } from '../../../config/shopAPI/types'
import { BxCart, bxLineItemType, BxLineItemType } from '../requests/bxTypes'
import { PaymentResponse } from '../requests/payments/types'
import { normalizeDiscount } from './normalizeDiscount'
import { normalizeLineItem } from './normalizeLineItem'
import { normalizeShipping } from './normalizeShipping'

export const normalizedCartType = [...bxLineItemType, 'mixed'] as const
export type NormalizedCartType = (typeof normalizedCartType)[number] | null

export const normalizeCartType = (
  bxLineItemTypes: BxLineItemType[]
): NormalizedCartType => {
  if (bxLineItemTypes.length === 1) {
    return bxLineItemTypes[0]
  } else if (bxLineItemTypes.length > 1) {
    return 'mixed'
  }
  return null
}

export const getResponseFromPayment = (
  bxCart: BxCart
): PaymentResponse | undefined => {
  return !bxCart.bxCurrentPayment
    ? undefined
    : {
        amountPlanned: bxCart.bxCurrentPayment.amountPlanned,
        id: bxCart.bxCurrentPayment.id,
        version: bxCart.bxCurrentPayment.version,
        custom: {
          typeId: 'payment',
          typeKey: undefined,
          type: bxCart.bxCurrentPayment.custom?.type,
          fields: bxCart.bxCurrentPayment.custom?.fields,
        },
      }
}
export const normalizeCart = (
  bxCart: BxCart,
  lcCC: EcomLocale
): NormalizedCart => {
  const bxLineItems = bxCart.lineItems || []
  const lineItems = bxLineItems.map(li => normalizeLineItem(li, lcCC))

  return {
    id: bxCart.id,
    version: bxCart.version,
    count: lineItems.reduce((prev, li) => prev + li.quantity, 0),
    addresses: {
      billing: bxCart.billingAddress,
      shipping: bxCart.shippingAddress,
    },
    bxLineItems,
    bxLineItemTypes: bxCart.bxLineItemTypes,
    normalizedCartType: normalizeCartType(bxCart.bxLineItemTypes),
    country: bxCart.country,
    currentPayment: bxCart.bxCurrentPayment,
    currentPaymentResponse: getResponseFromPayment(bxCart),
    cartDiscount: normalizeDiscount(lcCC, bxCart),
    flags: {
      isReadyForOrder: bxCart.bxReadyForOrder,
    },
    lineItems,
    paymentInfo: bxCart.paymentInfo,
    price: {
      original: {
        amount: bxCart.bxOriginalTotalPrice.amount,
        centAmount: bxCart.bxOriginalTotalPrice.centAmount,
        currency: bxCart.bxOriginalTotalPrice.currencyCode as Currency,
      },
      total: {
        amount: bxCart.totalPrice.amount || 0,
        centAmount: bxCart.totalPrice.centAmount,
        currency: bxCart.bxOriginalTotalPrice.currencyCode as Currency,
      },
    },
    shipping: normalizeShipping(lcCC, bxCart),
    shippingInfo: bxCart.shippingInfo,
    cartState: bxCart.cartState,
    customerId: bxCart.customerId,
  }
}
