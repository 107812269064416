import { createContext } from 'react'

export type ToniesCookiesProps = {
  cartId: Readonly<string | undefined>
  cartUserId: Readonly<string | undefined>
  cartItemCount: Readonly<number>

  setCartId: (cartId: string | undefined) => void
  setCartUserId: (cartId: string | undefined) => void
  setCartItemCount: (count: number) => void
}

export const defaultProps = {
  cartId: undefined,
  cartUserId: undefined,
  cartItemCount: 0,

  setCartId: () => undefined,
  setCartUserId: () => undefined,
  setCartItemCount: () => undefined,
}

export const toniesCookiesContext =
  createContext<ToniesCookiesProps>(defaultProps)
