import React, { FunctionComponent, PropsWithChildren } from 'react'
import { MediaPlayerContextProvider } from '@boxine/tonies-ui'
import { ToniesFontStyles } from '../tonies-ui/styles/ToniesFontStyles'
import { ToniesGlobalStyles } from '../tonies-ui/styles/ToniesGlobalStyles'
import { ThemeProvider } from 'styled-components'
import { FeatureFlagContextProvider } from './featureFlags'
import { SupportContextProvider } from './support'
import { AuthProvider } from './auth/AuthProvider'
import { CartStateProvider } from './cartState/CartStateProvider'
import { PaymentProvider } from './payment/PaymentProvider'
import { mergedTheme } from '../styles/theme'
import { AddressesProvider } from './addresses/AddressesProvider'
import { LocaleSelectorContextProvider } from './localeSelector'
import { ShippingContextProvider } from './shipping'
import { ToniesCookiesProvider } from './toniesCookies/ToniesCookiesProvider'
import { CartActionsProvider } from './cartActions/CartActionsProvider'
import { SyncCartCookiesWithCartStateProvider } from './syncCartCookiesWithCartState/SyncCartCookiesWithCartStateProvider'
import { SyncCartCookiesWithAuthProvider } from './syncCartCookiesWithAuth/SyncCartCookiesWithAuthProvider'
import { DiscountCodeInputProvider } from './discountCodeInput/DiscountCodeInputProvider'
import { TranslationsProvider } from './translations'
import { AggregatedShopLocaleProvider } from './aggregatedShopLocale'
import { AggregatedShopLocale } from '../lib/transformation/aggregateShopLocale/types'
import { NativeAppProvider } from './nativeApp'
import { PaymentTypeContextProvider } from './paymentTypes'
import { ShopAPIErrorHandlingProvider } from './shopAPIErrorHandling/ShopAPIErrorHandlingProvider'
import { MeasurementsProvider } from './measurements'
import { ModalNotifications } from './ModalNotifications'
import { SidebarOverlayProvider } from './SidebarOverlay'
import { ConsentContextProvider } from './consent'
import { AuthConsumer } from './auth'
import { WishlistProvider } from './Wishlist'
import { OwnAudioContentProvider } from './ownAudioContent/OwnAudioContentProvider'
import { RecommendationsProvider } from './recommendations'

export interface SharedProvidersProps {
  aggregatedShopLocale: AggregatedShopLocale
  withZendesk?: boolean
  isPageAllowedInAppView?: boolean
}

export const SharedProviders: FunctionComponent<
  SharedProvidersProps & PropsWithChildren
> = ({
  aggregatedShopLocale,
  children,
  withZendesk = false,
  isPageAllowedInAppView = false, // defaults to false, because only add-2-cart, cart, checkout, etc. are allowed
}) => (
  <ThemeProvider theme={mergedTheme}>
    <ConsentContextProvider>
      <MeasurementsProvider>
        <NativeAppProvider isPageAllowedInAppView={isPageAllowedInAppView}>
          <AggregatedShopLocaleProvider value={aggregatedShopLocale}>
            <MediaPlayerContextProvider>
              <TranslationsProvider>
                <ToniesCookiesProvider>
                  <LocaleSelectorContextProvider>
                    <AuthProvider>
                      <AuthConsumer>
                        {({ user }) => (
                          <FeatureFlagContextProvider>
                            <RecommendationsProvider>
                              <OwnAudioContentProvider>
                                <CartStateProvider>
                                  <PaymentTypeContextProvider>
                                    <CartActionsProvider>
                                      <ModalNotifications>
                                        <WishlistProvider
                                          wishlistId={user?.wishlists?.[0]?.id}
                                          context="SharedProviders"
                                        >
                                          <SyncCartCookiesWithAuthProvider>
                                            <SyncCartCookiesWithCartStateProvider>
                                              <ShopAPIErrorHandlingProvider>
                                                <PaymentProvider>
                                                  <AddressesProvider>
                                                    <ShippingContextProvider>
                                                      <SupportContextProvider
                                                        withZendesk={
                                                          withZendesk
                                                        }
                                                      >
                                                        <DiscountCodeInputProvider>
                                                          <SidebarOverlayProvider>
                                                            <ToniesGlobalStyles />
                                                            <ToniesFontStyles />
                                                            {children}
                                                          </SidebarOverlayProvider>
                                                        </DiscountCodeInputProvider>
                                                      </SupportContextProvider>
                                                    </ShippingContextProvider>
                                                  </AddressesProvider>
                                                </PaymentProvider>
                                              </ShopAPIErrorHandlingProvider>
                                            </SyncCartCookiesWithCartStateProvider>
                                          </SyncCartCookiesWithAuthProvider>
                                        </WishlistProvider>
                                      </ModalNotifications>
                                    </CartActionsProvider>
                                  </PaymentTypeContextProvider>
                                </CartStateProvider>
                              </OwnAudioContentProvider>
                            </RecommendationsProvider>
                          </FeatureFlagContextProvider>
                        )}
                      </AuthConsumer>
                    </AuthProvider>
                  </LocaleSelectorContextProvider>
                </ToniesCookiesProvider>
              </TranslationsProvider>
            </MediaPlayerContextProvider>
          </AggregatedShopLocaleProvider>
        </NativeAppProvider>
      </MeasurementsProvider>
    </ConsentContextProvider>
  </ThemeProvider>
)
