import { BxAdyenCustomFields } from '../../lib/commercetools/requests/bxTypes'
import { PaymentResponse } from '../../lib/commercetools/requests/payments/types'
import {
  PaymentType,
  PaymentResults,
  OriginError,
  PaymentMethodsResponseObject,
} from './types'

const adyenResultCodeTypes = [
  'Authorised',
  'Refused',
  'Error',
  'RedirectShopper',
  'Received',
  'PresentToShopper',
  'Pending',
  'IdentifyShopper',
  'ChallengeShopper',
  'Cancelled',
  'AuthenticationNotRequired',
  'AuthenticationFinished',
]

export const paymentTypes = [
  'scheme',
  'paypal',
  /**
   * `googlepay` is recommend over `paywithgoogle` according to Adyen
   * see {@link https://boxine.atlassian.net/wiki/spaces/TSH/pages/3837493336/Adyen+-+payment+method+and+icons+control}
   */
  'googlepay',
  'applepay',
  'ideal',
  /**
   * paymentMethod type for `bancontact card`
   */
  'bcmc',
  /**
   * paymentMethod type for `bancontact mobile` / `payconiq by bancontact`
   */
  'bcmc_mobile',
  'klarna',
  'klarna_account',
  'klarna_paynow',
] as const

/**
 * includes all paymentTypes plus brand specific CC icons.
 * For CC payments the paymentType is always `scheme`
 */
export const paymentIconNames = [
  ...paymentTypes,
  'visa',
  /**
   * paymentMethod type for `mastercard`
   */
  'mc',
  'maestro',
  'cartebancaire',
] as const

export type PaymentIconName = (typeof paymentIconNames)[number]

const internalResultCodeTypes = ['InternalError', 'OriginError'] as const
const resultCodeTypes = [...adyenResultCodeTypes, ...internalResultCodeTypes]

const isBxPaymentResponse = (
  response: PaymentResults
): response is PaymentResults => {
  return (
    response != null &&
    typeof response === 'object' &&
    'resultCode' in response &&
    typeof response.resultCode === 'string' &&
    resultCodeTypes.includes(response.resultCode)
  )
}

const isOriginError = <T>(response: T): response is T => {
  return (
    response != null &&
    typeof response === 'object' &&
    'status' in response &&
    'errorCode' in response &&
    'message' in response &&
    'errorType' in response
  )
}

const isPaymentMethods = (
  paymentMethods: PaymentMethodsResponseObject | undefined
): paymentMethods is PaymentMethodsResponseObject => {
  return (
    typeof paymentMethods === 'object' &&
    paymentMethods != null &&
    'paymentMethods' in paymentMethods &&
    Array.isArray(paymentMethods.paymentMethods)
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getPaymentResult = (response: any): PaymentResults => {
  if (isBxPaymentResponse(response)) {
    const { resultCode, refusalReason, refusalReasonCode } = response
    return {
      resultCode,
      refusalReasonCode,
      refusalReason,
    }
  } else if (isOriginError<OriginError>(response)) {
    // Errors like https://docs.adyen.com/development-resources/error-codes
    const { status, errorCode, message, errorType, pspReference } = response
    return {
      resultCode: 'OriginError' as const,
      refusalReason: message,
      refusalReasonCode: errorCode,
      origin: {
        status,
        errorCode,
        message,
        errorType,
        pspReference,
      },
    }
  } else {
    return {
      resultCode: 'UnknownError' as const,
      refusalReason: 'Something went wrong.',
      refusalReasonCode: '4411',
    }
  }
}

const isPaymentError = (response: unknown) => {
  const { resultCode } = getPaymentResult(response)
  // See Refusal reason https://docs.adyen.com/development-resources/refusal-reasons
  //  and https://docs.adyen.com/online-payments/payment-result-codes
  //  and we also handle responses with 40n status and a errorCode according to our api documentation
  return (
    resultCode === 'Refused' ||
    resultCode === 'Cancelled' ||
    resultCode === 'Error' ||
    resultCode === 'OriginError'
  )
}

const getPaymentErrorMessage = (response: unknown) => {
  const { refusalReason, resultCode } = getPaymentResult(response)
  return `${refusalReason ?? resultCode ?? 'unknown'}`
}

const getPaymentOriginError = (response: unknown) => {
  return getPaymentResult(response)
}

const getMakePaymentResultCode = (p: PaymentResponse) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fields = (p.custom as any)?.fields
  const { makePaymentResponse } = fields
  const { resultCode } = makePaymentResponse
    ? getPaymentResult(JSON.parse(makePaymentResponse))
    : { resultCode: undefined }

  return resultCode
}

const getSubmitAdditionalPaymentDetailsResultCode = (p: PaymentResponse) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fields = (p.custom as any)?.fields
  const { submitAdditionalPaymentDetailsResponse } = fields
  const { resultCode } = submitAdditionalPaymentDetailsResponse
    ? getPaymentResult(JSON.parse(submitAdditionalPaymentDetailsResponse))
    : { resultCode: undefined }

  return resultCode
}

const getFinalResultCode = (p: PaymentResponse) => {
  const submitAdditionalPaymentDetailsResponseResultCode =
    getSubmitAdditionalPaymentDetailsResultCode(p)
  const makePaymentResponseResultCode = getMakePaymentResultCode(p)
  return (
    submitAdditionalPaymentDetailsResponseResultCode ||
    makePaymentResponseResultCode
  )
}

const isRedirectShopper = (p: PaymentResponse) => {
  return getFinalResultCode(p) === 'RedirectShopper'
}

const isChallengeShopper = (p: PaymentResponse) => {
  return getFinalResultCode(p) === 'ChallengeShopper'
}

const isIdentifyShopper = (p: PaymentResponse) => {
  return getFinalResultCode(p) === 'IdentifyShopper'
}

const isAuthorized = (p: PaymentResponse) => {
  return getFinalResultCode(p) === 'Authorised'
}

/** FIXME: shouldn't we rely on `cart.currentPayment` instead? */
const getNewestPaymentId = (cart: NormalizedCart): string | undefined => {
  return cart?.paymentInfo?.payments
    ? cart?.paymentInfo.payments[cart?.paymentInfo.payments.length - 1].id
    : undefined
}

const getCurrentPaymentResponse = (
  cart: NormalizedCart
): PaymentResponse | undefined => {
  const bxCurrentPayment = cart?.currentPayment ?? undefined

  if (cart?.flags.isReadyForOrder || !bxCurrentPayment) {
    return undefined
  }

  // Transform a cart paymentInfo Payment to a BxPaymentResponse
  const { amountPlanned, id, version, custom } = bxCurrentPayment
  return {
    amountPlanned,
    id,
    version,
    custom: {
      typeId: 'payment',
      typeKey: undefined,
      type: custom?.type,
      fields: custom?.fields,
    },
  }
}
const hasPayment = (cart?: NormalizedCart) => {
  if (!cart) {
    return false
  }
  if (cart?.flags.isReadyForOrder) {
    // no payment required
    return false
  }

  // get the current and newest payment IDs
  const currentPayment = getCurrentPaymentResponse(cart)
  const currentPaymentId = currentPayment?.id
  const newestPaymentId = getNewestPaymentId(cart)

  // parse amounts
  const cartCentAmount = cart?.price.total?.centAmount || 0
  const paymentCentAmount = currentPayment?.amountPlanned?.centAmount ?? 0

  // Validate
  // - currentPayment and newestPayment
  // - cent amount of payment and cart
  return Boolean(
    currentPaymentId &&
      newestPaymentId &&
      currentPaymentId === newestPaymentId &&
      cartCentAmount !== 0 &&
      paymentCentAmount !== 0 &&
      cartCentAmount === paymentCentAmount
  )
}

const isAuthorizing = (p: PaymentResponse) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return !!(p.custom as any)?.fields?.makePaymentResponse
}

type ParsePaymentTypeProps = {
  blockedPaymentTypes: PaymentType[]
  fields: BxAdyenCustomFields | undefined
}
const parsePaymentType = ({
  blockedPaymentTypes,
  fields,
}: ParsePaymentTypeProps): PaymentType | undefined => {
  const makePaymentRequest = fields?.makePaymentRequest
    ? JSON.parse(fields?.makePaymentRequest)
    : undefined

  const paymentType = makePaymentRequest?.paymentMethod?.type

  if (paymentType === undefined) return undefined

  if (!blockedPaymentTypes.includes(paymentType)) {
    return paymentType as PaymentType
  }

  return undefined
}
const parseGetPaymentCountryCode = (
  fields: BxAdyenCustomFields | undefined
): PaymentType | undefined => {
  const getPaymentMethodsRequest = fields?.getPaymentMethodsRequest
    ? JSON.parse(fields?.getPaymentMethodsRequest)
    : undefined

  const paymentCountryCode = getPaymentMethodsRequest?.countryCode

  if (paymentCountryCode === undefined) return undefined

  return paymentCountryCode
}

const parseAvailablePaymentMethods = (
  fields: BxAdyenCustomFields | undefined
): PaymentType[] | undefined => {
  const getPaymentMethodsResponse: PaymentMethodsResponseObject =
    fields?.getPaymentMethodsResponse
      ? JSON.parse(fields?.getPaymentMethodsResponse)
      : undefined

  const availablePaymentMethods = getPaymentMethodsResponse?.paymentMethods

  if (availablePaymentMethods === undefined) return undefined

  return availablePaymentMethods.map(pm => pm.type as PaymentType)
}

export {
  isAuthorizing,
  isAuthorized,
  isRedirectShopper,
  isChallengeShopper,
  isIdentifyShopper,
  isPaymentError,
  isPaymentMethods,
  getMakePaymentResultCode,
  getSubmitAdditionalPaymentDetailsResultCode,
  getPaymentErrorMessage,
  getPaymentOriginError,
  getCurrentPaymentResponse,
  hasPayment,
  parsePaymentType,
  parseGetPaymentCountryCode,
  parseAvailablePaymentMethods,
}
