import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from '@/tonies-ui/atoms/Toast'
import { ownAudioContentContext } from './OwnAudioContext'
import { useAuth } from '../auth'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { useShopLocaleConfig } from '../../hooks/useShopLocaleConfig'
import { EcomLocale } from '../../config/shopAPI/types'
import { getGlobalConfig } from '../../lib/shopApi/requests/config/getGlobalConfig'
import { getProducts } from '../../lib/opensearch/requests/products/getProducts'
import { useAssignStatus } from '../../hooks/useAssignStatus'
import { GlobalConfigQueryResult } from '../../lib/shopApi/requests/config/types'
import { useToniesToAssign } from '../../hooks/useToniesToAssign'
import { sentryWithExtras } from '../../hooks/useSentry'

export const OwnAudioContentProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { user, hasBearerToken } = useAuth()
  const { t } = useTranslation()
  const ownTunes = useMemo(() => {
    if (!hasBearerToken) {
      return undefined
    }
    if (
      user &&
      user.household?.ownTunes?.length &&
      user.household.ownTunes.length > 1
    ) {
      // On the dev environment salesIds are often not maintained properly, so we need this workaround.
      return user.household.ownTunes.filter(tune => tune.item.salesId !== null)
    }
    return undefined
  }, [hasBearerToken, user])

  const [localesWithAudioContentToFetch, setLocalesWithAudioContentToFetch] =
    useState<EcomLocale[] | undefined>(undefined)
  const [products, setProducts] = useState<NormalizedProduct[]>([])
  const { isDirectOwnAudioContentAssignmentEnabled } = useFeatureFlags()

  // This is a workaround that is needed because customers can end up in a different shop locale they usually buy in while traveling or after moving.
  // In the described cases the backend would filter out the own products from different locales than the current.
  // To display all own audio-content we fetch every locale in which audio-content is available for now.
  // TODO: Test out if this could be improved using vercel api-routes.
  const currentUserLocale = useShopLocaleConfig({
    live: Boolean(ownTunes && ownTunes.length > 0),
  })
  const [isFetchingOwnTunes, setIsFetchingOwnTunes] = useState(true)
  const [isFetchingGlobals, setIsFetchingGlobals] = useState(true)
  const { isFetchingTonies } = useToniesToAssign()
  const isRegionWithAudioContent = Boolean(currentUserLocale?.showTunes)

  useEffect(() => {
    getGlobalConfig()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        if (response.result === 'successful') {
          setLocalesWithAudioContentToFetch(
            response.data
              .filter(
                (item: GlobalConfigQueryResult['localeConfigs'][number]) =>
                  item.showTunes
              )
              .map(
                (config: GlobalConfigQueryResult['localeConfigs'][number]) =>
                  config.lcCC
              ) as EcomLocale[]
          )
        }

        if (response.result !== 'successful') {
          console.log(
            response.result,
            'Could not fetch global Config, applying fallback'
          )
        }
      })
      .catch(error => console.log(error))
      .finally(() => setIsFetchingGlobals(false))
  }, [])

  const userRelevantEcomLocalesWithAudioContent = useMemo(() => {
    if (currentUserLocale?.showTunes && localesWithAudioContentToFetch) {
      return [
        currentUserLocale.lcCC,
        ...localesWithAudioContentToFetch.filter(
          item => item !== currentUserLocale.lcCC
        ),
      ]
    }
    if (!currentUserLocale?.showTunes && localesWithAudioContentToFetch) {
      return localesWithAudioContentToFetch
    }
    return ['de-DE', 'en-EU']
  }, [
    currentUserLocale?.lcCC,
    currentUserLocale?.showTunes,
    localesWithAudioContentToFetch,
  ])

  useEffect(() => {
    if (
      !hasBearerToken ||
      isFetchingGlobals ||
      !ownTunes ||
      (ownTunes.length && ownTunes.length < 1)
    ) {
      setIsFetchingOwnTunes(false)
      return
    }
    setIsFetchingOwnTunes(true)
    Promise.all(
      userRelevantEcomLocalesWithAudioContent.map(lcCC => {
        return getProducts({
          lcCC: lcCC as EcomLocale,
          categoryKey: ['tunes'],
          salesId: ownTunes.map(tune => tune.item.salesId),
        }).then(response => {
          if (
            response.result === 'successful' &&
            response.data &&
            response.data.products
          ) {
            return response.data.products
          } else {
            return []
          }
        })
      })
    )
      .then(res => {
        setProducts(res.flat())
      })
      .catch(error => {
        sentryWithExtras('OwnAudioContentProvider', error)
        console.log(error)
      })
      .finally(() => {
        setIsFetchingOwnTunes(false)
        if (ownTunes.length > products.length) {
          // We want to track the impact of current end of life products here by sending data to sentry.
          sentryWithExtras(
            'OwnAudioContentProvider',
            new Error(
              'Could not receive all own audio-content. Request seems to include EOL products.'
            )
          )
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetchingGlobals,
    ownTunes,
    userRelevantEcomLocalesWithAudioContent,
    hasBearerToken,
  ])

  const { assignStatus, setAssignStatus, isFetchingAssignStatus } =
    useAssignStatus(
      products,
      isFetchingGlobals || isFetchingOwnTunes || isFetchingTonies
        ? 'resolving'
        : 'done'
    )
  const isAllOwnAudioContentReady =
    hasBearerToken &&
    !isFetchingGlobals &&
    !isFetchingOwnTunes &&
    !isFetchingTonies &&
    !isFetchingAssignStatus

  const updateAssignedContentList = useCallback(
    (sku: string, assignedTonieId: string, assignedTuneTitle: string) => {
      const list = [...assignStatus].filter(
        item =>
          (assignedTonieId === item.assignedTonieId && sku === item.sku) ||
          (item.sku !== sku && item.assignedTonieId !== assignedTonieId)
      )

      setAssignStatus([
        ...list,
        {
          sku: sku,
          assignedTonieId: assignedTonieId,
          assignedTuneTitle: assignedTuneTitle,
        },
      ])
    },
    [assignStatus, setAssignStatus]
  )

  if (
    hasBearerToken &&
    !isFetchingOwnTunes &&
    ownTunes &&
    ownTunes.length > 0 &&
    !products
  ) {
    toast(t('ownAudioContent:TOASTOwnAudioContentErrorMessage'), 'error', {
      toastId: 'Own content collection error',
    })
  }

  return (
    <ownAudioContentContext.Provider
      value={{
        assignStatus: assignStatus,
        setAssignstatus: setAssignStatus,
        updateAssignedContentList: updateAssignedContentList,
        isDirectOwnAudioContentAssignmentEnabled:
          isDirectOwnAudioContentAssignmentEnabled,
        ownAudioContentCollection: products,
        isAllOwnAudioContentReady: isAllOwnAudioContentReady,
        localesWithAudioContentToFetch: localesWithAudioContentToFetch,
        isRegionWithAudioContent: isRegionWithAudioContent,
      }}
    >
      {children}
    </ownAudioContentContext.Provider>
  )
}
