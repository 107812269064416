import { ParsedUrlQuery } from 'querystring'
import { createContext, useContext } from 'react'

export type UrlQueryProps = {
  setUrlParam: (params: ParsedUrlQuery) => void
  getUrlParam: (key: string) => string | string[] | undefined
  getUrlParamAsArray: (key: string) => string[]
  getUrlParamAsSingleton: (key: string) => string | undefined
}

export const defaultProps = {
  setUrlParam: () => Promise.reject(),
  getUrlParam: () => undefined,
  getUrlParamAsArray: () => [],
  getUrlParamAsSingleton: () => undefined,
}

export const UrlQueryContext = createContext<UrlQueryProps>(defaultProps)

export const useUrlQuery = (): UrlQueryProps => useContext(UrlQueryContext)
