/* eslint-disable sonarjs/no-duplicated-branches */

import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { syncCartCookiesWithAuth, State } from '.'
import { useToniesCookies } from '../../hooks/useToniesCookies'
import { useAuth } from '../auth'
import { useLocale } from '../locale'
import { isEcomLocale } from '../../config/shopAPI'

const userIdPattern =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

/**
 * This provider updates the cart cookies when the authentication state changes:
 *
 * - when a guest user logs in, reassign the cart to the authenticated user
 * - when an authenticated user logs out: invalidate the cart
 * - when the user ID changes for any other reason: invalidate the cart
 */
export const SyncCartCookiesWithAuthProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  // Carts are always based on a locale; we need two different spellings for the code below
  const lcCC = useLocale()
  const {
    authenticated,
    isPending,
    uuid: authUserId,
    isTokenPresent,
    hasBearerToken,
  } = useAuth()

  const { setCartId, setCartItemCount, cartUserId, setCartUserId } =
    useToniesCookies()
  const [state, setState] = useState<State>('initializing')

  // invalidate cart id and update user
  useEffect(() => {
    // wait for Keycloak to finish
    if (isPending) {
      return
    }

    // the following is only necessary when we have a EcomLocale
    if (isEcomLocale(lcCC)) {
      if (authenticated && !authUserId) {
        // looks like an invalid state -- does this even occur, and can we handle it any better??
      } else if (
        cartUserId &&
        cartUserId !== 'guest' &&
        !userIdPattern.test(cartUserId)
      ) {
        // invalid cart user ID; clear the cart
        setCartUserId('guest')
        setCartId(undefined)
        setCartItemCount(0)
      } else if (authenticated && authUserId && cartUserId === 'guest') {
        // guest logs in; replace cart user ID with auth user ID
        setCartUserId(authUserId)
        // TODO: sync cart addresses of guest to user after login
      } else if (
        authenticated &&
        cartUserId &&
        authUserId !== cartUserId &&
        cartUserId !== 'guest'
      ) {
        // mismatch between cart user ID and auth user ID; clear cart and update cart user ID to auth user ID
        setCartUserId(authUserId)
        setCartId(undefined)
        setCartItemCount(0)
      }
    }

    setState('finished')
  }, [
    isPending,
    isTokenPresent,
    setCartId,
    setCartUserId,
    setCartItemCount,
    cartUserId,
    authUserId,
    authenticated,
    lcCC,
  ])

  const value = useMemo(
    () => ({
      state,
      isTokenPresent,
      hasBearerToken,
    }),
    [state, isTokenPresent, hasBearerToken]
  )

  return (
    <syncCartCookiesWithAuth.Provider value={value}>
      {children}
    </syncCartCookiesWithAuth.Provider>
  )
}
