import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react'
import { I18nextProvider } from 'react-i18next'
import * as Sentry from '@sentry/nextjs'
import { createI18nConfig } from '@boxine/tonies-ui'
import { useLocale } from '../locale'
import deDE from '../../locales/de.json'
import enAU from '../../locales/en-au.json'
import enEU from '../../locales/en.json'
import enGB from '../../locales/en-gb.json'
import enHK from '../../locales/en-hk.json'
import enNZ from '../../locales/en-nz.json'
import frFR from '../../locales/fr.json'

const { NEXT_PUBLIC_ENVIRONMENT } = process.env

const config = createI18nConfig(
  {
    'de-DE': deDE,
    'en-AU': enAU,
    'en-EU': enEU,
    'en-GB': enGB,
    'en-HK': enHK,
    'en-NZ': enNZ,
    'fr-FR': frFR,
  },
  function onMissingKey(language, namespace, key, fallbackValue) {
    if (NEXT_PUBLIC_ENVIRONMENT === 'production') {
      Sentry.withScope(scope => {
        scope.setTag('feature', 'i18n')

        scope.setExtra('language', language)
        scope.setExtra('namespace', namespace)
        scope.setExtra('key', key)
        scope.setExtra('fallbackValue', fallbackValue)

        scope.setLevel('error')
        scope.setFingerprint(['i18n-failed']) // Group under 'i18n-failed'

        Sentry.captureMessage(`I18n: onMissingKey was triggered for key ${key}`)
      })
    }
  },
  function onMissingInterpolationVariable(key) {
    if (NEXT_PUBLIC_ENVIRONMENT === 'production') {
      Sentry.withScope(scope => {
        scope.setTag('feature', 'i18n')

        scope.setExtra('key', key)

        scope.setLevel('error')
        scope.setFingerprint(['i18n-failed']) // Group under 'i18n-failed'

        Sentry.captureMessage(
          `I18n: onMissingInterpolationVariable was triggered for key ${key}`
        )
      })
    }
  },
  NEXT_PUBLIC_ENVIRONMENT === 'production'
)

export const TranslationsProvider: FunctionComponent<
  unknown & PropsWithChildren
> = ({ children }) => {
  const lcCC = useLocale()

  useMemo(() => {
    // This is code is wrapped in useMemo() because we only want it to
    // run when the locale changes, but synchronously *before* the first
    // render pass. If we wrapped the code in useEffect(), it would not
    // run on the server-side at all, and the most pregenerated pages
    // would have the wrong language.
    config.changeLanguage(lcCC)
  }, [lcCC])

  return <I18nextProvider i18n={config}>{children}</I18nextProvider>
}
