import { useEcomLocale } from '../providers/locale'
import { AddressFormCountry } from '../components/molecules/CheckoutAddressForm/types'
import { useCountryCodes } from '@boxine/tonies-ui'
import { BxEcomLocaleConfig } from '../lib/shopApi/requests/bxTypes'
import { getEcomLocaleConfig } from '../config/shopAPI'

export const useShippingCountries = () => {
  const locale = useEcomLocale()

  const allCountries = useCountryCodes(locale).map(
    // Country codes need to be uppercase here
    ({ label, value }) => ({ label, value: (value as string).toUpperCase() })
  )

  const physicalShippingCountries = getEcomLocaleConfig(
    locale,
    'physicalShippingCountries'
  ) as BxEcomLocaleConfig['commercetools']['physicalShippingCountries']

  return physicalShippingCountries.map(countryCode => ({
    value: countryCode,
    label: allCountries.find(country => country.value === countryCode)?.label,
  })) as AddressFormCountry[]
}
