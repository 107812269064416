/* eslint-disable sonarjs/prefer-immediate-return */
import { BxProduct } from '../requests/bxTypes'
import {
  normalizeTranslation,
  isCommerceToolsTranslation,
} from './normalizeTranslation'
import { Category, TypedMoney } from '@commercetools/platform-sdk'
import {
  NormalizedBundledProductKeys,
  NormalizedProductKeys,
  hasSeriesGroupAndSeriesCategoryKeys,
  isSupportedProductFlagEnum,
} from '../../../types/normalize/product'
import { Currency, EcomLocale } from '../../../config/shopAPI/types'
import { ContentError } from '../../../utils/ContentError'
import { toAltNotation } from '../../../providers/locale/altNotation'
import { lcccRegexPattern } from '../../../providers/locale/NextLocaleProvider'
import { isSlug } from '../../../utils/slugify'
import { currencies } from '../../../config/shopAPI'
import { shopConfig } from '../../../config/shop'
import { newlines2Array } from '../../../utils/textHelpers'

const hasSeriesGroupAndSeries = (p: BxProduct, lcCC: EcomLocale) =>
  hasSeriesGroupAndSeriesCategoryKeys.includes(
    getNormalizedCategories(p, lcCC)[0].key
  )

type CommerceToolsEnum = {
  key: string
  label: string | Record<string, string | number | boolean>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isCommerceToolsEnum = (a: any): a is CommerceToolsEnum =>
  a &&
  typeof a === 'object' &&
  typeof a.key === 'string' &&
  (typeof a.label === 'string' || typeof a.label === 'object')

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isCommerceToolsEnumSet = (a: any): a is CommerceToolsEnum[] =>
  a &&
  typeof a === 'object' &&
  Array.isArray(a) &&
  a.every(b => typeof b.key === 'string') &&
  a.every(b => typeof b.label === 'string' || typeof b.label === 'object')

const getNormalizedEnum = (value: CommerceToolsEnum, lcCC: EcomLocale) => {
  // translate enums
  const { key, label } = value

  // If enum resolves to a translation map, return the translation
  if (isCommerceToolsTranslation(label)) {
    const t = normalizeTranslation(label, lcCC)

    if (t === undefined) {
      return undefined
    }

    return {
      key: value.key,
      label: t,
    }
  }

  if (typeof label !== 'string') {
    return undefined
  }

  return {
    key,
    label,
  }
}

/**
 * Generic helper to resolve the attribute with the given name.
 * Eventual translations are automatically resolved with the locale parameter.
 */
const getAttribute = (
  p: BxProduct,
  name: string,
  lcCC: EcomLocale
):
  | string
  | number
  | boolean
  | NormalizedEnum
  | NormalizedEnumSet
  | undefined => {
  const attributes = p.masterData.current?.masterVariant.attributes
  let value = attributes?.find(a => a.name === name)?.value

  if (isCommerceToolsEnum(value)) {
    return getNormalizedEnum(value, lcCC)
  }

  if (isCommerceToolsEnumSet(value)) {
    return value.map(a => {
      return getNormalizedEnum(a, lcCC)
    }) as NormalizedEnumSet
  }

  if (isCommerceToolsTranslation(value)) {
    value = normalizeTranslation(value, lcCC)
  }

  // TODO: This runtime condition is only to satisfy the type checker. It should be refactored.
  if (typeof value === 'number') {
    return value as number
  } else if (typeof value === 'string') {
    return value as string
  } else if (typeof value === 'boolean') {
    return value as boolean
  }

  return undefined
}

/**
 * Type-safe convenience function to fetch an attribute whose value is known
 * to be a string. If the attribute is undefined, or if it has a different
 * type (including string enums), the function returns `undefined`.
 */
const getStringAttribute = (p: BxProduct, name: string, lcCC: EcomLocale) => {
  const value = getAttribute(p, name, lcCC)
  return typeof value === 'string' ? value : undefined
}

/**
 * Type-safe convenience function to fetch an attribute whose value is known
 * to be a (stringified) DateTime. If the attribute is undefined, or if it
 * has a different type (including enums), or if the date format is unsupported,
 * the function returns `undefined`. The date is returned as a number (milliseconds
 * since 1 Jan 1970) to ease JSON serializations.
 */
const getDateAttribute = (p: BxProduct, name: string, lcCC: EcomLocale) => {
  const value = getStringAttribute(p, name, lcCC)
  return (value && Date.parse(value)) || undefined
}

/**
 * Type-safe convenience function to fetch an attribute whose value is known
 * to be a number. If the attribute is undefined, or if it has a different
 * type (including stringified numbers), the function returns `undefined`.
 */
const getNumberAttribute = (p: BxProduct, name: string, lcCC: EcomLocale) => {
  const value = getAttribute(p, name, lcCC)
  return typeof value === 'number' ? value : undefined
}

/**
 * Type-safe convenience function to fetch an attribute whose value is known
 * to be an enum. If the attribute is undefined, or if it has a different
 * type, the function returns `undefined`.
 */
export const getEnumAttribute = (
  p: BxProduct,
  name: string,
  lcCC: EcomLocale
) => {
  const value = getAttribute(p, name, lcCC)
  return isCommerceToolsEnum(value) ? value : undefined
}

/**
 * Type-safe convenience function to fetch an attribute whose value is known
 * to be an enum set. If the attribute is undefined, or if it has a different
 * type, the function returns `undefined`.
 */
const getEnumSetAttribute = (p: BxProduct, name: string, lcCC: EcomLocale) => {
  const value = getAttribute(p, name, lcCC)
  return isCommerceToolsEnumSet(value) ? value : undefined
}

/**
 * Type-safe convenience function to fetch an attribute whose value is known
 * to be a boolean. If the attribute is undefined, or if it has a different
 * type, the function returns `undefined`.
 */
const getBooleanAttribute = (p: BxProduct, name: string, lcCC: EcomLocale) => {
  const value = getAttribute(p, name, lcCC)
  return typeof value === 'boolean' ? value : undefined
}

export const normalizeCategory = (
  category: Category,
  lcCC: EcomLocale,
  includeAttributes?: boolean
): NormalizedCategory => {
  const key = category.key || ''
  if (!key.trim().length) {
    const err = new ContentError({
      type: 'CommercetoolsCategoryKeyEmpty',
      id: category.id,
      locale: lcCC,
    })
    console.error(err.message)
  }

  const name = normalizeTranslation(category.name, lcCC) || ''
  if (!name.trim().length) {
    const err = new ContentError({
      type: 'CommercetoolsCategoryNameEmpty',
      id: category.id,
      key: key,
      locale: lcCC,
    })
    console.error(err.message)
  }

  const slug = normalizeTranslation(category.slug, lcCC) || ''
  if (!slug.trim().length) {
    const err = new ContentError({
      type: 'CommercetoolsCategorySlugEmpty',
      id: category.id,
      key: key,
      locale: lcCC,
    })
    console.error(err.message)
  } else if (!isSlug(slug)) {
    const err = new ContentError({
      type: 'CommercetoolsCategorySlugInvalid',
      id: category.id,
      key: key,
      slug: slug,
      locale: lcCC,
    })
    console.error(err.message)
  }

  const fields = category.custom?.fields
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let attributes: { name: string; value: any }[] | undefined

  if (fields && includeAttributes) {
    attributes = Object.keys(fields).map(name => {
      let value = fields[name]

      if (isCommerceToolsTranslation(value)) {
        value = normalizeTranslation(value, lcCC)
      }

      return {
        name: name,
        value: value,
      }
    })
  }

  return {
    key,
    name,
    slug,
    parentKey: category.parent?.obj?.key,
    attributes,
  }
}

/**
 * Normalize a product's categories
 */
const getNormalizedCategories = (
  p: BxProduct,
  lcCC: EcomLocale,
  includeAttributes?: boolean
) => {
  const value = p.masterData.current?.categories || []

  if (!value || value.length === 0) {
    const err = new ContentError({
      type: 'CommercetoolsProductCategoriesEmpty',
      id: p.id,
      sku: getSku(p, lcCC),
      locale: lcCC,
    })
    console.error(err.message)
  }

  const normalizedCategories: NormalizedCategory[] = value
    .sort((a, b) => (a.obj.ancestors.length > b.obj.ancestors.length ? 1 : -1))
    .map(category => {
      if (!category.obj) {
        throw new ContentError({
          type: 'CommercetoolsCategoryObjectEmpty',
          id: category.id,
          locale: lcCC,
        })
      }
      return normalizeCategory(category.obj, lcCC, includeAttributes)
    })

  if (
    hasSeriesGroupAndSeriesCategoryKeys.includes(normalizedCategories[0].key) &&
    /**
     * must have 3 categories
     * second category must be a child of "characters"
     * third category must be a child of second category
     */
    (normalizedCategories.length !== 3 ||
      normalizedCategories[1].parentKey !== 'characters' ||
      normalizedCategories[2].parentKey !== normalizedCategories[1].key)
  ) {
    const err = new ContentError({
      type: 'CommercetoolsProductCharacterSeriesCategoriesInvalid',
      id: p.id,
      sku: getSku(p, lcCC),
      locale: lcCC,
    })
    console.error(err.message)
  }

  return normalizedCategories
}

/**
 * Generic helper to resolve the category attribute with the given name.
 * Eventual translations are automatically resolved with the locale parameter.
 *
 * Some category attributes can be maintained on all (nested) category levels,
 * so that e.g. an attribute defined on the first level can be overwritten on
 * the second or third ... level when needed.
 *
 * We therefor need to search in all categories in a reversed order to find
 * the attribute's value that should be returned.
 */
const getCategoryAttribute = (
  p: BxProduct,
  name: string,
  lcCC: EcomLocale
): string | boolean | undefined => {
  const categories = getNormalizedCategories(p, lcCC, true).reverse()
  for (let i = 0; i < categories.length; i++) {
    const value = categories[i].attributes?.find(a => a.name === name)?.value
    if (value) {
      return value
    }
  }
  return undefined
}

const getCategoryStringAttribute = (
  p: BxProduct,
  name: string,
  lcCC: EcomLocale
) => {
  const value = getCategoryAttribute(p, name, lcCC)
  return typeof value === 'string' ? value : undefined
}

const getCategoryBooleanAttribute = (
  p: BxProduct,
  name: string,
  lcCC: EcomLocale
) => {
  const value = getCategoryAttribute(p, name, lcCC)
  return typeof value === 'boolean' ? value : undefined
}

/**
 * This function finds the MOST SPECIFIC shop category of the given product
 * by using the last category of all normalizedCategories.
 *
 * Example: a Carrier product gets `carrier`, not `accessories`.
 *
 * Exception: Tonies, Audio Content, Clever Tonies and Repairs have additional categories assignded,
 * but their shopCategory should always be the `root` category.
 */
const getShopCategory = (p: BxProduct, lcCC: EcomLocale) => {
  const normalizedCategories = getNormalizedCategories(p, lcCC)

  if (
    ['tonies', 'tunes', 'clever-tonies', 'repairs'].includes(
      normalizedCategories[0].key
    )
  ) {
    return normalizedCategories[0].key
  }

  return normalizedCategories[normalizedCategories.length - 1].key
}

const getImages = (p: BxProduct, lcCC: EcomLocale): NormalizedImage[] => {
  let value = p.masterData.current?.masterVariant.images

  // add a fallback image if we do not get images from the API
  if (!value || value.length === 0) {
    value = [
      {
        url: shopConfig.assets.product,
        label: 'missing image',
        dimensions: {
          w: 1200,
          h: 900,
        },
      },
    ]

    const err = new ContentError({
      type: 'CommercetoolsProductImagesEmpty',
      id: p.id,
      sku: getSku(p, lcCC),
      locale: lcCC,
    })
    console.error(err.message)
  }

  const images = value.map(({ url }) => ({
    src: url,
    alt: getName(p, lcCC),
  }))

  /**
   * For bundles, we create a combined set of images:
   * 1. All wrapper product images
   * 2. One image of every bundled product
   */
  const bundledProducts = getBundledProducts(p, lcCC)
  if (bundledProducts) {
    bundledProducts.forEach(p => {
      images.push({
        src: p.image.src,
        alt: p.name,
      })
    })
  }

  return images
}

/**
 * For Tonies we want the second image to be displayed on cards and lineitems.
 * All other products should show the first image.
 */
const getImage = (p: BxProduct, lcCC: EcomLocale): NormalizedImage => {
  const value = getImages(p, lcCC)
  const shopCategory = getShopCategory(p, lcCC)
  return value.length === 1
    ? value[0]
    : shopCategory === 'tonies'
    ? value[1]
    : value[0]
}

export const getSeries = (
  p: BxProduct,
  lcCC: EcomLocale
): NormalizedEnum | undefined => {
  if (hasSeriesGroupAndSeries(p, lcCC)) {
    const value = getNormalizedCategories(p, lcCC)[2]
    return {
      key: value.slug,
      label: value.name,
    }
  }

  return undefined
}

const getSeriesGroup = (
  p: BxProduct,
  lcCC: EcomLocale
): NormalizedEnum | undefined => {
  if (hasSeriesGroupAndSeries(p, lcCC)) {
    const value = getNormalizedCategories(p, lcCC)[1]
    return {
      key: value.slug,
      label: value.name,
    }
  }

  return undefined
}

const getSubName = (p: BxProduct, lcCC: EcomLocale) => {
  if (hasSeriesGroupAndSeries(p, lcCC)) {
    return getSeriesGroup(p, lcCC)?.label || ''
  }

  const normalizedCategories = getNormalizedCategories(p, lcCC)
  if (
    normalizedCategories[0].key === 'accessories' &&
    normalizedCategories.length > 1
  ) {
    return normalizedCategories[1].name
  }
  return normalizedCategories[0].name
}

const getColor = (p: BxProduct, lcCC: EcomLocale) => {
  let value = getStringAttribute(p, 'colorcode', lcCC)

  // RGB color code validation
  if (typeof value === 'string') {
    // Trim whitespace, convert uppercase characters, remove hashes
    value = value.trim().toLowerCase().replace('#', '')

    // Check if remaining color code matches HTML RGB notation
    if (value.match(/^[0-9a-f]{3}([0-9a-f]{3})?$/)) {
      // Add hash prefix
      return '#' + value
    }
  }

  return undefined
}

const getAgeMin = (p: BxProduct, lcCC: EcomLocale) => {
  const value = getNumberAttribute(p, 'age_min', lcCC)

  if (typeof value === 'number' && value > 0) {
    return value
  }

  return undefined
}

const getSku = (p: BxProduct, lcCC: EcomLocale) => {
  const value = p.masterData.current?.masterVariant.sku || ''

  if (!value.trim().length) {
    const err = new ContentError({
      type: 'CommercetoolsProductSkuEmpty',
      id: p.id,
      locale: lcCC,
    })
    console.error(err.message)
  }

  if (!value.match(/^[0-9a-z-_]+$/i)) {
    const err = new ContentError({
      type: 'CommercetoolsProductSkuInvalid',
      id: p.id,
      sku: value,
      locale: lcCC,
    })
    console.error(err.message)
  }

  return value
}

export const getAvailability = (
  p: BxProduct,
  lcCC: EcomLocale
): NormalizedAvailability => {
  // The following checks implement the ruleset defined in TWAS-2169.
  // Since they are consecutive, please DO NOT REORDER!

  const now = new Date().getTime()

  const orderFromDate = getDateAttribute(p, 'orderFrom', lcCC)

  if (typeof orderFromDate === 'number' && orderFromDate > now) {
    return {
      state: 'announced',
      orderFromDate,
    }
  }

  const shippingFromDate = getDateAttribute(p, 'shippingFrom', lcCC)

  if (typeof shippingFromDate === 'number' && shippingFromDate > now) {
    return {
      state: 'preorderable',
      shippingFromDate,
    }
  }

  const hasStock = p.masterData.current?.masterVariant.bxVariantIsAvailable

  if (!hasStock) {
    return {
      state: 'sold-out',
    }
  }

  return {
    state: 'orderable',
  }
}

const getName = (p: BxProduct, lcCC: EcomLocale) => {
  const value = normalizeTranslation(p.masterData.current?.name, lcCC) || ''

  if (!value.trim().length) {
    const err = new ContentError({
      type: 'CommercetoolsProductNameEmpty',
      id: p.id,
      sku: getSku(p, lcCC),
      locale: lcCC,
    })
    console.error(err.message)
  }

  // Prepend a "[LC]" for Tonies where the language differs from the shop language.
  // We are only interested in the language part (lc) for this and not in the country (CC).
  const shopLc = toAltNotation(lcCC, 'lc')
  // Fallback here to the shop's lcCC, because only Tonies have the language attribute
  const productLc = toAltNotation(getLcCC(p, lcCC) || lcCC, 'lc')
  if (shopLc !== productLc) {
    return `[${productLc.toUpperCase()}] ${value}`
  }

  return value
}

const getSlug = (p: BxProduct, lcCC: EcomLocale) => {
  const value = normalizeTranslation(p.masterData.current?.slug, lcCC) || ''

  if (!value.trim().length) {
    const err = new ContentError({
      type: 'CommercetoolsProductSlugEmpty',
      id: p.id,
      sku: getSku(p, lcCC),
      locale: lcCC,
    })
    console.error(err.message)
  } else if (!isSlug(value)) {
    const err = new ContentError({
      type: 'CommercetoolsProductSlugInvalid',
      id: p.id,
      sku: getSku(p, lcCC),
      slug: value,
      locale: lcCC,
    })
    console.error(err.message)
  }

  return value
}

export const getPath = (p: BxProduct, lcCC: EcomLocale) => {
  // A small patch to fix a BE problem: empty path segments result in multiple slashes, which next.js won't accept
  const value = p.bxProductUrl?.replace(/\/+/g, '/') || ''

  if (!value.trim().length) {
    const err = new ContentError({
      type: 'CommercetoolsProductPathEmpty',
      id: p.id,
      sku: getSku(p, lcCC),
      locale: lcCC,
    })
    console.error(err.message)
  }

  return value
}

const getNormalizedPrice = (price: TypedMoney) => {
  if (price.type !== 'centPrecision') {
    return undefined
  }

  const currency = price.currencyCode as Currency

  if (!currencies.includes(currency)) {
    return undefined
  }

  const result: NormalizedAmount = {
    amount: price.centAmount / Math.pow(10, price.fractionDigits),
    centAmount: price.centAmount,
    currency,
  }

  return result
}

const getPrice = (p: BxProduct) => {
  const value = p.masterData.current?.masterVariant.price?.bxValue
  return value ? getNormalizedPrice(value) : undefined
}

const getStrikePrice = (p: BxProduct) => {
  const value = p.masterData.current?.masterVariant.price?.bxStrikeValue
  return value ? getNormalizedPrice(value) : undefined
}

export const getDescription = (p: BxProduct, lcCC: EcomLocale) =>
  normalizeTranslation(p.masterData.current?.description, lcCC)

const getSalesId = (p: BxProduct, lcCC: EcomLocale) => {
  const value = getStringAttribute(p, 'Artikelnummer', lcCC) || ''

  if (!value.trim().length) {
    const err = new ContentError({
      type: 'CommercetoolsProductSalesIdEmpty',
      id: p.id,
      sku: getSku(p, lcCC),
      locale: lcCC,
    })
    console.error(err.message)
  }

  return value
}

const getTracks = (p: BxProduct, lcCC: EcomLocale) => {
  const value = getStringAttribute(p, 'tracks', lcCC)

  if (typeof value !== 'string') {
    return undefined
  }

  return newlines2Array(value)
}

const getAudioLibraryAssignUrl = (p: BxProduct) =>
  p.masterData.current?.masterVariant.bxAudioLibraryAssignUrl

const getAudioLibraryId = (p: BxProduct) => {
  const assignUrl = getAudioLibraryAssignUrl(p)

  // @FIXME: get cloudservices uuid/product key from BE
  // or use SaledId instead
  if (assignUrl) {
    const url = new URL(assignUrl)
    const pathnames = url.pathname.split('/')

    return pathnames[pathnames.length - 2]
  }

  return undefined
}

const getMaxQuantity = (p: BxProduct, lcCC: EcomLocale) => {
  // TODO: remove after it is maintained in commercetools
  // only temporary work-around
  if (['tunes'].includes(getShopCategory(p, lcCC))) {
    return 1
  }

  return getNumberAttribute(p, 'max_quantity', lcCC)
}

const getGenre = (p: BxProduct, lcCC: EcomLocale) => {
  //TODO: check if we need clever tonies logic here
  if (getShopCategory(p, lcCC) !== 'tonies') {
    return undefined
  }

  return getEnumAttribute(p, 'subcategory', lcCC)
}

const getEcoTax = (
  p: BxProduct,
  lcCC: EcomLocale
): NormalizedAmount | undefined => {
  if (lcCC !== 'fr-FR') {
    // Applies only to France
    return undefined
  }

  const categories = getNormalizedCategories(p, lcCC)

  for (const category of categories) {
    if (category.key === 'headphones') {
      return {
        currency: 'EUR',
        centAmount: 2,
        amount: 0.02,
      }
    }

    if (category.key === 'tonieboxes') {
      return {
        currency: 'EUR',
        centAmount: 13,
        amount: 0.13,
      }
    }
  }

  const salesId = getSalesId(p, lcCC)

  if (salesId === '10002269') {
    return {
      currency: 'EUR',
      centAmount: 7,
      amount: 0.07,
    }
  }

  return undefined
}

const getSoldLastMonth = (p: BxProduct, lcCC: EcomLocale) => {
  const value = getStringAttribute(p, 'soldLastMonth', lcCC)
  return value && typeof parseInt(value) === 'number'
    ? parseInt(value)
    : undefined
}

const getCommonAttributes = (p: BxProduct, lcCC: EcomLocale) => {
  /**
   * The common attributes (aka "blue box") are set on a category level for all
   * products of that category, but can be overwritten on a product level, e.g.
   * all Tonies are "plastic" and "hand-painted" except for Steiff Tonies.
   */
  const value = getStringAttribute(p, 'productsCommonAttributes', lcCC)
  if (value && value.trim().length) {
    return value
  }

  return getCategoryStringAttribute(p, 'productsCommonAttributes', lcCC)
}

const getHasChokingHazardWarning = (p: BxProduct, lcCC: EcomLocale) => {
  /**
   * The choking hazard warning is set on a category level for all products
   * of that category, but can be overwritten on a product level, e.g.
   * all Tonies should have this warning except for Steiff Tonies.
   */
  const value = getBooleanAttribute(p, 'hasChokingHazardWarning', lcCC)
  if (value !== undefined) {
    return value
  }

  return getCategoryBooleanAttribute(p, 'hasChokingHazardWarning', lcCC)
}

const getFlag = (p: BxProduct, lcCC: EcomLocale) => {
  const value = getEnumAttribute(p, 'flag', lcCC)

  if (isSupportedProductFlagEnum(value)) {
    return value
  }

  return undefined
}

export const getLcCC = (p: BxProduct, lcCC: EcomLocale) => {
  // "language" is lc-cc in commercetools for compatibility with the Octonie.
  const lccc = getEnumAttribute(p, 'language', lcCC)

  // We convert this to a Locale here for better compatibility with our code.
  if (lccc) {
    if (!lccc.key.match(new RegExp(lcccRegexPattern))) {
      console.error(`Invalid language '${lccc.key}' for product ${p.id}`)
      return undefined
    }

    const [lc, cc] = lccc.key.split('-')
    return (lc + '-' + cc.toUpperCase()) as EcomLocale
  }
}

/**
 * TODO GTMV4
 * Brand could become important later for GA4, e.g. for products like
 * Affenzahn, Steiff, Sterntaler, ...
 * This is some prework for this. For now, we only return 'Tonies' here.
 */
const getBrand = () => {
  return 'Tonies'
}

const getSearchKeywords = (p: BxProduct, lcCC: EcomLocale) => {
  const value = p.masterData.current?.searchKeywords

  if (typeof value === 'object') {
    // commercetools UK is currently "lc", will later be migrated to "lc-CC"
    const lc = toAltNotation(lcCC, 'lc')

    const searchKeywordsArray = value[lcCC] || value[lc]

    if (searchKeywordsArray && searchKeywordsArray.length > 0) {
      return searchKeywordsArray
        .map(searchKeyword => {
          return searchKeyword.text
        })
        .join(' ')
    }
  }

  return undefined
}

/**
 * Retrieves the runtime of a product.
 * @param p - The BxProduct object.
 * @param lcCC - The EcomLocale object.
 * @returns The runtime of the product.
 */
const getRuntime = (p: BxProduct, lcCC: EcomLocale) => {
  const value = getNumberAttribute(p, 'run_time', lcCC)
  const shopCategory = getShopCategory(p, lcCC)

  // FIXME: This is a temporary workaround until the BE provides the correct values for tonies and clever-tonies which are in minutes instead of seconds.
  // Also see: lib/opensearch/normalizers/normalizeProduct.ts
  // See ticket: https://boxine.atlassian.net/browse/TWAS-5566
  // See conversation: https://boxine.slack.com/archives/C04JZPYKHKR/p1715155431872469
  if (value && shopCategory === 'tunes') {
    return Math.round(value / 60)
  }

  return value
}

const getBundledProducts = (
  p: BxProduct,
  lcCC: EcomLocale,
  variant?: 'extended'
) => {
  const value =
    p.masterData.current?.masterVariant.bxAttributes?.bundled_products

  if (value && value.length > 0) {
    return value.map(bundledProduct =>
      variant === 'extended'
        ? normalizeProductExtended(bundledProduct.data, lcCC)
        : normalizeBundledProduct(bundledProduct.data, lcCC)
    )
  }

  return undefined
}

// Tunes only attribute
const getAuthors = (p: BxProduct, lcCC: EcomLocale) => {
  const value = getStringAttribute(p, 'authors', lcCC)

  if (typeof value !== 'string') {
    return undefined
  }

  return newlines2Array(value)
}

/**
 * Converts a CommerceTools ProductsApiProduct to a leaner, type-safe version
 * that contains only the attributes that are actually needed by the shop.
 */
export const normalizeProductExtended = (
  p: BxProduct,
  lcCC: EcomLocale
): NormalizedProductExtended => ({
  ageMin: getAgeMin(p, lcCC),
  assignableToAllCreativeTonies: getBooleanAttribute(
    p,
    'assignableToAllCreativeTonies',
    lcCC
  ),
  audioLibraryUrl: getStringAttribute(p, 'tuneSpaUrl', lcCC),
  audioLibraryAssignUrl: getAudioLibraryAssignUrl(p),
  audioLibraryId: getAudioLibraryId(p),
  audioSampleUrl: getStringAttribute(p, 'audio_sample', lcCC),
  authors: getAuthors(p, lcCC),
  availability: getAvailability(p, lcCC),
  brand: getBrand(),
  bundleIdentifier: getStringAttribute(p, 'bundle_identifier', lcCC),
  bundledProductsExtended: getBundledProducts(
    p,
    lcCC,
    'extended'
  ) as NormalizedProductExtended[],
  bundledProducts: getBundledProducts(p, lcCC) as NormalizedBundledProduct[],
  color: getColor(p, lcCC),
  colors: getStringAttribute(p, 'colors', lcCC),
  commonAttributes: getCommonAttributes(p, lcCC),
  copyrights: getStringAttribute(p, 'copyrights', lcCC),
  description: getDescription(p, lcCC),
  dimensions: getStringAttribute(p, 'dimensions', lcCC),
  ecoTax: getEcoTax(p, lcCC),
  flag: getFlag(p, lcCC),
  genre: getGenre(p, lcCC),
  gtin: getStringAttribute(p, 'gtin', lcCC),
  hasChokingHazardWarning: getHasChokingHazardWarning(p, lcCC),
  id: p.id,
  image: getImage(p, lcCC),
  images: getImages(p, lcCC),
  lcCC: getLcCC(p, lcCC),
  metaDescription: normalizeTranslation(
    p.masterData.current?.metaDescription,
    lcCC
  ),
  metaTitle: normalizeTranslation(p.masterData.current?.metaTitle, lcCC),
  maxQuantity: getMaxQuantity(p, lcCC),
  name: getName(p, lcCC),
  normalizedCategories: getNormalizedCategories(p, lcCC),
  packageContents: getStringAttribute(p, 'packageContents', lcCC),
  path: getPath(p, lcCC),
  price: getPrice(p),
  production: getStringAttribute(p, 'production', lcCC),
  publicationDate: getDateAttribute(p, 'publication_date', lcCC),
  readers: getStringAttribute(p, 'Sprecher', lcCC),
  runTime: getRuntime(p, lcCC),
  salesId: getSalesId(p, lcCC),
  searchKeywords: getSearchKeywords(p, lcCC),
  series: getSeries(p, lcCC),
  seriesGroup: getSeriesGroup(p, lcCC),
  shopCategory: getShopCategory(p, lcCC),
  sku: getSku(p, lcCC),
  slug: getSlug(p, lcCC),
  soldLastMonth: getSoldLastMonth(p, lcCC),
  strikePrice: getStrikePrice(p),
  subName: getSubName(p, lcCC),
  theme: getEnumSetAttribute(p, 'theme', lcCC),
  tracks: getTracks(p, lcCC),
  variantLabel: getCategoryStringAttribute(p, 'variantsLabel', lcCC),
  variantSelectValue: getStringAttribute(p, 'variantLabel', lcCC),
  variantType: getCategoryStringAttribute(p, 'variantsType', lcCC),
  weight: getStringAttribute(p, 'weight', lcCC),
})

const reduceObject = <
  ExtendedType extends object,
  ReduceKeysArray extends readonly (keyof ExtendedType)[]
>(
  obj: ExtendedType,
  keys: ReduceKeysArray
): Pick<ExtendedType, ReduceKeysArray[number]> =>
  Object.entries(obj).reduce((prev, curr): ExtendedType => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [key] = curr as [keyof ExtendedType, any]

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!keys.includes(key as any)) {
      delete prev[key]
    }

    return prev
  }, obj)

export const normalizeProduct = (
  p: Parameters<typeof normalizeProductExtended>[0],
  lcCC: Parameters<typeof normalizeProductExtended>[1]
) => reduceObject(normalizeProductExtended(p, lcCC), NormalizedProductKeys)

export const normalizeBundledProduct = (
  p: Parameters<typeof normalizeProductExtended>[0],
  lcCC: Parameters<typeof normalizeProductExtended>[1]
) =>
  reduceObject(normalizeProductExtended(p, lcCC), NormalizedBundledProductKeys)
