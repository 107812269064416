import { useEffect, useState } from 'react'
import { AssignStatusProps } from '../layouts/AccountLayout/components/View'
import { getTunesItemBySalesId } from '../lib/cloudservices/requests/graphql/getHouseholdData'
import { useEcomLocale } from '../providers/locale'
import { useFeatureFlags } from './useFeatureFlags'
import { toast } from '@/tonies-ui/atoms/Toast'
import { sentryWithExtras } from './useSentry'
import { useAuth } from '../providers/auth'

export function useAssignStatus(products: NormalizedProduct[], state: string) {
  const { hasBearerToken } = useAuth()
  const [assignStatus, setAssignStatus] = useState<AssignStatusProps>([])
  const [isFetchingAssignStatus, setIsFetchingAssignStatus] = useState(true)
  const lcCC = useEcomLocale()
  const { isDirectOwnAudioContentAssignmentEnabled } = useFeatureFlags()

  useEffect(() => {
    if (
      !hasBearerToken ||
      !isDirectOwnAudioContentAssignmentEnabled ||
      state !== 'done' ||
      products.length < 1
    ) {
      setIsFetchingAssignStatus(false)
      return
    }
    setIsFetchingAssignStatus(true)
    const list: {
      sku: string
      assignedTonieId: string
      assignedTuneTitle: string
    }[] = []
    Promise.all(
      products.map(tune => {
        setIsFetchingAssignStatus(true)
        return getTunesItemBySalesId(lcCC, tune.sku).then(response => {
          if (response.result === 'successful' && response.data) {
            const node =
              response.result === 'successful' &&
              response.data.data.tunesItems.edges[0]?.node
            if (
              node &&
              node.myTune?.assignedTonies &&
              node.myTune.assignedTonies.length &&
              node.myTune.assignedTonies.length > 0 &&
              tune.sku
            ) {
              list.push({
                sku: tune.sku,
                assignedTonieId: node.myTune.assignedTonies[0].id,
                assignedTuneTitle: tune.name,
              })
            }
          }
        })
      })
    )
      .catch(error => {
        toast('default:TOASTSomethingWentWrong', 'error', {
          toastId: 'useAssignStatus_Error',
        })
        sentryWithExtras('useAssignStatus', error)
        console.log(error)
      })
      .finally(() => {
        if (products && state === 'done') {
          setAssignStatus(list)
        }
        setIsFetchingAssignStatus(false)
      })
  }, [
    state,
    products,
    setAssignStatus,
    isDirectOwnAudioContentAssignmentEnabled,
    lcCC,
    hasBearerToken,
  ])

  return { assignStatus, setAssignStatus, isFetchingAssignStatus }
}
