import React, { PropsWithChildren, ReactNode, useEffect } from 'react'
import { FocusOn } from 'react-focus-on'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as icons from '@boxine/tonies-ui/icons'
import {
  FocusStyles,
  Headline3,
  Icon,
  Spacing,
  media,
  useKeyPress,
} from '@boxine/tonies-ui'
import { ColorContextProvider } from '@/tonies-ui/index'
import { AnimatePresence, motion } from 'framer-motion'
import { ToniesMotions } from '@/tonies-ui/motions'
import { toniesSpringTransition } from '@/tonies-ui/motions/constants'
import { Accent } from '@/tonies-ui/atoms/Accent'

/*
 * Implemented following the WAI-Aria Practices 1.1 (https://www.w3.org/TR/wai-aria-practices-1.1/#dialog_modal)
 *
 * react-focus-on handles all the tough parts:
 * - making anything outside of the modal inert (not scrollable, not clickable, not tabbable, not readable for screen readers etc.)
 * - auto-focusing the first element
 * - keeping the focus within the modal
 * - closing the modal when clicking on the outside
 * - restoring focus after closing the modal
 *
 * Closing the modal when Escape is pressed is implemented manually.
 */

const SPACE_TO_VIEWPORT = '2rem'
const SPACING_LARGE = '2rem'
const SPACING_SMALL = '1rem'
const SPACING_AROUND_MODAL = '1rem'
const SIZE_CLOSE_ICON_IN_PIXELS = 24
const CLOSE_ICON_SIZE = `${SIZE_CLOSE_ICON_IN_PIXELS / 16}rem`
/* There can only be 1 Modal at the same time --> ID can be constant */
const HEADLINE_ID = 'modal-headline-id'

const ModalBackground = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);

  /* Stretch to fit entire screen */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  /* Center children horizontally and vertically */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  /* Make sure it's above any content (including Zendesk and HotJar). */
  z-index: ${({ theme }) => theme.zIndex.modal};
`

const ModalContent = styled.div`
  /* Grid for children */
  display: grid;
  height: 100%; /* Use entire height available (allow children to be placed anywhere inside of the modal) */
  grid-template-columns: 1fr ${CLOSE_ICON_SIZE};
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header close-button'
    'body body'
    'footer footer';
`

const ModalContentWrapper = styled.div`
  padding: ${SPACING_SMALL};

  ${media.tablet`
    padding: ${SPACING_LARGE};
  `}
`

const StyledFocusOn = styled(FocusOn)<{ $size: ModalProps['size'] }>`
  /* Margin/Border-radius is only shown if modal is not full width/full height */
  border-radius: 4px;
  height: 100%;
  margin: ${SPACING_AROUND_MODAL};

  ${media.mobileM`
    height: auto; /* Limit height for large screens */
    margin: ${SPACE_TO_VIEWPORT};
  `}

  background-color: #fff;
  max-width: ${props => (props.$size === 'large' ? '760' : '640')}px;
  width: calc(
    100vw - 2 * ${SPACING_AROUND_MODAL}
  ); /* Avoid adapting to the width of the children */

  /* Scrollbar handling */
  overflow-y: auto;
  overflow-x: hidden; /* Avoid showing scrollbar when hovering close icon */
`

const ModalBody = styled.div`
  grid-area: body;
`

const ModalHeadline = styled(Headline3)`
  font-weight: 500;
  align-self: center; /* Center vertically inside of Grid cell */
  grid-area: header;
  margin: 0 0 ${SPACING_SMALL};

  ${media.tablet`
    margin: 0 0 ${SPACING_LARGE};
  `}
`

const ModalFooter = styled.div`
  grid-area: footer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: ${SPACING_SMALL} 0 0;

  button {
    margin: 0 0 1rem;
  }

  button:last-child {
    margin: 0;
  }

  ${media.tablet`
    flex-direction: row;

    button {
      margin: 0 1rem 0 0;
    }

    button:last-child {
      margin: 0;
    }
  `}
`

const CloseButton = styled(motion.button)`
  /* Positioning */
  grid-area: close-button;
  /* grid-column: close-icon-start / close-icon-end;
  grid-row: header-start / header-end; */
  place-self: start center;

  /* Overwrite default styles */
  background: none;
  border: none;
  height: ${CLOSE_ICON_SIZE}; /* Limit height to the exact icon size */
  padding: 0;

  cursor: pointer;

  /* Margin if header is not existing */
  margin-bottom: ${SPACING_SMALL};
  color: ${props => props.theme.colors.darkergrey};

  &:focus,
  &:hover {
    transform: rotate(-180deg); /* Flip to the left */
  }

  ${FocusStyles}
`

/**
 * Displays a dialog above the page. Allows to display information or prompt the user for input without changing the layout of the underlying page.
 */
export const Modal = ({
  ariaDescribedById = '',
  children,
  footer,
  headline,
  isOpen,
  onClose,
  role = 'dialog',
  size = 'normal',
  shouldFocusFirstElement,
  hideCloseButton,
  'data-trackingid': dataTrackingId,
  'data-testid': dataTestId,
}: PropsWithChildren<ModalProps>) => {
  const { t } = useTranslation('Modal')
  const [keyPressed, onKeyPressHandled] = useKeyPress('Escape')

  useEffect(() => {
    if (keyPressed && isOpen) {
      onKeyPressHandled()
      onClose()
    }
  }, [isOpen, keyPressed, onClose, onKeyPressHandled])

  return (
    <ColorContextProvider backgroundColor="#fff">
      <AnimatePresence>
        {isOpen && (
          <ModalBackground
            data-testid="modal-background"
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, delayChildren: 0.3 }}
          >
            <ToniesMotions.PopIt>
              {/* eslint-disable jsx-a11y/no-autofocus */}
              <StyledFocusOn
                autoFocus={shouldFocusFirstElement}
                onClickOutside={onClose}
                returnFocus={true}
                $size={size}
              >
                {/* eslint-enable jsx-a11y/no-autofocus */}
                <ModalContentWrapper data-testid={dataTestId}>
                  {/* React-Focus-On doesn't allow to set arbitrary prop items, therefore this wrapper is required */}
                  <ModalContent
                    aria-describedby={ariaDescribedById}
                    aria-labelledby={HEADLINE_ID}
                    aria-modal={true}
                    data-testid="modal-content"
                    role={role}
                  >
                    {headline && (
                      <ModalHeadline
                        asHTMLTag="h1"
                        data-testid="modal-headline"
                        id={HEADLINE_ID}
                      >
                        {typeof headline === 'string' ? (
                          <Accent text={headline} />
                        ) : (
                          headline
                        )}
                      </ModalHeadline>
                    )}

                    {!hideCloseButton && (
                      <CloseButton
                        aria-label={t('Modal:CloseModal')}
                        data-testid="modal-close-button"
                        onClick={onClose}
                        type="button"
                        data-trackingid={dataTrackingId}
                        whileHover={{ rotate: [0, -90], scale: 1.25 }}
                        transition={toniesSpringTransition}
                      >
                        <Icon
                          height={SIZE_CLOSE_ICON_IN_PIXELS}
                          type={icons.closeX}
                          width={SIZE_CLOSE_ICON_IN_PIXELS}
                        />
                      </CloseButton>
                    )}

                    <ModalBody data-testid="modal-body">{children}</ModalBody>

                    {footer && (
                      <ModalFooter data-testid="modal-footer">
                        <Spacing mt="spacing-s">{footer}</Spacing>
                      </ModalFooter>
                    )}
                  </ModalContent>
                </ModalContentWrapper>
              </StyledFocusOn>
            </ToniesMotions.PopIt>
          </ModalBackground>
        )}
      </AnimatePresence>
    </ColorContextProvider>
  )
}

type ModalProps = {
  /**
   * ID reference to the element describing the primary purpose of this modal.
   */
  ariaDescribedById?: string

  /**
   * Tracking id for close event used by google tag manager
   */
  'data-trackingid'?: string

  /**
   * Headline text of the modal.
   */
  headline?: ReactNode

  /**
   * Footer of the modal
   */
  footer?: ReactNode

  /**
   * Is the modal open? Should be controlled from the outside (Controlled Component pattern).
   */
  isOpen?: boolean

  /**
   * Custom maximum width in (normal (640px) | large (760px))
   */
  size?: 'normal' | 'large'

  /**
   * When the modal is closed, this callback will be triggered. You should update isOpen correspondingly, otherwise the modal won't close correctly.
   */
  onClose: () => void

  /**
   * Role of the modal. In most cases, 'dialog' is the correct one. Use 'alertdialog' only when something urgent demands the user's immediate attention. In this case, make sure to include at least a Confirmation or Cancel button and enable focusing the first focusable element.
   */
  role?: 'dialog' | 'alertdialog'

  /**
   * Allows to focus the first focusable element in this modal when it opens. Important for keyboard users.
   */
  shouldFocusFirstElement?: boolean

  /**
   * Allows to optionally hide the CloseButton
   */
  hideCloseButton?: boolean

  /**
   * Will be attached to the rendered ModalContentWrapper
   * */
  'data-testid'?: string
}
