import { makeSerializable } from '../../../../utils/makeSerializable'
import { getSorting } from '../../util/sorting'

export const osManipulation = ({
  limit = 24,
  offset = 0,
  ...params
}: Omit<NormalizedOpenSearchParameters, 'sort'> & {
  sort?: ReturnType<typeof getSorting>
}) => {
  const newParams = makeSerializable({
    ...params,
    limit,
    offset,
    sort: getSorting(params),
  })

  const handleProductsManipulation = (products: NormalizedProduct[]) => {
    // SKU List with fixed sorting?
    if (params.sort?.isFixedSkuList) {
      // Sort products as provided in the SKU List
      products.sort((p1, p2) => {
        const initialSku = params.sku || []
        const index1 = initialSku.indexOf(p1.sku)
        const index2 = initialSku.indexOf(p2.sku)
        return index1 - index2
      })

      // Sort sold out products to the end?
      if (params.sort?.sortOutOfStockToTheEnd) {
        products.sort((p1, p2) => {
          const soldOut1 = p1.availability.state === 'sold-out' ? 1 : 0
          const soldOut2 = p2.availability.state === 'sold-out' ? 1 : 0
          return soldOut1 - soldOut2
        })
      }

      // Slice products in case of pagination
      if (limit || offset) {
        products = products.slice(offset, offset + limit)
      }
    }
    return products
  }

  return { newParams, handleProductsManipulation }
}
