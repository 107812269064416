import { makeSerializable } from '../../../utils/makeSerializable'
import { ShopProductCategory } from '../../../config/shopAPI/types'
import {
  SupportedSortingIds,
  isSupportedSortingId,
} from '../requests/products/types'

const getSortingById = (id: string) => {
  if (!isSupportedSortingId(id)) {
    throw new Error(`"${id}" is no supported SortingId`)
  }

  const [key, order] = id?.split('-') as [string, 'asc' | 'desc']

  return { key, order, id }
}

const getSortingIdByCategory = (
  shopCategory: ShopProductCategory | undefined
): SupportedSortingIds => {
  return shopCategory &&
    [
      'accessories',
      'electronics',
      'transport',
      'home-and-deco',
      'textiles',
    ].includes(shopCategory)
    ? 'newest-asc'
    : 'bestseller-desc'
}

/**
 * gets the sorting attributes for the opensearch request
 * @param shopCategory
 * @param sortingType
 * @param searchTerm
 */
const getOSSorting = ({
  categoryKey,
  search,
  sort: { id, sortOutOfStockToTheEnd },
}: Pick<NormalizedOpenSearchParameters, 'categoryKey' | 'search'> & {
  sort: Partial<Pick<NormalizedSorting, 'sortOutOfStockToTheEnd'>> & {
    id: string
  }
}) => {
  const { key, order } = getSortingById(id)

  /**
   * to sort in descending order, attributes have to be prefixed with '-' in opensearch
   */
  const sortOrderPrefixed = (key: string) => (order === 'asc' ? '' : '-') + key

  /**
   * if a search term is present, we want to sort by relevance only
   */
  if (search) {
    return ['-_score']
  }

  let sort = ['name.keyword']

  /**
   * we want to have a different alphabetical sorting behavior for audio content vs. other products
   * * audio content should be sorted by series name first, so that all audio content from a series
   * appears in one "batch".  This is especially helpful since a lot of audio content names start with
   * something like "Episode x: ...."
   * * other products (e.g. Tonies) have a lot less products within a series therefore we only
   * want to sort by product name
   */
  if (categoryKey && categoryKey.length === 1 && categoryKey[0] === 'tunes') {
    sort = ['series.name.keyword', 'name.keyword']
  }

  switch (key) {
    case 'alphabetical':
      sort = sort.map(key => sortOrderPrefixed(key))
      break
    case 'newest':
      sort = ['-publicationDate'].concat(sort)
      if (typeof sortOutOfStockToTheEnd === 'undefined') {
        sort = ['-available'].concat(sort)
      }
      break
    case 'price':
      sort = [sortOrderPrefixed('price.value.amount')].concat(sort)
      break
    case 'bestseller':
    default:
      sort = ['-soldLastMonth'].concat(sort)
      if (typeof sortOutOfStockToTheEnd === 'undefined') {
        sort = ['-available'].concat(sort)
      }
  }

  if (sortOutOfStockToTheEnd) {
    sort = ['-available', ...sort]
  }

  return sort
}

export const getSorting = ({
  search,
  sort = {},
  categoryKey: categoryKeyProp,
}: Omit<Parameters<typeof getOSSorting>[0], 'sort'> & {
  sort?: Partial<NormalizedSorting>
}): Partial<NormalizedSorting> => {
  // rewrite categoryKey and use only first category to identify the default sorting
  const categoryKey =
    categoryKeyProp?.length && categoryKeyProp.length === 1
      ? categoryKeyProp
      : []

  const {
    id = getSortingIdByCategory(categoryKey[0]),
    sortOutOfStockToTheEnd,
  } = sort

  const openSearchSorting = getOSSorting({
    categoryKey,
    search,
    sort: {
      id,
      sortOutOfStockToTheEnd,
    },
  })

  return makeSerializable({
    ...sort,
    id: sort?.isFixedSkuList ? undefined : id,
    openSearchSorting: sort?.isFixedSkuList ? undefined : openSearchSorting,
  })
}
