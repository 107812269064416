import { BxCustomer } from '../bxTypes'
import { fetch } from '../../util/fetch'
import { EcomLocale } from '../../../../config/shopAPI/types'

/**
 * Fetches /me-data
 */
export const getCustomer = async (lcCC: EcomLocale) =>
  fetch<Record<string, unknown>, BxCustomer>({
    query: '/me',
    lcCC,
    method: 'get',
    request: {},
  })
