import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react'
import { useTranslation } from 'next-i18next'
import { useLocaleAltNotation } from '../locale/altNotation'

export type Option = {
  lccc: string
  label: string
  url: string
}

export type LocaleSelectorContextProps = {
  lcccOptions: Option[]
  lcccSelectedOption: Option
  isSelectorExpanded: boolean
  setIsSelectorExpanded: Dispatch<SetStateAction<boolean>>
  wasTriggeredByKeyboard: boolean
  setWasTriggeredByKeyboard: Dispatch<SetStateAction<boolean>>
}

const engb = {
  lccc: 'en-gb',
  label: 'Great Britain & Ireland',
  url: 'https://www.tonies.com/en-gb/',
}

const localeSelectorContext = createContext<LocaleSelectorContextProps>({
  lcccOptions: [engb],
  lcccSelectedOption: engb,
  isSelectorExpanded: false,
  setIsSelectorExpanded: () => false,
  wasTriggeredByKeyboard: false,
  setWasTriggeredByKeyboard: () => false,
})

/**
 * This context maintains a list of locale codes and labels from the translations
 * and the page-wide state of the associated locale selector components:
 *
 * - The MetaNavigation contains a locale selection link that toggles the visibility of the locale selector
 * - The locale selector in the page body allows users to pick from the list of available locales
 */
export const LocaleSelectorContextProvider: FunctionComponent<
  PropsWithChildren
> = ({ children }) => {
  const { i18n } = useTranslation()
  const [isSelectorExpanded, setIsSelectorExpanded] = useState(false)
  const [wasTriggeredByKeyboard, setWasTriggeredByKeyboard] = useState(false)
  const lccc = useLocaleAltNotation('lc-cc')

  const i18nOptions = i18n.getResource(
    i18n.language,
    'localeSelector',
    'option'
  )

  const lcccOptions = Object.keys(i18nOptions)
    .map(lccc => ({
      lccc,
      label: i18nOptions[lccc].label as unknown as string,
      url: i18nOptions[lccc].url as unknown as string,
    }))
    // remove empty entries
    .filter(option => option.label && option.url)

  const lcccSelectedOption =
    lcccOptions.find(option => option.lccc === lccc) || lcccOptions[0]

  const value = {
    lcccOptions,
    lcccSelectedOption,
    isSelectorExpanded,
    setIsSelectorExpanded,
    wasTriggeredByKeyboard,
    setWasTriggeredByKeyboard,
  }

  return (
    <localeSelectorContext.Provider value={value}>
      {children}
    </localeSelectorContext.Provider>
  )
}

export const useLocaleSelectorContext = () => useContext(localeSelectorContext)
