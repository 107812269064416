/**
 * THIS FILE IS AUTOGENERATED BY ./scripts/update-design-tokens.js
 * Please do not edit this file. Changes will be overwritten next time someone
 * runs the script.
 */
export const spacings = {
  'spacing-xxs': '0.25rem',
  'spacing-xs': '0.5rem',
  'spacing-s': '1rem',
  'spacing-m': '1.5rem',
  'spacing-l': '2rem',
  'spacing-xl': '2.5rem',
  'spacing-xxl': '3rem',
} as const

export type ThemeSpacing = keyof typeof spacings
