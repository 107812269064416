import { createContext, useContext } from 'react'
import { sentryWithExtras } from '../../hooks/useSentry'
import { isEcomLocale } from '../../config/shopAPI'
import { EcomLocale, Locale } from '../../config/shopAPI/types'

// FIXME
// Use undefined instead of 'en-GB' as fallback and fix all usages
export const useEcomLocale = (): EcomLocale => {
  const lcCC = useLocale()
  if (!isEcomLocale(lcCC)) {
    sentryWithExtras(
      'useEcomLocale',
      new Error(`${lcCC} is not a valid EcomLocale`)
    )
    return 'en-GB' as EcomLocale
  }
  return lcCC as EcomLocale
}

const localeContext = createContext<Locale>('en-GB')
export const useLocale = (): Locale => useContext(localeContext)

export const LocaleProvider = localeContext.Provider

export const toLcCC = (locale: string) =>
  locale.substring(0, 2) + locale.substring(2).toUpperCase()
