import { BxCart } from '../bxTypes'
import { fetch } from '../../util/fetch'
import { EcomLocale } from '../../../../config/shopAPI/types'

/**
 * Fetches the latest state of a shopping cart from CommerceTools.
 */
export const getCart = async (lcCC: EcomLocale, cartId: string) =>
  fetch<Record<string, unknown>, BxCart>({
    query: `/carts/${cartId}`,
    lcCC,
    method: 'get',
    request: {},
  })
