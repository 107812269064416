import { createContext } from 'react'
import {
  defaultFeatureFlags,
  FeatureFlags,
} from '../../lib/configcat/FeatureFlags'

export type FeatureFlagContextProps = FeatureFlags & { isPending: boolean }
export const FeatureFlagContext = createContext<FeatureFlagContextProps>({
  ...defaultFeatureFlags,
  isPending: false,
})
