import { EcomLocale } from '../../../../config/shopAPI/types'
import { fetch } from '../../util/fetch'

export const deleteWishlist = (
  lcCC: EcomLocale,
  id: string
): Promise<string> => {
  if (process.env.NEXT_PUBLIC_FEATUREFLAG_HAS_WISHLIST !== 'true') {
    return Promise.reject()
  }

  return fetch({
    query: `/wishlist/${id}`,
    method: 'delete',
    lcCC,
  }).then(res => {
    if (res.result === 'successful') {
      return id
    } else {
      throw res
    }
  })
}
