import { ValitaError } from '@badrap/valita'

// The code in this file was derived from the unexported function `formatIssueTree()`
// in the Valita project repository:
// https://github.com/badrap/valita/blob/8c4419019a91733eca5a7e55eec2bd4e713f826b/src/index.ts#L132

export const getHumanReadableValitaIssuePath = (
  issue: ValitaError['issues'][0]
) => {
  if (!issue.path) {
    return undefined
  }

  let path = ''

  for (let i = 0; i < issue.path.length; i++) {
    const segment = issue.path[i]

    if (Number.isNaN(Number(segment))) {
      if (i > 0) {
        path += '.'
      }
      path += segment
    } else {
      path += '[' + segment + ']'
    }
  }

  return path
}

export const getHumanReadableValitaIssueMessage = (
  issue: ValitaError['issues'][0]
) => {
  let message = issue.code

  const path = getHumanReadableValitaIssuePath(issue)
  if (path) {
    message += ' at ' + path
  }

  message += ': '

  if (issue.code === 'invalid_type' || issue.code === 'invalid_literal') {
    message += `expected ${issue.expected
      .map(e => JSON.stringify(e))
      .join(' or ')}`
  } else if (issue.code === 'missing_value') {
    message += `missing value`
  } else if (issue.code === 'unrecognized_keys') {
    const keys = issue.keys
    message += `unrecognized ${keys.length === 1 ? 'key' : 'keys'} ${keys
      .map(k => JSON.stringify(k))
      .join(' and ')}`
  } else if (issue.code === 'invalid_length') {
    const min = issue.minLength
    const max = issue.maxLength
    message += `expected an array with `
    if (min > 0) {
      if (max === min) {
        message += `${min}`
      } else if (max < Infinity) {
        message += `between ${min} and ${max}`
      } else {
        message += `at least ${min}`
      }
    } else {
      message += `at most ${max}`
    }
    message += ` item(s)`
  } else if (issue.code === 'custom_error') {
    const error = issue.error
    if (typeof error === 'string') {
      message += error
    } else if (error && error.message === 'string') {
      message += error.message
    }
  }

  return message
}

export const getHumanReadableValitaErrorMessage = (err: ValitaError) => {
  let message = `Validation failed with ${err.issues.length} issues:`

  for (const issue of err.issues) {
    message += `\n - ${getHumanReadableValitaIssueMessage(issue)}`
  }

  return message
}
