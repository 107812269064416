import { useEffect, useState } from 'react'
import { CompatibleTonie } from '../lib/cloudservices/requests/rest/checkTuneStatus'
import { getTunesItemBySalesId } from '../lib/cloudservices/requests/graphql/getHouseholdData'
import { useEcomLocale } from '../providers/locale'
import { toast } from '@/tonies-ui/atoms/Toast'
import { sentryWithExtras } from './useSentry'
import { useAuth } from '../providers/auth'

export function useToniesToAssign(salesId?: string) {
  const [isFetchingTonies, setIsFetchingTonies] = useState(true)
  const [toniesToAssign, setToniesToAssign] = useState<
    CompatibleTonie[] | undefined
  >(undefined)
  const { hasBearerToken } = useAuth()
  const lcCC = useEcomLocale()

  useEffect(() => {
    if (!salesId || !hasBearerToken) {
      setIsFetchingTonies(false)
    }
    if (salesId && hasBearerToken) {
      setIsFetchingTonies(true)
      getTunesItemBySalesId(lcCC, salesId)
        .then(response => {
          if (
            response.result === 'successful' &&
            response?.data?.data?.tunesItems?.edges[0]?.node?.compatibleTonies
              ?.length &&
            response.data.data.tunesItems.edges[0].node.compatibleTonies
              .length > 0
          ) {
            setToniesToAssign(
              response.data.data.tunesItems.edges[0].node.compatibleTonies
            )
          }
        })
        .catch(error => {
          toast('default:TOASTSomethingWentWrong', 'error', {
            toastId: 'UseToniesToAssign_Error',
          })
          sentryWithExtras('useToniesToAssign cloudservices', error)
          console.log(error)
        })
        .finally(() => {
          setIsFetchingTonies(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesId, hasBearerToken])

  return {
    isFetchingTonies: isFetchingTonies,
    toniesToAssign: toniesToAssign,
  }
}
