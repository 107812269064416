import { PaymentResponse } from './types'
import { fetch } from '../../util/fetch'
import { EcomLocale } from '../../../../config/shopAPI/types'

const checkoutPath: Record<EcomLocale, string> = {
  'en-GB': 'checkout',
  'en-EU': 'checkout',
  'fr-FR': 'caisse',
  'de-DE': 'kasse',
}
export const makePayment = async (
  lcCC: EcomLocale,
  paymentId: string,
  paymentVersion: number,
  currency: string,
  centAmount: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
) => {
  const { browserInfo, paymentMethod } = data

  type AdditionalData = {
    allow3DS2: boolean
    RequestedTestAcquirerResponseCode?: number
    openinvoicedata?: unknown
  }

  const additionalData: AdditionalData = {
    allow3DS2: true,
    /**
     * * HINT: apparently we only would need openinvoicedata for  partial capture {@link https://docs.adyen.com/payment-methods/klarna/invoice-lines#line-items-in-capture-request}
     * so deactivated for now
     */
    // openinvoicedata,
  }

  if (paymentMethod?.holderName && paymentMethod.holderName.includes('test')) {
    // RefusalCode is between 2 and 39 (See: https://docs.adyen.com/development-resources/refusal-reasons)
    const refusalCode = paymentMethod.holderName.includes('test expired')
      ? 6
      : Math.floor(Math.random() * (39 - 2) + 2)

    additionalData.RequestedTestAcquirerResponseCode = refusalCode
  }

  /**
   * if we do have a `context` query param we have to append it to our `returnUrl`
   * to keep the context information when coming back from a payment provider
   * (currently only used for audio-library-checkout)
   */
  const currentSearchParams = new URLSearchParams(window.location.search)
  const paramKeys = ['context']
  const newSearchParams = new URLSearchParams()
  /**
   * URLSearchParams do have their own `for...of` iterator
   * {@link https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams},
   * Our current ts transpile target/lib `es5` in `tsconfig.ts` does not support
   * theses iterators. To use the iterators a possible solution could be:
   * {@link https://github.com/Microsoft/TypeScript/issues/23174#issuecomment-379044619}
   *
   * Currently we are using `forEach()` here as a workaround.
   */
  currentSearchParams.forEach((value, key) => {
    if (paramKeys.includes(key)) newSearchParams.append(key, value)
  })

  const returnUrl = new URL(
    newSearchParams.toString().length ? '?' + newSearchParams.toString() : '',
    `${window.location.origin}/${lcCC.toLowerCase()}/${checkoutPath[lcCC]}`
  )

  const request = {
    version: paymentVersion,
    actions: [
      {
        action: 'setCustomField',
        name: 'makePaymentRequest',
        value: JSON.stringify({
          amount: {
            currency: currency,
            value: centAmount,
          },
          // The payload below must be enabled for 3DS2 Secure
          //   https://docs.adyen.com/online-payments/3d-secure/native-3ds2/web-drop-in#make-a-payment
          browserInfo,
          paymentMethod,
          additionalData,
          channel: process.env.NEXT_PUBLIC_ADYEN_DROPIN_CHANNEL_KEY,
          origin: window.location.origin,
          returnUrl: returnUrl.toString(),
        }),
      },
    ],
  }
  return fetch<Record<string, unknown>, PaymentResponse>({
    query: '/payments/' + paymentId,
    lcCC,
    method: 'post',
    request,
  })
}
