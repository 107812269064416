import { theme, themeHelpers } from '../../tonies-ui/themes/theme'
import merge from 'lodash/merge'

const rem = ({ px, base }: { px: number; base: number }) => `${px / base}rem`

export const { media } = themeHelpers

// See also https://gitlab.boxine.de/tonies/components/-/blob/master/components/themes/variables.js
// Fonts are loaded with the tonies.de CDN
export const AppTheme = {
  application: {
    backgroundColor: '#FFFFFF',
    color: '#333333',
    margin: rem({ px: theme.FontSize, base: theme.FontSize }),
    padding: rem({ px: theme.Padding, base: theme.FontSize }),
  },
  breakpoint: {
    screen4k: rem({ px: theme.screen4k, base: theme.FontSize }),
    screenLaptop: rem({ px: theme.screenLaptop, base: theme.FontSize }),
    screenLaptopL: rem({
      px: theme.screenLaptopL,
      base: theme.FontSize,
    }),
    screenMobileL: rem({
      px: theme.screenMobileL,
      base: theme.FontSize,
    }),
    screenMobileM: rem({
      px: theme.screenMobileM,
      base: theme.FontSize,
    }),
    screenMobileS: rem({
      px: theme.screenMobileS,
      base: theme.FontSize,
    }),
    screenMobileSM: rem({
      px: theme.screenMobileSM,
      base: theme.FontSize,
    }),
    screenTablet: rem({ px: theme.screenTablet, base: theme.FontSize }),
  },
  section: {
    maxWidth: '66.666%',
    marginBottom: rem({ px: theme.FontSize, base: theme.FontSize }),
    column: {
      padding: rem({ px: theme.Padding * 2, base: theme.FontSize }),
      paddingMobile: rem({ px: theme.Padding, base: theme.FontSize }),
    },
  },
  link: {
    color: theme.colors.primary,
    hoverColor: theme.colors['primary-hover'],
  },
  hero: {
    backgroundColor: theme.colors.primary,
  },
  header: {
    minHeightSticky: rem({
      px: 520,
      base: theme.FontSize,
    }),
  },
  animation: {
    heartBeat: {
      duration: '1s',
      timingFunction: 'linear',
      delay: '0.5s',
      keyframes: `0% {
        transform: scale(1);
      }
      5% {
        transform: scale(1.01);
      }
      10% {
        transform: scale(1);
      }
      15% {
        transform: scale(1.03);
      }
      50% {
        transform: scale(1.01);
      }
      100% {
        transform: scale(1);
      }`,
    },
  },
} as const

export const mergedTheme = merge(theme, AppTheme)
export type MergedTheme = typeof mergedTheme

/** Min height requirement to enable the sticky header */
export const minHeightSticky = '35rem'
