import { DefaultMotionWrapper, DefaultMotionSpanElement } from './constants'
import { PopIt } from './PopIt'
import { ScrollingTonieHeads } from './ScrollingTonieHeads'
import { TonieEars } from './TonieEars'
import { WobbleIt } from './WobbleIt'
import { PulseIt } from './PulseIt'
import { RotateIt } from './RotateIt'
import { ZoomIt } from './ZoomIt'
import { SlideIt } from './SlideIt'
import { ShakeIt } from './ShakeIt'

export const ToniesMotions = {
  DefaultMotionWrapper,
  DefaultMotionSpanElement,
  PopIt,
  PulseIt,
  RotateIt,
  ScrollingTonieHeads,
  TonieEars,
  WobbleIt,
  ZoomIt,
  SlideIt,
  ShakeIt,
}
