import * as v from '@badrap/valita'
import { ecomLocales } from '../../../../config/shopAPI/types'

export const ShopLocale = v.union(...ecomLocales.map(lcCC => v.literal(lcCC)))

export const KeyName = v.object({
  key: v.string().nullable(),
  name: v.string().nullable(),
})

export const PriceValue = v.object({
  type: v.string(),
  centAmount: v.number(),
  currencyCode: v.string(),
  fractionDigits: v.number(),
  amount: v.number(),
})

export const Price = v.object({
  id: v.string(),
  value: PriceValue,
  strikeValue: PriceValue.nullable(),
  validUntil: v.string().nullable(),
  validFrom: v.string().nullable(),
})

const Image = v.object({
  url: v.string(),
  label: v.string().nullable(),
  width: v.number(),
  height: v.number(),
})

export const Item = v.object({
  id: v.string(),
  key: v.string().nullable(),
  name: v.string().nullable(),
  slug: v.string().nullable(),
  description: v.string().nullable(),
  productsCommonAttributes: v.string().nullable(),
  hasChokingHazardWarning: v.boolean().nullable(),
  variantsLabel: v.string().nullable(),
  productsWarning: v.string().nullable(),
  shortName: v.string().nullable(),
  logoUrl: v.string().nullable(),
  variantsType: v.string().nullable(),
})

export const BundledProduct = v.object({
  series: Item.nullable(),
  character: Item.nullable(),
  variantId: v.number(),
  sku: v.string().nullable(),
  variantKey: v.string().nullable(),
  price: Price,
  images: v.array(Image),
  firstImage: Image.nullable(),
  productId: v.string(),
  productKey: v.string().nullable(),
  name: v.string().nullable(),
  slug: v.string().nullable(),
  description: v.string().nullable(),
  categories: v.array(
    v.object({
      items: v.array(Item),
    })
  ),
  productTypeKey: v.string(),
  productFamily: v.string().nullable(),
  searchKeywords: v.array(v.string()),
  metaTitle: v.string().nullable(),
  metaDescription: v.string().nullable(),
  shopLocale: ShopLocale,
  productIndex: v.string(),
  productUrl: v.string().nullable(),
  available: v.boolean(),
  salesId: v.string().nullable(),
  readers: v.string().nullable(),
  maxQuantity: v.number().nullable(),
  ageMin: v.number().nullable(),
  publicationDate: v.string().nullable(),
  runTime: v.number().nullable(),
  flag: KeyName.nullable(),
  tracks: v.string().nullable(),
  audioSampleUrl: v.string().nullable(),
  audioLibraryAssignUrl: v.string().nullable(),
  genre: KeyName.nullable(),
  theme: v.array(KeyName.nullable()),
  packageContents: v.string().nullable(),
  language: KeyName.nullable(),
  soldLastMonth: v.number().nullable(),
  orderFrom: v.string().nullable(),
  authors: v.string().nullable(),
  shippingFrom: v.string().nullable(),
  colors: v.string().nullable(),
  weight: v.string().nullable(),
  colorcode: v.string().nullable(),
  dimensions: v.string().nullable(),
  hasChokingHazardWarning: v.boolean().nullable(),
  production: v.string().nullable(),
  copyrights: v.string().nullable(),
  variantLabel: v.string().nullable(),
  episodeNumber: v.number().nullable(),
  bundleIdentifier: v.string().nullable(),
  assignableToAllCreativeTonies: v.boolean().nullable(),
  gtin: v.string().nullable(),
  bundledProducts: v.array(v.never()), // a bundled product should not have bundled products (avoids infinite recursion)
  idField: v.string(),
})

// The product may contain a list of bundled products and is of the same shape as a bundled product
export const Product = v.object({
  ...BundledProduct.shape,
  bundledProducts: v.array(BundledProduct),
})

const AggregationValue = v.object({
  key: v.string(),
  label: v.string(),
  doc_count: v.number(),
})

const AggregationObject = v.object({
  ...AggregationValue.shape,
  options: v.array(AggregationValue).optional(),
})

export const ProductAggregations = v.object({
  flag: v.array(AggregationObject),
  ageMin: v.array(AggregationObject),
  genre: v.array(AggregationObject),
  characterSeries: v.array(AggregationObject),
  language: v.array(AggregationObject),
  theme: v.array(AggregationObject),
})

// Currently there is only one type of query type: "products"
export const Params = v.object({
  shopLocale: ShopLocale,
  ageMin: v.array(v.string()).optional(),
  categoryKey: v.array(v.string()).optional(),
  categorySlug: v.array(v.string()).optional(),
  discounted: v.union(v.literal('true'), v.literal('false')).optional(),
  flag: v.array(v.string()).optional(),
  genre: v.array(v.string()).optional(),
  language: v.array(v.string()).optional(),
  salesId: v.array(v.string()).optional(),
  search: v.array(v.string()).optional(),
  sku: v.array(v.string()).optional(),
  slug: v.array(v.string()).optional(),
  sort: v.array(v.string()).optional(),
  theme: v.array(v.string()).optional(),
  withBucketAggs: v.union(v.literal('1'), v.literal('0')).optional(),
  available: v.union(v.literal('true'), v.literal('false')).optional(),
  limit: v.number().optional(),
  offset: v.number().optional(),
  priceGte: v.string().optional(),
  priceLte: v.string().optional(),
})
