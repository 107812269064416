import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { getWishlist } from '../../lib/shopApi/requests/wishlist/getWishlist'
import { useAuth } from '../../providers/auth'
import { useEcomLocale, useLocale } from '../../providers/locale'
import { useModalNotifications } from '../../providers/ModalNotifications'
import { LoginModal } from '../../components/organisms/LoginModal'
import { useTranslation } from 'react-i18next'
import { createWishlist } from '../../lib/shopApi/requests/wishlist/createWishlist'
import { addItemToWishlist } from '../../lib/shopApi/requests/wishlist/addItemToWishlist'
import { removeItemFromWishlist } from '../../lib/shopApi/requests/wishlist/removeItemFromWishlist'
import { useToastErrorMessage } from '../../hooks/useToastErrorMessage'
import { deleteWishlist } from '../../lib/shopApi/requests/wishlist/deleteWishlist'
import { isRequestFailed } from '../../lib/shopApi/util/fetch'
import { fullUrl } from '../../utils/urlHelpers'
import { toast } from '@/tonies-ui/atoms/Toast'
import { GtmV4Event } from '../../providers/gtmV4/types'
import { isEcomLocale } from '../../config/shopAPI'

type WishlistProviderWishlist = NormalizedWishlist & {
  url: string
}

export type WishlistProviderProps = {
  addToWishlist: (
    product: NormalizedProduct,
    pushGtmV4Event: (e: GtmV4Event) => void,
    wishlist?: NormalizedWishlist
  ) => Promise<NormalizedWishlist | undefined>
  deleteWishlist: (
    wishlist: NormalizedWishlist | undefined,
    redirectUri?: string
  ) => Promise<NormalizedWishlist | undefined>
  hasWishlist: boolean
  isPending: boolean
  myDefaultWishlist: WishlistProviderWishlist | undefined
  removeFromWishlist: (
    product: NormalizedProduct,
    pushGtmV4Event: (e: GtmV4Event) => void,
    wishlist?: NormalizedWishlist
  ) => Promise<NormalizedWishlist | undefined>
  toggleFromWishlist: (
    product: NormalizedProduct,
    pushGtmV4Event: (e: GtmV4Event) => void,
    wishlist?: NormalizedWishlist
  ) => Promise<NormalizedWishlist | undefined>
  wishlist: WishlistProviderWishlist | undefined
  context?: 'SharedProviders' | 'WishlistLayout'
}

const defaultProps: WishlistProviderProps = {
  addToWishlist: () => Promise.reject(),
  deleteWishlist: () => Promise.reject(),
  hasWishlist: false,
  isPending: true,
  myDefaultWishlist: undefined,
  removeFromWishlist: () => Promise.reject(),
  toggleFromWishlist: () => Promise.reject(),
  wishlist: undefined,
  context: undefined,
}

export const wishlistContext = createContext(defaultProps)

const useWishlistErrorHandling = () => {
  const { t } = useTranslation()
  const showErrorToastMessage = useToastErrorMessage()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (context: string, error: any) => {
    if (isRequestFailed(error)) {
      if (
        error.httpStatus === 400 &&
        error.data?.detail?.includes('exceeded the maximum number')
      ) {
        toast(t('wishlist:toast:maximumReached'), 'error', { autoClose: false })
      } else {
        showErrorToastMessage(context, error.httpStatus)
      }
    }
  }
}

export const WishlistProvider: FunctionComponentWithChildren<{
  context?: 'SharedProviders' | 'WishlistLayout'
  wishlistId: string | undefined
}> = ({ wishlistId, context, children }) => {
  const { t } = useTranslation()
  const lcCC = useEcomLocale()
  const showErrorToastMessage = useToastErrorMessage()
  const handleWishlistError = useWishlistErrorHandling()
  const { setModal } = useModalNotifications()
  const {
    isPending: isAuthPending,
    user,
    authenticated,
    updateUserContext,
  } = useAuth()

  const [isPending, setIsPending] = useState(true)
  const [wishlist, setWishlist] = useState<WishlistProviderWishlist>()

  const myDefaultWishlist: WishlistProviderWishlist | undefined =
    useMemo(() => {
      const firstWishlist = user?.wishlists?.[0]

      return firstWishlist
        ? {
            ...firstWishlist,
            // because we use translations we unfortunately have to manipulate the data here again
            url: fullUrl(t('header:wishlistLink') + `/${firstWishlist.id}`),
          }
        : undefined
    }, [t, user?.wishlists])

  const showLoginModal = useCallback(
    (_product?: NormalizedProduct) => {
      setModal(
        <LoginModal
          headline={t('wishlist:loginModal:headline')}
          onClose={() => {
            setModal(undefined)
          }}
        >
          {t('wishlist:loginModal:text')}
        </LoginModal>
      )
    },
    [setModal, t]
  )

  const handleWishlistStateUpdate = useCallback(
    (updatedWishlist: NormalizedWishlist) =>
      setWishlist({
        ...updatedWishlist,
        // because we use translations we unfortunately have to manipulate the data here again
        url: fullUrl(t('header:wishlistLink') + `/${updatedWishlist.id}`),
      }),
    [t]
  )

  const updateUserWishlists = useCallback(
    (updatedWishlist: NormalizedWishlist) =>
      updateUserContext(curr => {
        const myWishlists = curr?.wishlists || []
        const newUserWishlists = myWishlists.map(myWishlist => {
          if (myWishlist.id === updatedWishlist.id) {
            return updatedWishlist
          } else {
            return myWishlist
          }
        })

        return curr
          ? {
              ...curr,
              wishlists:
                newUserWishlists.length > 0
                  ? newUserWishlists
                  : [updatedWishlist],
            }
          : null
      }),
    [updateUserContext]
  )

  const addToWishlistAction = useCallback(
    (
      product: NormalizedProduct,
      pushGtmV4Event: (e: GtmV4Event) => void,
      wishlist: NormalizedWishlist
    ): Promise<NormalizedWishlist | undefined> => {
      return addItemToWishlist(lcCC, wishlist.id, product)
        .then(updatedWishlist => {
          pushGtmV4Event({
            eventType: 'addToWishlist',
            item: { ...product, quantity: 1 },
          })

          updateUserWishlists(updatedWishlist)
          return updatedWishlist
        })
        .catch(error => {
          handleWishlistError('add-to-wishlist-outage', error)
          return undefined
        })
    },
    [lcCC, updateUserWishlists, handleWishlistError]
  )

  const handleAddProduct: WishlistProviderProps['addToWishlist'] = useCallback(
    async (
      product: NormalizedProduct,
      pushGtmV4Event,
      wishlist = myDefaultWishlist
    ) => {
      if (isAuthPending) {
        showErrorToastMessage('add-to-wishlist-auth-pending')
        return undefined
      } else if (!authenticated) {
        showLoginModal(product)
        return undefined
      } else if (!wishlist) {
        // create wishlist when the user dont have one
        return createWishlist(lcCC)
          .then(newWishlist =>
            addToWishlistAction(product, pushGtmV4Event, newWishlist)
          )
          .catch(error => {
            if (isRequestFailed(error)) {
              showErrorToastMessage('add-to-wishlist-outage', error.httpStatus)
            }
            return undefined
          })
      } else {
        return addToWishlistAction(product, pushGtmV4Event, wishlist)
      }
    },
    [
      addToWishlistAction,
      authenticated,
      isAuthPending,
      lcCC,
      myDefaultWishlist,
      showErrorToastMessage,
      showLoginModal,
    ]
  )

  const handleRemoveFromWishlist: WishlistProviderProps['removeFromWishlist'] =
    useCallback(
      async (
        product: NormalizedProduct,
        pushGtmV4Event,
        wishlist = myDefaultWishlist
      ) => {
        const wishlistItem = wishlist?.getItem(product.sku)
        const quantity = 1

        if (isAuthPending) {
          showErrorToastMessage('remove-from-wishlist-auth-pending')
          return undefined
        } else if (!authenticated) {
          showLoginModal(product)
          return undefined
        } else if (!wishlist) {
          showErrorToastMessage('remove-from-wishlist', 404)
          return undefined
        } else if (!wishlistItem) {
          showErrorToastMessage('remove-from-wishlist-item-is-not-defined', 404)
          return undefined
        } else {
          return (
            removeItemFromWishlist(lcCC, wishlistItem)
              // get fresh wishlist
              .then(() => getWishlist(lcCC, wishlist.id))
              .then(updatedWishlist => {
                pushGtmV4Event({
                  eventType: 'removeFromWishlist',
                  item: { ...product, quantity },
                  value: ((product.price?.centAmount || 0) * quantity) / 100,
                })
                updateUserWishlists(updatedWishlist)
                return updatedWishlist
              })
              .catch(error => {
                handleWishlistError('remove-from-wishlist-outage', error)
                return undefined
              })
          )
        }
      },
      [
        myDefaultWishlist,
        isAuthPending,
        authenticated,
        showErrorToastMessage,
        showLoginModal,
        lcCC,
        updateUserWishlists,
        handleWishlistError,
      ]
    )

  const handleDeleteWishlist: WishlistProviderProps['deleteWishlist'] =
    useCallback(
      async (wishlist = myDefaultWishlist, redirectUri) => {
        if (isAuthPending) {
          showErrorToastMessage('delete-wishlist-auth-pending')
          return undefined
        } else if (!authenticated) {
          showLoginModal()
          return undefined
        } else if (!wishlist) {
          showErrorToastMessage('delete-wishlist', 404)
          return undefined
        } else {
          return deleteWishlist(lcCC, wishlist.id)
            .then(() => {
              if (redirectUri) {
                // redirect to wishlistOverview
                window.location.href = redirectUri
              }
              return wishlist
            })
            .catch(error => {
              handleWishlistError('delete-wishlist-outage', error)
              return undefined
            })
        }
      },
      [
        myDefaultWishlist,
        isAuthPending,
        authenticated,
        showErrorToastMessage,
        showLoginModal,
        lcCC,
        handleWishlistError,
      ]
    )

  /**
   * we only want wo show the add and remove toasts, when we know, that we only want to update one single wishlistItem (toggle function)
   * in cases where we add multible products, the toasts could be annoying
   */
  const handleToggleFromWishlist: WishlistProviderProps['toggleFromWishlist'] =
    useCallback(
      (
        product: NormalizedProduct,
        pushGtmV4Event,
        wishlist = myDefaultWishlist
      ) => {
        return wishlist?.getItem(product.sku)
          ? handleRemoveFromWishlist(product, pushGtmV4Event, wishlist).then(
              res => {
                if (res) {
                  toast(t('wishlist:toast:removedFromWishlist'), 'success')
                }
                return res
              }
            )
          : handleAddProduct(product, pushGtmV4Event, wishlist).then(res => {
              if (res) {
                toast(t('wishlist:toast:addedToWishlist'), 'success')
              }
              return res
            })
      },
      [myDefaultWishlist, handleRemoveFromWishlist, handleAddProduct, t]
    )

  // fetch Wishlist from API
  useEffect(() => {
    const myWishlist = user?.wishlists?.find(wl => wl.id === wishlistId)

    if (isAuthPending) {
      return
    } else if (wishlist) {
      // we want to persist the current state of the provider when we already have a wishlist
      // the removed products will be grey out (TWAS-4870)
      return
    } else if (myWishlist) {
      // set wishlist === myWishlist
      handleWishlistStateUpdate(myWishlist)
      setIsPending(false)
    } else if (wishlistId) {
      // fetch wishlist by id
      getWishlist(lcCC, wishlistId)
        .then(handleWishlistStateUpdate)
        .catch(res => {
          showErrorToastMessage('get-wishlist-outage', res.httpStatus)
        })
        .finally(() => setIsPending(false))
    } else {
      setIsPending(false)
    }
  }, [
    isAuthPending,
    lcCC,
    handleWishlistStateUpdate,
    showErrorToastMessage,
    user?.wishlists,
    wishlist,
    wishlistId,
  ])

  return (
    <wishlistContext.Provider
      value={{
        addToWishlist: handleAddProduct,
        context,
        deleteWishlist: handleDeleteWishlist,
        isPending: isPending || isAuthPending,
        hasWishlist:
          isEcomLocale(useLocale()) &&
          process.env.NEXT_PUBLIC_FEATUREFLAG_HAS_WISHLIST === 'true',
        myDefaultWishlist,
        removeFromWishlist: handleRemoveFromWishlist,
        toggleFromWishlist: handleToggleFromWishlist,
        wishlist,
      }}
    >
      {children}
    </wishlistContext.Provider>
  )
}

export const useWishlist = () => useContext(wishlistContext)
