export const variables = {
  /**
   * TODO: Remove color definitions after deprecation expired
   * Please avoid to use the color variables and use the generated ones below
   */
  /** @deprecated Please use `theme.colors['anthracite-10']` instead */
  Anthracite: '#f0f0f1',
  /** @deprecated Please use `theme.colors.primary` instead */
  BrandPrimary: '#d2000f',
  /** @deprecated Please use `theme.colors['primary-hover']` instead */
  BrandPrimaryHover: '#b5000d',
  /** @deprecated Please use `theme.colors` instead */
  Transparent: 'rgba(0,0,0,0)',
  /** @deprecated Please use `theme.colors` instead */
  BlackDarker: '#050e14',
  /** @deprecated Please use `theme.colors` instead */
  Black: '#2e2e2e',
  /** @deprecated Please use `theme.colors.white` instead */
  White: '#ffffff',
  /** @deprecated Please use `theme.colors.lightergrey` instead */
  NearlyWhite: '#f1f1f1',
  /** @deprecated Please use `theme.colors.middlegrey` instead */
  DirtyWhite: '#dbdbdb',
  /** @deprecated Please use `theme.colors[anthracite-40]` instead */
  DirtyWhiteDarker: '#c1c1c5',
  /** @deprecated Please use `theme.colors['yellow-100']` instead */
  BrandInfo: '#fac800',
  /** @deprecated Please use `theme.colors.darkergrey` instead */
  Gray: '#333333',
  /** @deprecated Please use `theme.colors` instead */
  GrayLighter: '#d1d1d4',
  /** @deprecated Please use `theme.colors.lightgrey` instead */
  GrayLightest: '#eeeeee',
  /** @deprecated Please use `theme.colors` instead */
  MediumGray: '#64646e',
  /** @deprecated Please use `theme.colors.normalgrey` instead */
  LightGray: '#9b9999',
  /** @deprecated Please use `theme.colors['lightblue-100']` instead */
  Success: '#82bedc',
  /** @deprecated Please use `theme.colors['green-100']` instead */
  Green: '#afb450',
  /** @deprecated Please use `theme.colors` instead */
  Berry: '#780050',
  /** @deprecated Please use `theme.colors` instead */
  Blue: '#009fe3',
  /** @deprecated Please use `theme.colors` instead */
  LightBlue: '#e6f2f8',
  /** @deprecated Please use `theme.colors` instead */
  LightPurple: '#f2e5ee',
  /** @deprecated Please use `theme.colors` instead */
  FocusOutline: '#4d90fe',
  /** @deprecated Please use `theme.colors['anthracite-30']` instead */
  CardShadow: '#d1d1d4',
  /** @deprecated Please use `theme.colors['green-40']` instead */
  CardLockedShadow: '#dfe1b9',
  /** @deprecated Please use `theme.colors` instead */
  MetaNavBackground: '#f9f9f9',
  /** @deprecated Please use `theme.colors` instead */
  FooterBorder: '#74747d',
  /** @deprecated Please use `theme.colors` instead */
  FocusBorder: '#83838b',
  FocusBorderWidth: '0.2rem',

  // Units
  BorderSize: 1,
  BorderRadius: 3,
  FontSize: 16,
  Padding: 8,
  DarkenFactor: 0.06,
  LightenFactor: 0.4,
  LineHeight: 1.4,
  h1FontSizeSmall: 24,
  h1FontSizeMedium: 32,
  h1FontSizeLarge: 55,
  h2FontSizeSmall: 24,
  h2FontSizeMedium: 28,
  h2FontSizeLarge: 32,
  h3FontSizeSmall: 18,
  h3FontSizeMedium: 20,
  h3FontSizeLarge: 24,
  h4FontSize: 20,

  // Breakpoints, specifying the MINIMUM size per viewport
  screenMobile: 0, // for completeness
  screenMobileS: 320, // xxs
  screenMobileSM: 340, // xs
  screenMobileM: 375, // s
  screenMobileL: 425, // m
  screenMobileXL: 608,
  screenTablet: 640,
  screenTabletL: 768, // l
  screenLaptop: 1024, // xl
  screenLaptopL: 1440, // xxl
  screen4k: 2560, // xxxl

  caretWidth: 10,
} as const

// TODO: For backward compatiblity. Remove if fixed.
export { colors } from './colors'
export type { ToniesColor } from './colors'
export { spacings } from './spacings'
export type { ThemeSpacing } from './spacings'

export const breakpoints = {
  s: '23.4375rem', // 375px, screenMobileS
  m: '48rem', // 768px, screenTablet
  l: '64rem', // 1024px, screenLaptop
  xl: '90rem', // 1440px, screenLaptopL
  xxl: '160rem', // 2560px, screen4k
} as const

export type Breakpoint = keyof typeof breakpoints
