import { PaymentResponse } from './types'
import { fetch } from '../../util/fetch'
import { EcomLocale } from '../../../../config/shopAPI/types'

export const submitAdditionalPaymentDetails = async (
  lcCC: EcomLocale,
  paymentId: string,
  paymentVersion: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalPaymentDetails: any
) => {
  const request = {
    version: paymentVersion,
    actions: [
      {
        action: 'setCustomField',
        name: 'submitAdditionalPaymentDetailsRequest',
        value: JSON.stringify(additionalPaymentDetails),
      },
    ],
  }

  return fetch<Record<string, unknown>, PaymentResponse>({
    query: '/payments/' + paymentId,
    lcCC,
    method: 'post',
    request,
  })
}
