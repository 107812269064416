import { EcomLocale } from '../../../../config/shopAPI/types'
import { fetch } from '../../../../lib/shopApi/util/fetch'
import { BxWishlist } from '../bxTypes'
import { normalizeWishlist } from '../../../../lib/shopApi/normalizers/normalizeWishlist'

export const getWishlists = (
  lcCC: EcomLocale
): Promise<NormalizedWishlist[]> => {
  if (process.env.NEXT_PUBLIC_FEATUREFLAG_HAS_WISHLIST !== 'true') {
    return Promise.reject()
  }

  return fetch<{ wishlists: BxWishlist[] }>({
    lcCC,
    query: '/wishlists',
    method: 'get',
  }).then(res => {
    if (res.result === 'successful') {
      return res.data.wishlists.map(wishlist =>
        normalizeWishlist(lcCC, wishlist)
      )
    } else {
      throw res
    }
  })
}
