import makeDebug from 'debug'
import axios, { AxiosResponse } from 'axios'
import { PHASE_PRODUCTION_BUILD } from 'next/constants'
import { LRUCache } from '../../../lib/utils/lrucache'

type RequestQuery = {
  type: string
  params: Record<string, unknown>
}

export type FetchResponse = {
  documents: Array<unknown>
  total: number
  aggregations: Record<string, unknown> | []
}

type FetchParams = {
  type: string
  params: Record<string, unknown>
  limit?: number
  offset?: number
  aggregationsOnly?: boolean
}

const debug = makeDebug('lib:opensearch:util:fetchFromOs')

const cache = new LRUCache<string, AxiosResponse<FetchResponse>>()

// Use caching only during the build phase on the server
if (process.env.NEXT_PHASE !== PHASE_PRODUCTION_BUILD) {
  cache.disable()
}

/**
 * Makes a request to the OpenSearch API.
 *
 * @param query - The query parameters for the request.
 * @param limit - The maximum number of results to return.
 * @param offset - The offset of the first result to return.
 * @returns A Promise that resolves to the response data.
 */
async function request(query: RequestQuery, limit: number, offset: number) {
  const baseURL = process.env.NEXT_PUBLIC_API_URL
  const searchParams = new URLSearchParams()

  Object.entries(query.params).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length !== 0) {
      searchParams.set(key, value.join(','))
    }

    if (typeof value === 'string' && value.length !== 0) {
      searchParams.set(key, value)
    }

    if (typeof value === 'boolean') {
      searchParams.set(key, value.toString())
    }
  })

  searchParams.set('offset', offset.toString())
  searchParams.set('limit', limit.toString())

  const url = `/os/${query.type}/?${searchParams.toString()}`
  const params: Record<string, unknown> = {}

  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'local') {
    params.XDEBUG_SESSION = 'yes' // Backend flag, see TWAS-3190
  }

  const cachedResponse = cache.has(url) ? cache.get(url) : undefined

  if (cachedResponse) {
    debug(`Cache hit on ${query.type}.`, {
      url,
      limit,
      offset,
    })

    return cachedResponse
  }

  const response = await axios.request<FetchResponse>({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params,
    method: 'get',
    baseURL,
    url,
  })

  cache.put(url, response)

  debug(`Fetching ${query.type}.`, {
    baseUrl: response.config.baseURL,
    url: response.config.url,
    limit,
    offset,
  })

  return response
}

const MAX_LIMIT = 200

/**
 * Calculates the limit for fetching data.
 * If the initial limit is provided and it is less than the maximum limit,
 * the initial limit is returned. Otherwise, the maximum limit is returned.
 *
 * @param initialLimit - The initial limit for fetching data.
 * @returns The calculated limit.
 */
function calculateLimit(initialLimit: number | undefined) {
  if (initialLimit !== undefined && initialLimit < MAX_LIMIT) {
    return initialLimit
  }

  return MAX_LIMIT
}

/**
 * Fetches data from the OpenSearch API.
 *
 * @param {FetchParams} options - The fetch options.
 * @param {string} options.type - The type of data to fetch.
 * @param {object} options.params - The parameters for the fetch request.
 * @param {number} options.limit - The maximum number of documents to fetch. If 0, it fetches only aggregations (same as `aggregationsOnly = true`).
 * @param {number} [options.offset=0] - The offset for pagination.
 * @param {boolean} [options.aggregationsOnly=false] - Flag indicating whether to fetch only aggregations.
 * @returns {Promise<FetchResponse>} The fetched data.
 * @throws {Error} If the offset is greater than the total number of documents.
 */
export async function fetchFromOs({
  type,
  params,
  limit: initialLimit,
  offset: initialOffset = 0,
  aggregationsOnly = false,
}: FetchParams): Promise<FetchResponse> {
  const executeQuery = (limit: number, offset: number) =>
    request({ type, params }, limit, offset)

  // Fetch the first page to get the total number of documents
  const offset = aggregationsOnly ? 0 : initialOffset
  const limit = aggregationsOnly ? 0 : calculateLimit(initialLimit)
  const initialResponse = await executeQuery(limit, offset)
  const total =
    initialLimit === undefined ? initialResponse.data.total : initialLimit

  if (initialOffset > initialResponse.data.total) {
    throw new Error('Offset is greater than total')
  }

  // Prepare all pages to be fetched
  const pendingRequests = Array.from({
    length: Math.ceil(total / limit) - 1,
  }).map((_, index) => {
    const nextOffset = offset + (index + 1) * limit
    return executeQuery(limit, nextOffset).then(({ data }) => ({ index, data }))
  })

  // Fetch all pages in parallel
  const responses = await Promise.all(pendingRequests)

  // Sort responses by the original order
  responses.sort((a, b) => a.index - b.index)

  const documents = initialResponse.data.documents

  // Flatten sorted responses into documents
  for (const response of responses) {
    documents.push(...response.data.documents)
  }

  debug(
    `Fetched ${total} documents in ${
      pendingRequests.length + 1
    } requests successfully. `
  )

  return {
    documents,
    total: initialResponse.data.total,
    aggregations: initialResponse.data.aggregations,
  }
}
