import { createContext } from 'react'
import { EcomLocale } from '../../config/shopAPI/types'

export type CartActionParams = {
  lcCC: EcomLocale
  fetchOrCreateCart: () => Promise<NormalizedCart>
  replaceCart: (cart: NormalizedCart | undefined) => void
  logError: (error: Error, context: string, sentryDetails?: unknown) => void
}

export type CartAction<T> = (params: CartActionParams) => Promise<T>

export type CartActionsProps = {
  push: <T>(cartAction: CartAction<T>) => Promise<T>
  error: Readonly<Error | undefined>
  state: Readonly<'idle' | 'processing' | 'failed'>
  resetError: () => void
  removeLineItemsFromCart: (
    lineItems: NormalizedCart['lineItems'] | undefined
  ) => Promise<NormalizedCart['lineItems']> | undefined
}

export const cartActionsContext = createContext<CartActionsProps>({
  push: async () => {
    throw new Error('Not implemented')
  },
  error: undefined,
  state: 'idle',
  resetError: () => {
    throw new Error('Not implemented')
  },
  removeLineItemsFromCart: () => Promise.reject(),
})
