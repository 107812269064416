import { AxiosError } from 'axios'
import { isAxiosError } from './isAxiosError'

export const normalizeError = (error: unknown): Error | AxiosError => {
  if (error instanceof Error || isAxiosError(error)) {
    return error
  } else {
    return new Error(normalizeErrorMessage(error))
  }
}

export const normalizeErrorMessage = (
  errorMessage: unknown
): string | undefined => {
  if (errorMessage instanceof Error || isAxiosError(errorMessage)) {
    return undefined
  }

  if (typeof errorMessage === 'string') {
    return errorMessage
  }

  try {
    return JSON.stringify(errorMessage) || 'undefined error'
  } catch (_error) {
    return 'undefined error'
  }
}
