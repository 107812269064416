import { createContext, Dispatch, SetStateAction } from 'react'
import { ErrorLayoutType } from '../../layouts/ErrorLayout'
import {
  GeoIpValidationError,
  LineItemViolationError,
  ShopApiViolation,
} from '../../utils'

export type ShopAPIErrorHandlingContextProps = {
  error: Error | undefined
  resetError: () => void
  errorPageLayout: ErrorLayoutType | undefined
  cartViolations: ShopApiViolation[] | undefined
  normalizedLineItemViolations: LineItemViolationError[] | undefined
  normalizedGeoIpViolations: GeoIpValidationError[] | undefined
  showCartViolationModal: boolean
  isPlaceOrderError: boolean
  pendingOperations: number
  setPendingOperations: Dispatch<SetStateAction<number>>
}

export const defaultProps: ShopAPIErrorHandlingContextProps = {
  error: undefined,
  resetError: () => undefined,
  errorPageLayout: undefined,
  cartViolations: undefined,
  normalizedGeoIpViolations: undefined,
  normalizedLineItemViolations: undefined,
  showCartViolationModal: false,
  isPlaceOrderError: false,
  pendingOperations: 0,
  setPendingOperations: () => undefined,
}

export const ShopAPIErrorHandlingContext =
  createContext<ShopAPIErrorHandlingContextProps>(defaultProps)
