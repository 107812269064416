// do some basic slugifying
export const slugify = (string: string, lc: string): string => {
  let slug = string.toLowerCase().split(' ').join('-')
  if (lc === 'de') {
    slug = slug.replace('&', 'und')
  } else if (lc === 'en') {
    slug = slug.replace('&', 'and')
  } else if (lc === 'fr') {
    slug = slug.replace('&', 'et')
  } else {
    slug = slug.replace('&', '-')
  }
  slug = slug
    .replace('ä', 'ae')
    .replace('ö', 'oe')
    .replace('ü', 'ue')
    .replace('ß', 'ss')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
  return slug
}

export const isSlug = (slug: string): boolean => {
  const regex = /(^[a-z0-9-_]+$)/
  return regex.test(slug)
}
