import * as configcat from 'configcat-js-ssr'
import { LogLevel } from 'configcat-common'

type ConfigCatClientProps = {
  SDK_KEY: string
}

export const configCatClient = ({ SDK_KEY }: ConfigCatClientProps) => {
  return configcat.getClient(SDK_KEY, configcat.PollingMode.LazyLoad, {
    cacheTimeToLiveSeconds: 600,
    logger:
      process.env.NODE_ENV === 'development'
        ? configcat.createConsoleLogger(LogLevel.Info)
        : null,
  })
}

type IsSupportedSettingProps = {
  key: string
  value: unknown
  defaultFlags: Record<string, unknown>
}
/**
 * Returns whether the given key refers to a valid feature flag
 * from the `defaultFlags` context and if the given value is
 * of the expected type of that featur flag.
 */
export const isSupportedSetting = ({
  key,
  value,
  defaultFlags,
}: IsSupportedSettingProps) => {
  if (!Object.keys(defaultFlags).includes(key)) {
    return false
  }
  return typeof value === typeof defaultFlags[key]
}
