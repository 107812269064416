import React from 'react'
import {
  DefaultMotionSpanElement,
  toniesSpringTransition,
} from '@/tonies-ui/motions/constants'

export const PopIt: NormalizedMotionComponent = ({ children, ...rest }) => {
  return (
    <DefaultMotionSpanElement
      data-testid="popit-motion"
      initial={{ scale: 1 }}
      animate={{ scale: [0.8, 1] }}
      exit={{ scale: 0, opacity: 0 }}
      transition={toniesSpringTransition}
      {...rest}
    >
      {children}
    </DefaultMotionSpanElement>
  )
}
