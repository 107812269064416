import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { ConsentContext } from './ConsentContext'
import { useUrlQuery } from '../urlQuery'

function createInitializationMessage() {
  return 'Usercentrics interface not initialized.'
}

export const ConsentContextProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  // `isInitialized` -> usercentrics is loaded
  const [isInitialized, setIsInitialized] = useState(false)
  // `isConsentRequired` -> user has not yet ansered the CMP Consent Layer
  const [isConsentRequired, setIsConsentRequired] = useState(false)
  const ucUIRef = useRef<Window['UC_UI'] | undefined>()
  const isPending = !isInitialized || (isInitialized && isConsentRequired)
  const showSecondUcUILayer = useCallback(() => {
    const ref = ucUIRef?.current ? ucUIRef.current : window.UC_UI
    return ref && ref.showSecondLayer !== undefined
      ? ref.showSecondLayer()
      : createInitializationMessage()
  }, [ucUIRef])

  useEffect(() => {
    /**
     * initially try to get the cmp initialization and isConsentRequired status
     */
    if (window.UC_UI) {
      if (!ucUIRef.current) {
        ucUIRef.current = window.UC_UI
      }
      if (ucUIRef.current?.isInitialized()) {
        setIsInitialized(true)
      }
      setIsConsentRequired(Boolean(ucUIRef.current.isConsentRequired()))
    } else {
      window.addEventListener(
        'UC_UI_INITIALIZED',
        () => {
          if (!window.UC_UI) {
            return console.log('Ucercentrics not available for initialization.')
          }
          if (window.UC_UI?.isInitialized()) {
            setIsInitialized(true)
          }
          if (window.UC_UI?.isConsentRequired) {
            setIsConsentRequired(Boolean(window.UC_UI.isConsentRequired()))
          }
        },
        { once: true }
      )
    }
  }, [ucUIRef])

  useEffect(() => {
    if (isInitialized) {
      /**
       * The `eventHandler` currently only checks `detail.view` === `NONE`
       * of the `UC_UI_VIEW_CHANGED` event which indicates that the CMP Layer
       * has been closed.
       * In this case we want to check if consent is still required.
       * Other values can be:
       *  - `FIRST_LAYER`
       *  - `PRIVACY_BUTTON`
       *  - `SECOND_LAYER`
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const eventHandler = (e: any) => {
        if (
          (ucUIRef?.current || window?.UC_UI) &&
          e.type === 'UC_UI_VIEW_CHANGED' &&
          e.detail.view === 'NONE'
        ) {
          const isRequired = ucUIRef.current
            ? ucUIRef.current.isConsentRequired()
            : window.UC_UI?.isConsentRequired()
          setIsConsentRequired(Boolean(isRequired))
        }
      }
      window.addEventListener('UC_UI_VIEW_CHANGED', eventHandler)
      return () => {
        window.removeEventListener('UC_UI_VIEW_CHANGED', eventHandler)
      }
    }
  }, [isInitialized, ucUIRef])

  // Show CMP by query param
  const urlQuery = useUrlQuery()
  const showCmpParam = urlQuery.getUrlParamAsSingleton('showCmp')
  useEffect(() => {
    if (isInitialized && showCmpParam) {
      if (!isConsentRequired) {
        showSecondUcUILayer()
      }
      urlQuery.setUrlParam({ showCmp: undefined })
    }
  }, [
    isConsentRequired,
    isInitialized,
    showCmpParam,
    showSecondUcUILayer,
    urlQuery,
  ])

  const value = {
    isPending,
    isInitialized,
    isConsentRequired,
    ucUI: ucUIRef.current,
    showSecondUcUILayer,
  }

  return (
    <ConsentContext.Provider value={value}>{children}</ConsentContext.Provider>
  )
}
