import { CartAction } from '..'
import { BxAddress } from '../../../lib/commercetools/requests/bxTypes'
import { normalizeCart } from '../../../lib/commercetools/normalizers/normalizeCart'
import { setAddress } from '../../../lib/commercetools/requests/carts/setAddress'
import { normalizeAddressViolations } from '../../../utils'
import { AddressType } from '../../../types/normalize/cart'
import { AddressFieldError } from '../../../components/molecules/CheckoutAddressForm/types'

export const setAddressAction =
  (type: AddressType, address: BxAddress): CartAction<AddressFieldError[]> =>
  async ({ lcCC, fetchOrCreateCart, logError, replaceCart }) => {
    const cart = await fetchOrCreateCart()
    const setAddressResponse = await setAddress(
      lcCC,
      type,
      address,
      cart.id,
      cart.version
    )

    if (setAddressResponse.result === 'successful') {
      replaceCart(normalizeCart(setAddressResponse.data, lcCC))
    } else {
      logError(
        setAddressResponse.error,
        'setAddressAction',
        setAddressResponse.data
      )
    }

    return normalizeAddressViolations(
      (setAddressResponse.result === 'request-failed' &&
        setAddressResponse.data?.violations) ||
        []
    )
  }
