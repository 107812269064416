import { useEcomLocale } from '../providers/locale'
import { useCartState } from '../hooks/useCartState'
import { useCountryCodes } from '@boxine/tonies-ui'
import { AddressFormCountry } from '../components/molecules/CheckoutAddressForm/types'
import { BxEcomLocaleConfig } from '../lib/shopApi/requests/bxTypes'
import { getEcomLocaleConfig } from '../config/shopAPI'

export const useBillingCountries = () => {
  const { cart } = useCartState()
  const locale = useEcomLocale()
  const allCountries = useCountryCodes(locale).map(
    // Country codes need to be uppercase here
    ({ label, value }) => ({ label, value: (value as string).toUpperCase() })
  )

  const digitalBillingCountries = getEcomLocaleConfig(
    locale,
    'digitalBillingCountries'
  ) as BxEcomLocaleConfig['commercetools']['digitalBillingCountries']

  const physicalShippingCountries = getEcomLocaleConfig(
    locale,
    'physicalShippingCountries'
  ) as BxEcomLocaleConfig['commercetools']['physicalShippingCountries']

  const billingCountries = cart?.bxLineItemTypes.includes('digital')
    ? digitalBillingCountries
    : physicalShippingCountries

  const countries = billingCountries.map(countryCode => ({
    value: countryCode,
    label: allCountries.find(country => country.value === countryCode)?.label,
  })) as AddressFormCountry[]

  const billingCountryCodes = Object.values(countries).map(
    country => country.value
  )

  const isBillingCountryCode = (cc: unknown): cc is string =>
    typeof cc === 'string' && billingCountryCodes.includes(cc as string)

  return { countries, isBillingCountryCode }
}
