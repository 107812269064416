import styled, { keyframes } from 'styled-components'
import { theme } from '@/tonies-ui/themes/theme'
import { cssTransition } from 'react-toastify'

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  90% {
    opacity: 0.9;
    transform: scale(1.075);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

export const Fade = cssTransition({
  enter: 'enter',
  exit: 'exit',
  collapse: false,
})

export const StyledContainer = styled.div`
  position: fixed;
  z-index: ${theme.zIndex.toast};

  .Toastify__toast-container {
    bottom: 0;
    box-sizing: border-box;
    color: ${theme.colors.white};
    left: 50%;
    margin: 0;
    max-width: 100vw;
    padding: 0 1rem;
    position: fixed;
    transform: translateX(-50%) translate3d(0, 0, 9999px);
    width: auto;
  }

  .Toastify__toast {
    animation-duration: 350ms;
    animation-iteration-count: 1;
    border-radius: 26px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    color: ${theme.colors.darkergrey};
    cursor: pointer;
    direction: ltr;
    display: flex;
    font-family: ${theme.fonts.serif};
    font-size: 0.75rem;
    font-weight: 300;
    justify-content: space-between;
    line-height: 1.33;
    margin: 1rem auto;
    max-height: 50rem;
    min-height: 3rem;
    overflow: hidden;
    padding: 0.5rem 1rem 0.5rem 3rem;
    position: relative;
    min-width: 0;
    max-width: min-content;
  }

  .Toastify__toast.enter {
    animation-name: ${fadeIn};
    animation-timing-function: ease-in-out;
  }

  .Toastify__toast.exit {
    animation-name: ${fadeOut};
    animation-fill-mode: forwards;
  }

  .Toastify__toast .close-icon {
    border-radius: 1rem;
    height: 2rem;
    left: 0.5rem;
    padding: 0.5rem;
    position: absolute;
    top: 0.5rem;
    width: 2rem;
  }

  .Toastify__toast-body {
    align-self: center;
    color: ${theme.colors.darkergrey};
    margin: 0;
    padding: 0;
    user-select: none;
    width: max-content;
  }

  .Toastify__toast--info {
    background-color: ${theme.colors['lightblue-20']};
  }
  .Toastify__toast--info .close-icon {
    background-color: ${theme.colors['lightblue-100']};
  }

  .Toastify__toast--success {
    background-color: ${theme.colors['green-20']};
  }
  .Toastify__toast--success .close-icon {
    background-color: ${theme.colors['green-100']};
  }

  .Toastify__toast--error {
    background-color: ${theme.colors['pink-20']};
  }
  .Toastify__toast--error .close-icon {
    background-color: ${theme.colors.primary};
  }

  /*
    Hint: React Toastify needs this to trigger the exit css transition
    otherwise the toast persists and never fades away

    Do not remove!
  */
  @keyframes Toastify__trackProgress {
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }

  .Toastify__progress-bar--animated {
    animation: Toastify__trackProgress linear 1 forwards;
  }
`
