import { BxCart } from '../bxTypes'
import { fetch } from '../../util/fetch'
import { EcomLocale } from '../../../../config/shopAPI/types'
import { PaymentType } from '../../../../providers/payment/types'
import { GeoIpRegion } from '@/tonies-ui/hooks/useGeoIp'

type Request =
  | {
      geoIpRegion: GeoIpRegion
      custom?: {
        fields: {
          getPaymentMethodsRequest: string
        }
      }
    }
  | Record<string, never>
/**
 * creates a new payment for this shopping cart.
 */
export const createPaymentForCart = async (
  lcCC: EcomLocale,
  cartId: string,
  blockedPaymentMethods: PaymentType[],
  geoIpRegion: GeoIpRegion
) => {
  return fetch<Request, BxCart>({
    query: `/bx/carts/${cartId}/payments`,
    lcCC,
    method: 'post',
    request: blockedPaymentMethods.length
      ? {
          geoIpRegion,
          custom: {
            fields: {
              getPaymentMethodsRequest: JSON.stringify({
                blockedPaymentMethods: blockedPaymentMethods,
              }),
            },
          },
        }
      : { geoIpRegion },
  })
}
