import {
  useSpring,
  useMotionValue,
  useTransform,
  SpringOptions,
  MotionValue,
} from 'framer-motion'

/**
 * Custom hook that tracks the mouse position within a specified element.
 * @param springOptions - Optional options for the spring animation.
 * @returns An object containing event handlers for mouse movement and leaving, as well as the current x and y positions of the mouse and the combined offset.
 */
export default function useMousePosition(springOptions?: SpringOptions) {
  const x: MotionValue<number> = useSpring(useMotionValue(0.5), springOptions)
  const y: MotionValue<number> = useSpring(useMotionValue(0.5), springOptions)

  const offsetX = useTransform(x, [0, 0.5, 1], [1, 0, 1])
  const offsetY = useTransform(y, [0, 0.5, 1], [1, 0, 1])

  const offset = useTransform(() => offsetX.get() + offsetY.get())

  const onMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = event.target as HTMLElement

    const _x = event.nativeEvent.offsetX
    const _y = event.nativeEvent.offsetY

    const _xPercent = _x / target.clientWidth
    const _yPercent = _y / target.clientHeight

    x.set(_xPercent)
    y.set(_yPercent)
  }

  const onMouseLeave = () => {
    x.set(0.5)
    y.set(0.5)
  }

  return { onMouseMove, onMouseLeave, x, y, offset }
}
