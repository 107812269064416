import { toast } from '@/tonies-ui/atoms/Toast'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const useToastErrorMessage = () => {
  const { t } = useTranslation()

  return useCallback(
    (
      toastId: string,
      httpStatus?: string | number,
      poEditorKeyDefault = 'default:TOASTSomethingWentWrong',
      poEditorKeyWithErrorCode = 'default:TOASTSomethingWentWrongWithErrorCode'
    ) => {
      const toastMessage = httpStatus
        ? t(poEditorKeyWithErrorCode, {
            errorCode: httpStatus,
          })
        : t(poEditorKeyDefault)

      toast(toastMessage, 'error', {
        autoClose: false,
        toastId,
      })
    },
    [t]
  )
}
