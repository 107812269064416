import {
  Currency,
  EcomLocale,
  ShopProductCategory,
} from '../../../config/shopAPI/types'

export type NormalizedAvailability =
  | {
      state: 'sold-out'
    }
  | {
      state: 'announced'
      orderFromDate: number
    }
  | {
      state: 'preorderable'
      shippingFromDate: number
    }
  | {
      state: 'orderable'
    }

export type NormalizedImage = {
  src: string
  alt: string
  ratio?: [number, number]
}

export type NormalizedAmount = {
  /** ISO currency code, e.g. 'EUR' */
  currency: Currency
  /** Amount in the ISO currency code, e.g. 8.95 */
  amount: number
  /** Precomputed amount in cents to avoid rounding errors, e.g. 895 for amount = 8.95 */
  centAmount: number
}

export type NormalizedCategory = {
  key: string
  name: string
  slug: string
  parentKey?: string
  // TODO: remove optional after legacy code was removed
  ancestorsCount?: number
  attributes?: {
    name: string
    value?: string | boolean
  }[]
  variantsLabel?: string
  variantsType?: string
}

export type NormalizedEnumSet = NormalizedEnum[]

export const hasSeriesGroupAndSeriesCategoryKeys = [
  'clever-tonies',
  'tonies',
  'tunes',
]

export const supportedProductFlags = [
  'backInStock',
  'bestseller',
  'blackFriday',
  'easter',
  'endOfLife',
  'exclusive',
  'favourite',
  'new',
  'sale',
  'seenOnTv',
  'xmas',
] as const

export type SupportedProductFlag = (typeof supportedProductFlags)[number]

export const isSupportedProductFlag = (
  str: unknown
): str is SupportedProductFlag => {
  if (typeof str !== 'string') {
    return false
  }

  return supportedProductFlags.some(flag => flag === str)
}

export const isSupportedProductFlagEnum = (
  flag: unknown
): flag is NormalizedEnum<SupportedProductFlag> => {
  if (typeof flag !== 'object' || !(flag && 'key' in flag)) return false

  return isSupportedProductFlag(flag?.key)
}

export type NormalizedProductExtended = {
  ageMin?: number // in years
  assignableToAllCreativeTonies?: boolean
  audioLibraryUrl?: string
  audioLibraryAssignUrl?: string
  audioLibraryId?: string
  audioSampleUrl?: string
  authors?: string[]
  availability: NormalizedAvailability
  brand: string
  bundleIdentifier?: string
  bundledProductsExtended?: NormalizedProductExtended[]
  bundledProducts?: NormalizedBundledProduct[]
  color?: string
  colors?: string
  commonAttributes?: string // markdown
  content?: string // multi-line, may be HTML
  copyrights?: string
  description?: string // multi-line, may be HTML
  dimensions?: string
  ecoTax?: NormalizedAmount
  episodeNumber?: string
  flag?: NormalizedEnum<SupportedProductFlag>
  genre?: NormalizedEnum
  gtin?: string
  hasChokingHazardWarning?: boolean
  id: string
  image: NormalizedImage
  images: NormalizedImage[]
  lcCC?: EcomLocale
  maxQuantity?: number // max. quantity in a shopping cart
  metaDescription?: string // multi-line, may be HTML
  metaTitle?: string
  name: string
  normalizedCategories: NormalizedCategory[]
  packageContents?: string // markdown
  path?: string
  price?: NormalizedAmount
  publicationDate?: number // intentionally no Date() to keep the structure JSON-serializable!
  production?: string // markdown
  readers?: string
  runTime?: number // in minutes
  salesId: string
  searchKeywords?: string
  series?: NormalizedEnum
  seriesGroup?: NormalizedEnum
  shopCategory: ShopProductCategory
  sku: string
  slug: string
  soldLastMonth?: number
  strikePrice?: NormalizedAmount
  subName: string
  tracks?: string[]
  theme?: NormalizedEnumSet
  variantLabel?: string
  variantSelectValue?: string
  variantType?: string
  weight?: string
}

export const NormalizedProductKeys = [
  'ageMin',
  'assignableToAllCreativeTonies',
  'audioLibraryAssignUrl',
  'audioLibraryId',
  'audioLibraryUrl',
  'audioSampleUrl',
  'availability',
  'bundleIdentifier',
  'bundledProducts',
  'color',
  'colors',
  'flag',
  'genre',
  'id',
  'image',
  'lcCC',
  'name',
  'normalizedCategories',
  'path',
  'price',
  'salesId',
  'searchKeywords',
  'series',
  'seriesGroup',
  'shopCategory',
  'soldLastMonth',
  'sku',
  'slug',
  'strikePrice',
  'subName',
  'variantLabel',
  'variantSelectValue',
  'variantType',
  'publicationDate',
  'theme',
  'runTime',
  'brand',
  'ecoTax',
  'maxQuantity',
] as const

export type NormalizedProduct = Pick<
  NormalizedProductExtended,
  (typeof NormalizedProductKeys)[number]
>

export const NormalizedBundledProductKeys = [
  'image',
  'name',
  'normalizedCategories',
  'series',
  'shopCategory',
  'subName',
] as const

export type NormalizedBundledProduct = Pick<
  NormalizedProductExtended,
  (typeof NormalizedBundledProductKeys)[number]
>
