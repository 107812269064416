import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { useIsAudioLibraryCheckout } from '../../hooks/useIsAudioLibraryCheckout'
import { getPaymentTypeFlags } from '../../lib/configcat/PaymentTypes'
import { useAuth } from '../auth'
import { useEcomLocale } from '../locale'
import { PaymentIconName, paymentTypes } from '../payment/lib'
import { PaymentType } from '../payment/types'
import {
  defaultPaymentTypesProps,
  PaymentIconContext,
  PaymentTypeContext,
} from './PaymentTypeContext'
import { useNativeApp } from '../../providers/nativeApp'
import { useCartState } from '../../hooks/useCartState'
import { shopConfig } from '../../config/shop'

const blockedPaymentTypesInApp: PaymentType[] = ['googlepay']

/**
 * provides payment types from the configCat config `tonies-next paymentTypes`.
 * {@link https://app.configcat.com/08d9dff3-40f2-46c6-8079-6a2ff9f4c1ac/08da6f16-22bd-4074-8e35-6cd2f152b35f/08d9e0b6-a91e-4f96-8cd7-3ca7c2dfee83}
 *
 * these payment types control
 *  - the available payment methods in Checkout
 *  - the payment icons displayed globally (Footer and Cart)
 *  - the payment icons displayed in Checkout
 * @param {PaymentType[]} paymentTypesPreset - can be used to preset paymentTypes for mocking
 */
export const PaymentTypeContextProvider: FunctionComponent<
  {
    paymentTypesPreset?: PaymentType[]
  } & PropsWithChildren
> = ({ paymentTypesPreset, children }) => {
  const { isApp } = useNativeApp()
  const lcCC = useEcomLocale()
  const { cart } = useCartState()
  const cartCentAmount = cart?.price.total.centAmount || 0
  const paymentMethodThreshold = useMemo(
    () => shopConfig.locales[lcCC].paymentMethodThresholds,
    [lcCC]
  )
  const isAudioLibraryCheckout = useIsAudioLibraryCheckout()
  const [cartContainsTunesOnly, setCartContainsTunesOnly] = useState(
    () => isAudioLibraryCheckout
  )
  const { tunesBlockedPaymentMethods } = useFeatureFlags()
  const [currentPaymentTypes, setCurrentPaymentTypes] = useState<PaymentType[]>(
    () => {
      const res = paymentTypes.filter(
        type =>
          !defaultPaymentTypesProps
            .getBlockedPaymentTypes('checkout')
            .includes(type)
      )
      if (paymentTypesPreset?.length) res.concat(paymentTypesPreset)
      return res as PaymentType[]
    }
  )

  const { isPending, uuid, user } = useAuth()

  const filterPaymentTypesForTunesCheckout = useCallback(
    (type: PaymentType) =>
      !tunesBlockedPaymentMethods.split(',').includes(type),
    [tunesBlockedPaymentMethods]
  )

  const filterPaymentTypesForCartAmountThreshold = useCallback(
    (type: PaymentType) => {
      const threshold = paymentMethodThreshold?.find(
        (item: { type: PaymentType }) => item.type === type
      )
      return !threshold || parseInt(threshold.minimum) <= cartCentAmount
    },
    [cartCentAmount, paymentMethodThreshold]
  )

  const filterPaymentTypesForInAppCheckout = useCallback(
    (type: PaymentType) =>
      blockedPaymentTypesInApp.length
        ? !blockedPaymentTypesInApp.includes(type)
        : true,
    []
  )
  /**
   * to prevent showing the Apple Pay icon to customers with incompatible devices
   * we check for Apple Pay availability following {@link https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/checking_for_apple_pay_availability}
   */
  const filterPaymentTypesForApplePay = useCallback((type: PaymentType) => {
    if (type.match(/.*applepay/)) {
      return !!window.ApplePaySession
    }
    return true
  }, [])
  /**
   * filter for allowed paymentTypes
   * - blocked paymentTypes by threshold
   * - blocked tunes checkout paymentTypes
   * - blocked inApp purchase paymentTypes
   * - blocked ApplePay paymentTypes
   */
  const filteredPaymentTypes = useCallback(
    (paymentTypes: PaymentType[], context: PaymentIconContext) => {
      let paymentTypesResult: PaymentType[] = [...paymentTypes]
      if (context === 'checkout')
        paymentTypesResult = paymentTypesResult.filter(
          filterPaymentTypesForCartAmountThreshold
        )
      if (isAudioLibraryCheckout || cartContainsTunesOnly) {
        paymentTypesResult = paymentTypesResult.filter(
          filterPaymentTypesForTunesCheckout
        )
      }
      if (isApp) {
        paymentTypesResult = paymentTypesResult.filter(
          filterPaymentTypesForInAppCheckout
        )
      }
      return paymentTypesResult.filter(filterPaymentTypesForApplePay)
    },
    [
      filterPaymentTypesForInAppCheckout,
      filterPaymentTypesForTunesCheckout,
      filterPaymentTypesForCartAmountThreshold,
      filterPaymentTypesForApplePay,
      isApp,
      isAudioLibraryCheckout,
      cartContainsTunesOnly,
    ]
  )

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      typeof paymentTypesPreset === 'undefined' &&
      !isPending
    ) {
      getPaymentTypeFlags({
        uuid,
        email: user?.email,
        locale: lcCC,
      }).then(flags => {
        const allTypes: PaymentType[] = Object.keys(flags)
          .filter((flagKey, _index) => flags[flagKey as PaymentType])
          .map(paymentFlag => paymentFlag as PaymentType)
        setCurrentPaymentTypes(allTypes)
      })
    } else {
      if (paymentTypesPreset?.length) setCurrentPaymentTypes(paymentTypesPreset)
    }
  }, [paymentTypesPreset, lcCC, user, uuid, isPending])

  const allowedPaymentTypes = (context: PaymentIconContext) =>
    filteredPaymentTypes(currentPaymentTypes, context)

  const getPaymentIcons = (context: PaymentIconContext): PaymentIconName[] => {
    const paymentTypes = allowedPaymentTypes(context)
    return paymentTypes.length
      ? paymentTypes.reduce(
          (previous, current, _currentIndex, _paymentTypes) => {
            /**
             * cc logos have to be activated separately in ConfigCat and
             * are not infered from generic 'scheme'
             * currently supported
             * - 'visa'
             * - 'cartebancaire'
             * - 'maestro'
             * - 'mc'
             */
            if (current !== 'scheme') {
              /**
               * prevent double Klarna or Bancontact ('bcmc') Icons to be shown
               */
              if (
                current.match(/.*klarna/) &&
                previous.find(iconType => /.*klarna/.test(iconType))
              ) {
                return previous
              }
              if (
                current.match(/.*bcmc/) &&
                previous.find(iconType => /.*bcmc/.test(iconType))
              ) {
                return previous
              }
              previous.push(current as PaymentIconName)
            }
            return previous
          },
          [] as PaymentIconName[]
        )
      : []
  }

  const getBlockedPaymentTypes = (context: PaymentIconContext) =>
    paymentTypes.filter(type => !allowedPaymentTypes(context).includes(type))

  const value = {
    getBlockedPaymentTypes,
    getPaymentIcons,
    setCartContainsTunesOnly,
  }
  return (
    <PaymentTypeContext.Provider value={value}>
      {children}
    </PaymentTypeContext.Provider>
  )
}
