import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useState,
} from 'react'
import { CartAction, cartActionsContext, CartActionsProps } from '.'
import { useCartState } from '../../hooks/useCartState'
import { useQueue } from '../../hooks/useQueue'
import { sentryWithExtras } from '../../hooks/useSentry'
import { normalizeCart } from '../../lib/commercetools/normalizers/normalizeCart'
import { createCart } from '../../lib/commercetools/requests/carts/createCart'
import { getCart } from '../../lib/commercetools/requests/carts/getCart'
import { useEcomLocale } from '../locale'
import * as Cookies from 'js-cookie'
import { useIsAudioLibraryCheckout } from '../../hooks/useIsAudioLibraryCheckout'
import { changeQuantityAction } from './actions/changeQuantityAction'

/**
 * Accepts cart actions from callers and ensures that they will be executed sequentially
 * in FIFO order, one action at a time.
 *
 * Internally reliant on the more generic `useQueue()` hook, but extended to pass common,
 * cart-related parameters to the cart actions at their execution time.
 */
export const CartActionsProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const isAudioLibraryCheckout = useIsAudioLibraryCheckout()
  const lcCC = useEcomLocale()
  const queue = useQueue({
    continueOnError: true, // we don't want a single failed Cart API request (e.g. heavy load, product got unavailable) to block the API for the rest of the page visit
  })
  const { setCart } = useCartState()
  const [error, setError] = useState<Error | undefined>()

  const resetError = () => {
    if (queue.state === 'idle') {
      setError(undefined)
    }
  }

  const push = useCallback(
    (cartAction: CartAction<unknown>) =>
      queue
        .push(() => {
          // reset error
          setError(undefined)
          // We wrap the actual cart action in another function that provides
          // common, cart-related parameters.
          return cartAction({
            lcCC,
            fetchOrCreateCart: async () => {
              const cartId = Cookies.get(
                `bxCartId${
                  isAudioLibraryCheckout ? 'AudioLibraryCheckout' : ''
                }_${lcCC}`
              )
              if (cartId) {
                const response = await getCart(lcCC, cartId)
                if (
                  response.result === 'successful' &&
                  response.data.cartState !== 'Ordered'
                ) {
                  return normalizeCart(response.data, lcCC)
                }
              }
              const response = await createCart(lcCC)
              if (response.result === 'successful') {
                const newCart = normalizeCart(response.data, lcCC)
                setCart(newCart)
                return newCart
              }
              throw response.error
            },
            replaceCart: setCart,
            logError: (error, context, sentryDetails) => {
              setError(error)
              sentryWithExtras(
                context || 'CartActionsProvider',
                error,
                sentryDetails
              )
            },
          })
        })
        .catch(e => {
          setError(e instanceof Error ? e : new Error('' + e))
          sentryWithExtras('CartActionsProvider', e)
          throw e
        }),
    [queue, lcCC, setCart, isAudioLibraryCheckout]
  )

  const removeLineItemsFromCart = useCallback(
    (lineItems: NormalizedCart['lineItems'] | undefined) => {
      if (lineItems && lineItems.length !== 0) {
        return Promise.all(
          lineItems.map(li => push(changeQuantityAction(li.id, 0)))
        ).then(() => {
          return lineItems
        })
      }

      return Promise.resolve([])
    },
    [push]
  )

  return (
    <cartActionsContext.Provider
      value={{
        push: push as CartActionsProps['push'],
        state: queue.state,
        error,
        resetError,
        removeLineItemsFromCart,
      }}
    >
      {children}
    </cartActionsContext.Provider>
  )
}
