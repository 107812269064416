/* eslint-disable sonarjs/prefer-immediate-return */
import {
  NormalizedBundledProductKeys,
  NormalizedProductKeys,
  hasSeriesGroupAndSeriesCategoryKeys,
  isSupportedProductFlagEnum,
} from '../../../types/normalize/product'
import { Currency, EcomLocale } from '../../../config/shopAPI/types'
import { ContentError } from '../../../utils/ContentError'
import { toAltNotation } from '../../../providers/locale/altNotation'
import { lcccRegexPattern } from '../../../providers/locale/NextLocaleProvider'
import { isSlug } from '../../../utils/slugify'
import { currencies } from '../../../config/shopAPI'
import {
  isOsProductEnum,
  isOsProductEnumSet,
  OsProduct,
  OsProductCategory,
  OsProductPriceValue,
} from '../requests/products/types'
import { shopConfig } from '../../../config/shop'
import { newlines2Array } from '../../../utils/textHelpers'

const hasSeriesGroupAndSeries = (p: OsProduct) =>
  hasSeriesGroupAndSeriesCategoryKeys.includes(
    getNormalizedCategories(p)[0].key
  )

/**
 * Type-safe convenience function to fetch an attribute whose value is known
 * to be a string. If the attribute is undefined, or if it has a different
 * type (including string enums), the function returns `undefined`.
 */
const getStringAttribute = (p: OsProduct, name: keyof OsProduct) => {
  const value = p[name]
  return typeof value === 'string' ? value : undefined
}

/**
 * Type-safe convenience function to fetch an attribute whose value is known
 * to be a (stringified) DateTime. If the attribute is undefined, or if it
 * has a different type (including enums), or if the date format is unsupported,
 * the function returns `undefined`. The date is returned as a number (milliseconds
 * since 1 Jan 1970) to ease JSON serializations.
 */
const getDateAttribute = (p: OsProduct, name: keyof OsProduct) => {
  const value = getStringAttribute(p, name)
  return (value && Date.parse(value)) || undefined
}

/**
 * Type-safe convenience function to fetch an attribute whose value is known
 * to be a number. If the attribute is undefined, or if it has a different
 * type (including stringified numbers), the function returns `undefined`.
 */
const getNumberAttribute = (p: OsProduct, name: keyof OsProduct) => {
  const value = p[name]
  return typeof value === 'number' ? value : undefined
}

/**
 * Type-safe convenience function to fetch an attribute whose value is known
 * to be an enum. If the attribute is undefined, or if it has a different
 * type, the function returns `undefined`.
 */
const getEnumAttribute = (p: OsProduct, name: keyof OsProduct) => {
  const value = p[name]
  return isOsProductEnum(value) && value.key && value.name
    ? { key: value.key, label: value.name }
    : undefined
}

/**
 * Type-safe convenience function to fetch an attribute whose value is known
 * to be an enum set. If the attribute is undefined, or if it has a different
 * type, the function returns `undefined`.
 */
const getEnumSetAttribute = (p: OsProduct, name: keyof OsProduct) => {
  const value = p[name]
  return isOsProductEnumSet(value)
    ? value.reduce<NormalizedEnum[]>((acc, curr) => {
        if (curr.key && curr.name) {
          acc.push({ key: curr.key, label: curr.name })
        }
        return acc
      }, [])
    : undefined
}

/**
 * Retrieves the SKU (Stock Keeping Unit) from the given OsProduct object.
 * If the SKU is not found, an empty string is returned.
 *
 * @param p - The OsProduct object.
 * @returns The SKU of the product, or an empty string if not found.
 */
const getSku = (p: OsProduct) => {
  return getStringAttribute(p, 'sku') || ''
}

export const normalizeCategory = (
  category: OsProductCategory,
  lcCC: EcomLocale,
  includeAttributes?: boolean
): NormalizedCategory => {
  const length = category.items.length
  const currentCategory = category.items[length - 1]
  const key = currentCategory.key || ''
  const variantsLabel = currentCategory.variantsLabel || ''
  const variantsType = currentCategory.variantsType || ''
  const parentKey = length > 1 ? category.items[length - 2].key : undefined
  if (!key.trim().length) {
    const err = new ContentError({
      type: 'CommercetoolsCategoryKeyEmpty',
      id: currentCategory.id,
      locale: lcCC,
    })
    console.error(err.message)
  }

  const name = currentCategory.name || ''
  if (!name.trim().length) {
    const err = new ContentError({
      type: 'CommercetoolsCategoryNameEmpty',
      id: currentCategory.id,
      key: key,
      locale: lcCC,
    })
    console.error(err.message)
  }

  const slug = currentCategory.slug || ''
  if (!slug.trim().length) {
    const err = new ContentError({
      type: 'CommercetoolsCategorySlugEmpty',
      id: currentCategory.id,
      key: key,
      locale: lcCC,
    })
    console.error(err.message)
  } else if (!isSlug(slug)) {
    const err = new ContentError({
      type: 'CommercetoolsCategorySlugInvalid',
      id: currentCategory.id,
      key: key,
      slug: slug,
      locale: lcCC,
    })
    console.error(err.message)
  }

  // TODO: Refactor usage of attributes after migration to opensearch is complete
  // This is a temporary solution to avoid breaking changes, so the PDP won't render empty
  const attributes: (keyof typeof currentCategory)[] = [
    'productsCommonAttributes',
    'productsWarning',
    'shortName',
    'logoUrl',
  ]

  const mappedAttributes = includeAttributes
    ? attributes.map(attribute => {
        const value = currentCategory[attribute]

        return {
          name: attribute,
          value: value !== null && value,
        }
      })
    : undefined

  return {
    key,
    name,
    slug,
    attributes: mappedAttributes,
    parentKey: parentKey || undefined,
    ancestorsCount: length - 1,
    variantsLabel,
    variantsType,
  }
}

/**
 * Normalize a product's categories
 */
const getNormalizedCategories = (p: OsProduct, includeAttributes?: boolean) => {
  const value = p.categories || []

  if (!value || value.length === 0) {
    const err = new ContentError({
      type: 'CommercetoolsProductCategoriesEmpty',
      id: p.productId,
      sku: getSku(p),
      locale: p.shopLocale,
    })
    console.error(err.message)
  }

  const normalizedCategories: NormalizedCategory[] = value
    .map(category => {
      return normalizeCategory(category, p.shopLocale, includeAttributes)
    })
    .sort(({ ancestorsCount: a = 0 }, { ancestorsCount: b = 0 }) =>
      a > b ? 1 : -1
    )

  if (
    hasSeriesGroupAndSeriesCategoryKeys.includes(normalizedCategories[0].key) &&
    /**
     * must have 3 categories
     * second category must be a child of "characters"
     * third category must be a child of second category
     */
    (normalizedCategories.length !== 3 ||
      normalizedCategories[1].parentKey !== 'characters' ||
      normalizedCategories[2].parentKey !== normalizedCategories[1].key)
  ) {
    new ContentError({
      type: 'CommercetoolsProductCharacterSeriesCategoriesInvalid',
      id: p.productId,
      sku: getSku(p),
      locale: p.shopLocale,
    })
  }

  return normalizedCategories
}

/**
 * TODO by BE: TWAS-4666
 * https://boxine.atlassian.net/browse/TWAS-4666
 *
 * Generic helper to resolve the category attribute with the given name.
 * Eventual translations are automatically resolved with the locale parameter.
 *
 * Some category attributes can be maintained on all nested category levels,
 * so that e.g. an attribute defined on the first level can be overwritten on
 * the second or third ... level when needed.
 *
 * We therefor need to search in all categories in a reversed order to find
 * the attribute's value that should be returned.
 */

/**
 * Retrieves the value of a specific property from the normalized category of a given OsProduct.
 *
 * @template T - The type of the property to retrieve.
 * @param p - The OsProduct object.
 * @param name - The name of the property to retrieve.
 * @returns The value of the specified property, or undefined if not found.
 */
const getCategoryKey = <T extends keyof NormalizedCategory>(
  p: OsProduct,
  name: T
) => {
  const categories = getNormalizedCategories(p, true).reverse()

  for (let i = 0; i < categories.length; i++) {
    const value = categories[i][name]
    if (value) {
      return value
    }
  }
  return undefined
}

/**
 * This function finds the MOST SPECIFIC shop category of the given product
 * by using the last category of all normalizedCategories.
 *
 * Example: a Carrier product gets `carrier`, not `accessories`.
 *
 * Exception: Tonies, Audio Content, Clever Tonies and Repairs have additional categories assignded,
 * but their shopCategory should always be the `root` category.
 */
const getShopCategory = (p: OsProduct) => {
  const normalizedCategories = getNormalizedCategories(p)

  if (
    ['tonies', 'tunes', 'clever-tonies', 'repairs'].includes(
      normalizedCategories[0].key
    )
  ) {
    return normalizedCategories[0].key
  }

  return normalizedCategories[normalizedCategories.length - 1].key
}

const getSeries = (p: OsProduct): NormalizedEnum | undefined => {
  if (hasSeriesGroupAndSeries(p)) {
    const value = p.series
    if (value && value.key && value.name) {
      return {
        key: value.key,
        label: value.name,
      }
    }
  }

  return undefined
}

const getSeriesGroup = (p: OsProduct): NormalizedEnum | undefined => {
  if (hasSeriesGroupAndSeries(p)) {
    const value = p.character
    if (value && value.key && value.name) {
      return {
        key: value.key,
        label: value.name,
      }
    }
  }

  return undefined
}

const getSubName = (p: OsProduct) => {
  if (hasSeriesGroupAndSeries(p)) {
    return getSeriesGroup(p)?.label || ''
  }

  const normalizedCategories = getNormalizedCategories(p)
  if (
    normalizedCategories[0].key === 'accessories' &&
    normalizedCategories.length > 1
  ) {
    return normalizedCategories[1].name
  }
  return normalizedCategories[0].name
}

const getImages = (p: OsProduct): NormalizedImage[] => {
  let value = p.images

  // add a fallback image if we do not get images from the API
  if (!value || value.length === 0) {
    value = [
      {
        url: shopConfig.assets.product,
        label: 'missing image',
        width: 1200,
        height: 900,
      },
    ]

    const err = new ContentError({
      type: 'CommercetoolsProductImagesEmpty',
      id: p.productId,
      sku: getSku(p),
      locale: p.shopLocale,
    })
    console.error(err.message)
  }

  const images = value.map(({ url, label }) => ({
    src: url,
    alt: label || '',
  }))

  /**
   * For bundles, we create a combined set of images:
   * 1. All wrapper product images
   * 2. One image of every bundled product
   */
  // const bundledProducts = getBundledProducts(p, lcCC)
  // if (bundledProducts) {
  //   bundledProducts.forEach(p => {
  //     images.push({
  //       src: p.image.src,
  //       alt: p.name,
  //     })
  //   })
  // }

  return images
}

/**
 * For Tonies we want the second image to be displayed on cards and lineitems.
 * All other products should show the first image.
 */
const getImage = (p: OsProduct): NormalizedImage => {
  const value = getImages(p)
  const shopCategory = getShopCategory(p)
  return value.length === 1
    ? value[0]
    : shopCategory === 'tonies'
    ? value[1]
    : value[0]
}

const getColor = (p: OsProduct) => {
  let value = getStringAttribute(p, 'colorcode')

  // RGB color code validation
  if (typeof value === 'string') {
    // Trim whitespace, convert uppercase characters, remove hashes
    value = value.trim().toLowerCase().replace('#', '')

    // Check if remaining color code matches HTML RGB notation
    if (value.match(/^[0-9a-f]{3}([0-9a-f]{3})?$/)) {
      // Add hash prefix
      return '#' + value
    }
  }

  return undefined
}

const getAgeMin = (p: OsProduct) => {
  const value = getNumberAttribute(p, 'ageMin')

  if (typeof value === 'number' && value > 0) {
    return value
  }

  return undefined
}

export const getAvailability = (p: OsProduct): NormalizedAvailability => {
  // The following checks implement the ruleset defined in TWAS-2169.
  // Since they are consecutive, please DO NOT REORDER!

  const now = new Date().getTime()
  const orderFromDate = getDateAttribute(p, 'orderFrom')

  if (typeof orderFromDate === 'number' && orderFromDate > now) {
    return {
      state: 'announced',
      orderFromDate,
    }
  }

  const shippingFromDate = getDateAttribute(p, 'shippingFrom')

  if (typeof shippingFromDate === 'number' && shippingFromDate > now) {
    return {
      state: 'preorderable',
      shippingFromDate,
    }
  }

  const hasStock = p.available

  if (!hasStock) {
    return {
      state: 'sold-out',
    }
  }

  return {
    state: 'orderable',
  }
}

const getName = (p: OsProduct) => {
  const value = getStringAttribute(p, 'name') || ''
  const lcCC = p.shopLocale

  if (!value?.trim().length) {
    const err = new ContentError({
      type: 'CommercetoolsProductNameEmpty',
      id: p.productId,
      sku: getSku(p),
      locale: lcCC,
    })
    console.error(err.message)
  }

  // Prepend a "[LC]" for Tonies where the language differs from the shop language.
  // We are only interested in the language part (lc) for this and not in the country (CC).
  const shopLc = toAltNotation(lcCC, 'lc')
  // Fallback here to the shop's lcCC, because only Tonies have the language attribute
  const productLc = toAltNotation(getLcCC(p) || lcCC, 'lc')
  if (shopLc !== productLc) {
    return `[${productLc.toUpperCase()}] ${value}`
  }

  return value
}

const getSlug = (p: OsProduct) => {
  const value = getStringAttribute(p, 'slug') || ''

  if (!value.trim().length) {
    const err = new ContentError({
      type: 'CommercetoolsProductSlugEmpty',
      id: p.productId,
      sku: getSku(p),
      locale: p.shopLocale,
    })
    console.error(err.message)
  } else if (!isSlug(value)) {
    const err = new ContentError({
      type: 'CommercetoolsProductSlugInvalid',
      id: p.productId,
      sku: getSku(p),
      slug: value,
      locale: p.shopLocale,
    })
    console.error(err.message)
  }

  return value
}

const getPath = (p: OsProduct) => {
  // A small patch to fix a BE problem: empty path segments result in multiple slashes, which next.js won't accept
  const value = p.productUrl?.replace(/\/+/g, '/') || ''

  if (!value.trim().length) {
    const err = new ContentError({
      type: 'CommercetoolsProductPathEmpty',
      id: p.productId,
      sku: getSku(p),
      locale: p.shopLocale,
    })
    console.error(err.message)
  }

  return value
}

const getNormalizedPrice = (price: OsProductPriceValue) => {
  if (price.type !== 'centPrecision') {
    return undefined
  }

  const currency = price.currencyCode as Currency

  if (
    !currencies.includes(currency) ||
    !price.fractionDigits ||
    !price.centAmount
  ) {
    return undefined
  }

  const result: NormalizedAmount = {
    // FIXME: The OS is providing the amount. Do we need to convert it?
    amount: price.centAmount / Math.pow(10, price.fractionDigits),
    centAmount: price.centAmount,
    currency,
  }

  return result
}

const getPrice = (p: OsProduct) => {
  const value = p.price.value
  return value ? getNormalizedPrice(value) : undefined
}

const getStrikePrice = (p: OsProduct) => {
  const value = p.price.strikeValue
  return value ? getNormalizedPrice(value) : undefined
}

export const getDescription = (p: OsProduct) =>
  getStringAttribute(p, 'description')

const getSalesId = (p: OsProduct) => {
  const value = getStringAttribute(p, 'salesId') || ''

  if (!value.trim().length) {
    const err = new ContentError({
      type: 'CommercetoolsProductSalesIdEmpty',
      id: p.productId,
      sku: getSku(p),
      locale: p.shopLocale,
    })
    console.error(err.message)
  }

  return value
}

const getTracks = (p: OsProduct) => {
  const value = getStringAttribute(p, 'tracks')

  if (typeof value !== 'string') {
    return undefined
  }

  return newlines2Array(value)
}

const getAudioLibraryId = (p: OsProduct) => {
  return getStringAttribute(p, 'productKey')
}

const getMaxQuantity = (p: OsProduct) => {
  // TODO: remove after it is maintained in commercetools
  // only temporary work-around
  if (['tunes'].includes(getShopCategory(p))) {
    return 1
  }

  return getNumberAttribute(p, 'maxQuantity')
}

const getEcoTax = (p: OsProduct): NormalizedAmount | undefined => {
  if (p.shopLocale !== 'fr-FR') {
    // Applies only to France
    return undefined
  }

  const categories = getNormalizedCategories(p)

  for (const category of categories) {
    if (category.key === 'headphones') {
      return {
        currency: 'EUR',
        centAmount: 2,
        amount: 0.02,
      }
    }

    if (category.key === 'tonieboxes') {
      return {
        currency: 'EUR',
        centAmount: 13,
        amount: 0.13,
      }
    }
  }

  return undefined
}

// const getCommonAttributes = (p: OsProduct) => {
//   /**
//    * The common attributes (aka "blue box") are set on a category level for all
//    * products of that category, but can be overwritten on a product level, e.g.
//    * all Tonies are "plastic" and "hand-painted" except for Steiff Tonies.
//    */
//   const value = getStringAttribute(p, 'productsCommonAttributes')
//   if (value && value.trim().length) {
//     return value
//   }

//   return getCategoryStringAttribute(p, 'productsCommonAttributes')
// }

// const getHasChokingHazardWarning = (p: OsProduct) => {
//   /**
//    * The choking hazard warning is set on a category level for all products
//    * of that category, but can be overwritten on a product level, e.g.
//    * all Tonies should have this warning except for Steiff Tonies.
//    */
//   const value = getBooleanAttribute(p, 'hasChokingHazardWarning')
//   if (value !== undefined) {
//     return value
//   }

//   return getCategoryBooleanAttribute(p, 'hasChokingHazardWarning')
// }

const getFlag = (p: OsProduct) => {
  const value = getEnumAttribute(p, 'flag')

  if (isSupportedProductFlagEnum(value)) {
    return value
  }

  return undefined
}

export const getLcCC = (p: OsProduct) => {
  // "language" is lc-cc in commercetools for compatibility with the Octonie.
  const lccc = p.language?.key

  // We convert this to a Locale here for better compatibility with our code.
  if (lccc) {
    if (!lccc.match(new RegExp(lcccRegexPattern))) {
      console.error(`Invalid language '${lccc}' for product ${p.productId}`)
      return undefined
    }

    const [lc, cc] = lccc.split('-')
    return (lc + '-' + cc.toUpperCase()) as EcomLocale
  }
}

/**
 * TODO GTMV4
 * Brand could become important later for GA4, e.g. for products like
 * Affenzahn, Steiff, import src from '../../../types/audio.d';
Sterntaler, ...
 * This is some prework for this. For now, we only return 'Tonies' here.
 */
const getBrand = () => {
  return 'Tonies'
}

const getSearchKeywords = (p: OsProduct) => {
  const searchKeywordsArray = p.searchKeywords

  if (searchKeywordsArray && searchKeywordsArray.length > 0) {
    return searchKeywordsArray.join(' ')
  }

  return undefined
}

/**
 * Retrieves the bundled products from the given OsProduct.
 * @param p - The OsProduct object.
 * @returns An array of normalized bundled products, or undefined if there are no bundled products.
 */
const getBundledProducts = (p: OsProduct) => {
  if (p.bundledProducts && p.bundledProducts.length > 0) {
    return p.bundledProducts.map(normalizeProductExtended)
  }

  return undefined
}

/**
 * Retrieves the bundled products extended information from the given OsProduct.
 * If the OsProduct has bundled products, it returns an array of normalized bundled products.
 * Otherwise, it returns undefined.
 *
 * @param p - The OsProduct to retrieve bundled products from.
 * @returns An array of normalized bundled products or undefined.
 */
const getBundledProductsExtended = (p: OsProduct) => {
  if (p.bundledProducts && p.bundledProducts.length > 0) {
    return p.bundledProducts.map(normalizeProductExtended)
  }

  return undefined
}

/**
 * Retrieves the runtime of a product.
 * @param p - The product object.
 * @returns The runtime of the product.
 */
const getRuntime = (p: OsProduct) => {
  const value = p.runTime || undefined
  const shopCategory = getShopCategory(p)

  // FIXME: This is a temporary workaround until the BE provides the correct values for tonies and clever-tonies which are in minutes instead of seconds.
  // Also see: lib/commercetools/normalizers/normalizeProduct.ts
  // See ticket: https://boxine.atlassian.net/browse/TWAS-5566
  // See conversation: https://boxine.slack.com/archives/C04JZPYKHKR/p1715155431872469
  if (value && shopCategory === 'tunes') {
    return Math.round(value / 60)
  }

  return value
}

/**
 * Based on the original CommerceTools ProductsApiProduct this function converts
 * an `OsProduct` to a leaner, type-safe version that contains only the attributes
 * that are actually needed by the shop.
 */
export const normalizeProductExtended = (
  p: OsProduct
): NormalizedProductExtended => ({
  ageMin: getAgeMin(p),
  assignableToAllCreativeTonies: p.assignableToAllCreativeTonies ?? undefined,
  audioLibraryUrl: p.audioLibraryAssignUrl?.replace('/assign', ''),
  audioLibraryAssignUrl: getStringAttribute(p, 'audioLibraryAssignUrl'),
  audioLibraryId: getAudioLibraryId(p),
  audioSampleUrl: getStringAttribute(p, 'audioSampleUrl'),
  availability: getAvailability(p),
  brand: getBrand(),
  bundleIdentifier: getStringAttribute(p, 'bundleIdentifier'),
  bundledProductsExtended: getBundledProductsExtended(p),
  bundledProducts: getBundledProducts(p),
  color: getColor(p),
  colors: getStringAttribute(p, 'colors'),
  // TODO: add commonAttributes / missing in API
  // commonAttributes: getCommonAttributes(p),
  copyrights: getStringAttribute(p, 'copyrights'),
  description: getDescription(p),
  dimensions: getStringAttribute(p, 'dimensions'),
  episodeNumber: getStringAttribute(p, 'episodeNumber'),
  ecoTax: getEcoTax(p),
  flag: getFlag(p),
  genre: getEnumAttribute(p, 'genre'),
  gtin: getStringAttribute(p, 'gtin'),
  hasChokingHazardWarning: p.hasChokingHazardWarning ?? undefined,
  id: p.productId,
  image: getImage(p),
  images: getImages(p),
  lcCC: getStringAttribute(p, 'shopLocale') as EcomLocale,
  metaDescription: getStringAttribute(p, 'metaDescription'),
  metaTitle: getStringAttribute(p, 'metaTitle'),
  maxQuantity: getMaxQuantity(p),
  name: getName(p) || '',
  normalizedCategories: getNormalizedCategories(p),
  packageContents: getStringAttribute(p, 'packageContents'),
  path: getPath(p),
  price: getPrice(p),
  production: getStringAttribute(p, 'production'),
  publicationDate: getDateAttribute(p, 'publicationDate'),
  readers: getStringAttribute(p, 'readers'),
  runTime: getRuntime(p),
  salesId: getSalesId(p),
  searchKeywords: getSearchKeywords(p),
  series: getSeries(p),
  seriesGroup: getSeriesGroup(p),
  shopCategory: getShopCategory(p),
  sku: getStringAttribute(p, 'sku') || '',
  slug: getSlug(p) || '',
  soldLastMonth: getNumberAttribute(p, 'soldLastMonth'),
  strikePrice: getStrikePrice(p),
  subName: getSubName(p),
  theme: getEnumSetAttribute(p, 'theme'),
  tracks: getTracks(p),
  variantLabel: getStringAttribute(p, 'variantLabel'),
  variantType: getCategoryKey(p, 'variantsType'),
  /** FIXME: as we are dealing with the product variant, this should be the same as the `name` attribute? */
  variantSelectValue: getStringAttribute(p, 'name'),
  weight: getStringAttribute(p, 'weight'),
})

const reduceObject = <
  ExtendedType extends object,
  ReduceKeysArray extends readonly (keyof ExtendedType)[]
>(
  obj: ExtendedType,
  keys: ReduceKeysArray
): Pick<ExtendedType, ReduceKeysArray[number]> =>
  Object.entries(obj).reduce((prev, curr): ExtendedType => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [key] = curr as [keyof ExtendedType, any]

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!keys.includes(key as any)) {
      delete prev[key]
    }

    return prev
  }, obj)

export const normalizeProduct = (
  p: Parameters<typeof normalizeProductExtended>[0]
) => reduceObject(normalizeProductExtended(p), NormalizedProductKeys)

export const normalizeBundledProduct = (
  p: Parameters<typeof normalizeProductExtended>[0]
) => reduceObject(normalizeProductExtended(p), NormalizedBundledProductKeys)
