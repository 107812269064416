import { submitAdditionalPaymentDetails } from '../../../lib/commercetools/requests/payments/submitAdditionalPaymentDetails'
import { CartAction } from '..'
import { FetchResult } from '../../../lib/commercetools/util/fetch'
import { PaymentResponse } from '../../../lib/commercetools/requests/payments/types'

type SubmitAdditionalPaymentDetailsForCartActionProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalPaymentDetails: any
}

export const submitAdditionalPaymentDetailsForCartAction =
  ({
    additionalPaymentDetails,
  }: SubmitAdditionalPaymentDetailsForCartActionProps): CartAction<
    FetchResult<PaymentResponse> | undefined
  > =>
  async ({ fetchOrCreateCart, lcCC, replaceCart, logError }) => {
    // make sure to have the latest current payment id and version
    const previousCart = await fetchOrCreateCart()
    const previousCartPaymentId = previousCart.currentPayment?.id
    const previousCartPaymentVersion = previousCart.currentPayment?.version

    if (!previousCartPaymentId || !previousCartPaymentVersion) {
      const error = new Error('missing currentPayment on cart')
      logError(error, 'submitAdditionalPaymentDetailsAction')
      return
    }

    const submitAdditionalPaymentDetailsResponse =
      await submitAdditionalPaymentDetails(
        lcCC,
        previousCartPaymentId,
        previousCartPaymentVersion,
        additionalPaymentDetails
      )

    if (submitAdditionalPaymentDetailsResponse.result !== 'successful') {
      logError(
        submitAdditionalPaymentDetailsResponse.error,
        'submitAdditionalPaymentDetailsAction',
        submitAdditionalPaymentDetailsResponse.data
      )
      return submitAdditionalPaymentDetailsResponse
    }
    const nextCart = await fetchOrCreateCart()

    replaceCart(nextCart)
    return submitAdditionalPaymentDetailsResponse
  }
