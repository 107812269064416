import React from 'react'
import { toniesSpringTransition } from '@/tonies-ui/motions/constants'
import { motion } from 'framer-motion'
import { Styleable } from '@boxine/tonies-ui/dest/types/styles/Styleable'

export const SlideIt: NormalizedMotionComponent<Styleable> = ({
  children,
  ...rest
}) => {
  return (
    <motion.div
      data-testid="slideit-motion"
      initial={{ x: '100%' }}
      animate={{ x: 0 }}
      exit={{ x: '100%' }}
      transition={toniesSpringTransition}
      {...rest}
    >
      {children}
    </motion.div>
  )
}
