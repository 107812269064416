import { ValitaError } from '@badrap/valita'
import {
  notifyContentfulEditors,
  NotifyContentfulEditors,
} from '../gitlab/lib/slack-notifications'
import { getHumanReadableValitaIssueMessage } from './valita'
import { Locale } from '../config/shopAPI/types'

export type Cause =
  | {
      type: 'ContentfulSchemaViolation'
      locale?: Locale
      slug: string
      issues: ValitaError['issues']
      level?: NotifyContentfulEditors['level']
    }
  | {
      type: 'ContentfulSkuResolutionFailure'
      locale: Locale
      slug: string
      invalidSkus: string[]
      level?: NotifyContentfulEditors['level']
    }
  | {
      type: 'ContentfulSkuListEmpty'
      locale: Locale
      slug: string
      level?: NotifyContentfulEditors['level']
    }
  | {
      type: 'CommercetoolsProductCategoriesEmpty'
      locale: Locale
      id: string
      sku: string
      level?: NotifyContentfulEditors['level']
    }
  | {
      type: 'CommercetoolsProductCharacterSeriesCategoriesInvalid'
      locale: Locale
      id: string
      sku: string
      level?: NotifyContentfulEditors['level']
    }
  | {
      type: 'CommercetoolsProductSkuEmpty'
      locale: Locale
      id: string
      level?: NotifyContentfulEditors['level']
    }
  | {
      type: 'CommercetoolsProductSkuInvalid'
      locale: Locale
      id: string
      sku: string
      level?: NotifyContentfulEditors['level']
    }
  | {
      type: 'CommercetoolsProductNameEmpty'
      locale: Locale
      id: string
      sku: string
      level?: NotifyContentfulEditors['level']
    }
  | {
      type: 'CommercetoolsProductSlugEmpty'
      locale: Locale
      id: string
      sku: string
      level?: NotifyContentfulEditors['level']
    }
  | {
      type: 'CommercetoolsProductSlugInvalid'
      locale: Locale
      id: string
      sku: string
      slug: string
      level?: NotifyContentfulEditors['level']
    }
  | {
      type: 'CommercetoolsProductSalesIdEmpty'
      locale: Locale
      id: string
      sku: string
      level?: NotifyContentfulEditors['level']
    }
  | {
      type: 'CommercetoolsProductImagesEmpty'
      locale: Locale
      id: string
      sku: string
      level?: NotifyContentfulEditors['level']
    }
  | {
      type: 'CommercetoolsProductPathEmpty'
      locale: Locale
      id: string
      sku: string
      level?: NotifyContentfulEditors['level']
    }
  | {
      type: 'CommercetoolsCategoryKeyEmpty'
      locale: Locale
      id: string
      level?: NotifyContentfulEditors['level']
    }
  | {
      type: 'CommercetoolsCategoryNameEmpty'
      locale: Locale
      id: string
      key: string
      level?: NotifyContentfulEditors['level']
    }
  | {
      type: 'CommercetoolsCategorySlugEmpty'
      locale: Locale
      id: string
      key: string
      level?: NotifyContentfulEditors['level']
    }
  | {
      type: 'CommercetoolsCategorySlugInvalid'
      locale: Locale
      id: string
      key: string
      slug: string
      level?: NotifyContentfulEditors['level']
    }
  | {
      type: 'CommercetoolsCategoryObjectEmpty'
      locale: Locale
      id: string
      level?: NotifyContentfulEditors['level']
    }
  | {
      type: 'Generic'
      error: unknown
      level?: NotifyContentfulEditors['level']
    }

export type Options = {
  sendSlackNotification: boolean
}

export const defaultOptions: Options = {
  /**
   * Posts this error in the editors Slack channel.
   * Limitation: only supported for errors that were found on the server side.
   */
  sendSlackNotification: true,
}

/**
 * Use this custom Error class to collect and report all content-related problems that
 * might occur during the build and should be reported to the editors via Slack.
 */
export class ContentError extends Error {
  /**
   * the private field `#cause` was added because using the reserved keyword `private`
   * on the class constructor yields storybook build errors
   * see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Classes/Private_class_fields}
   */
  #cause: Cause

  constructor(cause: Cause, options = defaultOptions) {
    super(
      [
        ContentError.formatTitle(cause),
        ...(ContentError.formatIssues(cause) || []),
      ]
        .filter(Boolean)
        .join('; ')
    )
    this.#cause = cause
    if (options.sendSlackNotification) {
      ContentError.sendSlackNotification(cause)
    }
  }

  private static sendSlackNotification(cause: Cause) {
    if (
      /**
       * ensure that this code gets removed from client-side bundles
       * see: {@link https://github.com/vercel/next.js/issues/5354}
       * not using deprecated `!process.browser` but `typeof window === 'undefined`
       * and: {@link https://github.com/vercel/next.js/issues/5354#issuecomment-520305040}
       * see: {@link https://github.com/vercel/next.js/pull/7651}
       */
      typeof window === 'undefined' &&
      // Prevent Slack notifications in Contract tests
      process.env.NODE_ENV !== 'test' &&
      // Prevent Slack notifications in local environments
      process.env.NEXT_PUBLIC_ENVIRONMENT !== 'local' &&
      process.env.NEXT_PUBLIC_ENVIRONMENT !== 'preview' &&
      // don't send messages at night in the hour when our commercetools copy script is running
      // 4am German time -> 2am UTC time
      new Date().getUTCHours() !== 2
    ) {
      notifyContentfulEditors({
        locale: 'locale' in cause ? cause.locale : undefined,
        title: ContentError.formatTitle(cause),
        body: ContentError.formatIssues(cause)
          ?.map(issue => `\n - ${issue}`)
          .join(''),
        level: cause.level,
      })
    }
  }

  private static formatTitle(cause: Cause) {
    switch (cause.type) {
      case 'ContentfulSchemaViolation':
        return `${cause.locale} | Contentful | SCHEMA violation for page (slug) "${cause.slug}"`
      case 'ContentfulSkuResolutionFailure':
        return `${cause.locale} | Contentful | SKU resolution failure for page (slug) "${cause.slug}"`
      case 'ContentfulSkuListEmpty':
        return `${cause.locale} | Contentful | SKU list empty for page (slug) "${cause.slug}"`
      case 'CommercetoolsProductSkuEmpty':
        return `${cause.locale} | Commercetools | PRODUCT sku empty for (id) "${cause.id}"`
      case 'CommercetoolsProductSkuInvalid':
        return `${cause.locale} | Commercetools | PRODUCT sku invalid "${cause.sku}" for (id) "${cause.id}"`
      case 'CommercetoolsProductCategoriesEmpty':
        return `${cause.locale} | Commercetools | PRODUCT categories empty for (id) "${cause.id}" (sku) "${cause.sku}"`
      case 'CommercetoolsProductCharacterSeriesCategoriesInvalid':
        return `${cause.locale} | Commercetools | PRODUCT character and / or series categories invalid for (id) "${cause.id}" (sku) "${cause.sku}"`
      case 'CommercetoolsProductNameEmpty':
        return `${cause.locale} | Commercetools | PRODUCT name empty for (id) "${cause.id}" (sku) "${cause.sku}"`
      case 'CommercetoolsProductSlugEmpty':
        return `${cause.locale} | Commercetools | PRODUCT slug empty for (id) "${cause.id}" (sku) "${cause.sku}"`
      case 'CommercetoolsProductSlugInvalid':
        return `${cause.locale} | Commercetools | PRODUCT slug invalid "${cause.slug}" for (id) "${cause.id}" (sku) "${cause.sku}"`
      case 'CommercetoolsProductSalesIdEmpty':
        return `${cause.locale} | Commercetools | PRODUCT salesId (Artikelnummer) empty for (id) "${cause.id}" (sku) "${cause.sku}"`
      case 'CommercetoolsProductImagesEmpty':
        return `${cause.locale} | Commercetools | PRODUCT images empty for (id) "${cause.id}" (sku) "${cause.sku}"`
      case 'CommercetoolsProductPathEmpty':
        return `${cause.locale} | Commercetools | PRODUCT path (bxProductUrl) empty for (id) "${cause.id}" (sku) "${cause.sku}"`
      case 'CommercetoolsCategoryKeyEmpty':
        return `${cause.locale} | Commercetools | CATEGORY key empty for (id) "${cause.id}"`
      case 'CommercetoolsCategoryObjectEmpty':
        return `${cause.locale} | Commercetools | CATEGORY object empty for (id) "${cause.id}"`
      case 'CommercetoolsCategoryNameEmpty':
        return `${cause.locale} | Commercetools | CATEGORY name empty for (key) "${cause.key}" (id) "${cause.id}"`
      case 'CommercetoolsCategorySlugEmpty':
        return `${cause.locale} | Commercetools | CATEGORY slug empty for (key) "${cause.key}" (id) "${cause.id}"`
      case 'CommercetoolsCategorySlugInvalid':
        return `${cause.locale} | Commercetools | CATEGORY slug invalid "${cause.slug}" for (key) "${cause.key}" (id) "${cause.id}"`
      case 'Generic':
        return `Content Error: "${cause.error}"`
    }
  }

  private static formatIssues(cause: Cause) {
    let issues: string[] | undefined

    if (cause.type === 'ContentfulSchemaViolation') {
      issues = cause.issues.map(getHumanReadableValitaIssueMessage)
    } else if (cause.type === 'ContentfulSkuResolutionFailure') {
      issues = cause.invalidSkus.map(
        sku => `"${sku}" not found or unpublished in Commercetools`
      )
    }

    return issues
  }

  get title() {
    return ContentError.formatTitle(this.#cause)
  }

  get issues() {
    return ContentError.formatIssues(this.#cause)
  }
}
