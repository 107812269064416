import React, {
  FunctionComponent,
  PropsWithChildren,
  useMemo,
  useState,
} from 'react'
import { DiscountCodeInputContext } from '.'

export const DiscountCodeInputProvider: FunctionComponent<
  PropsWithChildren
> = ({ children }) => {
  const [discountCodeInput, setDiscountCodeInput] = useState<
    string | undefined
  >(undefined)

  const value = useMemo(
    () => ({
      discountCodeInput,
      setDiscountCodeInput,
    }),
    [discountCodeInput]
  )

  return (
    <DiscountCodeInputContext.Provider value={value}>
      {children}
    </DiscountCodeInputContext.Provider>
  )
}
