import {
  useNormalizeFilter,
  generateLegacyProductFilterSet,
  getActiveFilters,
} from '../providers/productOverview/actions'
import { useTranslation } from 'next-i18next'
import { FilterItemType } from '../components/molecules/FilterExpandable'

/*
 * NOTE: this will set the order of the filter in the rendered UI
 * !! Please do not sort by alphabet !!
 */
export const supportedFilterKeys = [
  'ageMin',
  'series',
  'theme',
  'genre',
  'lcCC',
] as const
export type SupportedFilterKeys = (typeof supportedFilterKeys)[number]
export const isSupportedFilterKey = (s: unknown): s is SupportedFilterKeys =>
  supportedFilterKeys.includes(s as SupportedFilterKeys)

export type ProductFilter = {
  filters: FilterItemType[]
  filterKey: SupportedFilterKeys
  headline: string
  activeFilters: string[]
}

export default function useProductFilter(
  products: NormalizedProduct[],
  filterKey: SupportedFilterKeys,
  headline: string
): ProductFilter {
  const filters = useNormalizeFilter()(
    generateLegacyProductFilterSet(products, filterKey)
  )

  return {
    filters,
    filterKey,
    headline,
    activeFilters: getActiveFilters(filters),
  }
}

export type useProductFilterReturnType = ReturnType<typeof useProductFilter>

export const useOsProductFilters = ({
  aggregations,
}: {
  aggregations: NormalizedAggregation[]
}) => {
  const { t } = useTranslation()

  const getNormalizeFilter = useNormalizeFilter()

  return aggregations.map<ProductFilter>(a => {
    const normalized = getNormalizeFilter(a)

    return {
      filters: normalized,
      filterKey: a.key,
      headline: t(`productFilter:${a.key}Headline`),
      activeFilters: getActiveFilters(normalized),
    }
  })
}
