import { useLocalStorage } from '@boxine/tonies-ui'
import { useEffect } from 'react'

/**
 * check for 3rd party cookie support
 * FIXME: will hopefully be useless after updating to Keycloak 10
 */
export default function useCheckThirdPartyCookies(): boolean | null {
  const [thirdPartyEnabled, setThirdPartyEnabled] = useLocalStorage<
    boolean | null
  >('3rd_party_enabled', null)

  useEffect(() => {
    if (thirdPartyEnabled === null) {
      const iframe = document.createElement('iframe')
      iframe.setAttribute(
        'src',
        `${process.env.NEXT_PUBLIC_SSO_URL}/auth/realms/tonies/protocol/openid-connect/3p-cookies/step1.html`
      )
      iframe.setAttribute('title', 'keycloak-3p-check-iframe')
      iframe.style.display = 'none'
      document.body.appendChild(iframe)

      const iframeCallback = (event: MessageEvent) => {
        let checkLoginIframe = true

        if (iframe.contentWindow !== event.source) return

        if (
          (event.data !== 'supported' && event.data !== 'unsupported') ||
          event.data === 'unsupported'
        ) {
          checkLoginIframe = false
        }

        document.body.removeChild(iframe)
        window.removeEventListener('message', iframeCallback)

        setThirdPartyEnabled(checkLoginIframe)
      }

      window.addEventListener('message', iframeCallback, false)
    }
  }, [setThirdPartyEnabled, thirdPartyEnabled])

  return thirdPartyEnabled
}
