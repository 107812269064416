import React from 'react'
import { DefaultMotionSpanElement } from '@/tonies-ui/motions/constants'

export const ShakeIt: NormalizedMotionComponent = ({ children, ...rest }) => {
  return (
    <DefaultMotionSpanElement
      data-testid="shakeit-motion"
      initial={{ x: 0 }}
      whileHover={{ x: [-3, 0] }}
      transition={{
        type: 'spring',
        damping: 5,
        stiffness: 200,
      }}
      {...rest}
    >
      {children}
    </DefaultMotionSpanElement>
  )
}
