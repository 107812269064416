import { useEffect, useRef, useState } from 'react'
import { getItem, setItem } from '../hooks/useStorage'

/**
 * check for localStorage changes in other Browser Tabs / Windows
 * @param stateKey key to observe
 * @param initialCount start to count state changes from here
 * @param maxCount reset count to initialCount
 */

export function useCrossTabStateChange(
  stateKey: string,
  initialCount: number,
  maxCount = 9
) {
  const [state, setState] = useState(initialCount)
  const isNewSession = useRef(true)
  useEffect(() => {
    if (isNewSession.current) {
      const currentState =
        getItem(stateKey) > maxCount ? initialCount : getItem(stateKey)
      if (currentState) {
        setState(JSON.parse(currentState))
      } else {
        setState(initialCount)
      }
      isNewSession.current = false
      return
    }
    try {
      setItem(stateKey, JSON.stringify(state))
    } catch (error) {
      console.error('could not set localStorage', { stateKey, error })
    }
  }, [state, stateKey, initialCount, maxCount])
  useEffect(() => {
    const onReceiveMessage = (e: StorageEvent) => {
      const { key, newValue } = e
      if (newValue && key === stateKey) {
        setState(JSON.parse(newValue))
      }
    }
    window.addEventListener('storage', onReceiveMessage)
    return () => window.removeEventListener('storage', onReceiveMessage)
  }, [stateKey, setState])
  return [state, setState] as const
}
