import React, {
  ComponentType,
  createContext,
  FunctionComponent,
  useContext,
} from 'react'
import { NextGtmV4Provider } from './NextGtmV4Provider'
import { GtmV4ContextProps, WithReferenceTitle } from './types'

export const GtmV4Context = createContext<GtmV4ContextProps>({
  componentName: undefined,
  componentId: undefined,
  pushGtmV4Event: () => {
    throw new Error('GtmV4Context not ready')
  },
  pushDataLayer: () => {
    throw new Error('GtmV4Context not ready')
  },
})

export const useGtmV4 = () => useContext(GtmV4Context)

export const withGTMV4Context =
  <T extends object>(
    Component: ComponentType<WithReferenceTitle<T>>,
    context: string
  ): FunctionComponent<
    WithReferenceTitle<T> & { newGtmContextRoot?: boolean }
  > =>
  // eslint-disable-next-line react/display-name
  ({ referenceTitle, newGtmContextRoot = false, ...componentProps }) => {
    const currentState = useGtmV4()
    const currContext = context || currentState.componentId
    const currReferenceTitle = referenceTitle || currentState.componentName

    const id = newGtmContextRoot
      ? currContext
      : currentState.componentId || context
    const name = newGtmContextRoot
      ? currReferenceTitle
      : currentState.componentName || referenceTitle

    return (
      <NextGtmV4Provider componentId={id ?? ''} componentName={name ?? ''}>
        <Component {...(componentProps as T)} />
      </NextGtmV4Provider>
    )
  }
