/**
 * This file exports type definitions for the integration with the the Tonies Shop API.
 *
 * The data types herein are usually based on CommerceTools types (types/commercetoolsapi.d.ts),
 * but are often extended or modified to better match the needs at Boxine/Tonies.
 *
 * In the Frontend, we typically try to avoid working with these datatypes, since they are
 * often too bloated and need preprocessing, such as mapping or aggregation. In many cases,
 * we have added an additonal layer of "normalized" types and corresponding conversion
 * functions in the "../normalizers" directory that we try to call as soon as possible
 * after interacting with the API, so that we deal with leaner and safer types in the
 * rest of the Frontend.
 */

import {
  Category,
  ProductData,
  ProductVariant,
  Money,
  TypedMoney,
  LineItem,
  LineItemDraft,
  CartDraft,
  Payment,
  Cart,
  Address,
  Order,
  Customer,
  Price,
} from '@commercetools/platform-sdk'
import { Currency } from '../../../config/shopAPI/types'

export type BxAdyenCustomFields = {
  getPaymentMethodsRequest?: string
  getPaymentMethodsResponse?: string
  makePaymentRequest?: string
  makePaymentResponse?: string
  submitAdditionalPaymentDetailsResponse?: string
}

export type BxProductType = {
  key?: string | null
  name: string
  description: string
  attributeDefinitions: unknown
  id: string
  version: number
  createdAt: unknown
  lastModifiedAt: unknown
  createdBy?: unknown | null
  lastModifiedBy?: unknown | null
}

export type BxProduct = {
  // A very reduced projection of the CommerceTools "Product"
  id: string
  version: number
  lastModifiedAt: string // e.g. "2021-08-13T15:34:55+00:00"
  productType: {
    // ProductType references are resolved in the API
    typeId: 'product-type'
    id: string
    obj: BxProductType
  }
  masterData: {
    // only field "current" is populated in the API
    current?: Omit<ProductData, 'categories' | 'masterVariant' | 'variants'> & {
      categories: {
        // Category references are resolved in the API
        typeId: 'category'
        id: string
        obj: Category
      }[]
      masterVariant: Omit<ProductVariant, 'price'> & {
        // extended with custom attributes
        bxAttributes?: {
          bundled_products?: {
            data: BxProduct
          }[]
        }
        bxStrikePrice?: Price
        bxVariantIsAvailable: boolean
        bxAudioLibraryAssignUrl?: string
        price: Price & {
          bxValue: TypedMoney
          bxStrikeValue?: TypedMoney | null
        }
      }
      variants: [/* always empty in API responses */]
    }
  }
  bxProductUrl: string /* technically a path and not a URL; example: /en-gb/accessories/tonies-headphones-pink/ */
}

export type BxLineItemDraft = LineItemDraft & {
  bxName?: string | null
}

export type BxCartDraft = Omit<
  CartDraft,
  | 'currency' // unavailable in DEV API
  | 'lineItems' // custom type in BX
> & {
  lineItems?: BxLineItemDraft[] | null
}

export type BxMoney = {
  amount: number
  centAmount: number
  currencyCode: Currency
}

export type BxShippingInfo = {
  price: BxMoney
  shippingRate: {
    freeAbove?: BxMoney | null
  }
  shippingMethodState?: string | null
  shippingMethodName?: string | null
  shippingMethod?: {
    id: string
    obj?: BxShippingMethod | null
  } | null
}

export type BxAddress = Omit<
  Address,
  | 'contactInfo' // deprecated in CT
  | 'country' // mandatory in CT and optional in BX
> & {
  country?: Address['country'] | null
}

export type BxDiscountCodeInfo = {
  discountCode: {
    id: string
    typeId: string
    obj: {
      code: string
    }
  }
  state: string
}

export type BxCartDiscount = {
  typeId?: string
  id?: string
  obj?: {
    id: string
    key?: string
    description?: {
      [locale: string]: string
    } | null
    name?: {
      [locale: string]: string
    } | null
    target?: { type: string } | null
  }
}

export type BxDiscountCartDiscount = {
  cartDiscount: BxCartDiscount
  discounted: BxMoney & {
    fractionDigits: number
    type: string
  }
}

export type BxDiscountCode = {
  id: string
  version?: number
  createdAt?: string
  lastModifiedAt?: string
  name?: {
    [locale: string]: string
  } | null
  description?: {
    [locale: string]: string
  } | null
  code: string
  cartDiscounts: BxCartDiscount[]
  isActive?: boolean
  validUntil?: string | null
}

export type BxDiscountDiscountCode = {
  discountCode: BxDiscountCode
  state: string
  discounted: BxMoney
}

export type BxDiscounts = {
  bxDiscountDiscountCodes: BxDiscountDiscountCode[]
  bxDiscountCartDiscounts: BxDiscountCartDiscount[]
}

export const bxLineItemType = ['digital', 'physical', 'repair'] as const
export type BxLineItemType = (typeof bxLineItemType)[number]

export type BxCart = Omit<
  Cart,
  | 'billingAddress' // custom type in BX
  | 'discountCodes' // references resolved in BX
  | 'lineItems' // custom type in BX
  | 'paymentInfo' // custom type in BX
  | 'shippingAddress' // custom type in BX
  | 'shippingInfo' // custom type in BX
  | 'taxedPrice' // custom type in BX
  | 'totalPrice' // custom type in BX
> & {
  billingAddress: BxAddress
  bxCurrentPayment?: Payment
  bxOriginalTotalPrice: BxMoney
  bxLineItemTypes: BxLineItemType[]
  bxProductCounter?: number | null
  bxReadyForOrder: boolean
  bxShippingPrice: BxMoney
  bxDiscounts: BxDiscounts
  discountCodes: BxDiscountCodeInfo[]
  lineItems?: BxLineItem[] | null
  paymentInfo?: {
    payments: {
      typeId: 'payment'
      id: string
      obj?: Payment | null
    }[]
  } | null
  shippingAddress: BxAddress
  shippingInfo: BxShippingInfo
  taxedPrice?: BxMoney | null
  totalPrice: BxMoney
  customerId?: string | null
}

export type BxLineItemCustomFields = {
  tonieboxID?: string
  repairErrorDescription?: string
}

export type BxLineItemUpdate = {
  version: number
  actions: {
    action: 'changeLineItemQuantity'
    lineItemId: string
    quantity: number
  }[]
}

export type BxLineItem = Omit<LineItem, 'price'> & {
  bxBundledProducts?: BxProduct[] // Not yet delivered by BE; t.b.d.
  bxName?: string | null
  bxOriginalTotalPrice: Price
  bxProduct: Omit<BxProduct, 'masterData'> & {
    // Despite the name, this field contains no complete BxProduct, but
    // a stripped version without `.masterData.current.masterVariant`
    // because the details on the selected variant data are already
    // contained in `BxLineItem.variant`.
    masterData: Omit<BxProduct['masterData'], 'current'> & {
      current?: Omit<
        Required<BxProduct['masterData']>['current'],
        'masterVariant'
      >
    }
  }
  variant: ProductVariant & {
    bxAudioLibraryAssignUrl?: string
  }
  bxProductUrl: string
  bxVariantIsAvailable: boolean
  price: Price & {
    bxValue: TypedMoney
    bxStrikeValue?: TypedMoney
  }
  custom?: BxLineItemCustomFields
}

export type BxReducedLineItem = Pick<
  LineItem,
  'id' | 'name' | 'variant' | 'quantity'
>

export type BxOrder = Omit<
  Order,
  | 'billingAddress' // custom type in BX
  | 'shippingAddress' // custom type in BX
  | 'lineItems' // custom type in BX
  | 'discountCodes' // references resolved in BX
  | 'cart' // not resolved in BX
  | 'paymentInfo' // references resolved in BX
  | 'shippingInfo' // references resolved in BX
  | 'totalPrice' // custom type in BX
> & {
  billingAddress: BxAddress
  shippingAddress: BxAddress
  lineItems: BxLineItem[]
  discountCodes: BxDiscountCodeInfo[]
  bxDiscounts: BxDiscounts
  bxLineItemTypes: BxLineItemType[]
  cart: unknown[]
  bxCurrentPayment?: Omit<Payment, 'custom'> & {
    custom: {
      fields: BxAdyenCustomFields & {
        adyenMerchantAccount: string
        commercetoolsProjectKey: string
      }
    }
    type: {
      id: string
      typeId: 'type'
    }
  }
  bxOriginalTotalPrice: BxMoney
  bxShippingPrice: BxMoney
  paymentInfo?: {
    payments: {
      typeId: 'payment'
      id: string
      obj?: Payment | null
    }[]
  } | null
  shippingInfo: BxShippingInfo
  totalPrice: BxMoney
}

export type BxCustomerOrder = {
  id: string
  billingAddress: Address
  orderNumber: string
  totalPrice: Money
  shippingAddress: Address
  lineItems: BxReducedLineItem[]
  createdAt: string
}

export type BxCustomerOrders = {
  limit: number
  count: number
  total: number
  offset: number
  results: BxCustomerOrder[]
}

export type BxShippingMethod = {
  id: string
  key: string | null
  name: string
  description?: string | null
  localizedDescription?: Record<string, unknown> | null
  localizedName?: Record<string, unknown> | null
  zoneRates: {
    shippingRates: {
      price: BxMoney
      freeAbove?: BxMoney | null
    }[]
  }[]
}

export type BxShippingMethodCollection = {
  count: number
  limit?: number | null
  total?: number
  offset?: number | null
  results: BxShippingMethod[]
}
export type BxCustomer = Customer &
  Record<string, unknown> & {
    bxFilteredAddresses: Address[]
    authCode?: string
  }

export type BxResourceIdentifier = {
  typeId: string
  key?: string | null
  id?: string | null
}

export type BxResourceIdentifierInput = {
  typeId?: string | null
  id?: string | null
  key?: string | null
}

export type BxReferralCode = {
  code: string
}

export type BxCommercetoolsConfig = {
  project: string
  language: string
  channel?: string
  cartCountry: string
  physicalShippingCountries: string[]
  digitalBillingCountries: string[]
  currency: string
}
