import { isObject } from 'lodash'

/**
 * Makes an object serializable by converting it to JSON and then parsing it back.
 * If the input is not an object, it is returned as is.
 *
 * @param obj - The object to make serializable.
 * @returns The serializable object.
 */
export function makeSerializable<T extends Record<string, unknown>>(obj: T): T {
  if (!isObject(obj)) {
    return obj
  }

  return JSON.parse(JSON.stringify(obj))
}
