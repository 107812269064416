import * as v from '@badrap/valita'

import {
  Item,
  KeyName,
  Price,
  PriceValue,
  Product,
  ProductAggregations,
} from './validationSchema'
import { Parameter } from './getProducts'

export type OsProductCategoryItem = v.Infer<typeof Item>
export type OsProductPrice = v.Infer<typeof Price>
export type OsProductPriceValue = v.Infer<typeof PriceValue>
export type OsSearchKeywordItem = string[]
export type OsProductCategory = {
  items: v.Infer<typeof Item>[]
}
export type OsProduct = v.Infer<typeof Product>
export type OsGetProductParameterProps = Parameter
export type OsProductQueryResult = {
  documents: OsProduct[]
  total: number
  aggregations: v.Infer<typeof ProductAggregations>
  parameter: Parameter
}
export type OsProductEnum = v.Infer<typeof KeyName>
export type OsProductEnumSet = OsProductEnum[]
export type OsProductAggregations = v.Infer<typeof ProductAggregations>

function hasSchemaPassed(schema: v.Type, value: unknown) {
  try {
    schema.parse(value)

    return true
  } catch {
    return false
  }
}

export const isOsProductEnum = (a: unknown): a is OsProductEnum =>
  hasSchemaPassed(KeyName, a)

export const isOsProductEnumSet = (a: unknown): a is OsProductEnumSet =>
  hasSchemaPassed(v.array(KeyName), a)

export const supportedSortingIds = [
  'alphabetical-asc',
  'alphabetical-desc',
  'newest-asc',
  'newest-desc',
  'bestseller-desc',
  'price-asc',
  'price-desc',
] as const
export type SupportedSortingIds = (typeof supportedSortingIds)[number]
export const isSupportedSortingId = (s: unknown): s is SupportedSortingIds =>
  supportedSortingIds.includes(s as SupportedSortingIds)
