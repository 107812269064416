import useMousePosition from '@/tonies-ui/hooks/useMousePosition'
import React from 'react'
import { motion, useTransform } from 'framer-motion'
import { toniesSpringTransition } from '@/tonies-ui/motions/constants'

export const WobbleIt: NormalizedMotionComponent = ({ children, ...rest }) => {
  const { onMouseLeave, onMouseMove, x, y } = useMousePosition(
    toniesSpringTransition
  )
  const xFactor = 0.5
  const rotation = 1.5

  const rotateY = useTransform(
    x,
    [0, 1],
    [rotation * xFactor, -rotation * xFactor],
    {
      clamp: true,
    }
  )
  const rotateX = useTransform(y, [0, 1], [-rotation, rotation], {
    clamp: true,
  })

  return (
    <motion.div
      {...rest}
      data-testid="wobble-motion"
      style={{
        perspective: '400px',
        height: '100%',
        ...rest.style,
      }}
    >
      <motion.div
        onMouseMove={onMouseMove}
        onMouseOut={onMouseLeave}
        onBlur={onMouseLeave}
        style={{
          rotateX: rotateX,
          rotateY: rotateY,
          height: '100%',
        }}
        animate={{
          transition: {
            delay: 0.07,
            duration: 0.2,
            ease: 'backOut',
          },
        }}
      >
        {children}
      </motion.div>
    </motion.div>
  )
}
