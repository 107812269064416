import { CartAction } from '..'
import { normalizeCart } from '../../../lib/commercetools/normalizers/normalizeCart'
import { changeLineItemQuantity } from '../../../lib/commercetools/requests/carts/changeLineItemQuantity'
import {
  GtmV4AddToCartEvent,
  GtmV4Event,
  GtmV4RemoveFromCartEvent,
} from '../../gtmV4/types'

export const changeQuantityAction =
  (
    lineItemId: string,
    quantity: number,
    gtmV4?: {
      data: GtmV4AddToCartEvent | GtmV4RemoveFromCartEvent
      pushGtmV4Event: (e: GtmV4Event) => void
    }
  ): CartAction<unknown> =>
  async ({ lcCC, fetchOrCreateCart, replaceCart, logError }) => {
    const cart = await fetchOrCreateCart()

    const changeLineItemQuantityResponse = await changeLineItemQuantity(
      lcCC,
      lineItemId,
      cart.id,
      cart.version,
      quantity
    )

    if (changeLineItemQuantityResponse.result !== 'successful') {
      logError(
        changeLineItemQuantityResponse.error,
        'changeQuantityAction',
        changeLineItemQuantityResponse.data
      )
      return
    }

    // save updated cart contents
    replaceCart(normalizeCart(changeLineItemQuantityResponse.data, lcCC))

    // push to gtmV4
    if (gtmV4) {
      gtmV4.pushGtmV4Event(gtmV4.data)
    }
  }
