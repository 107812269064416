import { Address } from '@commercetools/platform-sdk'
import { BxAddress, BxCustomer } from '../bxTypes'
import { fetch } from '../../util/fetch'
import { EcomLocale } from '../../../../config/shopAPI/types'

export const changeAddress = async (
  lcCC: EcomLocale,
  customerVersion: number,
  addressId: Address['id'],
  address: BxAddress
) =>
  fetch<Record<string, unknown>, BxCustomer>({
    query: `/me`,
    lcCC,
    method: 'post',
    request: {
      version: customerVersion,
      actions: [
        {
          action: 'changeAddress',
          addressId,
          address,
        },
      ],
    },
  })
