import React, {
  FunctionComponent,
  PropsWithChildren,
  useState,
  useEffect,
  useRef,
} from 'react'
import { CartStateContext } from '.'
import { useCheckTunesStatus } from '../../lib/cloudservices/requests/rest/checkTuneStatus'
import { usePaymentTypes } from '../../hooks/usePaymentTypes'

export const CartStateProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [cart, setCart] = useState<NormalizedCart>()
  const [isPending, setIsPending] = useState<boolean>(true)
  const { setCartContainsTunesOnly } = usePaymentTypes()
  const prevCartVersion = useRef<number | null>(null)
  const checkTunesStatus = useCheckTunesStatus(
    cart?.lineItems.map(li => li.product)
  )
  const [allTunesStatus, setAllTunesStatus] =
    useState<Awaited<ReturnType<ReturnType<typeof useCheckTunesStatus>>>>(
      undefined
    )
  const isTunesOnlyCart = cart?.normalizedCartType === 'digital'

  useEffect(() => {
    if (cart) {
      setCartContainsTunesOnly(isTunesOnlyCart)
    }
  }, [cart, isTunesOnlyCart, setCartContainsTunesOnly])

  useEffect(() => {
    // fetch new tunes status when cart was updated
    if (cart && prevCartVersion.current !== cart.version) {
      checkTunesStatus().then(setAllTunesStatus)
      prevCartVersion.current = cart.version
    }
  }, [cart, cart?.version, checkTunesStatus, allTunesStatus])

  return (
    <CartStateContext.Provider
      value={{
        cart,
        setCart,
        allTunesStatus: allTunesStatus,
        checkTunesStatus,
        isItemInCart: (sku: string) =>
          isPending
            ? undefined
            : cart?.lineItems.some(i => i.product.sku === sku) || false,
        isPendingState: [isPending, setIsPending],
      }}
    >
      {children}
    </CartStateContext.Provider>
  )
}
