import debounce from 'lodash/debounce'
import { useRouter } from 'next/router'
import { ParsedUrlQuery, parse } from 'querystring'
import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useMemo,
} from 'react'
import { UrlQueryContext, UrlQueryProps } from '.'

const removeHash = (str: string) => str.split('#')[0]
const removeHashFromParams = (
  params: ReturnType<UrlQueryProps['getUrlParam']>
): string[] | undefined => {
  if (!params) {
    return undefined
  }

  const array = Array.isArray(params) ? params : [params]

  return array.map(removeHash)
}

export const NextUrlQueryProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const router = useRouter()
  const { replace } = router

  /**
   * Current Next.js router on client have a strange behaviour
   * the router.query is an empty object on first render
   *
   * Use router.asPath while router.query is not ready
   */
  const query: ParsedUrlQuery = useMemo(() => {
    return router.isReady ? router.query : parse(router.asPath.split('?')[1])
  }, [router])

  const setUrlParam: UrlQueryProps['setUrlParam'] = useMemo(
    () =>
      debounce(
        (params: ParsedUrlQuery) => {
          const { locale, slug, ...newParams }: ParsedUrlQuery = {
            ...query,
            ...params,
          }

          Object.keys(newParams).forEach(key => {
            if (!newParams[key]) delete newParams[key]
          })

          return replace(
            {
              pathname: window.location.href.split('?')[0],
              query: newParams,
            },
            undefined,
            { scroll: false, shallow: true }
          )
        },
        100,
        { leading: true, trailing: true }
      ),
    [query, replace]
  )

  const getUrlParam: UrlQueryProps['getUrlParam'] = useCallback(
    key => query[key],
    [query]
  )

  /**
   * @returns {string[] | []} `[query[key]]` (if query[key] is no array) or `query[key]`
   */
  const getUrlParamAsArray: UrlQueryProps['getUrlParamAsArray'] = useCallback(
    key => removeHashFromParams(query[key]) || [],
    [query]
  )

  /**
   * @returns {string | undefined} value of `query[key]` or `query[key][0]`
   */
  const getUrlParamAsSingleton: UrlQueryProps['getUrlParamAsSingleton'] =
    useCallback(
      key => {
        const values = removeHashFromParams(query[key])
        return values?.[0]
      },
      [query]
    )

  return (
    <UrlQueryContext.Provider
      value={{
        setUrlParam,
        getUrlParam,
        getUrlParamAsArray,
        getUrlParamAsSingleton,
      }}
    >
      {children}
    </UrlQueryContext.Provider>
  )
}
