import { makePayment } from '../../../lib/commercetools/requests/payments/makePayment'
import { CartAction } from '..'
import { FetchResult } from '../../../lib/commercetools/util/fetch'
import { PaymentResponse } from '../../../lib/commercetools/requests/payments/types'

type MakePaymentForCartActionProps = {
  paymentId: string
  paymentVersion: number
  currency: string
  centAmount: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
}

export const makePaymentForCartAction =
  ({
    paymentId,
    paymentVersion,
    currency,
    centAmount,
    data,
  }: MakePaymentForCartActionProps): CartAction<FetchResult<PaymentResponse>> =>
  async ({ fetchOrCreateCart, lcCC, replaceCart, logError }) => {
    const makePaymentResponse = await makePayment(
      lcCC,
      paymentId,
      paymentVersion,
      currency,
      centAmount,
      data
    )

    if (makePaymentResponse.result !== 'successful') {
      logError(
        makePaymentResponse.error,
        'makePaymentForCartAction',
        makePaymentResponse.data
      )
    }
    const cart = await fetchOrCreateCart()
    replaceCart(cart)
    return makePaymentResponse
  }
