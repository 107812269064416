import { createContext } from 'react'
import { PaymentContext } from './types'

export const emptyPaymentMethodResponseObject = {
  paymentMethods: [],
}

export const defaultPaymentContextProps: PaymentContext = {
  paymentStatus: 'pending',
  countryCode: 'GB',
  errorMessage: undefined,
  currentPaymentResponse: undefined,
  cartAvailablePaymentMethods: undefined,
  paymentMethodsResponseObject: emptyPaymentMethodResponseObject,
  cartCurrentPaymentType: undefined,
  initializePayment: async () => console.log('initializePayment'),
  authorizePayment: async () => console.log('authorizePayment'),
  setCountryCode: () => undefined,
  submitAdditionalPaymentDetails: async () =>
    console.log('submitAdditionalPaymentDetails'),
  pendingOperations: 0,
  submitAdditionalPaymentDetailsResultCode: undefined,
  makePaymentResultCode: undefined,
}

export const paymentContext = createContext<PaymentContext>(
  defaultPaymentContextProps
)

export const PaymentContextConsumer = paymentContext.Consumer
