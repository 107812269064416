import { BxCommercetoolsConfig } from '../../lib/commercetools/requests/bxTypes'
import {
  BxEcomLocaleConfig,
  BxLocaleConfig,
  BxConfig,
} from '../../lib/shopApi/requests/bxTypes'
import {
  Currency,
  EcomLocale,
  ecomLocales,
  Locale,
  locales,
  LocaleInclExternal,
  localesInclExternal,
} from './types'
import config from '../../public/global-config.json'

/**
 * type guard to determine the type of LocaleConfig
 */
export const isBxEcomLocaleConfig = (
  config: BxLocaleConfig | BxEcomLocaleConfig
): config is BxEcomLocaleConfig =>
  Object.prototype.hasOwnProperty.call(config, 'commercetools')

/**
 * construct a unique array of all currencies from commercetools projects
 */
export const currencies = [
  ...(config as BxConfig).localeConfigs
    .filter(isBxEcomLocaleConfig)
    .map(c => c.commercetools.currency as Currency)
    .reduce<Currency[]>((previous, current) => {
      if (!previous.find(currency => currency === current)) {
        previous.push(current)
      }
      return previous
    }, []),
]

export const isEcomLocale = (s: unknown): s is EcomLocale =>
  typeof s === 'string' && ecomLocales.includes(s as EcomLocale)

export const isLocale = (s: unknown): s is Locale =>
  typeof s === 'string' && locales.includes(s as Locale)

export const isLocaleInclExternal = (s: unknown): s is LocaleInclExternal =>
  typeof s === 'string' && localesInclExternal.includes(s as LocaleInclExternal)

/**
 * retrieve commercetools properties from EcomLocaleConfig
 * @param {EcomLocale} locale
 * @param {keyof BxCommercetoolsConfig} property
 * @returns value of given `property` per given `locale` from the `BxCommercetoolsConfig`
 */
export const getEcomLocaleConfig = (
  lcCC: EcomLocale,
  property: keyof BxCommercetoolsConfig
) => {
  return (
    (config as BxConfig).localeConfigs
      .filter(isBxEcomLocaleConfig)
      .filter(c => c.lcCC === lcCC)[0] as BxEcomLocaleConfig
  ).commercetools[property]
}

/**
 * retrieves the `key` of the commercetools project for the given `EcomLocale` from the shopAPI.
 * Fallback values are maintained as ENVs.
 * @param {EcomLocale} locale
 * @returns {string} projectKey
 */
export const getCommercetoolsProjectKey = (locale: EcomLocale): string => {
  const projectKey = getEcomLocaleConfig(locale, 'project') as string
  return projectKey || 'commercetools-project-key-not-set'
}

/**
 *
 * @param {EcomLocale} locale string of type `EcomLocale`
 * @returns string of type `Currency`
 */
export const getCurrencyCodeByLocale = (locale: EcomLocale): Currency => {
  return getEcomLocaleConfig(locale, 'currency') as Currency
}
