import { BxAddress, BxCart } from '../bxTypes'
import { fetch } from '../../util/fetch'
import { EcomLocale } from '../../../../config/shopAPI/types'
import { AddressType } from '../../../../types/normalize/cart'

type Request = {
  version: number
  actions: {
    action: 'setBillingAddress' | 'setShippingAddress'
    address: BxAddress
  }[]
}

/**
 * Sets the billing or the shipping address on the referenced cart.
 */
export const setAddress = async (
  lcCC: EcomLocale,
  type: AddressType,
  address: BxAddress,
  cartId: string,
  cartVersion: number
) =>
  fetch<Request, BxCart>({
    query: `/carts/${cartId}`,
    lcCC,
    method: 'post',
    request: {
      version: cartVersion,
      actions: [
        {
          action:
            type === 'billing' ? 'setBillingAddress' : 'setShippingAddress',
          address,
        },
      ],
    },
  })
