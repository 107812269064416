import { MotionValue, Spring, motion, useSpring } from 'framer-motion'
import styled from 'styled-components'

export const toniesSpringTransition: Spring = {
  type: 'spring',
  stiffness: 300,
  damping: 25,
}

export const useToniesSpring = (value: MotionValue) =>
  useSpring(value, toniesSpringTransition)

export const DefaultMotionWrapper = styled(motion.div)`
  overflow: hidden;
  width: 100%;
  height: 100%;
`

export const DefaultMotionSpanElement = styled(motion.span)`
  display: block;
`
