export function loadScript(url: string, id: string): Promise<void> {
  return new Promise(resolve => {
    const head = document.querySelector('head')
    const existingScript = document.querySelector(`script#${id}`)

    if (!head || existingScript) {
      return
    }

    const scriptElement = document.createElement('script')
    scriptElement.src = url
    scriptElement.setAttribute('id', id)
    scriptElement.addEventListener('load', () => resolve(), { once: true })
    head.appendChild(scriptElement)
  })
}

export function removeScript(id: string): void {
  const head = document.querySelector('head')
  const existingScript = document.querySelector(`script#${id}`)

  if (!head || !existingScript) {
    return
  }

  head.removeChild(existingScript)
}
