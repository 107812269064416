import { createContext } from 'react'

export type State = 'initializing' | 'finished'

export type SyncCartCookiesWithAuthProps = {
  state: State
  isTokenPresent: boolean
  hasBearerToken: boolean
}

export const syncCartCookiesWithAuth =
  createContext<SyncCartCookiesWithAuthProps>({
    state: 'initializing',
    isTokenPresent: false,
    hasBearerToken: false,
  })
