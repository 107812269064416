import { BxCart, BxCartDraft } from '../bxTypes'
import { fetch } from '../../util/fetch'
import { EcomLocale } from '../../../../config/shopAPI/types'

/**
 * Creates a new Shopping cart in CommerceTools and returns its state.
 */
export const createCart = async (lcCC: EcomLocale, cartDraft?: BxCartDraft) =>
  fetch<BxCartDraft, BxCart>({
    query: '/carts',
    lcCC,
    method: 'post',
    request: cartDraft || {},
  })
