/**
 * THIS FILE IS AUTOGENERATED BY ./scripts/update-design-tokens.js
 * Please do not edit this file. Changes will be overwritten next time someone
 * runs the script.
 */
export const colors = {
  primary: 'rgb(210, 0, 15)',
  'primary-hover': 'rgb(181, 0, 13)',
  /** @deprecated use `darkergray` instead */
  black: 'rgb(51, 51, 51)',
  darkergrey: 'rgb(51, 51, 51)',
  'darkgrey-50': 'rgba(51, 51, 51, 0.5)',
  normalgrey: 'rgb(155, 153, 153)',
  middlegrey: 'rgb(219, 219, 219)',
  lightgrey: 'rgb(238, 238, 238)',
  lightergrey: 'rgb(249, 249, 249)',
  white: 'rgb(255, 255, 255)',
  'lightblue-100': 'rgb(130, 190, 220)',
  'lightblue-90': 'rgb(143, 197, 224)',
  'lightblue-80': 'rgb(155, 203, 227)',
  'lightblue-70': 'rgb(168, 210, 231)',
  'lightblue-60': 'rgb(180, 216, 234)',
  'lightblue-50': 'rgb(192, 222, 237)',
  'lightblue-40': 'rgb(205, 229, 241)',
  'lightblue-30': 'rgb(217, 235, 244)',
  'lightblue-20': 'rgb(230, 242, 248)',
  'lightblue-10': 'rgb(243, 249, 252)',
  'green-100': 'rgb(175, 180, 80)',
  'green-90': 'rgb(183, 188, 98)',
  'green-80': 'rgb(191, 195, 115)',
  'green-70': 'rgb(199, 203, 133)',
  'green-60': 'rgb(207, 210, 150)',
  'green-50': 'rgb(215, 217, 167)',
  'green-40': 'rgb(223, 225, 185)',
  'green-30': 'rgb(231, 232, 202)',
  'green-20': 'rgb(239, 240, 220)',
  'green-10': 'rgb(247, 248, 238)',
  'berry-100': 'rgb(120, 0, 80)',
  'berry-90': 'rgb(134, 25, 98)',
  'berry-80': 'rgb(147, 51, 115)',
  'berry-70': 'rgb(161, 76, 133)',
  'berry-60': 'rgb(174, 102, 150)',
  'berry-50': 'rgb(188, 127, 168)',
  'berry-40': 'rgb(201, 153, 185)',
  'berry-30': 'rgb(215, 178, 203)',
  'berry-20': 'rgb(228, 204, 220)',
  'berry-10': 'rgb(242, 229, 238)',
  'pink-100': 'rgb(215, 50, 115)',
  'pink-90': 'rgb(219, 71, 129)',
  'pink-80': 'rgb(223, 91, 143)',
  'pink-70': 'rgb(227, 112, 157)',
  'pink-60': 'rgb(231, 132, 171)',
  'pink-50': 'rgb(235, 153, 185)',
  'pink-40': 'rgb(239, 173, 199)',
  'pink-30': 'rgb(243, 194, 213)',
  'pink-20': 'rgb(247, 214, 227)',
  'pink-10': 'rgb(251, 235, 241)',
  'anthracite-100': 'rgb(100, 100, 110)',
  'anthracite-90': 'rgb(116, 116, 125)',
  'anthracite-80': 'rgb(131, 131, 139)',
  'anthracite-70': 'rgb(147, 147, 154)',
  'anthracite-60': 'rgb(162, 162, 168)',
  'anthracite-50': 'rgb(178, 178, 183)',
  'anthracite-40': 'rgb(193, 193, 197)',
  'anthracite-30': 'rgb(209, 209, 212)',
  'anthracite-20': 'rgb(224, 224, 226)',
  'anthracite-10': 'rgb(240, 240, 241)',
  'yellow-100': 'rgb(250, 200, 0)',
  'yellow-90': 'rgb(251, 206, 25)',
  'yellow-80': 'rgb(251, 211, 51)',
  'yellow-70': 'rgb(252, 217, 76)',
  'yellow-60': 'rgb(252, 222, 102)',
  'yellow-50': 'rgb(253, 228, 127)',
  'yellow-40': 'rgb(253, 233, 153)',
  'yellow-30': 'rgb(254, 239, 178)',
  'yellow-20': 'rgb(254, 244, 204)',
  'yellow-10': 'rgb(255, 250, 229)',
  'yellow-hover': 'rgb(212, 168, 0)',
  '1-color-5-skin-light': 'rgb(225, 199, 148)',
  '1-color-5-skin-medium': 'rgb(196, 147, 88)',
  '1-color-5-skin-dark': 'rgb(116, 82, 63)',
  '1-color-6-shades-of-secondary-shaded-blue': 'rgb(103, 151, 175)',
  '1-color-6-shades-of-secondary-shaded-purple': 'rgb(90, 0, 60)',
  '1-color-6-shades-of-secondary-shaded-pink': 'rgb(171, 40, 92)',
  '1-color-6-shades-of-secondary-shaded-green': 'rgb(121, 130, 43)',
  '1-color-6-shades-of-secondary-shaded-grey': 'rgb(66, 70, 73)',
  '1-color-8-sleepy-friends-global-violet-01': 'rgb(60, 30, 95)',
  '1-color-8-sleepy-friends-global-violet-02': 'rgb(100, 65, 140)',
  '1-color-8-sleepy-friends-global-violet-03': 'rgb(190, 170, 210)',
  '1-color-8-sleepy-friends-global-accent': 'rgb(255, 245, 180)',
  '1-color-7-campaign-christmas-2023-shaded': 'rgb(167, 0, 12)',
  '1-color-7-campaigns-christmas-2022-100': 'rgb(70, 40, 115)',
  '1-color-7-campaigns-christmas-2022-80': 'rgb(105, 70, 135)',
  '1-color-7-campaigns-christmas-2022-50': 'rgb(155, 135, 180)',
  '1-color-7-campaigns-christmas-2022-20': 'rgb(215, 205, 225)',
  '1-color-7-campaigns-christmas-2022-10': 'rgb(235, 230, 240)',
  '1-color-9-special-edition-limegreen-100': 'rgb(167, 200, 44)',
  '1-color-9-special-edition-limegreen-90': 'rgb(176, 206, 65)',
  '1-color-9-special-edition-limegreen-80': 'rgb(185, 211, 87)',
  '1-color-9-special-edition-limegreen-70': 'rgb(194, 217, 107)',
  '1-color-9-special-edition-limegreen-60': 'rgb(203, 222, 129)',
  '1-color-9-special-edition-limegreen-50': 'rgb(211, 228, 150)',
  '1-color-9-special-edition-limegreen-40': 'rgb(220, 233, 171)',
  '1-color-9-special-edition-limegreen-30': 'rgb(229, 239, 192)',
  '1-color-9-special-edition-limegreen-20': 'rgb(238, 244, 213)',
  '1-color-9-special-edition-limegreen-10': 'rgb(247, 250, 234)',
} as const

export type ToniesColor = keyof typeof colors
export const isToniesColor = (str: string): str is ToniesColor =>
  Object.keys(colors).includes(str)
