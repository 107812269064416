import { createContext, Dispatch, SetStateAction, useContext } from 'react'
import { Address } from '@commercetools/platform-sdk'
import { BxAddress, BxCustomer } from '../../lib/commercetools/requests/bxTypes'
import { AddressFieldError } from '../../components/molecules/CheckoutAddressForm/types'
import { AddressType } from '../../types/normalize/cart'

export type AddressEditAction = 'add' | 'edit'

export type NewAddressUseAs = 'billing' | 'shipping' | 'billingAndShipping'

export type AddAddressesToAddressbookProps = {
  passedAddresses: BxAddress[]
  currentUser?: BxCustomer | null
  useAs?: NewAddressUseAs
}

export type AddressesContextType = {
  billingAddresses: BxAddress[] | undefined
  shippingAddresses: BxAddress[] | undefined
  selectedBillingAddress: BxAddress | undefined
  selectedShippingAddress: BxAddress | undefined
  setSelectedBillingAddress: Dispatch<SetStateAction<BxAddress | undefined>>
  setSelectedShippingAddress: Dispatch<SetStateAction<BxAddress | undefined>>
  defaultBillingAddress: BxAddress | undefined
  defaultShippingAddress: BxAddress | undefined
  addAddressesToAddressbook: ({
    passedAddresses,
    currentUser,
    useAs,
  }: AddAddressesToAddressbookProps) => Promise<{
    errors: AddressFieldError[]
    user: BxCustomer | null
  }>
  setDefaultAddress: (
    actions: {
      type: AddressType
      id: Address['id']
    }[],
    currentUser?: BxCustomer | null
  ) => Promise<void>
  changeAddress: (
    addressId: Address['id'],
    address: BxAddress,
    currentUser?: BxCustomer | null
  ) => Promise<{
    errors: AddressFieldError[]
    user: BxCustomer | null
  }>
  isReadOnly: boolean
  setIsReadOnly: Dispatch<SetStateAction<boolean>>
  isEditMode: boolean
  setIsEditMode: Dispatch<SetStateAction<boolean>>
  isFirstRender: boolean
  isContainerValid: boolean | undefined
  setIsContainerValid: Dispatch<SetStateAction<boolean | undefined>>
  isBillingAsShipping: boolean
  setIsBillingAsShipping: Dispatch<SetStateAction<boolean>>
  addAddressesToCart: (
    passedBilling: BxAddress,
    passedShipping?: BxAddress
  ) => Promise<void>
  addressToEdit: { address?: BxAddress; action: AddressEditAction } | undefined
  setAddressToEdit: Dispatch<
    SetStateAction<
      { address?: BxAddress; action: AddressEditAction } | undefined
    >
  >

  isPending: boolean
  isSubmitting: boolean
  setIsSubmitting: Dispatch<SetStateAction<boolean>>
  hasCartAddresses: boolean
  areCartAddressesEqual: boolean | undefined
}

export const AddressContextDefaultValues = {
  billingAddresses: undefined,
  shippingAddresses: undefined,
  selectedBillingAddress: undefined,
  selectedShippingAddress: undefined,
  setSelectedBillingAddress: () => undefined,
  setSelectedShippingAddress: () => undefined,
  changeAddress: async () => ({
    errors: [],
    user: null,
  }),
  defaultBillingAddress: undefined,
  defaultShippingAddress: undefined,
  addAddressesToAddressbook: async () => ({
    errors: [],
    user: null,
  }),
  setDefaultAddress: async () => undefined,
  isReadOnly: false,
  setIsReadOnly: () => undefined,
  isEditMode: false,
  setIsEditMode: () => undefined,
  isContainerValid: false,
  setIsContainerValid: () => undefined,
  isBillingAsShipping: false,
  setIsBillingAsShipping: () => undefined,
  addressToEdit: { action: 'add' as AddressEditAction, address: undefined },
  setAddressToEdit: () => undefined,
  addAddressesToCart: async () => undefined,
  isPending: true,
  isFirstRender: true,
  isSubmitting: false,
  setIsSubmitting: async () => undefined,
  hasCartAddresses: false,
  areCartAddressesEqual: undefined,
}

export const AddressesContext = createContext<AddressesContextType>(
  AddressContextDefaultValues
)

export const useAddresses = (): AddressesContextType =>
  useContext(AddressesContext)
