import { Currency } from '../../../config/shopAPI/types'
import { BxCart, BxOrder } from '../requests/bxTypes'

/**
 * If the given cart or order has discounts applied, this function extracts the FIRST
 * discount and returns its code, its state, and its "amount" (the actual value in savings,
 * based on the cart's or order's total).
 */
export const normalizeDiscount = (
  lcCC: string,
  src?: BxCart | BxOrder
): NormalizedDiscount | undefined => {
  const currentDiscountCode = src?.bxDiscounts.bxDiscountDiscountCodes[0]
  const otherCartDiscounts = src?.bxDiscounts.bxDiscountCartDiscounts
  // the current languageCodes used by Commercetools are 'en', 'fr-FR' and 'de-DE'
  const descriptionLC = lcCC.substring(0, 2) === 'en' ? 'en' : lcCC

  if (!src || (!currentDiscountCode && !otherCartDiscounts)) {
    return undefined
  }

  // normalize currentDiscountCode
  let byDiscountCode: NormalizedDiscountCode | undefined

  if (currentDiscountCode) {
    const appliedCartDiscounts =
      currentDiscountCode?.discountCode.cartDiscounts.map(d => ({
        key: d.obj?.key || '',
        name: (d.obj?.name && d.obj?.name[descriptionLC]) ?? '',
        description:
          (d.obj?.description && d.obj?.description[descriptionLC]) ?? '',
      }))
    byDiscountCode = {
      id: currentDiscountCode.discountCode.id,
      state: currentDiscountCode.state,
      code: currentDiscountCode.discountCode.code,
      name: currentDiscountCode.discountCode.name
        ? currentDiscountCode.discountCode.name[descriptionLC] || ''
        : '',
      description: currentDiscountCode.discountCode.description
        ? currentDiscountCode.discountCode.description[descriptionLC] || ''
        : '',
      savings: {
        amount: currentDiscountCode.discounted.amount,
        centAmount: currentDiscountCode.discounted.centAmount,
        currency: currentDiscountCode.discounted.currencyCode as Currency,
      },
      appliedCartDiscounts,
    }
  }

  /**
   * normalize otherCartDiscounts
   * only cartDiscounts with obj.target.type === 'lineItems' affect the cart
   * by appliyng a monetary discount. Other cartDiscount types are used to add
   * additional gift products, that are not to be shown in the cart and
   * therefore filtered out.
   */
  let others: NormalizedCartDiscount[] = []

  if (otherCartDiscounts?.length) {
    others = otherCartDiscounts
      .filter(
        ({ cartDiscount }) => cartDiscount.obj?.target?.type === 'lineItems'
      )
      .map(({ cartDiscount: { obj }, discounted }) => ({
        name: (obj?.name && obj?.name[descriptionLC]) || '',
        key: obj?.key || '',
        description:
          (obj?.description && obj?.description[descriptionLC]) || '',
        savings: {
          amount: discounted.amount,
          centAmount: discounted.centAmount,
          currency: discounted.currencyCode as Currency,
        },
      }))
  }

  return {
    byDiscountCode,
    others,
  }
}
