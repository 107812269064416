/**
 * FIXME: can be removed when tonies-ui export React18 components with type `PropsWithChildren`
 * or when tonies-ui components are migrated to `tonies-nextjs`
 */
import { FunctionComponent, PropsWithChildren } from 'react'
import {
  PrimaryNavigationItemProps,
  PrimaryNavigationProps,
  AccordionItem as ToniesUIAccordionItem,
  PrimaryNavigation as ToniesUIPrimaryNavigation,
  PrimaryNavigationItem as ToniesUIPrimaryNavigationItem,
  FlashMessage as ToniesUIFlashMessage,
  MobileOnlyNavigation as ToniesUIMobileOnlyNavigation,
  MobileOnlyNavigationItem as ToniesUIMobileOnlyNavigationItem,
  SecondaryNavigation as ToniesUISecondaryNavigation,
  SecondaryNavigationItem as ToniesUISecondaryNavigationItem,
  ColorContextProvider as ToniesUIColorContextProvider,
  ToniesColor,
} from '@boxine/tonies-ui'
import { HorizontalScrollList } from './molecules/HorizontalScrollList'
import type { HorizontalScrollListProps } from './molecules/HorizontalScrollList'
import { DefaultTheme } from 'styled-components'

export interface AccordionItemProps {
  /**
   * Allow using styled() wrapper on this component. Don't use this to set a
   * class manually!
   */
  className?: string
  title: React.ReactNode
  'data-trackingid'?: string
  'data-testid'?: string
}

export declare type FlashMessageTypes = 'error' | 'info' | 'warning' | 'success'

export interface FlashMessageProps {
  /**
   * Test ID in case it needs to be overwritten to test this component as part of a larger one.
   */
  'data-testid'?: string
  /**
   * True = Component is always visible and cannot be closed.
   */
  isPersistent?: boolean
  /**
   * Callback is triggered just after the component was closed.
   */
  onWasClosed?: () => void
  /**
   * Type of the component.
   */
  type: FlashMessageTypes
}
const AccordionItem: FunctionComponent<
  Partial<AccordionItemProps & PropsWithChildren>
> = ToniesUIAccordionItem

const PrimaryNavigation: FunctionComponent<
  PrimaryNavigationProps & PropsWithChildren
> = ToniesUIPrimaryNavigation

const PrimaryNavigationItem: FunctionComponent<
  PrimaryNavigationItemProps & PropsWithChildren
> = ToniesUIPrimaryNavigationItem

const FlashMessage: FunctionComponent<FlashMessageProps & PropsWithChildren> =
  ToniesUIFlashMessage

const MobileOnlyNavigation: FunctionComponent<PropsWithChildren> =
  ToniesUIMobileOnlyNavigation

const MobileOnlyNavigationItem: FunctionComponent<PropsWithChildren> =
  ToniesUIMobileOnlyNavigationItem

const SecondaryNavigation: FunctionComponent<PropsWithChildren> =
  ToniesUISecondaryNavigation

const SecondaryNavigationItem: FunctionComponent<PropsWithChildren> =
  ToniesUISecondaryNavigationItem

type ColorContextProps = {
  backgroundColor: string | ToniesColor | undefined
  theme?: DefaultTheme
}
const ColorContextProvider: FunctionComponent<
  ColorContextProps & PropsWithChildren
> = ToniesUIColorContextProvider

export type { HorizontalScrollListProps }
export {
  ColorContextProvider,
  HorizontalScrollList,
  AccordionItem,
  PrimaryNavigation,
  PrimaryNavigationItem,
  FlashMessage,
  MobileOnlyNavigation,
  MobileOnlyNavigationItem,
  SecondaryNavigation,
  SecondaryNavigationItem,
}
