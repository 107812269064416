import { EcomLocale } from '../../../config/shopAPI/types'
import { toAltNotation } from '../../../providers/locale/altNotation'

type CommerceToolsTranslation = Record<string, string>

export const isCommerceToolsTranslation = (
  a: unknown
): a is CommerceToolsTranslation =>
  typeof a === 'object' &&
  a !== null &&
  Object.values(a).findIndex(v => typeof v !== 'string') === -1

const getTranslatedValue = (
  value: CommerceToolsTranslation,
  lcCC: EcomLocale
) => {
  if (!value) {
    return undefined
  }

  // commercetools DE and FR are "lc-CC"
  // commercetools UK is currently "lc", will later be migrated to "lc-CC"
  const lc = toAltNotation(lcCC, 'lc')

  const t = value[lcCC] || value[lc]

  if (
    typeof t === 'number' ||
    typeof t === 'string' ||
    typeof t === 'boolean'
  ) {
    return t
  }

  return undefined
}

export const normalizeTranslation = (value: unknown, lcCC: EcomLocale) => {
  if (typeof value === 'string') {
    return value.trim()
  } else if (isCommerceToolsTranslation(value)) {
    const translation = getTranslatedValue(value, lcCC)
    return translation ? translation.trim() : ''
  } else {
    return undefined
  }
}
