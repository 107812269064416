import React, { FunctionComponent } from 'react'
import { AggregatedShopLocale } from '../lib/transformation/aggregateShopLocale/types'
import { NextProviders } from '../providers/NextProviders'
import '../public/react-carousel.es.css'
import { MotionConfig } from 'framer-motion'

export type AppStaticProps = {
  aggregatedShopLocale: AggregatedShopLocale
  withZendesk: boolean
  isPageAllowedInAppView?: boolean
}

type MyAppProps = {
  Component: FunctionComponent<unknown>
  pageProps: AppStaticProps
}

/**
 * See https://nextjs.org/docs/advanced-features/custom-app
 */
const MyApp: FunctionComponent<MyAppProps> = ({ Component, pageProps }) => {
  const { aggregatedShopLocale, withZendesk, isPageAllowedInAppView, ...rest } =
    pageProps

  return (
    <NextProviders
      aggregatedShopLocale={aggregatedShopLocale}
      withZendesk={withZendesk}
      isPageAllowedInAppView={isPageAllowedInAppView}
    >
      <MotionConfig reducedMotion="user">
        <Component {...rest} />
      </MotionConfig>
    </NextProviders>
  )
}

export default MyApp
