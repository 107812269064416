import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react'
import { useLocaleAltNotation } from '../locale/altNotation'
import { useNativeApp } from '../nativeApp'
import { loadScript } from '../../utils/scripts'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'

export type SupportContextProps = {
  withZendesk: boolean
  isFallback: boolean
  toggleZendesk(): void
}

export const SupportContext = React.createContext<SupportContextProps>({
  withZendesk: false,
  isFallback: true,
  toggleZendesk: () => undefined,
})

function useShouldSaveData(): boolean {
  const [shouldSaveData, setShouldSaveData] = useState(false)

  useEffect(() => {
    if (navigator.connection?.saveData) {
      setShouldSaveData(true)
    }
    if (['slow-2g', '2g'].includes(navigator.connection?.effectiveType)) {
      setShouldSaveData(true)
    }
    if (process.env.NODE_ENV === 'test') {
      setShouldSaveData(true)
    }
  }, [])

  return shouldSaveData
}

interface SupportContextProviderProps {
  withZendesk: boolean
}

function getZendeskLocaleBy(locale: string) {
  // the mapping from ShopLocale to ZendeskLocale is not 1:1
  // therefore we need these exception(s)
  // see: https://boxine.atlassian.net/browse/TWAS-2807
  return locale === 'de_DE' ? 'de' : locale
}

export const SupportContextProvider: FunctionComponent<
  SupportContextProviderProps & PropsWithChildren
> = props => {
  const { withZendesk, children } = props
  const { isApp } = useNativeApp()
  const { hasZendeskWidget } = useFeatureFlags()

  const isFallback = useShouldSaveData()

  const zendeskLocale = getZendeskLocaleBy(useLocaleAltNotation('lc_CC'))

  useEffect(() => {
    if (isFallback || !withZendesk || !hasZendeskWidget) return

    window.zESettings = {
      webWidget: {
        zIndex: 9999,
        offset: {
          horizontal: '-8px',
          vertical: '48',
        },
      },
    }

    loadScript(
      'https://static.zdassets.com/ekr/snippet.js?key=4c490ca0-9d17-4c0d-9eb7-5e67e1b4bd8e',
      'ze-snippet'
    ).then(() => {
      window.zE?.('webWidget', 'setLocale', zendeskLocale)
    })
  }, [hasZendeskWidget, isFallback, withZendesk, zendeskLocale])

  return (
    <SupportContext.Provider
      value={{
        withZendesk: !isApp && withZendesk && hasZendeskWidget,
        isFallback,
        toggleZendesk() {
          window.zE?.('webWidget', 'toggle')
        },
      }}
    >
      {children}
    </SupportContext.Provider>
  )
}
