import { useLocalStorage } from '@boxine/tonies-ui'
import { sentryWithExtras } from '../../hooks/useSentry'
import React, {
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useUrlQuery } from '../urlQuery'

const NativeAppContext = React.createContext({
  isApp: false,
})

interface NativeAppProviderProps {
  isPageAllowedInAppView: boolean
}

export const NativeAppProvider: FunctionComponent<
  NativeAppProviderProps & PropsWithChildren
> = ({ isPageAllowedInAppView, children }) => {
  const urlQuery = useUrlQuery()
  const [isApp, setIsApp] = useLocalStorage('isApp', false)
  const webviewParam = urlQuery.getUrlParamAsSingleton('webview')
  /**
   * first try to get and persist the "App-Mode" by localstorage
   * set/remove "isApp" based on url query "webview"
   */
  useEffect(() => {
    if (typeof webviewParam !== 'undefined') {
      setIsApp(webviewParam === 'app')
    }
  }, [webviewParam, setIsApp])

  useMemo(() => {
    if (isApp && !isPageAllowedInAppView) {
      const msg = `The current URL ${window.location.href} should not be accessed with webview=app`
      sentryWithExtras('AppViewWhitelistError', new Error(msg), {
        url: window.location.href,
      })
      console.error(msg)
    }
  }, [isApp, isPageAllowedInAppView])

  return (
    <NativeAppContext.Provider value={{ isApp }}>
      {children}
    </NativeAppContext.Provider>
  )
}

export const useNativeApp = () => useContext(NativeAppContext)
