import { useContext, useMemo } from 'react'
import { CartStateContext } from '../providers/cartState'

export const useCartState = () => useContext(CartStateContext)

export const useTunesInCart = () => {
  const { cart, allTunesStatus, checkTunesStatus } = useCartState()

  const tunesLineItems = useMemo(
    () =>
      cart?.lineItems.filter(item => item.product.shopCategory === 'tunes') ||
      [],
    [cart]
  )

  const alreadyMyTuneLineItems = useMemo(
    () =>
      tunesLineItems.filter(
        ({ product: { audioLibraryId } }) =>
          allTunesStatus?.items.find(i => i.id === audioLibraryId)?.isBought
      ),
    [tunesLineItems, allTunesStatus?.items]
  )

  return {
    hasTunes: tunesLineItems?.length !== 0,
    tunesLineItems,
    alreadyMyTuneLineItems,
    allTunesStatus,
    checkTunesStatus,
  }
}
