import React from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'
import { useRef } from 'react'
import { ToniesColor } from '@/tonies-ui/themes/colors'
import { AnimatedToniehead } from '@/tonies-ui/atoms/TonieHead/AnimatedToniehead'
import { useToniesSpring } from '@/tonies-ui/motions/constants'
import { useViewportConfig } from '@/tonies-ui/hooks/useViewport'

export const TonieEars: NormalizedMotionComponent<{
  fill: ToniesColor | (string & Record<never, never>)
}> = ({ fill = 'darkergrey' }) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { scrollYProgress } = useScroll({
    target: wrapperRef,
    offset: ['end start', 'start end'],
  })
  const progress = useTransform(scrollYProgress, [0.9, 0], [1, 0])
  const height = useViewportConfig({
    mobile: '8rem',
    tablet: '10rem',
    desktop: '12rem',
  })

  return (
    <motion.div
      data-testid="tonie-ears-motion"
      aria-hidden
      ref={wrapperRef}
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        overflow: 'hidden',
        height: height,
        isolation: 'isolate',
        pointerEvents: 'none',
        zIndex: -1,
        y: useTransform(progress, [1, 0], ['-80%', '0%']),
      }}
    >
      <AnimatedToniehead
        style={{
          right: '1rem',
          width: '40vw',
          maxWidth: '18rem',
          y: 0,
          x: useTransform(
            useToniesSpring(progress),
            [1, 0],
            ['10vw', '-100vw']
          ),
          rotate: useTransform(progress, [1, 0], [10, 5]),
          scaleX: useTransform(progress, [1, 0], [1, 2.25]),
        }}
        fill={fill}
      />
    </motion.div>
  )
}
