import { Address } from '@commercetools/platform-sdk'
import { BxCustomer } from '../bxTypes'
import { fetch } from '../../util/fetch'
import { EcomLocale } from '../../../../config/shopAPI/types'
import { AddressType } from '../../../../types/normalize/cart'

export const setDefaultAddress = async (
  lcCC: EcomLocale,
  customerVersion: number,
  actions: {
    id: Address['id']
    type: AddressType
  }[]
) =>
  fetch<Record<string, unknown>, BxCustomer>({
    query: `/me`,
    lcCC,
    method: 'post',
    request: {
      version: customerVersion,
      actions: actions.map(({ id, type }) => ({
        action:
          type === 'billing'
            ? 'setDefaultBillingAddress'
            : 'setDefaultShippingAddress',
        addressId: id,
      })),
    },
  })
