import { normalizeWishlist } from '../../../../lib/shopApi/normalizers/normalizeWishlist'
import { EcomLocale } from '../../../../config/shopAPI/types'
import { fetch } from '../../../../lib/shopApi/util/fetch'
import { BxWishlist } from '../bxTypes'

export const addItemToWishlist = async (
  lcCC: EcomLocale,
  wishlistId: string,
  product: NormalizedProduct
): Promise<NormalizedWishlist> => {
  if (process.env.NEXT_PUBLIC_FEATUREFLAG_HAS_WISHLIST !== 'true') {
    return Promise.reject()
  }

  return fetch<BxWishlist>({
    query: `/wishlist-line-item`,
    method: 'post',
    lcCC,
    request: {
      sku: product.sku,
      wishlist: wishlistId,
    },
  }).then(res => {
    if (res.result === 'successful') {
      return normalizeWishlist(lcCC, res.data)
    } else {
      throw res
    }
  })
}
