export const currencies = ['EUR', 'GBP'] as const
export type Currency = (typeof currencies)[number]

export const shopProductMainCategories = [
  'accessories',
  'creative-tonies',
  'toniebox-bundles',
  'tonieboxes',
  'tonieboxes-refurbished',
  'tonies',
  'tunes',
  'repairs',
  'clever-tonies',
] as const
export type ShopProductCategory =
  | (typeof shopProductMainCategories)[number]
  | (string & Record<never, never>)

/**
 * EcomLocales includes:
 *
 * locales configured in commercetools
 */
export const ecomLocales = ['de-DE', 'en-EU', 'en-GB', 'fr-FR'] as const
export type EcomLocale = (typeof ecomLocales)[number]

/**
 * Locales includes:
 *
 * EcomLocales
 * + locales configured in contentful
 */
export const locales = [...ecomLocales, 'en-AU', 'en-HK', 'en-NZ'] as const
export type Locale = (typeof locales)[number]

/**
 * localesInclExternal includes:
 *
 * Locales
 * + "external" locales that we e.g.
 *   - do some SEO stuff for (sitemap, homepage alternates, ...)
 *   - want to check via GeoIP (GeoIP banner, ...)
 *   - ...
 *   - but don't have any i18n for (!)
 */
export const localesInclExternal = [
  ...locales,
  'en-CA',
  'en-US',
  'fr-CA',
] as const
export type LocaleInclExternal = (typeof localesInclExternal)[number]
