import { createContext } from 'react'

export type State = 'initializing' | 'fetching' | 'finished'

export type SyncCartCookiesWithCartStateProps = {
  state: State
}

export const syncCartCookiesWithCartState =
  createContext<SyncCartCookiesWithCartStateProps>({
    state: 'initializing',
  })
