var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ZocRv9MwAVVGbebF6gBPD"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

const debug = process.env.NODE_ENV === 'development'
const environment = process.env.NEXT_PUBLIC_ENVIRONMENT || process.env.NODE_ENV

const SENTRY_DSN: string | undefined =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

if (SENTRY_DSN) {
  Sentry.init({
    debug,
    dsn: SENTRY_DSN,
    environment,
    beforeSend: (event, _hint) => {
      event.tags = { ...event.tags, bx_source: 'client' }
      return event
    },
    // copied from mytonies
    ignoreErrors: [
      // TOC-1551: This error is caused by Google Translate mutating
      // the DOM. The way React works it expects to have full
      // ownership of the DOM without anybody else messing with it.
      // See the ticket TOC-1551 for an in-depth description of what's
      // happening.
      /a\[b\]\.target\.className\.indexOf\(ac\)/,
      /a\[b\]\.target\.className\.indexOf\(bc\)/,

      // The ResizeObserver will print errors when it is not able to
      // deliver all observations within a single animation frame.
      // It has no effect on the correctness of our application. See:
      // https://stackoverflow.com/a/50387233 (TOC-3691)
      /ResizeObserver loop/i,

      // Ignores "IndexSizeError: Failed to execute 'deleteRule' on 'CSSStyleSheet'"
      // error. It is caused by other resources messing with our stylesheets.
      // See: https://github.com/styled-components/styled-components/issues/2382
      /Failed to execute 'deleteRule' on 'CSSStyleSheet'/i,

      // ...Firefox formats the same error as above differently
      // https://sentry.boxine.de/boxine/my-tonies/issues/27758/
      /CSSStyleSheet\.deleteRule(.*)number of rules is only/i,
    ],
    denyUrls: [
      // ignore errors from bad Safari and Firefox extensions, like
      // https://sentry.boxine.de/boxine/my-tonies/issues/31052/ safari-extension://
      // https://sentry.boxine.de/boxine/my-tonies/issues/31655/ safari-extension:(//
      // https://sentry.boxine.de/boxine/my-tonies/issues/31006/ moz-extension://
      /-extension:\(?\/\//,

      // Ignore faulty (likely kaspersky) browser extension which sometimes runs
      // into an infinite loop and floods our sentry instance with events.
      // Those events always have the shape `uuid/main.js`. See:
      // https://sentry.boxine.de/boxine/my-tonies/issues/31060 (TOC-3697)
      /[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}\/main\.js/i,

      // Ignore internal PayPal errors rendering errors. Those are not relevant to us
      // and they don't affect payment. See:
      // https://sentry.boxine.de/boxine/my-tonies/?query=is%3Aunresolved+%2Fsdk%2Fjs (TOC-3810)
      /\.paypal\.com\/sdk\/js/i,
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.2,

    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
}
