import { EcomLocale } from '../../../config/shopAPI/types'
import { generateKey } from '../../../utils'
import { PaymentType } from '../../../providers/payment/types'
import { configCatClient, isSupportedSetting } from '../util'
import { PaymentIconName } from 'providers/payment/lib'

const SDK_KEY = process.env.NEXT_PUBLIC_CONFIGCAT_SDK_KEY_PAYMENT_TYPES

if (!SDK_KEY) {
  throw new Error(
    'ConfigCat: NEXT_PUBLIC_CONFIGCAT_SDK_KEY_PAYMENT_TYPES key is missing.'
  )
}

export type PaymentTypeFlags = {
  [key in PaymentType]: boolean
}
export const defaultPaymentTypeFlags: PaymentTypeFlags = {
  paypal: true,
  scheme: true,
  ideal: false,
  bcmc: false,
  bcmc_mobile: false,
  klarna: false,
  klarna_account: false,
  klarna_paynow: false,
  googlepay: false,
  applepay: false,
}

export type PaymentIconFlags = {
  [key in PaymentIconName]: boolean
}
export const defaultPaymentIconFlags: PaymentIconFlags = {
  ...defaultPaymentTypeFlags,
  visa: true,
  mc: true,
  maestro: false,
  cartebancaire: false,
}

/**
 * Returns all payment types with regard to the given user details.
 * Unconfigured values fall back to their hardcoded defaults.
 */
export const getPaymentTypeFlags = async (params: {
  uuid?: string
  email?: string
  locale: EcomLocale
}): Promise<PaymentTypeFlags & PaymentIconFlags> => {
  const values = await configCatClient({ SDK_KEY }).getAllValuesAsync({
    identifier: params.uuid || generateKey('guest'),
    email: params.email,
    custom: {
      ShopLocale: params.locale,
    },
  })

  return values.reduce(
    (prev, { settingKey, settingValue }) => {
      if (
        isSupportedSetting({
          key: settingKey,
          value: settingValue,
          defaultFlags: {
            ...defaultPaymentTypeFlags,
            ...defaultPaymentIconFlags,
          },
        })
      ) {
        return {
          ...prev,
          [settingKey]: settingValue,
        }
      }

      return prev
    },
    { ...defaultPaymentTypeFlags, ...defaultPaymentIconFlags }
  )
}
