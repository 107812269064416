/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react'
import * as Cookies from 'js-cookie'
import { useCrossTabStateChange } from './useCrossTabStateChange'

/**
 * https://github.com/rrudol/useCookie/blob/master/src/index.js
 * useCookie - React Hook for Cookies based on js-cookie
 * @param {string} key Cookie name
 * @param {Object|string} [initialValue]  Value will be assign if cookie doesn't exist.
 * @returns {Array} Returns cookie value, and the function to update it.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useCookie(key: string, initialValue?: string) {
  const [, setCookieUpdate] = useCrossTabStateChange('bxCookieUpdate', 0)
  const [item, innerSetValue] = useState(Cookies.get(key) || initialValue)
  const setValue = useCallback(
    (value: string | undefined, options?: Cookies.CookieAttributes) => {
      if (value === undefined) {
        Cookies.remove(key, options)
      } else {
        Cookies.set(key, value, options)
      }
      setCookieUpdate(state => state + 1)
      innerSetValue(value)
    },
    [setCookieUpdate, key]
  )

  return [item, setValue] as const
}

export default useCookie
