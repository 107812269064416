export function newlines2Array(text: string) {
  // Split lines on \n
  let lines = text.split('\n')

  // Strip whitespace
  lines = lines.map(l => l.trim())

  // Remove empty lines
  lines = lines.filter(l => l.length > 0)

  if (lines.length > 0) {
    return lines
  }

  return undefined
}
