import { BxLineItemType } from '../../../lib/commercetools/requests/bxTypes'
import {
  CheckoutConfig,
  CheckoutConfigCollection,
  ConfirmedStepsState,
  IsCheckedCollection,
} from './types'
import { NormalizedCartType } from '../../../lib/commercetools/normalizers/normalizeCart'

export const defaultConfirmedSteps: ConfirmedStepsState = {
  login: false,
  addresses: false,
  shipping: false,
  review: false,
  payment: false,
}

export const defaultIsChecked: IsCheckedCollection = {
  repair: false,
  newsletter: false,
  termsAndPrivacy: false,
  digitalProduct: false,
}

const indicatorSteps = [
  {
    order: 0,
    key: 'addresses',
    state: undefined,
  },
  {
    order: 1,
    key: 'shipping',
    state: undefined,
  },
  {
    order: 2,
    key: 'review',
    state: undefined,
  },
  {
    order: 3,
    key: 'payment',
    state: undefined,
  },
]

const indicatorStepsNoShipping = [
  {
    order: 0,
    key: 'addresses',
    state: undefined,
  },
  {
    order: 1,
    key: 'review',
    state: undefined,
  },
  {
    order: 2,
    key: 'payment',
    state: undefined,
  },
]

const defaultConfig: CheckoutConfig = {
  addresses: {
    hasShippingAddress: true,
  },
  shipping: {
    shippingMethodSelect: true,
    freeShippingIndicator: true,
  },
  lineItems: {
    quantityChange: {
      hasQuantityChange: true,
      hasRemove: true,
    },
  },
  coupon: {
    hasCouponCode: true,
  },
  orderReview: {
    checkboxes: {
      termsAndPrivacy: { isShown: true, isMandatoryForCheckout: true },
      newsletter: { isShown: true, isMandatoryForCheckout: false },
      repair: { isShown: false, isMandatoryForCheckout: true },
      digitalProduct: { isShown: false, isMandatoryForCheckout: true },
    },
    price: {
      shouldShowShippingCosts: true,
      shouldShowSubtotal: true,
      shouldShowUSP: true,
    },
  },
  /**
   * the orderSuccess config is not yet used
   */
  orderSuccess: {
    addresses: {
      hasShippingAddress: true,
    },
    i18nHeadlineTerm: 'checkout:orderSuccess:thankYouHeadline',
    showEditorialTeasers: true,
    summary: {
      price: {
        shouldShowShippingCosts: true,
        shouldShowSubtotal: true,
        shouldShowUSP: true,
      },
    },
  },
  indicatorSteps: indicatorSteps,
}

export type GetCheckoutConfigProps = {
  normalizedCartType: NormalizedCartType
  bxLineItemTypes: BxLineItemType[]
}

/**
 * returns the checkout config based on
 *  - `normalizedCartType` (general config) and
 *  - `bxLineItemTypes`(showing the correct T&S checkboxes)
 * @param {GetCheckoutConfigProps}props
 * @returns {CheckoutConfig}
 */
export const getCheckoutConfig = ({
  normalizedCartType,
  bxLineItemTypes,
}: GetCheckoutConfigProps): CheckoutConfig => {
  const variant = normalizedCartType || 'default'
  const hasDigital = bxLineItemTypes.includes('digital')
  const hasPhysical = bxLineItemTypes.includes('physical')
  const hasRepair = bxLineItemTypes.includes('repair')
  const checkoutConfig: CheckoutConfigCollection = {
    /**
     * `default` is used for carts with cartContains = `null` (no lineItems)
     */
    default: {
      ...defaultConfig,
    },
    mixed: {
      ...defaultConfig,
      shipping: {
        shippingMethodSelect: hasPhysical,
        freeShippingIndicator: hasPhysical,
      },
      orderReview: {
        ...defaultConfig.orderReview,
        checkboxes: {
          ...defaultConfig.orderReview.checkboxes,
          digitalProduct: {
            isShown: hasDigital,
            isMandatoryForCheckout: hasDigital,
          },
          repair: { isShown: hasRepair, isMandatoryForCheckout: hasRepair },
        },
        price: {
          shouldShowShippingCosts: hasPhysical,
          shouldShowSubtotal: hasPhysical,
          shouldShowUSP: hasPhysical,
        },
      },
      indicatorSteps: hasPhysical ? indicatorSteps : indicatorStepsNoShipping,
    },
    physical: {
      ...defaultConfig,
    },
    repair: {
      ...defaultConfig,
      shipping: {
        shippingMethodSelect: false,
        freeShippingIndicator: false,
      },
      orderReview: {
        checkboxes: {
          ...defaultConfig.orderReview.checkboxes,
          repair: { isShown: true, isMandatoryForCheckout: true },
        },
        price: {
          shouldShowShippingCosts: false,
          shouldShowSubtotal: false,
          shouldShowUSP: false,
        },
      },
      orderSuccess: {
        addresses: {
          ...defaultConfig.addresses,
        },
        i18nHeadlineTerm: 'checkout:orderSuccess:thankYouHeadlineRepair',
        showEditorialTeasers: true,
        summary: {
          price: {
            shouldShowShippingCosts: false,
            shouldShowSubtotal: false,
            shouldShowUSP: false,
          },
        },
      },
      indicatorSteps: indicatorStepsNoShipping,
    },
    digital: {
      addresses: {
        hasShippingAddress: false,
      },
      shipping: {
        shippingMethodSelect: false,
        freeShippingIndicator: false,
      },
      lineItems: {
        quantityChange: {
          hasQuantityChange: false,
          hasRemove: true,
        },
      },
      coupon: defaultConfig.coupon,
      orderReview: {
        checkboxes: {
          ...defaultConfig.orderReview.checkboxes,
          digitalProduct: { isShown: true, isMandatoryForCheckout: true },
        },
        price: {
          shouldShowShippingCosts: false,
          shouldShowSubtotal: true,
          shouldShowUSP: false,
        },
      },
      orderSuccess: {
        addresses: {
          hasShippingAddress: false,
        },
        i18nHeadlineTerm: 'checkout:orderSuccess:thankYouHeadline',
        showEditorialTeasers: false,
        summary: {
          price: {
            shouldShowShippingCosts: false,
            shouldShowSubtotal: true,
            shouldShowUSP: false,
          },
        },
      },
      indicatorSteps: indicatorStepsNoShipping,
    },
  }

  return checkoutConfig[variant]
}
