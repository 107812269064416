import { BxShippingMethodCollection } from '../bxTypes'
import { fetch } from '../../util/fetch'
import { EcomLocale } from '../../../../config/shopAPI/types'

/**
 * Get the shipping Methods by referenced country.
 */
export const getShippingMethods = async (lcCC: EcomLocale, cartId: string) =>
  fetch<Record<string, unknown>, BxShippingMethodCollection>({
    query: `/bx/cart/${cartId}/shipping_methods`,
    lcCC,
    method: 'get',
    request: {},
  })
