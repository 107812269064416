import { Modal } from '@boxine/tonies-ui'
import React, {
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'

type ModalNotificationsProviderProps = {
  setModal: (modal: ReturnType<typeof Modal> | undefined) => void
}

const ModalNotificationsContext =
  React.createContext<ModalNotificationsProviderProps>({
    setModal: () => null,
  })

export const ModalNotifications: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [modal, setModal] = useState<ReturnType<typeof Modal> | undefined>()

  return (
    <ModalNotificationsContext.Provider
      value={{
        setModal,
      }}
    >
      {children}

      {modal}
    </ModalNotificationsContext.Provider>
  )
}

export const useModalNotifications = (): ModalNotificationsProviderProps =>
  useContext(ModalNotificationsContext)
