import { EcomLocale } from '../../../config/shopAPI/types'
import { normalizeProduct } from '../../opensearch/normalizers/normalizeProduct'
import { BxWishlist } from '../requests/bxTypes'

export const normalizeWishlist = (
  _lcCC: EcomLocale,
  { lineItems, ...rest }: BxWishlist
): NormalizedWishlist => {
  const products = lineItems.map<NormalizedWishlistItem>(
    ({ lineItemId, quantity, ...osProduct }) => {
      return {
        lineItemId,
        quantity,
        ...normalizeProduct(osProduct),
      }
    }
  )

  return {
    ...rest,
    isEmpty: rest.count === 0,
    products,
    getItem: sku => products.find(p => p.sku === sku),
    // since we need a translation for this, it is not possible to pass the url here already
    url: undefined,
  }
}
