import React, { PropsWithChildren } from 'react'
import isEqual from 'lodash/isEqual'
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'next-i18next'
import { useCartActions } from '../../hooks/useCartActions'
import { useCartState } from '../../hooks/useCartState'
import { BxAddress, BxCustomer } from '../../lib/commercetools/requests/bxTypes'
import { useAuth } from '../auth'
import { useEcomLocale } from '../locale'
import { normalizeAddressViolations, ShopApiViolation } from '../../utils'
import { addAddress as ctAddAddress } from '../../lib/commercetools/requests/me/addAddress'
import { setDefaultAddress as ctSetDefaultAddress } from '../../lib/commercetools/requests/me/setDefaultAddress'
import { changeAddress as ctChangeAddress } from '../../lib/commercetools/requests/me/changeAddress'
import { toast } from '@/tonies-ui/atoms/Toast'
import { isAxiosError } from '../../utils/isAxiosError'
import { AxiosError } from 'axios'
import { sentryWithExtras } from '../../hooks/useSentry'
import { normalizeErrorMessage } from '../../utils/normalizeError'
import { Address } from '@commercetools/platform-sdk'
import { AddressEditAction, AddressesContext, AddressesContextType } from '.'
import { setAddressAction } from '../cartActions/actions/setAddressAction'
import { usePayment } from '../../hooks/usePayment'
import { getCheckoutConfig } from '../checkout/config'
import { useBillingCountries } from '../../hooks/useBillingCountries'
import { useShippingCountries } from '../../hooks/useShippingCountries'
import {
  AddressFieldError,
  AddressFormCountry,
} from '../../components/molecules/CheckoutAddressForm/types'

interface FindAddressesInUserAddressesProps {
  user?: BxCustomer | null
  idToFind?: Address['id']
  allowedCountries: AddressFormCountry[]
}
/**
 * filters Addresses by allowedCountries of current cart country and
 * returns the address matching `idToFind` (if given) or the first address
 * of the filter result
 * @param FindAddressesInUserAddressesProps
 * @returns Address | undefined
 */
const findAddressInUserAddresses = ({
  user,
  idToFind,
  allowedCountries,
}: FindAddressesInUserAddressesProps) => {
  if (!user) return
  const allowedAddresses = user.bxFilteredAddresses.filter(address =>
    allowedCountries.find(country => country.value === address.country)
  )
  if (!allowedAddresses.length) return
  if (!idToFind) return allowedAddresses[0]
  return allowedAddresses.find(address => idToFind === address.id)
}

export const AddressesProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const firstRender = useRef(true)
  const hasSyncedCartAddresses = useRef(false)
  const { cart } = useCartState()

  /**
   * handle initial setup of currently selected billing and shipping addresses
   * useRef to keep the value consistent during rerenders from `setDefaultAddress`
   */
  const selectedBillingAddressRef = useRef<BxAddress | undefined>(
    (cart && cart.addresses.billing?.id && cart.addresses.billing) || undefined
  )
  const [selectedBillingAddress, setSelectedBillingAddress] = useState<
    BxAddress | undefined
  >(selectedBillingAddressRef.current || undefined)

  useEffect(() => {
    selectedBillingAddressRef.current = selectedBillingAddress
  }, [selectedBillingAddress])

  const selectedShippingAddressRef = useRef<BxAddress | undefined>(
    (cart && cart.addresses.shipping?.id && cart.addresses.shipping) ||
      undefined
  )
  const [selectedShippingAddress, setSelectedShippingAddress] = useState<
    BxAddress | undefined
  >(selectedShippingAddressRef.current || undefined)

  useEffect(() => {
    selectedShippingAddressRef.current = selectedShippingAddress
  }, [selectedShippingAddress])

  /**
   * update selectedAddress when cart is updated
   */
  useEffect(() => {
    if (cart && cart.addresses.billing?.id) {
      setSelectedBillingAddress(cart.addresses.billing)
    }
    if (cart && cart.addresses.shipping?.id) {
      setSelectedShippingAddress(cart.addresses.shipping)
    }
  }, [cart])

  const { push: queue, state: queueState } = useCartActions()
  const { user, updateUserContext } = useAuth()
  const lcCC = useEcomLocale()
  const { t } = useTranslation()
  const { countryCode, setCountryCode } = usePayment()
  const { countries: allowedBillingCountries, isBillingCountryCode } =
    useBillingCountries()
  const allowedShippingCountries = useShippingCountries()
  const allowedBillingAddresses = user?.bxFilteredAddresses.filter(address =>
    allowedBillingCountries.find(country => country.value === address.country)
  )
  const allowedShippingAddresses = user?.bxFilteredAddresses.filter(address =>
    allowedShippingCountries.find(country => country.value === address.country)
  )
  // if we initially do not have addresses we know we do not have to check again
  useEffect(() => {
    if (
      !hasSyncedCartAddresses.current &&
      user &&
      cart?.lineItems &&
      cart.lineItems.length > 0 &&
      !allowedBillingAddresses?.length &&
      !allowedShippingAddresses?.length
    ) {
      hasSyncedCartAddresses.current = true
    }
  }, [cart, allowedBillingAddresses, allowedShippingAddresses, user])
  const currentBillingCountry = cart?.addresses?.billing?.country

  /**
   * states
   */

  const [isPendingRequest, setIsPendingRequest] = useState(false)
  const isPendingRequestOrQueue = isPendingRequest || queueState !== 'idle'
  const [isSubmitting, setIsSubmitting] = useState(false)
  // To display the address information read only
  const [isReadOnly, setIsReadOnly] = useState(false)
  // if user sets edit mode manually
  const [isEditMode, setIsEditMode] = useState(false)
  // If the validation fails on submit, the container get invalidated
  //  TODO: atm we do not anything with this flag, we might use it for error handling
  const [isContainerValid, setIsContainerValid] = useState<boolean | undefined>(
    undefined
  )
  /**
   * The billing address is mandatory and the shipping address is optional within the checkout.
   * In the cart context, billing and shipping is mandatory.
   * If only a billing address is given, we automatically assume the shipping address
   * is equal to the billing address to match the cart requirements.
   */
  const [isBillingAsShipping, setIsBillingAsShipping] = useState(true)
  const [addressToEdit, setAddressToEdit] = useState<
    { address?: BxAddress; action: AddressEditAction } | undefined
  >()
  /**
   * cart addresses
   */
  const hasShippingAddress = Boolean(cart?.addresses.billing?.streetName)
  const hasBillingAddress = Boolean(cart?.addresses.shipping?.streetName)
  const hasCartAddresses = hasBillingAddress && hasShippingAddress

  /**
   * get checkout Config without checkoutProvider
   */
  const config = getCheckoutConfig({
    normalizedCartType: cart?.normalizedCartType || null,
    bxLineItemTypes: cart?.bxLineItemTypes || [],
  })

  // Is billing equal shipping address
  const areCartAddressesEqual = useMemo(() => {
    const cartHasBothAddresses =
      cart?.addresses.billing && cart?.addresses.shipping
    if (cart && !cartHasBothAddresses) {
      // if we only have one address on the cart, we assume that that address is used for billing & shipping
      return true
    } else if (cart && cartHasBothAddresses) {
      return isEqual(cart?.addresses.billing, cart?.addresses.shipping)
    }
    return undefined
  }, [cart])

  /**
   * default addresses of the user within the auth context
   */
  const defaultBillingAddress = useMemo(
    () =>
      findAddressInUserAddresses({
        user,
        idToFind: user?.defaultBillingAddressId,
        allowedCountries: allowedBillingCountries,
      }),
    [allowedBillingCountries, user]
  )
  const defaultShippingAddress = useMemo(() => {
    if (!config?.addresses?.hasShippingAddress) {
      return defaultBillingAddress
    } else {
      return findAddressInUserAddresses({
        user,
        idToFind: user?.defaultShippingAddressId,
        allowedCountries: allowedShippingCountries,
      })
    }
  }, [
    allowedShippingCountries,
    config.addresses.hasShippingAddress,
    defaultBillingAddress,
    user,
  ])

  /**
   * if we do have a defaultBillingAddress but the cart has none we assume
   * that we are still setting up the carts addresses
   */
  const isPendingSyncAddressesToCart =
    cart && defaultBillingAddress && !cart?.addresses.billing ? true : false

  useEffect(() => {
    if (isPendingRequestOrQueue || isEditMode) return
    if (cart && cart.addresses.shipping?.id) {
      setSelectedShippingAddress(cart.addresses.shipping)
    }
  }, [cart, isPendingRequestOrQueue, isEditMode])

  /**
   * effects
   */

  // If we have equal cart billing and shipping addresses update isBillingAsShipping
  //  If we have no cart, or cart billing or cart shipping address, set this also to true
  // NOTE: If we have have only a shipping address on the cart, this is true because
  //  areCartAddressesEqual is undefined
  // NOTE2: do not change isBillingAsShipping while addresses are updated subsequently
  useEffect(() => {
    if (!isSubmitting && !isPendingRequestOrQueue && !isEditMode) {
      setIsBillingAsShipping(Boolean(areCartAddressesEqual) ?? true)
    }
  }, [areCartAddressesEqual, isSubmitting, isPendingRequestOrQueue, isEditMode])

  // If we have valid addresses (billing and shipping) on the cart
  //  update read only and container validation.
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }
    if (firstRender.current || isPendingRequest || addressToEdit) return
    if (!isEditMode) {
      setIsReadOnly(hasCartAddresses)
      setIsContainerValid(hasCartAddresses ? true : undefined)
    }
  }, [isPendingRequest, hasCartAddresses, addressToEdit, cart, isEditMode])

  // If read only update the container validation
  useEffect(() => {
    if (isReadOnly) {
      setIsContainerValid(true)
    }
  }, [isReadOnly])

  useEffect(() => {
    if (currentBillingCountry) {
      // check if paymentCountryCode has to be changed
      if (isBillingCountryCode(currentBillingCountry)) {
        if (countryCode !== currentBillingCountry)
          setCountryCode(currentBillingCountry)
      } else {
        sentryWithExtras(
          'address',
          new Error(`billing.country ${currentBillingCountry} not valid`),
          {}
        )
      }
    }
  }, [currentBillingCountry, countryCode, setCountryCode, isBillingCountryCode])

  /**
   * callbacks
   */
  const handleError = useCallback(
    (error: Error | AxiosError) => {
      let message
      if (
        isAxiosError(error) &&
        (error.response?.status === 403 || error.response?.status === 404)
      ) {
        message = t('cart:error:network')
      } else if (isAxiosError(error) && error.response?.status === 422) {
        error.response?.data?.violations?.find(
          (violation: ShopApiViolation) => violation.message === 'invalid'
        )
        message = t('checkoutAddressForm:invalidInputError')
      } else {
        message = t('cart:error:generic')
      }
      message && toast(message, 'error')

      sentryWithExtras('address', error, {})
    },
    [t]
  )

  const addAddressesToCart: AddressesContextType['addAddressesToCart'] =
    useCallback(
      async (passedBilling, passedShipping?) => {
        try {
          setIsPendingRequest(true)
          await queue(setAddressAction('billing', passedBilling))
          await queue(
            setAddressAction('shipping', passedShipping ?? passedBilling)
          )
        } catch (error) {
          const sentryError =
            error instanceof Error
              ? error
              : new Error(normalizeErrorMessage(error))
          sentryWithExtras('address', sentryError, {
            ...passedBilling,
            ...passedShipping,
          })
        } finally {
          setIsPendingRequest(false)
        }
      },
      [queue]
    )

  const getNewAddress = (
    newAddresses: BxAddress[],
    passedAddress?: BxAddress
  ) => {
    return passedAddress
      ? newAddresses.filter(address => {
          const keysToCompare = Object.keys(passedAddress)
          let result = false
          for (let i = 0; i < keysToCompare.length; i++) {
            if (
              passedAddress[keysToCompare[i] as keyof BxAddress] ===
              address[keysToCompare[i] as keyof BxAddress]
            ) {
              result = true
            } else {
              return false
            }
          }
          return result
        })[0]
      : undefined
  }

  // Set default addresses when no cart addresses are available
  useEffect(() => {
    if (
      hasSyncedCartAddresses.current ||
      hasCartAddresses ||
      !cart ||
      isPendingRequestOrQueue ||
      isSubmitting
    )
      return
    // use defaultBillingAddress (defaultShippingAddress as backup)
    const billingAddressToSet = defaultBillingAddress
      ? defaultBillingAddress
      : defaultShippingAddress
    // no need to set any addresses, when we do not have a billingAddressToSet
    if (!billingAddressToSet) return
    const shippingAddressToSet = defaultShippingAddress
      ? defaultShippingAddress
      : defaultBillingAddress
    addAddressesToCart(billingAddressToSet, shippingAddressToSet).finally(
      () => {
        hasSyncedCartAddresses.current = true
      }
    )
  }, [
    defaultBillingAddress,
    defaultShippingAddress,
    hasCartAddresses,
    addAddressesToCart,
    queue,
    cart,
    isPendingRequestOrQueue,
    isSubmitting,
  ])

  /**
   * @param {BxAddress[]} passedAddresses - array of addresses to add: index 0 can be billing and/or shipping (see `useAs`), if two addresses are passed the first is always billing the second is shipping
   * @param {BxCustomer | null | undefined} currentUser - optional user
   * @param {NewAddressUseAs} useAs - declare usage fot the passedAddress if only one address is passed
   */
  const addAddressesToAddressbook: AddressesContextType['addAddressesToAddressbook'] =
    useCallback(
      async ({
        passedAddresses,
        currentUser = user,
        useAs = 'billingAndShipping',
        // eslint-disable-next-line sonarjs/cognitive-complexity
      }) => {
        const passedBillingAddress =
          passedAddresses.length === 2
            ? passedAddresses[0]
            : useAs === 'billing' || useAs === 'billingAndShipping'
            ? passedAddresses[0]
            : undefined

        const passedShippingAddress =
          passedAddresses.length === 2
            ? passedAddresses[1]
            : useAs === 'shipping' || useAs === 'billingAndShipping'
            ? passedAddresses[0]
            : undefined

        const result: { errors: AddressFieldError[]; user: BxCustomer | null } =
          {
            errors: [],
            user: null,
          }

        try {
          if (currentUser && passedAddresses.length > 0) {
            setIsPendingRequest(true)
            const res = await ctAddAddress(
              lcCC,
              currentUser.version,
              passedAddresses
            )

            if (res.result === 'successful') {
              // use first new Address as cart address
              const newAddresses = res.data.bxFilteredAddresses.filter(
                address =>
                  !user?.bxFilteredAddresses
                    .map(currentAddress => currentAddress.id)
                    .includes(address.id)
              ) as BxAddress[]

              const newBillingAddress = getNewAddress(
                newAddresses,
                passedBillingAddress
              )
              const newShippingAddress = getNewAddress(
                newAddresses,
                passedShippingAddress
              )
              const newValidBillingAddress =
                newBillingAddress &&
                allowedBillingCountries.find(
                  country => country.value === newBillingAddress.country
                )
                  ? newBillingAddress
                  : undefined
              const newValidShippingAddress =
                newShippingAddress &&
                allowedShippingCountries.find(
                  country => country.value === newShippingAddress.country
                )
                  ? newShippingAddress
                  : undefined
              if (
                newValidShippingAddress &&
                (!selectedShippingAddress ||
                  newValidShippingAddress.id !== selectedShippingAddress.id)
              ) {
                setSelectedShippingAddress(newValidShippingAddress)
              }
              const billingAddressForCart =
                newValidBillingAddress || selectedBillingAddress
              if (billingAddressForCart) {
                addAddressesToCart(
                  billingAddressForCart,
                  newValidShippingAddress || selectedShippingAddress
                )
              }

              updateUserContext(curr => ({
                ...res.data,
                household: {
                  data: curr?.household.data,
                  tunesStatus: curr?.household.tunesStatus,
                },
                wishlists: curr?.wishlists || [],
              }))
              setAddressToEdit(undefined)
              result.user = res.data
            } else {
              handleError(res.error)
            }

            result.errors = normalizeAddressViolations(
              (res.result === 'request-failed' && res.data?.violations) || []
            )
          }

          return result
        } catch (error) {
          const sentryError =
            error instanceof Error
              ? error
              : new Error(normalizeErrorMessage(error))
          sentryWithExtras('address', sentryError, {
            ...passedAddresses,
            ...result,
          })
          return result
        } finally {
          setIsPendingRequest(false)
        }
      },
      [
        user,
        lcCC,
        allowedBillingCountries,
        allowedShippingCountries,
        selectedShippingAddress,
        selectedBillingAddress,
        updateUserContext,
        addAddressesToCart,
        handleError,
      ]
    )

  const setDefaultAddress: AddressesContextType['setDefaultAddress'] =
    useCallback(
      async (actions, currentUser = user) => {
        if (currentUser) {
          try {
            setIsPendingRequest(true)
            const res = await ctSetDefaultAddress(
              lcCC,
              currentUser.version,
              actions
            )

            if (res.result === 'successful') {
              updateUserContext(curr => ({
                ...res.data,
                household: {
                  data: curr?.household.data,
                  tunesStatus: curr?.household.tunesStatus,
                },
                wishlists: curr?.wishlists || [],
              }))
            } else {
              handleError(res.error)
            }
          } catch (error) {
            const sentryError =
              error instanceof Error
                ? error
                : new Error(normalizeErrorMessage(error))
            sentryWithExtras('address', sentryError, {
              ...actions,
              ...currentUser,
            })
          } finally {
            setIsPendingRequest(false)
          }
        }
      },
      [handleError, lcCC, updateUserContext, user]
    )

  const changeAddress: AddressesContextType['changeAddress'] = useCallback(
    async (addressId, address, currentUser = user) => {
      const result: { errors: AddressFieldError[]; user: BxCustomer | null } = {
        errors: [],
        user: null,
      }

      if (currentUser) {
        try {
          setIsPendingRequest(true)
          const res = await ctChangeAddress(
            lcCC,
            currentUser.version,
            addressId,
            address
          )

          if (res.result === 'successful') {
            const shouldChangeCartAddresses =
              cart?.addresses.billing.id === addressId ||
              cart?.addresses.shipping.id === addressId

            if (shouldChangeCartAddresses) {
              const billingAddress =
                cart?.addresses.billing.id === addressId
                  ? { ...address, id: addressId }
                  : cart?.addresses.billing
              const shippingAddress =
                cart?.addresses.shipping.id === addressId
                  ? { ...address, id: addressId }
                  : cart?.addresses.shipping
              if (billingAddress) {
                addAddressesToCart(billingAddress, shippingAddress)
              }
            }
            updateUserContext(curr => ({
              ...res.data,
              household: {
                data: curr?.household.data,
                tunesStatus: curr?.household.tunesStatus,
              },
              wishlists: curr?.wishlists || [],
            }))
            result.user = res.data
          } else {
            handleError(res.error)
          }

          result.errors = normalizeAddressViolations(
            (res.result === 'request-failed' && res.data?.violations) || []
          )
        } catch (error) {
          const sentryError =
            error instanceof Error
              ? error
              : new Error(normalizeErrorMessage(error))
          sentryWithExtras('address', sentryError, {
            ...result,
            ...currentUser,
          })
        } finally {
          setIsPendingRequest(false)
        }
      }

      return result
    },
    [addAddressesToCart, cart, handleError, lcCC, updateUserContext, user]
  )
  return (
    <AddressesContext.Provider
      value={{
        billingAddresses: allowedBillingAddresses,
        shippingAddresses: allowedShippingAddresses,
        defaultBillingAddress,
        defaultShippingAddress,
        selectedBillingAddress,
        selectedShippingAddress,
        setSelectedBillingAddress,
        setSelectedShippingAddress,
        addAddressesToAddressbook,
        setDefaultAddress,
        changeAddress,
        isReadOnly,
        setIsReadOnly,
        isEditMode,
        setIsEditMode,
        isContainerValid,
        setIsContainerValid,
        isBillingAsShipping,
        setIsBillingAsShipping,
        addressToEdit,
        setAddressToEdit,
        addAddressesToCart,
        isPending: isPendingRequest || isPendingSyncAddressesToCart,
        isFirstRender: firstRender.current,
        isSubmitting,
        setIsSubmitting,
        hasCartAddresses,
        areCartAddressesEqual,
      }}
    >
      {children}
    </AddressesContext.Provider>
  )
}
