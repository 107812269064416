import React, { FunctionComponent, PropsWithChildren } from 'react'
import { NextGtmV4Provider } from './gtmV4/NextGtmV4Provider'
import { NextLocaleProvider } from './locale/NextLocaleProvider'
import { SharedProviders, SharedProvidersProps } from './SharedProviders'
import { NextUrlQueryProvider } from './urlQuery/NextUrlQueryProvider'

export type NextProvidersProps = SharedProvidersProps

export const NextProviders: FunctionComponent<
  NextProvidersProps & PropsWithChildren
> = ({
  aggregatedShopLocale,
  children,
  withZendesk,
  isPageAllowedInAppView,
}) => (
  <NextLocaleProvider>
    <NextGtmV4Provider>
      <NextUrlQueryProvider>
        <SharedProviders
          aggregatedShopLocale={aggregatedShopLocale}
          withZendesk={withZendesk}
          isPageAllowedInAppView={isPageAllowedInAppView}
        >
          {children}
        </SharedProviders>
      </NextUrlQueryProvider>
    </NextGtmV4Provider>
  </NextLocaleProvider>
)
