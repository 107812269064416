import * as v from '@badrap/valita'
import debug from 'debug'
import { FetchResponse, fetchFromOs } from '../../util/fetchFromOs'
import { normalizeAggregations } from '../../../../lib/opensearch/normalizers/normalizeAggregations'
import { normalizeProduct } from '../../../../lib/opensearch/normalizers/normalizeProduct'
import {
  Params,
  Product,
  ProductAggregations,
  ShopLocale,
} from './validationSchema'
import { osManipulation } from './osManipulation'

export type Parameter = {
  lcCC: v.Infer<typeof ShopLocale>
  limit?: number
  offset?: number
  available?: boolean
  discounted?: boolean
  withBucketAggs?: boolean
} & Partial<
  Omit<
    v.Infer<typeof Params>,
    | 'shopLocale'
    | 'available'
    | 'discounted'
    | 'withBucketAggs'
    | 'limit'
    | 'offset'
  >
>

type Data = {
  products: v.Infer<typeof Product>[]
  total: number
  aggregations: v.Infer<typeof ProductAggregations> | []
  parameter: Parameter
}

type Result<D> =
  | {
      result: 'successful'
      data: D
    }
  | {
      result: 'validation-failed'
      data: D
      errors: ValidationError[]
    }
  | {
      result: 'failed'
      error: Error
    }

type ValidationError = {
  message: string
  error: v.ValitaError
}

/**
 * Validates the response received from the server and extracts the products, aggregations, and validation errors.
 *
 * @param response - The response object received from the server.
 * @param options - Additional options for validation.
 * @param options.withBucketAggs - A boolean indicating whether to include bucket aggregations in the response.
 * @returns An object containing the extracted products, aggregations, validation errors, and a flag indicating if there are any validation errors.
 */
function validateResponse(
  response: FetchResponse,
  options: { withBucketAggs?: boolean }
) {
  const products: v.Infer<typeof Product>[] = []
  let aggregations: v.Infer<typeof ProductAggregations> | [] = []
  const validationErrors: ValidationError[] = []

  response.documents.forEach(document => {
    try {
      const product = Product.parse(document)

      products.push(product)
    } catch (error) {
      if (error instanceof v.ValitaError) {
        // We still want to include the product in the response even if it's not valid 💥
        // TODO: Remove this once we have a better way to handle invalid products
        products.push(document as unknown as v.Infer<typeof Product>)

        const productId =
          document != null &&
          typeof document === 'object' &&
          'productId' in document
            ? document.productId
            : 'unknown'

        const slug =
          document != null && typeof document === 'object' && 'slug' in document
            ? document.slug
            : 'unknown'

        validationErrors.push({
          message: `Validation not passed for product with 'productId': ${productId} and 'slug': ${slug}`,
          error,
        })
      }
    }
  })

  try {
    if (options.withBucketAggs) {
      aggregations = ProductAggregations.parse(response.aggregations)
    }
  } catch (error) {
    if (error instanceof v.ValitaError) {
      // We still want to include the aggregations in the response even if it's not valid 💥
      // TODO: Remove this once we have a better way to handle invalid products
      aggregations = response.aggregations as unknown as v.Infer<
        typeof ProductAggregations
      >

      validationErrors.push({
        message: `Validation of aggregations failed`,
        error,
      })
    }
  }

  return {
    products,
    aggregations,
    validationErrors,
    hasValidationErrors: validationErrors.length > 0,
  }
}

/**
 * Retrieves OpenSearch products (not normalized) based on the provided parameters.
 * Mostly used by gitlab scripts in ./gitlab/
 *
 * @param {Parameter} options - The options for retrieving OpenSearch products.
 * @returns {Promise<Result<Data>>} - A promise that resolves to the result of the operation.
 */
export async function getOsProducts(params: Parameter): Promise<Result<Data>> {
  const {
    lcCC,
    available,
    discounted,
    withBucketAggs,
    limit,
    offset,
    ...overrideParams
  } = params
  const fetchParams: v.Infer<typeof Params> = {
    shopLocale: lcCC,
    ...overrideParams,
  }

  if (available !== undefined) {
    fetchParams.available = available ? 'true' : 'false'
  }

  if (discounted !== undefined) {
    fetchParams.discounted = discounted ? 'true' : 'false'
  }

  if (withBucketAggs !== undefined) {
    fetchParams.withBucketAggs = withBucketAggs ? '1' : '0'
  }

  try {
    Params.parse(fetchParams)

    const response = await fetchFromOs({
      type: 'products',
      params: fetchParams,
      limit,
      offset,
    })

    const { products, aggregations, validationErrors, hasValidationErrors } =
      validateResponse(response, {
        withBucketAggs,
      })

    const data = {
      aggregations,
      products,
      total: response.total,
      parameter: params,
    }

    if (hasValidationErrors) {
      return {
        result: 'validation-failed',
        errors: validationErrors,
        data,
      }
    }

    return {
      result: 'successful',
      data,
    }
  } catch (error) {
    if (error instanceof Error) {
      return {
        result: 'failed',
        error,
      }
    }

    return {
      result: 'failed',
      error: new Error('Unknown error'),
    }
  }
}

/**
 * Retrieves normalized products based on the provided parameters.
 *
 * @param params - The parameters for retrieving products.
 * @returns {Promise<Result<NormalizedOpenSearchProductsResponse>>} - A promise that resolves to the result of the operation.
 */
export async function getProducts(
  params: Parameters<typeof osManipulation>[0]
): Promise<Result<NormalizedOpenSearchProductsResponse>> {
  const { newParams, handleProductsManipulation } = osManipulation(params)

  const response = await getOsProducts({
    ...newParams,
    sort: newParams.sort?.openSearchSorting,
    limit: newParams.sort?.isFixedSkuList ? undefined : params.limit,
    offset: newParams.sort?.isFixedSkuList ? 0 : params.offset,
  })

  if (response.result === 'failed') {
    return response
  }

  // Normalize the response
  const data = {
    ...response.data,
    products: handleProductsManipulation(
      response.data.products.map(normalizeProduct)
    ),
    parameter: newParams,
    aggregations: normalizeAggregations(response.data.aggregations),
  }

  debug('lib:opensearch:requests:products:getProducts')({
    params,
    response: response.data,
    normalized: data,
  })

  if (response.result === 'validation-failed') {
    return {
      result: 'validation-failed',
      errors: response.errors,
      data,
    }
  }

  return {
    result: 'successful',
    data,
  }
}
