import {
  AddressFieldError,
  AddressFieldName,
} from '../components/molecules/CheckoutAddressForm/types'
import {
  generateSalt,
  generateKey,
  generateId,
  generateRandomNumber,
  generateSha256,
} from './generate'
import { removeEmpty } from './removeEmpty'

const pipe =
  (
    // eslint-disable-next-line @typescript-eslint/ban-types
    ...functions: Function[]
  ) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (args: unknown): any =>
    functions.reduce((arg, fn) => fn(arg), args)

export type ShopApiViolation = {
  propertyPath: string
  message: string
  code: string
}

export const normalizeAddressViolations = (
  serverViolations: ShopApiViolation[]
): AddressFieldError[] =>
  serverViolations.map(v => ({
    path: v.propertyPath,
    fieldName: v.propertyPath.split('.').pop() as AddressFieldName,
    type: v.message as AddressFieldError['type'],
  }))

export type LineItemViolationError = {
  message: string
  sku: string
  cause:
    | 'product_is_out_of_stock'
    | 'geo_ip_country_not_allowed'
    | 'tune_already_owned'
}

export type GeoIpValidationError = {
  message: 'payment.blocked.geo_ip'
  propertyPath: string
}

export type PaymentTransactionError = {
  message: string
  propertyPath: string
}

export const normalizeLineItemViolations = (
  serverViolations: ShopApiViolation[] | undefined = []
): LineItemViolationError[] =>
  [...serverViolations]
    .filter(v => v.message.includes('line_item.'))
    .map(v => ({
      message: v.message,
      sku: v.code,
      cause: v.message.split(
        'line_item.'
      )[1] as LineItemViolationError['cause'],
    }))

export const normalizePaymentViolations = (
  serverViolations: ShopApiViolation[]
): PaymentTransactionError[] =>
  [...serverViolations]
    .filter(v => v.message === 'transaction_invalid')
    .map(v => ({
      message: v.message,
      propertyPath: v.propertyPath,
    }))

export const normalizeGeoIpViolations = (
  serverViolations: ShopApiViolation[]
): GeoIpValidationError[] =>
  [...serverViolations]
    .filter(v => v.message === 'payment.blocked.geo_ip')
    .map(v => ({
      message: 'payment.blocked.geo_ip',
      propertyPath: v.propertyPath,
    }))

export const isArray = (v: unknown): v is [] => {
  return Array.isArray(v)
}

export const isObject = (v: unknown): v is Record<string, unknown> => {
  return typeof v === 'object' && v !== null && !Array.isArray(v)
}

export {
  generateKey,
  generateId,
  generateRandomNumber,
  generateSalt,
  generateSha256,
  pipe,
  removeEmpty,
}
