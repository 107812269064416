import { createContext, useContext } from 'react'
import { AssignStatusProps } from '../../layouts/AccountLayout/components/View'
import { EcomLocale } from '../../config/shopAPI/types'

export type OwnAudioContentContextProps = {
  setAssignstatus?: React.Dispatch<React.SetStateAction<AssignStatusProps>>
  isDirectOwnAudioContentAssignmentEnabled: boolean
  updateAssignedContentList?: (
    id: string,
    sku: string,
    assignedTuneTitle: string
  ) => void
  assignStatus: AssignStatusProps
  ownAudioContentCollection?: NormalizedProduct[]
  isAllOwnAudioContentReady: boolean
  localesWithAudioContentToFetch?: EcomLocale[]
  isRegionWithAudioContent: boolean
}

export const ownAudioContentContext =
  createContext<OwnAudioContentContextProps>({
    setAssignstatus: undefined,
    isDirectOwnAudioContentAssignmentEnabled: false,
    updateAssignedContentList: undefined,
    assignStatus: [],
    ownAudioContentCollection: undefined,
    isAllOwnAudioContentReady: false,
    localesWithAudioContentToFetch: undefined,
    isRegionWithAudioContent: false,
  })

export const useOwnAudioContentContext = () =>
  useContext(ownAudioContentContext)
