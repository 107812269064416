import React from 'react'
import { DefaultMotionSpanElement } from '@/tonies-ui/motions/constants'

export const PulseIt: NormalizedMotionComponent = ({ children, ...rest }) => {
  return (
    <DefaultMotionSpanElement
      data-testid="pulse-it-motion"
      whileInView={{
        scale: [1, 1.1, 1],
        opacity: [1, 0.9, 1],
        x: ['0%', '5%', '0%'],
        y: ['0%', '5%', '0%'],
        transition: {
          duration: 15,
          repeat: Infinity,
        },
      }}
      {...rest}
    >
      {children}
    </DefaultMotionSpanElement>
  )
}
