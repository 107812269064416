import { toast } from '@/tonies-ui/atoms/Toast'
import { AxiosError } from 'axios'
import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'next-i18next'
import { sentryWithExtras } from '../../hooks/useSentry'
import { useCartState } from '../../hooks/useCartState'
import { useEcomLocale } from '../locale'
import { ShippingContext, ShippingContextProps } from './ShippingContext'
import {
  NormalizedShippingMethods,
  normalizeShippingMethods,
} from '../../lib/commercetools/normalizers/normalizeShippingMethods'
import { getShippingMethods } from '../../lib/commercetools/requests/shipping/getShippingMethods'
import { useLocaleAltNotation } from '../locale/altNotation'
import { BxShippingMethodCollection } from '../../lib/commercetools/requests/bxTypes'
import { useCheckoutConfig } from '../checkout'
import { useCheckout } from '../../hooks/useCheckout'

export const ShippingContextProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { cart } = useCartState()
  const { orderId } = useCheckout()
  const { shipping: config } = useCheckoutConfig()
  const hasCartShipping = config.shippingMethodSelect
  const cartShippingMethodId = cart?.shippingInfo?.shippingMethod?.id
  const totalPriceCentAmount = cart?.price?.total?.centAmount || 0
  const shippingCentAmount = cart?.shipping?.price?.centAmount || 0
  const totalPriceWithoutShippingCentAmount =
    totalPriceCentAmount - shippingCentAmount
  const prevShippingPrice = useRef<number | undefined>(undefined)
  const localeCC = useLocaleAltNotation('CC')
  const localeLcCC = useEcomLocale()
  const cartShippingCC = cart?.addresses?.shipping?.country
  const cartShippingPostalCode = cart?.addresses?.shipping?.postalCode
  const [shippingCC, setShippingCC] = useState<string>(
    cartShippingCC || localeCC
  )
  const prevShippingCC = useRef('')
  const prevShippingPostalCode = useRef('')

  // Error texts are translated per locale
  const { t } = useTranslation()

  // Preserve a local copy of the latest state in CommerceTools
  const [shippingMethods, setShippingMethods] =
    useState<NormalizedShippingMethods>([])
  const [pendingRequests, setPendingRequests] = useState(0)
  const [isReadOnly, setIsReadOnly] = useState(false)
  const [error, setError] = useState<Error>()

  // Helper function to update hook state when the Shipping changes
  const update = useCallback(
    (
      bxShippingMethodCollection: BxShippingMethodCollection | undefined,
      error?: Error | AxiosError,
      data?: unknown
    ) => {
      // only update Shipping if we got a success response
      const bxShippingMethods = bxShippingMethodCollection?.results
      if (bxShippingMethods) {
        setShippingMethods(
          normalizeShippingMethods(
            bxShippingMethods,
            totalPriceWithoutShippingCentAmount,
            localeLcCC
          )
        ) // React state
      } else if (!error) {
        setShippingMethods([])
      }

      setError(error)

      // Eventual errors will be displayed as Toasts
      if (error) {
        let message

        if (error.message.includes('Network Error')) {
          // 'Network Error' is implemented by Axios and hence cross-browser-safe
          message = t('shipping:error:network')
        } else {
          message = t('shipping:error:generic')
        }

        message && toast(message, 'error')

        sentryWithExtras('shippingProvider', error, data)
      }
    },
    [totalPriceWithoutShippingCentAmount, localeLcCC, t]
  )

  /**
   * check if cart is allowed to fetch new shippingMethods
   * do not fetch if we:
   * - have an orderId (if an order is already created the cart is not writeable anymore)
   * - have shippingMethodSelect set to true in config
   */
  const cartIsAllowedToGetShippingMethods =
    cart && cartShippingPostalCode && config.shippingMethodSelect && !orderId

  /**
   * check for changes in postalCode/countryCode or shippingPrice
   * the respective white- / blacklists for country- and postalCodes are maintained in BE:
   * - no shipping allowed: {@link https://gitlab.boxine.de/shop/tonies.de/-/blob/master/config/packages/bx_shop.yaml#L376}
   * - no _express_ shipping allowed: {@link https://gitlab.boxine.de/shop/tonies.de/-/blob/master/config/packages/bx_shop.yaml#L41}
   */
  const postalCodeHasChanged =
    prevShippingPostalCode.current !== cartShippingPostalCode
  const countryCodeHasChanged = prevShippingCC.current !== shippingCC
  const shippingPriceHasChanged =
    prevShippingPrice.current !== cart?.shipping?.price?.amount

  useEffect(() => {
    if (
      cartIsAllowedToGetShippingMethods &&
      (countryCodeHasChanged || postalCodeHasChanged || shippingPriceHasChanged)
    ) {
      setPendingRequests(n => n + 1)
      getShippingMethods(localeLcCC, cart.id)
        .then(fr => {
          if (fr.result === 'successful') {
            update(fr.data)
            prevShippingCC.current = shippingCC
            prevShippingPostalCode.current = cartShippingPostalCode
            prevShippingPrice.current = cart.shipping?.price?.amount
          } else {
            update(undefined, fr.error, fr.data)
          }
        })
        .finally(() => {
          setPendingRequests(n => n - 1)
        })
    }
  }, [
    cart,
    shippingCC,
    localeLcCC,
    orderId,
    update,
    cartShippingPostalCode,
    cartIsAllowedToGetShippingMethods,
    countryCodeHasChanged,
    postalCodeHasChanged,
    shippingPriceHasChanged,
  ])

  /**
   * update shippingCC if cartShippingCC changes
   */
  useEffect(() => {
    if (cartShippingCC && cartShippingCC !== shippingCC)
      setShippingCC(cartShippingCC)
  }, [cartShippingCC, shippingCC])

  // Create context value
  const value: ShippingContextProps = {
    error,
    pendingRequests,
    shippingMethodId: cartShippingMethodId ?? undefined,
    shippingMethods,
    hasCartShipping,
    shippingMethodCount: shippingMethods.length,
    isPending: pendingRequests > 0,
    isReadOnly,
    setIsReadOnly,
  }

  return (
    <ShippingContext.Provider value={value}>
      {children}
    </ShippingContext.Provider>
  )
}
