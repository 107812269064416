/**
 * Constructs a GraphQL query string by interpolating the provided template strings and values.
 * This function is used for tagging graqphl queries to provicdxe syntax highlighting, linting, and formatting.
 * See: https://marketplace.visualstudio.com/items?itemName=GraphQL.vscode-graphql-syntax
 *
 * @param strings - An array of template strings.
 * @param keys - The values to be interpolated into the template strings.
 * @returns The constructed GraphQL query string.
 */
export function gql(strings: TemplateStringsArray, ...keys: unknown[]) {
  const lastIndex = strings.length - 1
  return (
    strings.slice(0, lastIndex).reduce((p, s, i) => p + s + keys[i], '') +
    strings[lastIndex]
  )
}
