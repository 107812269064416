import { useEffect } from 'react'

// TODO review the usage of this questionable module
export function useAsyncEffect(
  effect: () => Promise<void>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  propDependencies: any[]
) {
  useEffect(() => {
    // eslint-disable-next-line no-console
    effect().catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, propDependencies)
}
