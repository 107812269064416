import { normalizeProduct } from '../../../../lib/opensearch/normalizers/normalizeProduct'
import { EcomLocale } from '../../../../config/shopAPI/types'
import { fetch } from '../../util/fetch'
import { RecommendationsQueryResult } from './types'

type GetRecommendationsProps = {
  lcCC: EcomLocale
  accessToken?: string
  sku?: string
}

export const getRecommendations = async ({
  lcCC,
  accessToken,
  sku,
}: GetRecommendationsProps) => {
  const response = await fetch<RecommendationsQueryResult>({
    query: `/recommendations/${sku ? sku + '/sku' : 'user'}`,
    lcCC,
    accessToken,
  })

  if (response.result === 'successful') {
    const normalizedResponse = {
      products: response.data.recommendations
        .filter(r => !!r.price)
        .map(normalizeProduct),
    }

    return {
      ...response,
      data: normalizedResponse,
    }
  }

  // Return results
  return response
}
