import { createContext } from 'react'
import {
  getCheckTunesStatusExtended,
  useCheckTunesStatus,
} from '../../lib/cloudservices/requests/rest/checkTuneStatus'

export type CartStateContextProps = {
  cart: NormalizedCart | undefined
  setCart: (c: NormalizedCart | undefined) => void
  allTunesStatus: Awaited<ReturnType<typeof getCheckTunesStatusExtended>>
  checkTunesStatus: ReturnType<typeof useCheckTunesStatus>
  isItemInCart: (sku: string) => boolean | undefined
  isPendingState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

export const CartStateContext = createContext<CartStateContextProps>({
  cart: undefined,
  setCart: () => undefined,
  allTunesStatus: undefined,
  checkTunesStatus: () => Promise.resolve(undefined),
  isItemInCart: () => undefined,
  isPendingState: [true, () => null],
})
