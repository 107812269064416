import React, { forwardRef } from 'react'
import { motion } from 'framer-motion'
import TonieHead from '.'
import styled from 'styled-components'
import { ToniesColor } from '@/tonies-ui/themes/colors'

const MotionDiv = styled(motion.div)`
  position: absolute;
  z-index: 10;
`

export const AnimatedToniehead: NormalizedMotionComponent<{
  fill: ToniesColor | (string & Record<never, never>)
}> = forwardRef(({ fill, ...props }, ref) => {
  return (
    <MotionDiv
      {...props}
      // @FIXME
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={ref as any}
    >
      <TonieHead fill={fill} />
    </MotionDiv>
  )
})

AnimatedToniehead.displayName = 'AnimatedToniehead'
