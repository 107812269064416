import { useState, useEffect } from 'react'
import { CountryCodes } from '@/tonies-ui/i18n/countrycodes'

export const GEOIP_API_ENDPOINT = process.env.NEXT_PUBLIC_CLOUDSERVICES_API_URL
  ? `${process.env.NEXT_PUBLIC_CLOUDSERVICES_API_URL}/geoip`
  : 'https://api.tonie.cloud/v2/geoip'

export enum RegionLanguageMapperEnum {
  DACH = 'de',
  UKI = 'en-GB',
  USA = 'en-US',
  FRA = 'fr-FR',
  EUR = 'en-GB',
  ROW = 'en-GB',
}

export enum RegionLcCCMapperEnum {
  DACH = 'de-DE',
  UKI = 'en-GB',
  USA = 'en-US',
  FRA = 'fr-FR',
  EUR = 'en-EU',
  ROW = 'en-EU',
}

export type GeoIpRegion = keyof typeof RegionLanguageMapperEnum
export type GeoIpLanguage = `${RegionLanguageMapperEnum}`
export type GeoIpLanguageWithoutRegion = 'de' | 'en' | 'fr'
export type GeoIpLcCC = `${RegionLcCCMapperEnum}`

export interface UseGeoIpInterface {
  geoIpRegion: GeoIpRegion
  geoIpLanguage: GeoIpLanguage
  geoIpLcCC: GeoIpLcCC
  geoIpCountry?: CountryCodes
  geoIpLanguageWithoutRegion: GeoIpLanguageWithoutRegion
  geoIpCanBuyTunes: boolean
  retailerUrl: string | null
  isLoading: boolean
}

interface UseGeoIpProps {
  pause?: boolean
  endpoint?: string
}

export const isValidRegion = (region: GeoIpRegion) =>
  Object.keys(RegionLanguageMapperEnum).includes(region)

export const getRegionLanguage = (
  region: GeoIpRegion = 'ROW'
): GeoIpLanguage => {
  if (!isValidRegion(region)) return 'en-GB'

  return RegionLanguageMapperEnum[region]
}

export const getRegionLcCC = (region: GeoIpRegion = 'ROW'): GeoIpLcCC => {
  if (!isValidRegion(region)) return 'en-GB'

  return RegionLcCCMapperEnum[region]
}

export const useGeoIp = ({
  pause,
  endpoint = GEOIP_API_ENDPOINT,
}: UseGeoIpProps = {}): UseGeoIpInterface => {
  const [region, setRegion] = useState<GeoIpRegion>('ROW')
  const [country, setCountry] = useState<CountryCodes>()
  const [canBuyTunes, setCanBuyTunes] = useState(false)
  const [retailerUrl, setRetailerUrl] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (pause) {
      return
    }
    fetch(endpoint)
      .then(response => {
        return response.json()
      })
      .then(response => {
        const geoRegion = response.region.toUpperCase()
        const canBuyTunes = response.canBuyTunes as boolean

        setRegion(geoRegion)
        setCanBuyTunes(canBuyTunes)
        setCountry(response.country)
        setRetailerUrl(response.retailerUrl)
        setIsLoading(false)
      })
  }, [pause, endpoint])

  return {
    isLoading,
    geoIpRegion: isValidRegion(region) ? region : 'ROW',
    geoIpCountry: country,
    geoIpLanguage: getRegionLanguage(region),
    geoIpLcCC: getRegionLcCC(region),
    geoIpLanguageWithoutRegion: getRegionLanguage(region)
      .replace(/[-_].*/, '')
      .toLowerCase() as GeoIpLanguageWithoutRegion,
    geoIpCanBuyTunes: canBuyTunes,
    retailerUrl,
  }
}
