import React, {
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react'

type MeasurementsContextProps = {
  headersHeight: number
  mainNavigationHeight: number
  stickyHeaderHeight: number
  stickyFooterHeight: number
  setHeadersHeight: Dispatch<SetStateAction<number>>
  setMainNavigationHeight: Dispatch<SetStateAction<number>>
  setStickyHeaderHeight: Dispatch<SetStateAction<number>>
  setStickyFooterHeight: Dispatch<SetStateAction<number>>
  getHeightOfRefs: (refs: React.RefObject<HTMLElement>[]) => number
}

const MeasurementsContext = React.createContext<MeasurementsContextProps>({
  headersHeight: 0,
  mainNavigationHeight: 0,
  stickyHeaderHeight: 0,
  stickyFooterHeight: 0,
  setHeadersHeight: () => undefined,
  setMainNavigationHeight: () => undefined,
  setStickyHeaderHeight: () => undefined,
  setStickyFooterHeight: () => undefined,
  getHeightOfRefs: () => 0,
})

const getHeightOfRefs = (refs: React.RefObject<HTMLElement>[]) => {
  return refs
    .map(ref => ref.current?.getBoundingClientRect())
    .map(rect => {
      if (rect && rect.bottom > 0) {
        return rect.bottom - Math.max(rect.top, 0)
      }

      return 0
    })
    .reduce((prev, height) => prev + height, 0)
}

export const MeasurementsProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [headersHeight, setHeadersHeight] = useState(0)
  const [mainNavigationHeight, setMainNavigationHeight] = useState(0)
  const [stickyHeaderHeight, setStickyHeaderHeight] = useState(0)
  const [stickyFooterHeight, setStickyFooterHeight] = useState(0)

  return (
    <MeasurementsContext.Provider
      value={{
        headersHeight,
        mainNavigationHeight,
        stickyHeaderHeight,
        stickyFooterHeight,
        setHeadersHeight,
        setMainNavigationHeight,
        setStickyHeaderHeight,
        setStickyFooterHeight,
        getHeightOfRefs,
      }}
    >
      {children}
    </MeasurementsContext.Provider>
  )
}

export const useMeasurements = () => useContext(MeasurementsContext)
