import { EcomLocale } from 'config/shopAPI/types'
import { getItem, setItem } from '../../../../hooks/useStorage'

export type RecommendationsSource = 'personal' | 'sku-based'
type RecommendationsStorage = {
  lastSeenSkuList: string[]
  products: {
    items: NormalizedProduct[]
    source: RecommendationsSource
    lastUpdatedAt: string
  }
}

export const getRecommendationsStore = (
  lcCC: EcomLocale
): RecommendationsStorage => {
  return getItem(`recommendations-${lcCC}`, 'localStorage')
}

export const setRecommendationsStore = (
  recommendationsStorage: RecommendationsStorage,
  lcCC: EcomLocale
) => {
  setItem(`recommendations-${lcCC}`, recommendationsStorage, 'localStorage')
}

export const getRecommendationsAge = (lcCC: EcomLocale) => {
  const productsLastUpdatedAt =
    getRecommendationsStore(lcCC)?.products?.lastUpdatedAt || ''
  return new Date().getTime() - new Date(productsLastUpdatedAt).getTime()
}

export const getRecommendationsSource = (lcCC: EcomLocale) => {
  return getRecommendationsStore(lcCC)?.products?.source
}

export const setRecommendedProducts = (
  products: NormalizedProduct[],
  source: RecommendationsSource,
  lcCC: EcomLocale
) => {
  const RecommendationsState = getRecommendationsStore(lcCC)
  setRecommendationsStore(
    {
      ...RecommendationsState,
      products: {
        items: products,
        source,
        lastUpdatedAt: new Date().toISOString(),
      },
    },
    lcCC
  )
}
/**
 * maintains a list of the last 10 "seen" SKUs
 * (last seen = PDP impressions, add2cart, onPlay AudioSample)
 */
export const addLastSeenSku = (sku: string, lcCC: EcomLocale) => {
  const recommendationsState = getRecommendationsStore(lcCC)
  const skuList: string[] = recommendationsState?.lastSeenSkuList || []
  const { length } = skuList
  if (length > 0 && skuList[length - 1] === sku) return
  if (length >= 10) skuList.shift()
  skuList.push(sku)
  setRecommendationsStore(
    {
      ...recommendationsState,
      lastSeenSkuList: skuList,
    },
    lcCC
  )
}

export const getLastSeenSku = (lcCC: EcomLocale) => {
  const skuList: string[] = getRecommendationsStore(lcCC)?.lastSeenSkuList || []
  return skuList.pop()
}

export const getRecommendations = (lcCC: EcomLocale): NormalizedProduct[] => {
  const storedRecommendations = getRecommendationsStore(lcCC)?.products?.items
  if (!storedRecommendations) return []
  return storedRecommendations.filter(
    (p: NormalizedProduct) => p.sku !== getLastSeenSku(lcCC)
  )
}
