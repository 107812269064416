/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

const httpConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
}

/**
 * Axios instance to use for http calls
 *
 * @type {AxiosInstance}
 */
export const http: AxiosInstance = axios.create(httpConfig)
export type HttpResponse<T> = AxiosResponse<T>

// Interceptors
export const addResponseInterceptor = (success: any, error: any) => {
  http.interceptors.response.use(success, error)
  return true
}

export const onNetworkError = (callback: (error: any) => void) => {
  http.interceptors.response.use(
    success => success,
    error => {
      if (!error.response && error.request) {
        callback(error)
      }
      return Promise.reject(error)
    }
  )
  return true
}

export const onUnauthorizedError = (callback: () => void) => {
  http.interceptors.response.use(
    success => success,
    error => {
      if (error.response?.status === 401) {
        callback()
      }
      return Promise.reject(error)
    }
  )
}
