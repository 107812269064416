import { ShopConfig } from './types'

export const shopConfig: ShopConfig = {
  /**
   * Static assets used in our components and pages
   * Should be added to https://app.contentful.com/spaces/l6vo76cztcax/entries/Pw7QCvlIg2PIzR6BsjOve
   */
  assets: {
    audioPlayerSectionBackground:
      'https://images.ctfassets.net/l6vo76cztcax/7qKzg8e0XTAOlmn2LG6inP/bf0bdf41abfe18786bc3862ab646b287/toniebox-red-bottom.png',
    emptyState:
      'https://images.ctfassets.net/l6vo76cztcax/1IzrHpwqVNmOMeNBX6qwOO/ffcc7a310d5039a43fa659f3a691138c/Asset_EmptyState.png',
    ogImage:
      'https://images.ctfassets.net/l6vo76cztcax/4TA4DUQl2Yj4NmLtqyAzZR/0a830f5d5a8172d7498a74b3eb3faefb/og-image.jpg',
    product:
      'https://images.ctfassets.net/l6vo76cztcax/3ss3Hke90C2AiVWQShb2d6/c3b4c47aafd0ad6dc8262cbb3d678302/product-image-fallback.png',
    toniesAvatar:
      'https://images.ctfassets.net/l6vo76cztcax/6GsFc3deb1dlUqe9vr1vlq/08af7324b99340f2d7b93a399455dcde/tonieAvatar-c3.svg',
    toniesFairy:
      'https://images.ctfassets.net/l6vo76cztcax/5QFRNWgF9zgHb2r0grwmHD/e45203412ab6a29618e7e494b6849eef/tonie-fairy.svg',
    toniesLogo:
      'https://images.ctfassets.net/l6vo76cztcax/54byI2c62blpOTL2LBAGyO/10328bd063218d3a0762e470644cdb90/logo-tonies.svg',
    videoPlayerPosterDesktop:
      'https://images.ctfassets.net/l6vo76cztcax/6ilbQHqjbyDLWbO7NWvhNf/852d37d04ac93d1a0dbb7be8cc9b778d/posterDesktop.png',
    videoPlayerPosterMobile:
      'https://images.ctfassets.net/l6vo76cztcax/1Ejahnjfu2UMieW2q8s43u/60a4e9e9924770210e56334f265e93e1/posterMobile.png',
  },
  /**
   * Legal links
   */
  legalLinks: {
    imprint: `${process.env.NEXT_PUBLIC_MY_TONIES_URL}/legals/imprint`,
    privacyPolicy: `${process.env.NEXT_PUBLIC_MY_TONIES_URL}/legals/privacy-policy`,
    termsOfService: `${process.env.NEXT_PUBLIC_MY_TONIES_URL}/legals/terms-of-service`,
  },
  /**
   * Locale overwrites and additions
   * - `bazaarvoice` config for reviews & ratings
   * - `commonPageUrls` for sitemap alternates
   * - `customSitemapUrls`, e.g. for pages in /pages/{lc-cc}/
   * - `hrefLang` for meta and sitemap alternates
   * - `htmlLang` for meta
   * - `paymentMethodThresholds` for ecom checkout derived from {@link https://boxine.atlassian.net/wiki/spaces/TSH/pages/3124396054/Adyen+-+payment+test+credentials#Amount-Restrictions}
   */
  locales: {
    'de-DE': {
      bazaarvoice: {
        clientKey: 'tonies-dach',
        sharedEncodingKey: 'SpCaGpMdOriHzb0rDvDDIE8d4',
      },
      customSitemapUrls: {
        customBundler: '/de-de/bundler/',
      },
      helpCenterLocale: 'de',
      hrefLang: 'de',
      paymentMethodThresholds: [
        { type: 'klarna', minimum: '100' }, // pay later
        { type: 'klarna_account', minimum: '4500' }, // pay over time
        { type: 'klarna_paynow', minimum: '100' }, // pay now
      ],
    },
    'en-AU': {
      helpCenterLocale: 'en-au',
      hrefLang: 'en-au',
    },
    'en-CA': {
      commonPageUrls: {
        mainPage: 'https://us.tonies.com/pages/canada',
      },
      helpCenterLocale: 'en-ca',
      hrefLang: 'en-ca',
    },
    'en-EU': {
      customSitemapUrls: {
        customBundler: '/en-eu/bundler/',
      },
      helpCenterLocale: 'en-150',
      hrefLang: 'en',
      htmlLang: 'en',
      paymentMethodThresholds: [
        { type: 'klarna', minimum: '100' }, // pay later
        { type: 'klarna_account', minimum: '4500' }, // pay over time
        { type: 'klarna_paynow', minimum: '100' }, // pay now
      ],
    },
    'en-GB': {
      bazaarvoice: {
        clientKey: 'tonies-uk',
        sharedEncodingKey: 'OE811PY4o1fbjgvUXFqO9iesB',
      },
      customSitemapUrls: {
        customBundler: '/en-gb/bundler/',
      },
      helpCenterLocale: 'en-gb',
      hrefLang: 'en',
      hrefLangs: ['en-gb', 'en-ie'],
      paymentMethodThresholds: [
        { type: 'klarna', minimum: '100' }, // pay later
        { type: 'klarna_account', minimum: '3500' }, // pay over time
        { type: 'klarna_paynow', minimum: '100' }, // pay now
      ],
    },
    'en-HK': {
      helpCenterLocale: 'en-hk',
      hrefLang: 'en-hk',
    },
    'en-NZ': {
      helpCenterLocale: 'en-nz',
      hrefLang: 'en-nz',
    },
    'en-US': {
      commonPageUrls: {
        aboutUs: 'https://us.tonies.com/pages/about',
        accessoriesElectronicsPage:
          'https://us.tonies.com/collections/headphones',
        accessoriesHomeAndDekoPage: 'https://us.tonies.com/collections/shelves',
        accessoriesTransportPage:
          'https://us.tonies.com/collections/cases-bags',
        bundlesPage: 'https://us.tonies.com/pages/bundle',
        contactPage: 'https://us.tonies.com/pages/contact-us',
        creativeToniesPage: 'https://us.tonies.com/collections/creative-tonies',
        eWastePage: 'https://us.tonies.com/pages/device-recycling',
        howItWorksPage: 'https://us.tonies.com/pages/toniebox',
        mainPage: 'https://us.tonies.com',
        newsletterPage: 'https://us.tonies.com/pages/signup',
        press: 'https://us.tonies.com/pages/press',
        referralPage: 'https://us.tonies.com/pages/refer-a-friend',
        tonieboxPage:
          'https://us.tonies.com/products/toniebox-playtime-puppy-starter-set-red',
        toniesPage: 'https://us.tonies.com/collections/content',
      },
      customSitemapUrls: {
        customBundler: 'https://us.tonies.com/pages/bundle',
      },
      helpCenterLocale: 'en-us',
      hrefLang: 'en-us',
    },
    'fr-CA': {
      commonPageUrls: {
        mainPage: 'https://us.tonies.com/pages/canada',
      },
      helpCenterLocale: 'fr-ca',
      hrefLang: 'fr-ca',
    },
    'fr-FR': {
      bazaarvoice: {
        clientKey: 'tonies-fr',
        sharedEncodingKey: 'uhyuyowtWrtOlCsh4gLyfDKA0',
      },
      customSitemapUrls: {
        customBundler: '/fr-fr/packs-liberte/',
      },
      helpCenterLocale: 'fr-fr',
      hrefLang: 'fr',
      paymentMethodThresholds: [
        { type: 'klarna', minimum: '100' }, // pay later
        { type: 'klarna_account', minimum: '3500' }, // pay over time
        { type: 'klarna_paynow', minimum: '100' }, // pay now
      ],
    },
  },
}
