import { useLocale } from '.'
import { Locale } from '../../config/shopAPI/types'

export type AltNotation = 'CC' | 'lc' | 'lc_CC' | 'LC_CC' | 'lc-cc' | 'lc-CC'

/**
 * Converts the given Locale string to an alternative format.
 *
 * Apply with caution, since the result of this function is a `string` with any value
 * and hence less type-safe than the original `LocaleString`.
 */
export const toAltNotation = (lcCC: Locale, altNotation: AltNotation) => {
  const [lc, CC] = lcCC.split('-')

  switch (altNotation) {
    case 'CC':
      return CC
    case 'lc':
      return lc
    case 'lc_CC':
      return lc + '_' + CC
    case 'LC_CC':
      return lc.toUpperCase() + '_' + CC
    case 'lc-cc':
      return lc + '-' + CC.toLowerCase()
    case 'lc-CC':
      return lc + '-' + CC.toUpperCase()
  }
}

/**
 * Returns the current Locale string in an alternative format.
 *
 * Apply with caution, since the result of this function is a `string`
 * and hence less type-safe than the original `LocaleString`.
 */
export const useLocaleAltNotation = (altNotation: AltNotation) => {
  const lcCC = useLocale()
  return toAltNotation(lcCC, altNotation)
}
