import { EcomLocale, Currency } from '../../../config/shopAPI/types'
import { BxCart, BxOrder } from '../requests/bxTypes'
import { normalizeTranslation } from './normalizeTranslation'

export const normalizeShipping = (
  lcCC: EcomLocale,
  src?: BxCart | BxOrder
): NormalizedShipping => {
  let shippingFromDate: number | undefined

  if (src?.lineItems) {
    const now = new Date().getTime()

    src.lineItems.forEach(li => {
      const attr = li.variant?.attributes?.find(a => a?.name === 'shippingFrom')

      if (attr && typeof attr.value === 'string') {
        const liShippingFromDate = new Date(attr.value).getTime()

        if (
          liShippingFromDate > now &&
          (shippingFromDate === undefined ||
            shippingFromDate < liShippingFromDate)
        ) {
          shippingFromDate = liShippingFromDate
        }
      }
    })
  }

  return {
    freeThreshold: src?.shippingInfo?.shippingRate?.freeAbove
      ? {
          amount: src.shippingInfo.shippingRate.freeAbove.amount,
          centAmount: src.shippingInfo.shippingRate.freeAbove.centAmount,
          currency: src.shippingInfo?.shippingRate.freeAbove
            .currencyCode as Currency,
        }
      : undefined,
    fromDate: shippingFromDate,
    method: src?.shippingInfo?.shippingMethodName
      ? {
          id: src.shippingInfo.shippingMethodName,
          name: normalizeTranslation(
            src.shippingInfo.shippingMethod?.obj?.localizedName,
            lcCC
          ),
          description: normalizeTranslation(
            src.shippingInfo.shippingMethod?.obj?.localizedDescription,
            lcCC
          ),
        }
      : undefined,
    price: src?.bxShippingPrice && {
      amount: src.bxShippingPrice.amount,
      centAmount: src.bxShippingPrice.centAmount,
      currency: src.bxShippingPrice.currencyCode as Currency,
    },
  }
}
