/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios'
import * as Yup from 'yup'
import { ShopApiViolation } from '../../../utils'
import { isAxiosError } from '../../../utils/isAxiosError'
import { http } from '../../http.service'
import { EcomLocale } from '../../../config/shopAPI/types'
import { toAltNotation } from '../../../providers/locale/altNotation'

const baseURL = process.env.NEXT_PUBLIC_API_URL

export type NormalizedFetchSuccess<SuccessDataType> = {
  result: 'successful'
  data: SuccessDataType
}

export type NormalizedFetchRequestFailedError = {
  result: 'request-failed'
  data?: {
    type?: string
    title?: string
    detail?: string
    violations?: ShopApiViolation[]
  }
  error: Error
  httpStatus?: number
}

export type NormalizedFetchUnexpectedResponseFormatError = {
  result: 'unexpected-response-format'
  error: Error
  data: unknown
}

export type FetchResult<SuccessDataType extends Record<string, unknown>> =
  | NormalizedFetchSuccess<SuccessDataType>
  | NormalizedFetchRequestFailedError
  | NormalizedFetchUnexpectedResponseFormatError

export const isRequestSucceeded = <T>(
  error: any
): error is NormalizedFetchSuccess<T> =>
  (error as NormalizedFetchSuccess<T>)?.result === 'successful'

export const isRequestFailed = (
  error: any
): error is NormalizedFetchRequestFailedError =>
  (error as NormalizedFetchRequestFailedError)?.result === 'request-failed'

export const isUnexpectedResponseFormat = (
  error: any
): error is NormalizedFetchUnexpectedResponseFormatError =>
  (error as NormalizedFetchUnexpectedResponseFormatError)?.result ===
  'unexpected-response-format'

/**
 * Our standard implementation for Shop API calls taken from `lib/commercetools/util/fetch.ts`
 *
 * - it takes Typescript types for the request and response
 *   payloads to improve type safety at build time.
 * - it takes an optional Yup schema for the response type to
 *   validate the response and improve type safety at runtime.
 *
 * The return value has a `result` field which indicates if the
 * operation succeeded. Depending on the `result`, additional
 * parameters may be present. In case of a successful request,
 * the response payload is in the type-safe field `data` of the
 * return value.
 *
 * Convenience hint: this function does NOT raise or pass-through
 * exceptions; callers need not implement try/catch clauses.
 */
export const fetch = async <SuccessDataType extends Record<string, unknown>>({
  query,
  lcCC,
  accessToken,
  method = 'get',
  responseSchema,
  request,
}: {
  query: string
  lcCC?: EcomLocale
  accessToken?: string
  request?: Record<string, unknown>
  method?: 'get' | 'post' | 'put' | 'delete'
  responseSchema?: Yup.Schema<SuccessDataType>
  // eslint-disable-next-line sonarjs/cognitive-complexity
}): Promise<FetchResult<SuccessDataType>> => {
  let response: AxiosResponse<SuccessDataType> | undefined

  const params: Record<string, unknown> = method === 'get' ? { ...request } : {}

  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'local') {
    params.XDEBUG_SESSION = 'yes' // Backend flag, see TWAS-3190
  }
  if (lcCC) {
    const locale = toAltNotation(lcCC, 'lc_CC')
    params.shopLocale = locale
  }
  if (accessToken) {
    http.defaults.headers.Authorization = `Bearer ${accessToken}`
  }
  try {
    response = await axios.request<SuccessDataType>({
      headers: {
        ...http.defaults.headers,
        Accept: 'application/json',
        'Accept-Language': lcCC || 'en-GB',
        'Content-Type': 'application/json',
      },
      method,
      params,
      baseURL,
      url: query,
      data: method === 'get' ? undefined : { ...request },
    })
  } catch (error) {
    if (isAxiosError(error)) {
      const { data, status } = error.response || {}
      const { type, title, detail, violations } = data || {}

      return {
        result: 'request-failed',
        data: {
          type: typeof type === 'string' ? type : undefined,
          title: typeof title === 'string' ? title : undefined,
          detail: typeof detail === 'string' ? detail : undefined,
          violations: Array.isArray(violations) ? violations : undefined,
        },
        error,
        httpStatus: typeof status === 'number' ? status : undefined,
      }
    } else {
      return {
        result: 'request-failed',
        error:
          error instanceof Error ? error : new Error('fetch failed: ' + error),
      }
    }
  }

  if (responseSchema) {
    try {
      responseSchema.validateSync(response.data)
    } catch (error) {
      return {
        result: 'unexpected-response-format',
        data: response.data,
        error:
          error instanceof Error ? error : new Error('fetch failed: ' + error),
      }
    }
  }

  return {
    result: 'successful',
    data: response.data,
  }
}
