import React, { FunctionComponent } from 'react'
import { Accent as AccentOrigin } from '@boxine/tonies-ui'
import { DefaultTheme, useTheme } from 'styled-components'

export const Accent: FunctionComponent<{
  text: string
  color?: keyof Pick<DefaultTheme['colors'], 'primary' | 'white'>
}> = ({ text, color: colorProp }) => {
  const { colorContext } = useTheme()
  const color = colorProp || (colorContext === 'dark' ? 'white' : 'primary')

  return (
    <>
      {text
        .replace(/<em>([^<]*)<\/em>/gi, '[$1]') // will prevent us from wrong markup
        .split(/\[|\]/)
        .map((text, index) => {
          const hasAccent = index % 2 === 1 // wrap every second in Accent
          const key = `accent-${index}`
          return hasAccent ? (
            <AccentOrigin data-testid="accent-em" key={key} color={color}>
              {text}
            </AccentOrigin>
          ) : (
            text && (
              <span
                key={key}
                data-testid="accent-innerHTML"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            )
          )
        })}
    </>
  )
}
