import { EcomLocale } from '../../../config/shopAPI/types'
import { generateKey } from '../../../utils'
import { BxShippingMethod } from '../requests/bxTypes'
import { normalizeTranslation } from './normalizeTranslation'

export type NormalizedShippingMethod = {
  id: string
  key: string
  name: string
  description: string
  free: boolean
  price: {
    amount: number
  }
}

export type NormalizedShippingMethods = NormalizedShippingMethod[]

export const normalizeShippingMethods = (
  shippingMethods: BxShippingMethod[],
  totalPriceWithoutShippingCentAmount: number | undefined,
  lcCC: EcomLocale
): NormalizedShippingMethods => {
  return shippingMethods.map(
    ({
      id = 'id',
      key,
      name,
      localizedName,
      description,
      localizedDescription,
      zoneRates,
    }) => ({
      id,
      key: key ?? generateKey('shipping-'),
      name:
        (localizedName && normalizeTranslation(localizedName, lcCC)) || name,
      description:
        ((localizedDescription &&
          normalizeTranslation(localizedDescription, lcCC)) ||
          description) ??
        '',
      free:
        // Shipping is free if the original total price is higher then the freeAbove amount
        //  if freeAbove is null, free shipping is false
        totalPriceWithoutShippingCentAmount &&
        zoneRates[0].shippingRates[0].freeAbove
          ? zoneRates[0].shippingRates[0].freeAbove.centAmount <
            totalPriceWithoutShippingCentAmount
          : false,
      price: {
        amount: zoneRates[0].shippingRates[0].price.amount,
      },
    })
  )
}
