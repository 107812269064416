import React, { FunctionComponent, PropsWithChildren, useCallback } from 'react'
import { GtmV4ContextProps, GtmV4Event } from './types'
import { mapToDataLayerEvent } from './mapToDataLayerEvent'
import { GtmV4Context } from '.'

export const NextGtmV4Provider: FunctionComponent<
  Partial<Omit<GtmV4ContextProps, 'pushGtmV4Event'>> & PropsWithChildren
> = ({ children, componentId, componentName }) => {
  const pushDataLayer = (dataLayer: object) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(dataLayer)
  }

  const pushGtmV4Event = useCallback(
    (params: GtmV4Event) => {
      // Temp disabled events
      if (params.eventType === 'viewItemList') {
        return
      }

      // Call `mapToDataLayerEvent()` to apply transformations from FE data structures to
      // the notation that business has requested
      const dataLayerEvent = mapToDataLayerEvent(params)

      // "It's recommended that you use the following command to clear the ecommerce object prior to
      // pushing an ecommerce event to the data layer. Clearing the object will prevent multiple ecommerce
      // events on a page from affecting each other."
      // See https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#clearing_the_ecommerce_object
      if ('ecommerce' in dataLayerEvent) {
        pushDataLayer({
          ecommerce: null,
        })
      }

      // Push transformed data to the GtmV4
      pushDataLayer({
        ...dataLayerEvent,
        component_name: componentName,
        component_id: componentId,
      })
    },
    [componentId, componentName]
  )

  return (
    <GtmV4Context.Provider
      value={{
        componentId,
        componentName,
        pushGtmV4Event,
        pushDataLayer,
      }}
    >
      {children}
    </GtmV4Context.Provider>
  )
}
