import { GtmV4Event, GtmV4GenerateLeadEvent } from '../../providers/gtmV4/types'
import { Locale } from '../../config/shopAPI/types'
import { Card } from '@braze/web-sdk'

export const brazeBaseUrl = 'sdk.fra-01.braze.eu'
export const brazeApiKey = process.env.NEXT_PUBLIC_BRAZE_API_KEY

/**
 * dynamically import braze SDK elements as suggested here: {@link https://www.braze.com/docs/developer_guide/platform_integration_guides/web/initial_sdk_setup/#alternative-integration-methods}
 */

/**
 * @param uuid - current user keycloak uuid
 */
export const changeBrazeUser = (uuid: string | undefined) => {
  import(
    /* webpackExports: ["changeUser"] */
    './exports'
  ).then(({ changeUser }) => {
    if (uuid) {
      changeUser(uuid)
    }
  })
}

// export const getCachedContentCards = (): Card[] => {
//   import(
//     /* webpackExports: ["getCachedContentCards"] */
//     './exports'
//   ).then(({ getCachedContentCards }) => {
//     getCachedContentCards().cards
//   })
// }

export const requestContentCardsRefresh = () => {
  import(
    /* webpackExports: ["requestContentCardsRefresh"] */
    './exports'
  ).then(({ requestContentCardsRefresh }) => {
    requestContentCardsRefresh()
  })
}

export const logContentCardImpressions = (contentCards: Card[]) => {
  import(
    /* webpackExports: ["logContentCardImpressions"] */
    './exports'
  ).then(({ logContentCardImpressions }) => {
    logContentCardImpressions(contentCards)
  })
}

export const logContentCardClick = (contentCard: Card) => {
  import(
    /* webpackExports: ["logContentCardClick"] */
    './exports'
  ).then(({ logContentCardClick }) => {
    logContentCardClick(contentCard)
  })
}

export const submit = ({
  aiStoryRequests = [],
  giftFinderResults = [],
  children = [],
  country,
  customUserAttributes,
  email,
  eventMessage = '',
  eventName,
  firstName,
  gtmV4,
  lastName,
  lcCC,
  optinEventName = 'newsletter_registration',
  phone,
}: {
  aiStoryRequests?: {
    name: string
    age: string
    tonie1: string
    tonie2: string
    tonie3: string
  }[]
  children?: {
    name: string
    day: string
    month: string
    year: string
  }[]
  country?: string | null
  customUserAttributes?: {
    key: string
    value: boolean | string | number
  }[]
  email: string
  eventMessage?: string
  eventName?: string
  firstName?: string
  giftFinderResults?: string[]
  gtmV4?: {
    formLocation: string
    leadType: GtmV4GenerateLeadEvent['leadType']
    pushGtmV4Event: (e: GtmV4Event) => void
  }
  lastName?: string
  lcCC: Locale
  optinEventName?: string
  phone?: string
}) => {
  // check if braze is available
  if (typeof window === 'undefined' || !window.__BRAZE__) {
    return
  }

  const braze = window.__BRAZE__
  const brazeUser = braze.getUser()

  brazeUser?.addAlias(email, 'user_email')
  brazeUser?.setEmail(email)

  if (phone) {
    brazeUser?.setPhoneNumber(phone)
  }

  if (firstName) {
    brazeUser?.setFirstName(firstName)
  }

  if (lastName) {
    brazeUser?.setLastName(lastName)
  }

  if (country) {
    brazeUser?.setCountry(country)
  }

  if (aiStoryRequests.length > 0) {
    aiStoryRequests.forEach(r => {
      braze.logCustomEvent('ai_story_requested', {
        name: r.name,
        age: r.age,
        preferred_tonie_names: [r.tonie1, r.tonie2, r.tonie3].filter(Boolean),
        shop_locale: lcCC,
      })
    })
  }

  if (giftFinderResults.length > 0) {
    braze.logCustomEvent('gift_finder_submission')
    brazeUser?.setCustomUserAttribute('gift_finder_results', giftFinderResults)
  }

  if (children.length > 0) {
    brazeUser?.setCustomUserAttribute('children', {
      $add: children.map(c => ({
        name: c.name,
        dob: { $time: `${c.year}-${c.month}-${c.day}` },
      })),
    })
  }

  customUserAttributes?.forEach(a => {
    brazeUser?.setCustomUserAttribute(a.key, a.value)
  })

  braze.logCustomEvent(optinEventName, {
    shop_locale: lcCC,
  })

  if (eventName) {
    braze.logCustomEvent(eventName, {
      message: eventMessage,
      shop_locale: lcCC,
    })
  }

  if (gtmV4) {
    gtmV4.pushGtmV4Event({
      eventType: 'generateLead',
      leadType: gtmV4.leadType,
      formLocation: gtmV4.formLocation,
      email: email,
    })
  }
}
