import { createContext, useContext } from 'react'
import { AggregatedShopLocale } from '../../lib/transformation/aggregateShopLocale/types'

export const defaultProps: AggregatedShopLocale = {
  lcCC: 'en-GB',
  commonPages: {
    mainPage: {
      fieldName: 'mainPage',
      lastModified: 0,
      slug: 'mainpage',
      title: '',
    },
  },
  config: {
    primaryNavigationList: [],
  },
  content: {
    cartDeliveryInfo: '',
    cartDeliveryInfoTeaserList: [],
    deliveryInfoTeaserList: [],
    footer: {
      contentTypeId: 'footer',
      legalLinksAdditionalEntries: [],
      linksList1Entries: [],
      linksList1Title: '',
      linksList2Entries: [],
      linksList2Title: '',
      referenceTitle: '',
      socialMediaLinksEntries: [],
    },
    urlModularContentSections: [],
    accountSidebarLinkTeaserList: [],
  },
  localeSpecificPages: [],
  localeSpecificBlogPosts: [],
  warnings: [],
}

export const aggregatedShopLocaleContext =
  createContext<AggregatedShopLocale>(defaultProps)

export const useAggregatedShopLocale = () =>
  useContext(aggregatedShopLocaleContext)

export const AggregatedShopLocaleProvider = aggregatedShopLocaleContext.Provider
