import { css } from 'styled-components'

export const ROOT_BASE = 16

/**
 * For the specified media query, returns a tag function that can be used to
 * automatically wrap the tagged template literal in its media query.
 *
 * https://github.com/morajabi/styled-media-query
 * @param {string} query The string or template literal containing the media
 *   query features.
 */
export const mediaQuery = (query: string) => (rules: TemplateStringsArray) =>
  css`
    @media ${query} {
      ${css(rules)};
    }
  `

/**
 * Converts `px` values to `rem` according a given baseπ
 *
 * @param {number} px
 * @param {number} base default is 16
 */
export const rem = (px: number, base: number = ROOT_BASE) => {
  return `${px / base}rem`
}
