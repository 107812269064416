import React, { ReactElement } from 'react'
import {
  toast as toastify,
  ToastContainer as ToastifyContainer,
  ToastOptions,
} from 'react-toastify'
import * as icons from '@boxine/tonies-ui/icons'
import { Icon } from '@boxine/tonies-ui'
import { theme } from '@/tonies-ui/themes/theme'
import { Fade, StyledContainer } from './styles'

type Severity = 'info' | 'success' | 'error'

const toastFunctions: Record<Severity, typeof toastify.success> = {
  info: toastify.info,
  success: toastify.success,
  error: toastify.error,
}

const autoCloseTimeouts: Record<Severity, number> = {
  info: 5000,
  success: 5000,
  error: 5000,
}

const closeButton: Record<Severity, ReactElement> = {
  info: (
    /** Icon breaks without this surrounding fragment */
    <>
      <Icon
        className="close-icon"
        type={icons.exclamationMark}
        fill={theme.colors.white}
        title={null}
      />
    </>
  ),
  success: (
    /** Icon breaks without this surrounding fragment */
    <>
      <Icon
        className="close-icon"
        type={icons.checkmark}
        fill={theme.colors.white}
        title={null}
      />
    </>
  ),
  error: (
    /** Icon breaks without this surrounding fragment */
    <>
      <Icon
        className="close-icon"
        type={icons.exclamationMark}
        fill={theme.colors.white}
        title={null}
      />
    </>
  ),
}

export const ToastContainer = () => (
  <StyledContainer>
    <ToastifyContainer
      closeOnClick
      draggable={false}
      hideProgressBar
      newestOnTop={false}
      pauseOnHover={true}
      position="bottom-center"
      transition={Fade}
      icon={false}
    />
  </StyledContainer>
)

export const toast = (
  message: string,
  severity: Severity = 'success',
  options: ToastOptions = {}
) =>
  toastFunctions[severity](message, {
    autoClose: autoCloseTimeouts[severity],
    closeButton: closeButton[severity],
    ...options,
  })

export { toastify }
