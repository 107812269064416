import React, { ComponentProps } from 'react'
import { Button, CTARow } from '@boxine/tonies-ui'
import { LoginTypes, useAuth } from '../../../providers/auth'
import { Modal } from '@/tonies-ui/molecules/Modal'
import { useTranslation } from 'react-i18next'

export const LoginModal: NormalizedFunctionComponent<
  Omit<ComponentProps<typeof Modal>, 'isOpen' | 'footer'> &
    Pick<LoginTypes, 'redirectUri'>
> = ({ redirectUri, ...modalProps }) => {
  const { login, register } = useAuth()
  const { t } = useTranslation()

  return (
    <Modal
      {...modalProps}
      data-testid="login-modal"
      isOpen
      footer={
        <CTARow justifyContent="center" flexDirection="row-reverse">
          <Button
            onClick={() => {
              login({ redirectUri })
            }}
          >
            {t('checkout:login:loginButtonText')}
          </Button>
          <Button variant="secondary" onClick={() => register({ redirectUri })}>
            {t('checkout:login:registerButtonText')}
          </Button>
        </CTARow>
      }
    />
  )
}
