import { createHash } from 'crypto'

// Generate a unique key with a prefix
function generateKey(prefix = 'key'): string {
  const prefixedSalt = `${prefix}${generateSalt(true)}`
  return `${prefix}${generateHash(prefixedSalt)}`
}

// Generate a unique, HTML compatible id starting with a letter
//  See: https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/id
function generateId(letters = 'id'): string {
  if (!letters.match(/^[a-z,A-Z]+/g)) {
    if (process.env.NODE_ENV === 'development') {
      console.warn(
        `generateId must start with a letter [a-z,A-Z] and not ${letters}`
      )
    }
    letters = 'id'
  }
  const letteredSalt = `${letters}${generateSalt()}`
  return `${letters}${generateHash(letteredSalt)}`
}

// generate unique a numeric salt
function generateSalt(big = false) {
  const fallback = generateFallback()
  return typeof window !== 'undefined'
    ? generateRandomNumber(big, fallback)
    : fallback
}

function generateHash(str: string) {
  return str
    .split('')
    .reduce(
      (prevHash, currVal) =>
        ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
      0
    )
}

function generateFallback() {
  return Math.floor(Math.random() * Date.now())
}

export const RANDOM_NUMBER_SIZE = 10000
export const RANDOM_NUMBER_SIZE_BIG = 14000
// generate a unique number
function generateRandomNumber(big = false, fallback = generateFallback()) {
  const generate = () => {
    try {
      return big
        ? window?.crypto?.getRandomValues(new BigUint64Array(1))[0] ??
            Math.floor(Math.pow(fallback * 0.01, 2))
        : window?.crypto?.getRandomValues(new Uint32Array(1))[0] ?? fallback
    } catch (_error) {
      return big ? Math.floor(Math.pow(fallback * 0.01, 2)) : fallback
    }
  }
  const number = generate()
  const size = big ? RANDOM_NUMBER_SIZE_BIG : RANDOM_NUMBER_SIZE
  return number >= size ? number : generate()
}

function generateSha256(input: string) {
  return createHash('sha256').update(input).digest('hex')
}

export {
  generateSalt,
  generateKey,
  generateId,
  generateRandomNumber,
  generateSha256,
}
