import { extractLocaleFromPath } from '../providers/locale/NextLocaleProvider'
//
// This file is derived from https://gitlab.boxine.de/tonies/my-tonies/-/blob/master/src/utils/sentry.ts
// and adjusted for the Shop / next.js environment
//

import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'
import { AuthContextInterface } from '../providers/auth'
import { removeEmpty } from '../utils'
import { useToniesCookies } from './useToniesCookies'

/**
 * Send Errors with extras to sentry
 * @param feature The text of the feature tag shown in the sentry error pages.
 * @param error {Error} The trace of the error shown in sentry error pages.
 *  Note: To get a expressive trace, the error chain must start early.
 *  See: https://boxine.atlassian.net/wiki/spaces/TSH/pages/3536257268/Sentry+Error+chaining
 * @param extras {unknown} The extra information shown in sentry error pages.
 */
export function sentryWithExtras(
  feature: string,
  error: Error,
  extras?: unknown
) {
  Sentry.withScope(scope => {
    try {
      const lcCC = extractLocaleFromPath(window.location.pathname)
      scope.setTag('locale', lcCC)

      const { cartId } = useToniesCookies()
      scope.setTag('cart', cartId)
    } catch (err) {
      // ignore
    }

    scope.setTag('feature', feature)

    setExtrasFromError(scope, error)

    if (extras) {
      setExtrasFromExtras(scope, extras)
    }

    Sentry.captureException(error)
  })
}

export function setExtrasFromError(sentry: Sentry.Scope, error: Error) {
  if (error && typeof error === 'object') {
    const { name, message, stack } = error
    sentry.setExtra('errorName', name)
    sentry.setExtra('errorMessage', message)
    if (stack) sentry.setExtra('errorStack', stack)
  }
}

export function setExtrasFromExtras(sentry: Sentry.Scope, extras: unknown) {
  try {
    const normalizedExtras = removeEmpty(
      typeof extras === 'object' && extras != null ? extras : { extras }
    )
    const entries = Object.entries(normalizedExtras)
    entries.forEach(([key, value]) => {
      if (value) {
        sentry.setExtra(key, value)
      }
    })
  } catch (error) {
    if (process.env.NODE_ENV === 'development') console.warn(error)
  }
}

type SentryUser = {
  id: string
  externalId: string
  customerNumber: string
  cartId: string
}

export function useCurrentUserInSentry(
  user: AuthContextInterface['user'] | null
) {
  const { cartId } = useToniesCookies()

  useEffect(() => {
    let sentryUser: SentryUser | null = null

    if (user) {
      sentryUser = {
        id: user.id ?? user.externalId ?? user.customerNumber ?? '',
        externalId: user.externalId ?? '',
        customerNumber: user.customerNumber ?? '',
        cartId: cartId ?? '',
      }
    } else {
      sentryUser = {
        id: 'anonymous',
        externalId: 'anonymous',
        customerNumber: 'anonymous',
        cartId: cartId ?? '',
      }
    }

    Sentry.configureScope(scope => {
      scope.setUser(sentryUser)
    })
    Sentry.withScope(scope => {
      scope.setTag('cart', cartId)
    })
  }, [cartId, user])
}
