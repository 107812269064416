import { useIsViewportMobile } from '@boxine/tonies-ui'
import { useMeasurements } from '../../providers/measurements'
import React, {
  ComponentProps,
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useGtmV4 } from '../../providers/gtmV4'
import { AnimatePresence } from 'framer-motion'
import { SidebarOverlay } from '../../components/organisms/SidebarOverlay'

type SidebarOverlayType = React.ReactElement<
  ComponentProps<typeof SidebarOverlay>
>
type SidebarOverlayProviderProps = {
  setSidebarOverlay: (sidebarOverlay: SidebarOverlayType | undefined) => void
  checkIsOverlayOpen: (id: string) => boolean
  closeOverlay: () => void
}

const SidebarOverlayContext = React.createContext<SidebarOverlayProviderProps>({
  setSidebarOverlay: () => null,
  checkIsOverlayOpen: () => false,
  closeOverlay: () => null,
})

export const SidebarOverlayProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const isMobile = useIsViewportMobile()
  const { headersHeight } = useMeasurements()
  const [sidebarOverlay, setSidebarOverlay] = useState<
    SidebarOverlayType | undefined
  >()

  // default offsetTop of the SidebarOverlay
  const defaultOffset = useMemo(
    () => (isMobile ? 0 : headersHeight),
    [headersHeight, isMobile]
  )

  const OverlayWithHeaderOffset = useMemo(() => {
    // overwrite top prop with defaultOffset if top is not set
    return sidebarOverlay && !sidebarOverlay.props.top
      ? {
          ...sidebarOverlay,
          props: {
            ...sidebarOverlay.props,
            top: defaultOffset,
          },
        }
      : sidebarOverlay
  }, [sidebarOverlay, defaultOffset])

  // id, which is currently open
  const currentId = useMemo(
    () => OverlayWithHeaderOffset?.props.id,
    [OverlayWithHeaderOffset?.props.id]
  )

  const checkIsOverlayOpen = useCallback(
    (id: string) => (currentId ? currentId === id : false),
    [currentId]
  )

  return (
    <SidebarOverlayContext.Provider
      value={{
        setSidebarOverlay,
        checkIsOverlayOpen,
        closeOverlay: () => setSidebarOverlay(undefined),
      }}
    >
      {children}

      <AnimatePresence>{OverlayWithHeaderOffset}</AnimatePresence>
    </SidebarOverlayContext.Provider>
  )
}

export const useSidebarOverlayProvider = () => useContext(SidebarOverlayContext)

export const useSidebarOverlay = (Overlay: SidebarOverlayType) => {
  const { checkIsOverlayOpen, closeOverlay, setSidebarOverlay } =
    useSidebarOverlayProvider()
  const { pushGtmV4Event } = useGtmV4()

  const isOpen = useMemo(
    () => checkIsOverlayOpen(Overlay.props.id),
    [Overlay, checkIsOverlayOpen]
  )

  const setIsOpen = useCallback(
    (value: boolean) => {
      if (value) {
        if (!isOpen) {
          pushGtmV4Event({
            eventType: 'click',
            label: `[coded] SidebarOverlay ${Overlay.props.id}`,
            action: 'sidebarOverlay_show',
            type: 'onClick',
          })
          setSidebarOverlay(Overlay)
        }
      } else {
        closeOverlay()
      }
    },
    [Overlay, closeOverlay, isOpen, pushGtmV4Event, setSidebarOverlay]
  )

  return [isOpen, setIsOpen] as const
}
