import React, { useEffect, useState } from 'react'
import {
  defaultFeatureFlags,
  FeatureFlags,
  getFeatureFlags,
} from '../../lib/configcat/FeatureFlags'
import { FeatureFlagContext } from './FeatureFlagContext'
import { useAuth } from '../auth'
import { useGtmV4 } from '../gtmV4'
import { useLocale } from '../locale'

export const FeatureFlagContextProvider: NormalizedFunctionComponent<{
  featureFlags?: Partial<FeatureFlags>
}> = ({ featureFlags: featureFlagsProp, children }) => {
  const [featureFlags, setFeatureFlags] = useState({
    ...defaultFeatureFlags,
    ...featureFlagsProp,
  })
  const lcCC = useLocale()
  const { isPending: isAuthPending, uuid, user } = useAuth()
  const { pushGtmV4Event } = useGtmV4()
  const [isPending, setIsPending] = useState(true)

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      typeof featureFlagsProp === 'undefined' &&
      !isAuthPending
    ) {
      getFeatureFlags({
        uuid,
        email: user?.email,
        lcCC,
      })
        .then(ff => {
          setFeatureFlags(ff)

          // push FF to GTM
          Object.entries(ff).map(([key, value]) => {
            return pushGtmV4Event({
              eventType: 'experimentImpression',
              key,
              value,
            })
          })
        })
        .finally(() => setIsPending(false))
    }
  }, [featureFlagsProp, lcCC, user, uuid, isAuthPending, pushGtmV4Event])

  return (
    <FeatureFlagContext.Provider
      value={{ ...featureFlags, isPending: isPending || isAuthPending }}
    >
      {children}
    </FeatureFlagContext.Provider>
  )
}
