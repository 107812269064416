import { useCallback, useEffect, useMemo, useState } from 'react'

export type UseQueueParameters = {
  /**
   * Whether the queue should continue processing after an action has failed.
   * Default: `false`
   */
  continueOnError?: boolean
}

type Entry<F extends () => Promise<R>, R> = {
  action: F
  resolve: (result: R) => void
  reject: (error?: unknown) => void
}

/**
 * `useQueue()` defers and serializes the execution of asynchronous function calls.
 *
 * `useQueue().queue(fn)` takes a function `fn` and adds it to the internal queue.
 * Once the internal processor is ready to execute a new task, it pops the next
 * element from the queue and runs that function. The entries are worked off in
 * FIFO order, one entry at a time.
 *
 * The entire hook is generic with regard to the the return type of the functions that
 * it accepts and queues. This allows us to return promises of the same type from
 * `useQueue().queue(fn)`.
 *
 * These additional promises, created by the `queue()` function, represent the result
 * of the future invocations of the queued functions, but returned immediately and
 * hence ready to use before the function is actually executed. This allows callers
 * to coveniently chain additional operations with `.then()`, `.catch()`, or
 * `.finally()` to the future outcome of the function execution.
 *
 * The `useQueue().isProcessing` flag indicates if the hook is currently
 * executing a function or idle.
 */
export const useQueue = <F extends () => Promise<R>, R>(
  options?: UseQueueParameters
) => {
  const continueOnError = options?.continueOnError || false

  const [data, setData] = useState({
    entries: [] as Entry<F, R>[],
    error: undefined as Error | undefined,
    state: 'idle' as 'idle' | 'processing' | 'failed',
  })

  const push = useCallback(
    (action: F) =>
      new Promise<R>((resolve, reject) => {
        setData(prev => ({
          entries: [
            ...prev.entries,
            {
              action,
              resolve,
              reject,
            },
          ],
          error: prev.error,
          state: prev.state,
        }))
      }),
    []
  )

  useEffect(() => {
    setData(prev => {
      if (prev.state !== 'idle' || prev.entries.length === 0) {
        // can't work off entries now
        return prev
      }

      const [entry, ...remainingEntries] = prev.entries
      const { action, resolve, reject } = entry

      action()
        .then(result => {
          setData(prev => ({
            entries: prev.entries,
            error: undefined,
            state: 'idle',
          }))
          resolve(result)
        })
        .catch(e => {
          const error = e instanceof Error ? e : new Error(e)
          setData(prev => ({
            entries: prev.entries,
            error,
            state: continueOnError ? 'idle' : 'failed',
          }))
          reject(e)
        })

      return {
        entries: remainingEntries,
        error: prev.error,
        state: 'processing',
      }
    })
  }, [data.state, data.entries, continueOnError])

  return useMemo(
    () => ({
      push,
      state: data.state,
      error: data.error,
    }),
    [data.error, data.state, push]
  )
}
