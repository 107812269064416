import { useIsViewportDesktop, useIsViewportTablet } from '@boxine/tonies-ui'

export const useViewport = () => {
  const isTablet = useIsViewportTablet()
  const isDesktop = useIsViewportDesktop()

  return isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile'
}

export type ViewportConfigType<ViewportValue> = {
  mobile: ViewportValue
  tablet?: ViewportValue
  desktop?: ViewportValue
}

export const useViewportConfig = <ViewportValue>(
  props: ViewportConfigType<ViewportValue>,
  defaultProps?: ViewportValue
) => {
  const viewport = useViewport()
  const getConfig = props[viewport]

  return getConfig || defaultProps || props['mobile']
}
