import { BxLocaleConfig } from '../lib/shopApi/requests/bxTypes'
import {
  GetLocaleConfigProps,
  getLocaleConfig,
} from '../lib/shopApi/requests/config/getLocaleConfig'
import { useLocale } from '../providers/locale'
import { useEffect, useState } from 'react'

export const useShopLocaleConfig = (
  params?: Omit<GetLocaleConfigProps, 'lcCC'>
) => {
  const lcCC = useLocale()
  const [config, setConfig] = useState<BxLocaleConfig | undefined>(undefined)

  useEffect(() => {
    if (config?.lcCC !== lcCC) {
      getLocaleConfig({ lcCC, ...params }).then(setConfig)
    }
  }, [config, lcCC, params])

  return config
}
