import axios, { AxiosResponse } from 'axios'
import * as Yup from 'yup'
import { EcomLocale } from '../../../config/shopAPI/types'
import { ShopApiViolation } from '../../../utils'
import { isAxiosError } from '../../../utils/isAxiosError'
import { http } from '../../http.service'
import { GraphQLRequest } from '@commercetools/platform-sdk'

export type FetchResult<SuccessDataType extends Record<string, unknown>> =
  | {
      result: 'successful'
      data: SuccessDataType
    }
  | {
      result: 'request-failed'
      data?: {
        type?: string
        title?: string
        detail?: string
        violations?: ShopApiViolation[]
      }
      error: Error
      httpStatus?: number
    }
  | {
      result: 'unexpected-response-format'
      error: Error
      data: unknown
    }

interface BaseFetchProps {
  query: string
  lcCC: EcomLocale
}
export const fetch = async <
  SuccessDataType extends Record<string, unknown>,
  RequestType extends Record<string, unknown> | GraphQLRequest = GraphQLRequest
>({
  query,
  lcCC,
  method = 'get',
  request,
  responseSchema,
}:
  | (BaseFetchProps & {
      responseSchema?: Yup.Schema<SuccessDataType>
      method: 'post' | 'put'
      request: RequestType
    })
  | (BaseFetchProps & {
      responseSchema?: Yup.Schema<SuccessDataType>
      method?: 'get'
      request?: never
    })): Promise<
  FetchResult<SuccessDataType>
  // eslint-disable-next-line sonarjs/cognitive-complexity
> => {
  let response: AxiosResponse<SuccessDataType> | undefined

  try {
    response = await axios.request<SuccessDataType>({
      headers: {
        ...http.defaults.headers,
        Accept: 'application/json',
        'Accept-Language': lcCC,
        'Content-Type': 'application/json',
      },
      data: method === 'get' ? undefined : JSON.stringify(request),
      method,
      baseURL: process.env.NEXT_PUBLIC_CLOUDSERVICES_API_URL,
      url: query,
    })
  } catch (error) {
    if (isAxiosError(error)) {
      const { data, status } = error.response || {}
      const { type, title, detail, violations } = data || {}

      return {
        result: 'request-failed',
        data: {
          type: typeof type === 'string' ? type : undefined,
          title: typeof title === 'string' ? title : undefined,
          detail: typeof detail === 'string' ? detail : undefined,
          violations: Array.isArray(violations) ? violations : undefined,
        },
        error,
        httpStatus: typeof status === 'number' ? status : undefined,
      }
    } else {
      return {
        result: 'request-failed',
        error:
          error instanceof Error ? error : new Error('fetch failed: ' + error),
      }
    }
  }

  if (responseSchema) {
    try {
      responseSchema.validateSync(response.data)
    } catch (error) {
      return {
        result: 'unexpected-response-format',
        data: response.data,
        error:
          error instanceof Error ? error : new Error('fetch failed: ' + error),
      }
    }
  }

  return {
    result: 'successful',
    data: response.data,
  }
}
