import { BxConfig, BxEcomLocaleConfig, BxLocaleConfig } from '../bxTypes'
import { Locale } from '../../../../config/shopAPI/types'
import config from '../../../../public/global-config.json'
import { getGlobalConfig } from './getGlobalConfig'

export const getStaticLocaleConfig = (
  lcCC: Locale
): BxLocaleConfig | BxEcomLocaleConfig => {
  const staticLocaleConfig = (config as BxConfig).localeConfigs.find(
    config => config.lcCC === lcCC
  )

  return staticLocaleConfig as BxLocaleConfig | BxEcomLocaleConfig
}

export type GetLocaleConfigProps = {
  lcCC: Locale
  live?: boolean
}
export const getLocaleConfig = async ({
  lcCC,
  live,
}: GetLocaleConfigProps): Promise<BxLocaleConfig | BxEcomLocaleConfig> => {
  if (live) {
    try {
      const globalConfig = await getGlobalConfig()
      const localeConfig =
        globalConfig.result === 'successful' &&
        globalConfig.data.localeConfigs.find(config => config.lcCC === lcCC)

      if (localeConfig) {
        return localeConfig
      } else {
        throw Error(`locale ${lcCC} can not be found in api response`)
      }
    } catch (error) {
      console.error('localeConfig could not be extracted', error)
    }
  }
  // fallback to static localeConfig
  return getStaticLocaleConfig(lcCC)
}
