import { isAxiosError } from '../../../utils/isAxiosError'
import { CartAction } from '..'
import { normalizeCart } from '../../../lib/commercetools/normalizers/normalizeCart'
import { createPaymentForCart } from '../../../lib/commercetools/requests/carts/createPaymentForCart'
import { PaymentType } from '../../payment/types'
import { GeoIpRegion } from '@/tonies-ui/hooks/useGeoIp'

type CreatePaymentForCartActionProps = {
  blockedPaymentMethods: PaymentType[]
  geoIpRegion: GeoIpRegion
}

export const createPaymentForCartAction =
  ({
    blockedPaymentMethods,
    geoIpRegion,
  }: CreatePaymentForCartActionProps): CartAction<void> =>
  async ({ fetchOrCreateCart, lcCC, replaceCart, logError }) => {
    const cart = await fetchOrCreateCart()
    const createPaymentForCartResponse = await createPaymentForCart(
      lcCC,
      cart.id,
      blockedPaymentMethods,
      geoIpRegion
    )

    if (createPaymentForCartResponse.result !== 'successful') {
      const error = createPaymentForCartResponse.error
      if (
        isAxiosError(error) &&
        error.response?.data?.errorType === 'commercetools' &&
        error.response?.config?.data?.includes('getPaymentMethodsRequest')
      ) {
        throw new Error('getPaymentMethodsRequest:' + error.message)
      }
      logError(
        createPaymentForCartResponse.error,
        'createPaymentForCartAction',
        createPaymentForCartResponse.data
      )
      return
    }

    replaceCart(normalizeCart(createPaymentForCartResponse.data, lcCC))
  }
