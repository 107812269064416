import { CartAction } from '..'
import { normalizeCart } from '../../../lib/commercetools/normalizers/normalizeCart'
import { getCart } from '../../../lib/commercetools/requests/carts/getCart'

export const restoreCartAction =
  (cartId: string): CartAction<unknown> =>
  async ({ lcCC, replaceCart }) => {
    const getCartResponse = await getCart(lcCC, cartId)

    if (getCartResponse.result === 'successful') {
      replaceCart(normalizeCart(getCartResponse.data, lcCC))
    } else {
      throw getCartResponse
    }
  }
