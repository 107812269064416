import { Locale } from '../../../config/shopAPI/types'
import { generateKey } from '../../../utils'
import { configCatClient, isSupportedSetting } from '../util'

const SDK_KEY = process.env.NEXT_PUBLIC_CONFIGCAT_SDK_KEY_FEATURE_FLAGS

if (!SDK_KEY) {
  throw new Error(
    'ConfigCat: NEXT_PUBLIC_CONFIGCAT_SDK_KEY_FEATURE_FLAGS key is missing.'
  )
}

export const defaultFeatureFlags = {
  hasKeycloak: true,
  hasBIPoCs: false,
  hasReferral: false,
  hasProductFlagFilter: true,
  hasPredefinedBundleAddToCart: false,
  customBundlerUpselling: false,
  /**
   * @deprecated replaced by billing/shipping countries from config/shopAPI
   */
  allowedCheckoutCountriesEU: 'BE,LU,NL,ES,PT',
  manualGeoIpBlock: false,
  tunesBlockedPaymentMethods: 'klarna,klarna_account,klarna_paynow',
  isDirectOwnAudioContentAssignmentEnabled: false,
  hasOwnAudioContentInAccount: false,
  hasWhishlistSurvey: false,
  hasSideCartAutoExpandOnAddToCart: true,
  hasBrazeFormInGiftfinder: false,
  useRecommendationsAPI: false,
  hasZendeskWidget: false,
}

export type FeatureFlags = typeof defaultFeatureFlags

/**
 * Returns all feature flags with regard to the given user details.
 * Unconfigured values fall back to their hardcoded defaults.
 */
export const getFeatureFlags = async (params: {
  uuid?: string
  email?: string
  lcCC: Locale
}): Promise<FeatureFlags> => {
  const values = await configCatClient({ SDK_KEY }).getAllValuesAsync({
    identifier: params.uuid || generateKey('guest'),
    email: params.email,
    custom: {
      ShopLocale: params.lcCC,
    },
  })

  return values.reduce((prev, { settingKey, settingValue }) => {
    if (
      isSupportedSetting({
        key: settingKey,
        value: settingValue,
        defaultFlags: defaultFeatureFlags,
      })
    ) {
      return {
        ...prev,
        [settingKey]: settingValue,
      }
    }

    return prev
  }, defaultFeatureFlags)
}
