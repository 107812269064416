import { isToniesColor } from '@/tonies-ui/themes/colors'
import { useIsViewportDesktop } from '@boxine/tonies-ui'
import { Styleable } from '@boxine/tonies-ui/dest/types/styles/Styleable'
import { motion, useAnimate, useScroll, useTransform } from 'framer-motion'
import React from 'react'
import styled, { useTheme } from 'styled-components'

export type CurveLayout = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'

const StyledWrapper = styled(motion.div)<{
  $backgroundColor: string
}>`
  pointer-events: none;
  position: relative;
  background-color: ${(props): string =>
    props.$backgroundColor || 'transparent'};
`

type CurveProps = {
  layout?: CurveLayout
  color?: string
  backgroundColor?: string
  isInverted?: boolean
  size?: 'normal' | 'small'
}

export function Curve({
  layout = 'bottomRight',
  color: colorProp = 'transparent',
  backgroundColor: bgProp = 'transparent',
  isInverted = false,
  className,
  size = 'normal',
}: CurveProps & Styleable) {
  const isDesktop = useIsViewportDesktop()
  const { colors } = useTheme()
  const [curveRef] = useAnimate()
  const progress = useScroll({
    target: curveRef,
  })

  const color = !isInverted ? colorProp : bgProp
  const backgroundColor = !isInverted ? bgProp : colorProp
  const height = useTransform(
    progress.scrollYProgress,
    [1, 0.85, 0.07],
    ['2rem', '3rem', '0rem'].map(value =>
      size === 'normal' && isDesktop ? value : `calc(${value}*0.7)`
    )
  )
  return (
    <StyledWrapper
      $backgroundColor={
        isToniesColor(backgroundColor)
          ? colors[backgroundColor]
          : backgroundColor
      }
      className={className}
      ref={curveRef}
    >
      <motion.div
        variants={{
          topLeft: { scale: '-1, 1' },
          bottomLeft: { scale: '-1, -1' },
          bottomRight: { scale: '1, -1' },
        }}
        initial={layout}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          overflow: 'hidden',
          zIndex: 5,
          height,
          // y-achse
          [layout.includes('top')
            ? isInverted
              ? 'top'
              : 'bottom'
            : isInverted
            ? 'bottom'
            : 'top']: '-1px',
        }}
      >
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 53"
          preserveAspectRatio="none"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            // x-achse
            [isInverted ? 'right' : 'left']: useTransform(
              progress.scrollYProgress,
              [0.33, 0.85],
              ['0%', isInverted ? '0%' : '15%']
            ),
          }}
        >
          <path
            fill={isToniesColor(color) ? colors[color] : color}
            fillRule="evenodd"
            d={
              isInverted
                ? 'm956.28 0c-89.002-.014-181.844 2.604-273.506 6.69-297.314 13.251-582.22 41.94-682.774 46.31v-53zm483.72 53c-103.825-39.665-283.891-52.953-483.421-53h483.421z'
                : 'M0 53c201.107-8.74 1139.624-114.754 1440 0H0z'
            }
          />
        </motion.svg>
      </motion.div>
    </StyledWrapper>
  )
}
