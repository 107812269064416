import { supportedFilterKeys } from '../../../hooks/useProductFilter'
import { OsProductAggregations } from '../requests/products/types'
import { isNotUndefined } from '../../../utils/isNotUndefined'
import { isLiteralString } from '../../../utils/isLitrealString'

const sortOptions = (options: NormalizedAggregationOption[] | undefined = []) =>
  options.sort(({ label: labelA }, { label: labelB }) => {
    if (typeof labelA === 'string' && typeof labelB === 'string') {
      return labelA.localeCompare(labelB, undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    }

    if (typeof labelA === 'number' && typeof labelB === 'number') {
      if (labelA < labelB) {
        return -1
      }
      if (labelA > labelB) {
        return 1
      }
    }

    return 0
  })

export const normalizeAggregations = (
  osAggregation: OsProductAggregations | []
) => {
  return (
    Object.entries(osAggregation)
      .map(([key, options]): NormalizedAggregation | undefined => {
        // check if key is a supportedFilterKey
        if (
          !isLiteralString(key, [
            ...supportedFilterKeys,
            'characterSeries' as const,
            'language' as const,
          ])
        ) {
          return undefined
        }

        return {
          key:
            key === 'characterSeries'
              ? 'series'
              : key === 'language'
              ? 'lcCC'
              : key,
          doc_count: options.reduce((prev, curr) => prev + curr.doc_count, 0),
          options: sortOptions(options),
          // FIXME: idk why, but the sorting of the second level doesnt work (activating a filter is not possible...)
          //
          // .map(lvl1 => ({
          //   ...lvl1,
          //   options: sortOptions(lvl1.options),
          // })),
        }
      })
      .filter(isNotUndefined)
      // sorted by supportedFilterKeys
      .sort(
        (a, b) =>
          supportedFilterKeys.indexOf(a.key) -
          supportedFilterKeys.indexOf(b.key)
      )
  )
}
