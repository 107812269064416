import { BxAddress, BxCustomer } from '../bxTypes'
import { fetch } from '../../util/fetch'
import { EcomLocale } from '../../../../config/shopAPI/types'

/**
 * update customer
 */
export const addAddress = async (
  lcCC: EcomLocale,
  customerVersion: number,
  addresses: BxAddress[]
) =>
  fetch<Record<string, unknown>, BxCustomer>({
    query: `/me`,
    lcCC,
    method: 'post',
    request: {
      version: customerVersion,
      actions: addresses.map(address => ({
        action: 'addAddress',
        address,
      })),
    },
  })
