import {
  SupportedFilterKeys,
  useProductFilterReturnType,
} from '../../hooks/useProductFilter'
import { generateId } from '../../utils'
import { FilterItemType } from '../../components/molecules/FilterExpandable'
import { isCommerceToolsEnumSet } from '../../lib/commercetools/normalizers/normalizeProduct'
import { toAltNotation } from '../../providers/locale/altNotation'
import { Locale } from '../../config/shopAPI/types'
import { useTranslation } from 'react-i18next'

const isEnumSetFilter = (
  attr: NormalizedProduct[SupportedFilterKeys]
): attr is NormalizedEnumSet => {
  return isCommerceToolsEnumSet(attr)
}

export const isProductShownByFilter = <T>(activeFilters: T[], filterValue: T) =>
  activeFilters.some(activeFilter => activeFilter === filterValue)

export const isProductShownByEnumSetFilter = (
  attr: NormalizedProduct[SupportedFilterKeys],
  filter: useProductFilterReturnType
) =>
  isEnumSetFilter(attr) &&
  attr.some(a =>
    isProductShownByFilter(filter.activeFilters, getStringEnumValue(a))
  )

export const isProductShownBySearchValue = (
  stringArray: string[],
  searchValue: string
) =>
  searchValue
    .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    .split(' ')
    .every(searchValuePart => {
      return stringArray.join(' ').match(new RegExp(searchValuePart, 'i'))
    })

export const isNoFilterActive = (
  currentFilters: useProductFilterReturnType[]
) => currentFilters.every(filter => filter.activeFilters.length === 0)

const getStringEnumValue = (
  currentFilterValue: NormalizedProduct[SupportedFilterKeys],
  attr: keyof NormalizedEnum = 'key'
): string | undefined => {
  return currentFilterValue && !isEnumSetFilter(currentFilterValue)
    ? typeof currentFilterValue === 'number' ||
      typeof currentFilterValue === 'string'
      ? `${currentFilterValue}`
      : currentFilterValue[attr]
    : undefined
}

const getProductsByFilter = (
  initialProducts: NormalizedProduct[],
  currentFilters: useProductFilterReturnType[]
): NormalizedProduct[] => {
  if (isNoFilterActive(currentFilters)) return initialProducts

  return initialProducts.filter(product => {
    return currentFilters.every(filter => {
      if (filter.activeFilters.length === 0) return true

      const attr = product[filter.filterKey]

      return isEnumSetFilter(attr)
        ? isProductShownByEnumSetFilter(attr, filter)
        : isProductShownByFilter(filter.activeFilters, getStringEnumValue(attr))
    })
  })
}

const getProductsBySearch = (
  initialProducts: NormalizedProduct[],
  currentSearchValue: string
): NormalizedProduct[] => {
  if (currentSearchValue === '') return initialProducts

  return initialProducts.filter(product => {
    const filterArray = [product.name, product.subName]

    if (typeof product.series === 'string') {
      filterArray.push(product.series)
    }

    if (typeof product.searchKeywords === 'string') {
      filterArray.push(product.searchKeywords)
    }

    return isProductShownBySearchValue(filterArray, currentSearchValue)
  })
}

export const getProducts = (
  initialProducts: NormalizedProduct[],
  currentFilters: useProductFilterReturnType[],
  currentSearchValue: string
): NormalizedProduct[] => {
  return getProductsBySearch(
    getProductsByFilter(initialProducts, currentFilters),
    currentSearchValue
  )
}

/**
 *
 *
 * FILTER_MAPPER
 *
 *
 */

export const getToggledProductFilter = (
  filters: useProductFilterReturnType[],
  key: SupportedFilterKeys,
  value: boolean,
  id: string
) =>
  // map ALL filter groups
  filters.map(filter => {
    const hasId = filter.filters.some(item => {
      return item.id === id || item.options?.some(o => o.id === id)
    })
    // is filterKey === trigged key ?
    // is id a child from filter.allFilters
    if (filter.filterKey === key && hasId) {
      // get toggled filter group
      const newFilter = getToggledValueFilters(filter.filters, value, id)

      // update filter group with new toggledFilter and new activeFilters
      return {
        ...filter,
        filters: newFilter,
        activeFilters: getActiveFilters(newFilter),
      }
    }

    return filter
  })

export const getDisabledFilterState = (
  filteredProducts: NormalizedProduct[],
  filters: useProductFilterReturnType[],
  key?: SupportedFilterKeys,
  value?: boolean
) =>
  filters.map(filter => {
    if (
      (!value && filter.activeFilters.length === 0) ||
      filter.filterKey !== key
    ) {
      const newFilter = getDisabledFilters(
        filteredProducts,
        filter.filters,
        filter.filterKey
      )

      return {
        ...filter,
        filters: newFilter,
      }
    }

    return filter
  })

/**
 *
 * FILTER HOOKS
 *
 */
type Filter = {
  key: string | undefined
  label: string | number
  // TODO: remove optional after legacy code was removed
  doc_count?: number | undefined
  options?: NormalizedAggregation['options']
}

export const generateLegacyProductFilterSet = (
  products: NormalizedProduct[],
  key: SupportedFilterKeys
): {
  key: SupportedFilterKeys
  options: Filter[]
} => ({
  key,
  options: products
    // get array of attribute keys (filter keys)
    .map((product): Filter[] => {
      const attr = product[key]
      if (isEnumSetFilter(attr)) {
        return attr.map(t => {
          return {
            key: getStringEnumValue(t) ?? undefined,
            label: getStringEnumValue(t, 'label') ?? '',
          }
        })
      }
      return [
        {
          key: getStringEnumValue(attr) ?? undefined,
          label: getStringEnumValue(attr, 'label') ?? '',
        },
      ]
    })
    .reduce<Filter[]>((a, b) => a.concat(b), [])
    // filter no undefined keys
    .filter(({ label }) => label !== '')
    .reduce<Filter[]>((prev, curr) => {
      if (!prev.some(({ label }) => label === curr.label)) {
        // label is not included in Set, then add current
        return [...prev, curr]
      }

      // otherwise return the previous state
      return prev
    }, [])
    // sort ASC
    .sort(({ label: labelA }: Filter, { label: labelB }: Filter) => {
      if (typeof labelA === 'string' && typeof labelB === 'string') {
        return labelA.localeCompare(labelB, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      }

      if (typeof labelA === 'number' && typeof labelB === 'number') {
        if (labelA < labelB) {
          return -1
        }
        if (labelA > labelB) {
          return 1
        }
      }

      return 0
    }),
})

const useFilterTranslations = () => {
  const { t } = useTranslation()

  return (filterValue: string | number, type: SupportedFilterKeys) => {
    if (type === 'ageMin') {
      return t('productFilter:age', {
        ageMin:
          filterValue +
          ' ' +
          t('default:year', { count: parseInt(`${filterValue}`) }),
      })
    }

    if (type === 'lcCC' && typeof filterValue === 'string') {
      try {
        return t(
          'productFilter:language:' +
            toAltNotation(filterValue as Locale, 'lc-CC')
        )
      } catch {
        console.info(
          `ℹ️ productFilter:language - "${filterValue}" is not supported in translations`
        )
        return filterValue
      }
    }

    if (typeof filterValue === 'string') return filterValue

    return ''
  }
}

export const useNormalizeFilter = () => {
  const filterTrans = useFilterTranslations()

  return ({
    options,
    key: filterKey,
  }: {
    options: Filter[]
    key: SupportedFilterKeys
  }): FilterItemType[] => {
    return options
      .map(({ label, key, doc_count, options }) => ({
        id: key ?? generateId(),
        value: false,
        label: filterTrans(label, filterKey),
        name: label.toString(),
        disabled: false,
        doc_count,
        options: options
          ?.map(option => ({
            id: option.key,
            value: false,
            label: filterTrans(option.label, filterKey),
            name: option.label.toString(),
            disabled: false,
            doc_count: option.doc_count,
          }))
          .sort((optionA, optionB) => {
            return optionA.label.localeCompare(optionB.label, undefined, {
              numeric: true,
              sensitivity: 'base',
            })
          }),
      }))
      .sort((optionA, optionB) => {
        return optionA.label.localeCompare(optionB.label, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      })
  }
}

export const getActiveFilters = (filters: FilterItemType[] = []): string[] => {
  const activeFilters: string[] = []

  filters.forEach(filter => {
    if (filter.value && !filter.options) {
      activeFilters.push(filter.id)
    }

    filter.options?.forEach(option => {
      if (option.value) {
        activeFilters.push(option.id)
      }
    })
  })

  return [...new Set(activeFilters)]
}

export const getToggledValueFilters = (
  filters: FilterItemType[],
  value: boolean,
  id: string
): FilterItemType[] => {
  // map ALL Checkbox-Rows
  return filters.map(filter => {
    const newOptions = filter.options?.map(o => ({
      ...o,
      value: filter.id === id || o.id === id ? value : o.value,
    }))

    return {
      ...filter,
      value:
        filter.id === id
          ? value
          : newOptions?.every(o => o.value) ?? filter.value,
      options: newOptions,
    }
  })
}

export const getDisabledFilters = (
  products: NormalizedProduct[],
  currentFilters: FilterItemType[],
  filterKey: SupportedFilterKeys
): FilterItemType[] => {
  return currentFilters.map(filter => {
    const { id, value, doc_count } = filter
    /**
     * legacy code, `doc_count` is undefined
     */
    const isLegacyFilter = doc_count === undefined
    const noProductFound =
      isLegacyFilter &&
      !products.some(product => {
        const attr = product[filterKey]
        if (isEnumSetFilter(attr)) {
          return attr.some(a => getStringEnumValue(a) === id)
        }
        return getStringEnumValue(attr) === id
      })

    return {
      ...filter,
      disabled: Boolean(!value && noProductFound),
    }
  })
}

export type getNewFiltersType = {
  products: NormalizedProduct[]
  filters: useProductFilterReturnType[]
  search: string
  updateSingleFilter?: {
    key: SupportedFilterKeys
    value: boolean
    id: string
  }
}

export const getNewFilters = ({
  products: productProp,
  filters: filtersProp,
  search,
  updateSingleFilter,
}: getNewFiltersType) => {
  const filters = updateSingleFilter
    ? // toggle clicked filter in filter-group
      getToggledProductFilter(
        filtersProp,
        updateSingleFilter.key,
        updateSingleFilter.value,
        updateSingleFilter.id
      )
    : filtersProp

  // pre-filter products with toggeled filter-group
  const filteredProducts = getProducts(productProp, filters, search)

  // disabledFilter state (based on pre-filtered products)
  return getDisabledFilterState(
    filteredProducts,
    filters,
    updateSingleFilter?.key,
    updateSingleFilter?.value
  )
}
