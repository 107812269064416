import { createContext } from 'react'

export type ConsentContextProps = {
  /**
   * state of getting consent, `true` when consent has not been given yet or CMP window is still open
   */
  isPending: boolean
  isInitialized: boolean
  /**
   * consent is required if user has not yet answered the CMP
   */
  isConsentRequired: boolean
  /**
   * contains usercentrics script from `window.UC_UI` as soon as available
   */
  ucUI: Window['UC_UI'] | undefined
  /**
   * Function to open settings menu from usercentrics script from `window.UC_UI`
   */
  showSecondUcUILayer: () => boolean | void | string
}

export const defaultConsent = {
  isPending: true,
  isInitialized: false,
  isConsentRequired: true,
  ucUI: undefined,
  showSecondUcUILayer: () => 'Usercentrics interface not initialized.',
}
export const ConsentContext = createContext<ConsentContextProps>(defaultConsent)
