import React, { createContext, Dispatch, SetStateAction } from 'react'
import { Message } from '../../layouts/LoadingLayout'
import { ShopApiViolation } from '../../utils'
import {
  getCheckoutConfig,
  defaultConfirmedSteps,
  defaultIsChecked,
} from './config'
import {
  CheckboxVariant,
  CheckoutConfig,
  CheckoutIndicatorStep,
  CheckoutStep,
  CheckoutStepRefProps,
  ConfirmedStepsState,
  IsCheckedCollection,
} from './config/types'

export type CheckoutContextProps = {
  confirmedSteps: ConfirmedStepsState
  confirmStep: (step: CheckoutStep) => void
  editStep: (step: CheckoutStep) => void
  hasSelectedAddress: boolean
  hasShipping: boolean
  hasPayment: boolean
  noPaymentNeeded: boolean
  hasPlacedOrder: boolean
  setHasPlacedOrder: Dispatch<SetStateAction<boolean>>
  isLoggedIn: boolean
  isReadyForPayment: boolean // new checkout
  isOrderReady: boolean
  isPending: boolean
  isPendingMessage: Message | undefined
  useCounterDebug: [number, Dispatch<SetStateAction<number>>]
  useIsGuestCheckout: [boolean, Dispatch<SetStateAction<boolean>>]
  pageRef: React.RefObject<HTMLDivElement> | null
  setPageRef: Dispatch<SetStateAction<React.RefObject<HTMLDivElement>>>
  orderSummaryRef: React.RefObject<HTMLDivElement> | null
  setOrderSummaryRef: Dispatch<SetStateAction<React.RefObject<HTMLDivElement>>>
  loginRef: React.RefObject<HTMLDivElement> | null
  setLoginRef: Dispatch<SetStateAction<React.RefObject<HTMLDivElement>>>
  addressesRef: React.RefObject<HTMLDivElement> | null
  setAddressesRef: Dispatch<SetStateAction<React.RefObject<HTMLDivElement>>>
  shippingRef: React.RefObject<HTMLDivElement> | null
  setShippingRef: Dispatch<SetStateAction<React.RefObject<HTMLDivElement>>>
  paymentRef: React.RefObject<HTMLDivElement> | null
  setPaymentRef: Dispatch<SetStateAction<React.RefObject<HTMLDivElement>>>
  orderReviewRef: React.RefObject<HTMLDivElement> | null
  setOrderReviewRef: Dispatch<SetStateAction<React.RefObject<HTMLDivElement>>>
  orderId: string | undefined
  orderNumber: string | undefined
  setOrderId: (orderId: string | undefined) => void
  setOrderNumber: (orderNumber: string | undefined) => void
  orderError: string | undefined
  setOrderError: Dispatch<SetStateAction<string | undefined>>
  focusOn: React.RefObject<HTMLDivElement> | null
  setFocusOn: (step: React.RefObject<HTMLDivElement> | null) => void
  isReadOnlyOrderReview: boolean
  setIsReadOnlyOrderReview: Dispatch<SetStateAction<boolean>>
  currentStep: CheckoutStep
  setCurrentStep: Dispatch<SetStateAction<CheckoutStep>>
  editOrderReview: boolean
  setEditOrderReview: Dispatch<SetStateAction<boolean>>
  paymentRedirectResult: string | undefined
  config: CheckoutConfig
  checkoutStepRef: CheckoutStepRefProps | undefined
  scrollToPageStart: () => void
  toggleCheckbox: (box: CheckboxVariant) => void
  isChecked: IsCheckedCollection
  cartViolations: ShopApiViolation[] | undefined
  indicatorSteps: CheckoutIndicatorStep[]
  stepIndicatorReadOnly: boolean
  setStepIndicatorReadOnly: Dispatch<SetStateAction<boolean>>
  currentStepIndicatorStep: number
  setFocusStep: ({
    toTop,
    stepKey,
  }: {
    toTop: boolean
    stepKey: CheckoutStep
  }) => void
}

export const defaultProps: CheckoutContextProps = {
  confirmedSteps: defaultConfirmedSteps,
  confirmStep: () => null,
  editStep: () => null,
  hasSelectedAddress: false,
  hasShipping: false,
  hasPayment: false,
  noPaymentNeeded: false,
  hasPlacedOrder: false,
  setHasPlacedOrder: () => null,
  isLoggedIn: false,
  isReadyForPayment: false,
  isOrderReady: false,
  isPending: false,
  isPendingMessage: undefined,
  useCounterDebug: [0, () => null],
  useIsGuestCheckout: [false, () => null],
  orderError: undefined,
  setOrderError: () => undefined,
  orderId: undefined,
  setOrderId: () => undefined,
  orderNumber: undefined,
  setOrderNumber: () => undefined,
  focusOn: null,
  setFocusOn: () => null,
  orderReviewRef: null,
  setOrderReviewRef: () => null,
  pageRef: null,
  setPageRef: () => null,
  orderSummaryRef: null,
  setOrderSummaryRef: () => null,
  loginRef: null,
  setLoginRef: () => null,
  addressesRef: null,
  setAddressesRef: () => null,
  shippingRef: null,
  setShippingRef: () => null,
  paymentRef: null,
  setPaymentRef: () => null,
  isReadOnlyOrderReview: false,
  setIsReadOnlyOrderReview: () => undefined,
  editOrderReview: false,
  setEditOrderReview: () => undefined,
  paymentRedirectResult: '',
  config: getCheckoutConfig({ normalizedCartType: null, bxLineItemTypes: [] }),
  currentStep: 'login',
  scrollToPageStart: () => undefined,
  setCurrentStep: () => undefined,
  checkoutStepRef: undefined,
  isChecked: defaultIsChecked,
  toggleCheckbox: () => undefined,
  cartViolations: undefined,
  indicatorSteps: [],
  stepIndicatorReadOnly: false,
  setStepIndicatorReadOnly: () => undefined,
  currentStepIndicatorStep: 0,
  setFocusStep: () => null,
}

export const CheckoutContext = createContext<CheckoutContextProps>(defaultProps)
