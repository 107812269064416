import React, { useRouter } from 'next/router'
import { FunctionComponent, PropsWithChildren } from 'react'
import { Locale } from '../../config/shopAPI/types'
import { isLocale } from '../../config/shopAPI'
import { LocaleProvider } from '.'

export const NextLocaleProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { asPath } = useRouter()
  const locale = extractLocaleFromPath(asPath)

  return <LocaleProvider value={locale}>{children}</LocaleProvider>
}

export const lcccRegexPattern = '([a-z]{2})-([a-z]{2})'

export function extractLocaleFromPath(path: string): Locale {
  /**
   * FIXME
   * Return Locale `en-GB` in extractLocaleFromPath for _next or api paths (TWAS-3192) because for those paths we do not add a locale to the URL using GeoIP.
   * This can be removed once we proxy all requests to Vercel in our GeoProxy.
   */
  if (path.startsWith('/_next') || path.startsWith('/api')) {
    return 'en-GB'
  }

  const parts = path.match(new RegExp(`^/${lcccRegexPattern}/`))

  if (!parts) {
    throw new Error(`Unable to determine Next locale for "${path}"`)
  }

  const lcCC = `${parts[1]}-${parts[2].toUpperCase()}`

  if (!isLocale(lcCC)) {
    throw new Error(`Unsupported Next locale "${lcCC}" in path "${path}"`)
  }

  return lcCC
}
