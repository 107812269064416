import { gql } from '../../../../lib/cloudservices/util/graphql'
import { EcomLocale } from '../../../../config/shopAPI/types'
import { fetch } from '../../util/fetch'
import { SingleTunesItemCheckData } from '../rest/checkTuneStatus'

const GRAPHQL_ENDPOINT = '/graphql'

export type HouseholdData = {
  myTunes: [
    {
      item: {
        salesId: string
        allCreativeTonies?: boolean
        compatibleTonies: Array<{
          id: string
          salesId: string
          tonieType: string
          tune?: {
            id: string
            item: { title: string }
          }
        }>
      }
    }
  ]
}

/**
 * for now the household data contains only the currently owned tunes
 * for further graphQL schema information you can use the GraphiQL interface
 * here: {@link https://api.dev.tonie.cloud/v2/graphql}
 */

const HouseholdDataQuery = gql`
  query HouseholdData($includeOwnCollection: Boolean!) {
    myTunes {
      item {
        salesId
        title
        allCreativeTonies @include(if: $includeOwnCollection)
        compatibleTonies @include(if: $includeOwnCollection) {
          id
          salesId
          tonieType
          tune {
            id
            item {
              title
            }
          }
        }
      }
    }
  }
`

export const getHouseholdData = (
  lcCC: EcomLocale,
  isOwnCollectionFetch = false as boolean | undefined
) => {
  return fetch<{ data: HouseholdData }>({
    query: GRAPHQL_ENDPOINT,
    lcCC,
    method: 'post',
    request: {
      query: HouseholdDataQuery,
      variables: { includeOwnCollection: isOwnCollectionFetch },
    },
  })
}

/**
 * get household for a single tunesItem to be able to retrieve compatible tonies.
 * The `compatibleTonies` query only resolves results if asked for a specific tunesItem
 */
const HouseholdSingleTunesItemQuery = gql`
  query HouseholdData($salesId: String) {
    tunesItems(salesId: $salesId) {
      edges {
        node {
          title
          allCreativeTonies
          compatibleTonies {
            id
            salesId
            tonieType
            title
          }
          myTune {
            id
          }
        }
      }
    }
  }
`

export const getHouseholdSingleTunesItemData = (
  lcCC: EcomLocale,
  salesId: string
) =>
  fetch<{ data: SingleTunesItemCheckData }>({
    query: GRAPHQL_ENDPOINT,
    lcCC,
    method: 'post',
    request: {
      query: HouseholdSingleTunesItemQuery,
      variables: { salesId },
    },
  })

const AudioContentDataQuery = gql`
  query HouseholdData($salesId: String) {
    tunesItems(salesId: $salesId) {
      edges {
        node {
          id
          myTune {
            assignCountCurrent
            id
            assignedTonies {
              id
              title
              tune {
                id
                item {
                  title
                }
              }
            }
          }
          compatibleTonies {
            id
            salesId
            tonieType
            title
            imageUrl
            householdId
            tune {
              id
              item {
                title
              }
            }
          }
        }
      }
    }
  }
`

export const getTunesItemBySalesId = (lcCC: EcomLocale, salesId: string) =>
  fetch<{ data: SingleTunesItemCheckData }>({
    query: GRAPHQL_ENDPOINT,
    lcCC,
    method: 'post',
    request: {
      query: AudioContentDataQuery,
      variables: { salesId },
    },
  })
