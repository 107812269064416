import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useMemo,
} from 'react'
import { toniesCookiesContext } from '.'
import useCookie from '../../hooks/useCookie'
import { useIsAudioLibraryCheckout } from '../../hooks/useIsAudioLibraryCheckout'
import { useLocale } from '../locale'
import { isEcomLocale } from '../../config/shopAPI'

export const useCartId = () => {
  const lcCC = useLocale()
  const isAudioLibraryCheckout = useIsAudioLibraryCheckout()
  return useCookie(
    `bxCartId${isAudioLibraryCheckout ? 'AudioLibraryCheckout' : ''}_${lcCC}`
  )
}

export const useCartItemCountStr = () => {
  const lcCC = useLocale()
  const isAudioLibraryCheckout = useIsAudioLibraryCheckout()
  return useCookie(
    `bxCartCount${isAudioLibraryCheckout ? 'AudioLibraryCheckout' : ''}_${lcCC}`
  )
}

/**
 * A ToniesCookieContext provider implementation built around `useCookie()`
 * to keep our cookie keys centralized in one place, the getters and setters
 * type-safe and the state consistent across the entire application.
 *
 * Reminder: every instance of `useCookie()` keeps its own internal state;
 * states are not synchronized between instances; it is hence important
 * to have only one hook per key to prevent asynchronicy in the app.
 */
export const ToniesCookiesProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const lcCC = useLocale()
  const [cartId, setCartId] = useCartId()
  const [cartItemCountStr, setCartItemCountStr] = useCartItemCountStr()
  const [cartUserId, setCartUserId] = useCookie(
    `bxCartUserId_${lcCC}`,
    undefined
  )

  const cartItemCount = Number(cartItemCountStr) || 0

  const setCartItemCount = useCallback(
    (c: number) => {
      if (isEcomLocale(lcCC)) {
        setCartItemCountStr(c.toString())
      }
    },
    [setCartItemCountStr, lcCC]
  )

  const value = useMemo(
    () => ({
      cartId,
      setCartId,
      cartItemCount,
      setCartItemCount,
      cartUserId,
      setCartUserId,
    }),
    [
      cartId,
      setCartId,
      cartItemCount,
      setCartItemCount,
      cartUserId,
      setCartUserId,
    ]
  )

  return (
    <toniesCookiesContext.Provider value={value}>
      {children}
    </toniesCookiesContext.Provider>
  )
}
