import { ThemeProps, createGlobalStyle } from 'styled-components'
import { ThemeType } from '@/tonies-ui/themes/theme'

export const ToniesGlobalStyles = createGlobalStyle`
  /* Originally from http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602 <- not an official release? some modifications from us below
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  main, menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    /* reasonable error, but no idea what effect fixing that would have */
    /* stylelint-disable declaration-block-no-shorthand-property-overrides */
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, main, menu, nav, section {
    display: block;
  }
  /* HTML5 hidden-attribute fix for newer browsers */
  *[hidden] {
      display: none;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  *,
  *:after,
  *:before {
    /* See html {...} (https://www.paulirish.com/2012/box-sizing-border-box-ftw/) */
    box-sizing: inherit;
  }

  body {
    background-color: ${(props: ThemeProps<ThemeType>) =>
      props.theme.application.backgroundColor};
    color: ${(props: ThemeProps<ThemeType>) => props.theme.application.color};
    font-family: ${props => props.theme.fonts.serif};
    font-weight: normal;
    line-height: 22px;

    /* See https://justmarkup.com/log/2015/07/dealing-with-long-words-in-css/ for details */
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }

  html {
    box-sizing: border-box;

    /* Normalize.css: Prevent adjustments of font size after orientation changes in iOS */
    text-size-adjust: 100%;

    /* Remove the tap highlight color for iOS Safari which caused bugs (see TOC-2970 or TTT-261) */
    -webkit-tap-highlight-color: transparent;

    scroll-behavior: smooth;
  }

  b, strong {
    font-weight: 700;
  }

  i, em {
    font-style: italic;
  }

  code, pre {
    font-family: monospace;
  }

  /**
   * Note: The @supports selector is required as using the :focus-visible
   * selector directly invalidates the entire declaration block and would thus
   * not apply the styles. Once https://caniuse.com/#search=focus-visible is
   * supported in all of our supported browsers, the polyfill version can be
   * removed.
   */
  /* Polyfill version (.focus-visible) */
  @supports not (selector(:focus-visible)) {
    .js-focus-visible :focus:not(.focus-visible) {
      outline: none;
    }
  }

  /* Official version (:focus-visible) */
  @supports selector(:focus-visible) {
    :focus:not(.focus-visible) {
      outline: none;
    }
  }
`
