import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { syncCartCookiesWithCartState, State } from '.'
import { useCartActions } from '../../hooks/useCartActions'
import { useCartState } from '../../hooks/useCartState'
import { useSyncCartStateWithAuth } from '../../hooks/useSyncCartStateWithAuth'
import { useToniesCookies } from '../../hooks/useToniesCookies'
import { restoreCartAction } from '../cartActions/actions/restoreCartAction'
import { isAxiosNetworkError } from '../../utils/isAxiosError'
import { useTranslation } from 'react-i18next'
import { toast } from '@/tonies-ui/atoms/Toast'
import { useToastErrorMessage } from '../../hooks/useToastErrorMessage'
import { sentryWithExtras } from '../../hooks/useSentry'

/**
 * We use cookies to preserve the cookie ID and the number of items in
 * the cart inbetween page reloads and between next.js and Classic.
 *
 * This hook initializes the cart state using the cart ID cookie.
 * Later it updates the cart cookies whenever the cart state changes.
 */
export const SyncCartCookiesWithCartStateProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const {
    state: authState,
    isTokenPresent,
    hasBearerToken,
  } = useSyncCartStateWithAuth()
  const {
    cart,
    isPendingState: [, setIsPending],
  } = useCartState()
  const { t } = useTranslation()
  const { push: queue } = useCartActions()
  const { cartId, setCartId, setCartItemCount } = useToniesCookies()
  const defaultToast = useToastErrorMessage()
  const [state, setState] = useState<State>('initializing')

  /** authTokenHandlingFinished when
   * - no Token is present (Guest user) or
   * - the auth header `Bearer` is already set (authenticated user)
   */
  const authTokenHandlingFinished = !isTokenPresent || hasBearerToken

  /** authReadyToRestore is true when
   * - auth has finished and
   * - authTokenHandlingFinished
   */
  const authReadyToRestore =
    authState === 'finished' && authTokenHandlingFinished

  // Restore cart state from cookie
  useEffect(() => {
    if (state === 'initializing' && authReadyToRestore) {
      if (cartId) {
        setState('fetching')
        queue(restoreCartAction(cartId))
          .catch(e => {
            if ([404, 401].includes(e.httpStatus)) {
              // delete cart reference
              setCartId(undefined)
              setCartItemCount(0)
            } else if (isAxiosNetworkError(e.error)) {
              toast(t('cart:error:network'), 'error', {
                toastId: 'cart-request-outage--network',
              })
            } else {
              defaultToast('cart-request-outage')
              sentryWithExtras('SyncCartCookiesWithCartStateProvider', e)
            }
          })
          .finally(() => {
            setState('finished')
          })
      } else {
        setState('finished')
      }
    }
  }, [
    authReadyToRestore,
    state,
    setState,
    cartId,
    cart,
    queue,
    setCartId,
    setCartItemCount,
    t,
    defaultToast,
  ])

  // Update cookie on cart state change
  useEffect(() => {
    if (state === 'finished') {
      setIsPending(false)

      if (cart) {
        setCartId(cart.id)
        setCartItemCount(cart.count || 0)
      }
    }
  }, [cart, state, setCartId, setCartItemCount, setIsPending])

  const value = useMemo(
    () => ({
      state,
    }),
    [state]
  )

  return (
    <syncCartCookiesWithCartState.Provider value={value}>
      {children}
    </syncCartCookiesWithCartState.Provider>
  )
}
