import { EcomLocale } from '../../../../config/shopAPI/types'
import { fetch } from '../../../../lib/shopApi/util/fetch'

export const removeItemFromWishlist = async (
  lcCC: EcomLocale,
  wishlistItem: NormalizedWishlistItem
): Promise<null> => {
  if (process.env.NEXT_PUBLIC_FEATUREFLAG_HAS_WISHLIST !== 'true') {
    return Promise.reject()
  }

  return fetch({
    query: `/wishlist-line-item/${wishlistItem.lineItemId}`,
    method: 'delete',
    lcCC,
  }).then(res => {
    if (res.result === 'successful') {
      return null
    } else {
      throw res
    }
  })
}
