import React from 'react'
import { DefaultMotionSpanElement } from '@/tonies-ui/motions/constants'

export const RotateIt: NormalizedMotionComponent = ({ children, ...rest }) => {
  return (
    <DefaultMotionSpanElement
      data-testid="rotate-it-motion"
      whileInView={{
        rotate: ['0deg', '20deg', '0deg'],
        x: ['0px', '6px', '0px'],
        y: ['0px', '-5px', '0px'],
        opacity: [1, 0.5, 1],
        transition: {
          duration: 10,
          repeat: Infinity,
        },
      }}
      {...rest}
    >
      {children}
    </DefaultMotionSpanElement>
  )
}
