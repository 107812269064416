/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Recursively deletes undefined properties from an object.
 * HINT: This mutates the object.
 * @param obj - The object to delete undefined properties from.
 * @param exclude - An array of objects to exclude from deletion.
 */
export const deleteUndefined = (
  obj: Record<string, any> | undefined,
  exclude: Record<string, any>[] = []
): void => {
  if (obj) {
    Object.keys(obj).forEach((key: string) => {
      if (
        obj[key] &&
        typeof obj[key] === 'object' &&
        !exclude.includes(obj[key])
      ) {
        deleteUndefined(obj[key], [...exclude, obj[key]])
      } else if (typeof obj[key] === 'undefined') {
        delete obj[key]
      }
    })
  }
}
