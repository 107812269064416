import { createContext } from 'react'
import { PaymentIconName } from 'providers/payment/lib'
import { paymentTypes } from '../payment/lib'
import { PaymentType } from '../payment/types'

export const defaultPaymentTypesProps: PaymentTypeContextProps = {
  getBlockedPaymentTypes: _context =>
    paymentTypes.filter(method => !['paypal', 'scheme'].includes(method)),
  getPaymentIcons: _context => ['paypal', 'visa', 'maestro', 'mc'],
  setCartContainsTunesOnly: () => undefined,
}
export type PaymentIconContext = 'global' | 'checkout'

export type PaymentTypeContextProps = {
  getBlockedPaymentTypes: (context: PaymentIconContext) => PaymentType[]
  getPaymentIcons: (context: PaymentIconContext) => PaymentIconName[]
  setCartContainsTunesOnly: React.Dispatch<React.SetStateAction<boolean>>
}
export const PaymentTypeContext = createContext<PaymentTypeContextProps>(
  defaultPaymentTypesProps
)
