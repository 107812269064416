import { generateId, generateSha256 } from '../../utils'
import { createHmac } from 'crypto'
import { shopConfig } from '../../config/shop'
import {
  GtmV4AddToCartEvent,
  GtmV4CheckoutEvent,
  GtmV4CustomBundlerEvent,
  GtmV4Event,
  GtmV4Item,
  GtmV4MagicLinkEvent,
  GtmV4RemoveFromCartEvent,
  GtmV4ViewItemEvent,
  GtmV4ViewItemListEvent,
  GtmV4CouponEvent,
  GtmV4ReferralEvent,
  GtmV4AudioSampleEvent,
  GtmV4GenerateLeadEvent,
  GtmV4SelectItemEvent,
  GtmV4SelectContentEvent,
  GtmV4SelectVariantEvent,
  GtmV4SearchEvent,
  GtmV4ClickEvent,
  GtmV4ExperimentImpression,
  GtmV4AddToWishlistEvent,
  GtmV4RemoveFromWishlistEvent,
  GtmV4AudioContentEvent,
  GtmV4WishlistEvent,
  GtmV4ViewWishlistEvent,
} from './types'

import { deleteUndefined } from '../../utils/deleteUndefined'

const hasProductWithCategoryKey = (
  lineItems: NormalizedLineItem[],
  categoryKeys: string[]
): boolean => {
  return lineItems.some(li =>
    categoryKeys.includes(li.product.normalizedCategories[0].key)
  )
}

const mapGtmV4Item = (
  p: NormalizedProduct & { quantity?: number },
  li?: NormalizedLineItem
): GtmV4Item => {
  const gtmV4Item = {
    item_id: p.salesId,
    item_name: p.name,
    currency: li?.total.price.currency || p.price?.currency || '',
    item_brand: p.brand,
    item_category: p.normalizedCategories[0].key,
    item_category2: p.normalizedCategories[1]?.key || '',
    item_category3: p.normalizedCategories[2]?.key || '',
    item_category4: p.normalizedCategories[3]?.key || '',
    item_category5: p.normalizedCategories[4]?.key || '',
    price:
      li?.total.gtmV4Price?.amount ||
      li?.total.price.amount ||
      p.price?.amount ||
      0,
    original_price: li?.total.price.amount || p.price?.amount || 0,
    discount: li?.total.gtmV4Price
      ? (li?.total.price.centAmount - li?.total.gtmV4Price?.centAmount) / 100
      : 0,
    quantity: li?.quantity || p.quantity || 1,
    availability: p.availability.state,
    is_tunes_available: p.audioLibraryUrl ? true : false,
    play_time: p.runTime ? p.runTime * 60 : 0,
    sku: p.sku,
  }

  deleteUndefined(gtmV4Item)

  return gtmV4Item
}

const mapNormalizedProduct = (
  p: NormalizedProduct & { quantity?: number }
): GtmV4Item => {
  return mapGtmV4Item(p)
}

const mapLineItems = (lineItems: NormalizedLineItem[]): GtmV4Item[] => {
  return lineItems.map(li => {
    return mapGtmV4Item(li.product, li)
  })
}

const sha256 = (input?: string) => {
  return input && input.length ? generateSha256(input) : ''
}

const mapViewItemEvent = ({ item }: GtmV4ViewItemEvent) => ({
  event: 'view_item',
  event_id: generateId(),
  ecommerce: {
    items: [mapNormalizedProduct(item)],
  },
})

const mapViewItemListEvent = ({ items }: GtmV4ViewItemListEvent) => ({
  event: 'view_item_list',
  event_id: generateId(),
  ecommerce: {
    items: items.map(item => mapNormalizedProduct(item)),
  },
})

const mapAddToCartEvent = ({ item }: GtmV4AddToCartEvent) => ({
  event: 'add_to_cart',
  event_id: generateId(),
  ecommerce: {
    currency: item.price?.currency,
    value: item.price?.amount,
    items: [mapNormalizedProduct(item)],
  },
})

const mapRemoveFromCartEvent = ({ item, value }: GtmV4RemoveFromCartEvent) => ({
  event: 'remove_from_cart',
  event_id: generateId(),
  ecommerce: {
    currency: item.price?.currency,
    value,
    items: [mapNormalizedProduct(item)],
  },
})

const mapViewWishlistEvent = ({ items }: GtmV4ViewWishlistEvent) => ({
  event: 'view_wishlist',
  event_id: generateId(),
  items,
})

const mapWishlistEvent = ({ step }: GtmV4WishlistEvent) => ({
  event: 'wishlist',
  event_id: generateId(),
  step,
})

const mapAddToWishlistEvent = ({ item }: GtmV4AddToWishlistEvent) => ({
  event: 'add_to_wishlist',
  event_id: generateId(),
  ecommerce: {
    currency: item.price?.currency,
    value: item.price?.amount,
    items: [mapNormalizedProduct(item)],
  },
})

const mapRemoveFromWishlistEvent = ({
  item,
  value,
}: GtmV4RemoveFromWishlistEvent) => ({
  event: 'remove_from_wishlist',
  event_id: generateId(),
  ecommerce: {
    currency: item.price?.currency,
    value,
    items: [mapNormalizedProduct(item)],
  },
})

const mapCheckoutEvent = ({
  event,
  cart,
  billingAddress,
  shippingAddress,
  billingIsShipping,
  paymentType,
  orderNumber,
  newsletterSubscription,
  loginType,
  lcCC,
  user,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
GtmV4CheckoutEvent) => {
  // Add additional data depending on the step
  let additionalData = {}
  let additonalEcommerceData = {}

  // add_address_info and following
  if (
    [
      'add_address_info',
      'add_shipping_info',
      'review_order',
      'add_payment_info',
      'purchase',
    ].includes(event)
  ) {
    const billingCountry =
      billingAddress?.country || cart?.addresses.billing.country
    const shippingCountry =
      shippingAddress?.country ||
      cart?.addresses.shipping.country ||
      billingCountry

    additonalEcommerceData = {
      ...additonalEcommerceData,
      billing_is_shipping: billingIsShipping,
      billing_country: billingCountry,
      shipping_country: billingIsShipping ? billingCountry : shippingCountry,
    }
  }

  // add_shipping_info and following
  if (
    [
      'add_shipping_info',
      'review_order',
      'add_payment_info',
      'purchase',
    ].includes(event)
  ) {
    additonalEcommerceData = {
      ...additonalEcommerceData,
      shipping_tier: cart?.shipping?.method?.id,
      shipping: cart?.shipping?.price?.amount,
    }
  }

  // add_payment_info and following
  if (['add_payment_info', 'purchase'].includes(event)) {
    additonalEcommerceData = {
      ...additonalEcommerceData,
      payment_type: paymentType,
    }
  }

  // purchase
  if (['purchase'].includes(event)) {
    // https://knowledge.bazaarvoice.com/wp-content/conversations/en_US/Collect/site_authentication.html#step-4-encode-the-uas
    let bvToken = ''
    const bvKey = lcCC
      ? shopConfig.locales[lcCC].bazaarvoice?.sharedEncodingKey
      : undefined
    if (lcCC && bvKey) {
      const id = user?.id || cart?.id
      const email = user ? 'user@tonies.com' : 'guest@tonies.com'
      const params: string[] = []
      params.push(
        `date=${new Date().toISOString().split('T')[0].replace(/-/g, '')}`
      )
      params.push(`userid=${id}`)
      params.push(`emailaddress=${encodeURIComponent(email)}`)
      params.push(`verifiedpurchaser=True`)
      params.push(
        `subjectids=${cart?.lineItems.map(i => i.product.salesId).join('/')}`
      )
      const bvParams = params.join('&')
      const hmac = createHmac('sha256', bvKey).update(bvParams)
      const bvSignature = hmac.digest('hex')
      const bvParamsHex = bvParams
        .split('')
        .map(c => c.charCodeAt(0).toString(16))
        .join('')
      bvToken = bvSignature + bvParamsHex
    }

    additionalData = {
      ...additionalData,
      newsletter_subscription: newsletterSubscription,
      order_includes_toniebox: hasProductWithCategoryKey(
        cart?.lineItems || [],
        ['tonieboxes', 'tonieboxes-refurbished', 'toniebox-bundles']
      ),
      user_data: {
        user_email: cart?.addresses.billing.email || '',
        user_email_sha256: sha256(cart?.addresses.billing.email),
        user_phone: cart?.addresses.billing.phone || '',
        user_phone_sha256: sha256(cart?.addresses.billing.phone),
        user_bv_token: bvToken,
      },
    }
    additonalEcommerceData = {
      ...additonalEcommerceData,
      transaction_id: orderNumber,
    }
  }

  const shippingPrice = cart?.shipping?.price?.centAmount || 0
  const totalPrice = cart?.price.total.centAmount || 0
  const originalPrice = cart?.price.original.centAmount || 0
  const discount = (originalPrice + shippingPrice - totalPrice) / 100

  const cartDiscounts1 =
    cart?.cartDiscount?.byDiscountCode?.appliedCartDiscounts
      .map(d => d.key || `missing-key: ${d.name}`)
      .filter(Boolean) || []

  const cartDiscounts2 =
    cart?.cartDiscount?.others
      .map(d => d.key || `missing-key: ${d.name}`)
      .filter(Boolean) || []

  const res = {
    event,
    event_id: generateId(),
    login_type: loginType ? loginType : cart?.customerId ? 'user' : 'guest',
    ...additionalData,
    ecommerce: {
      currency: cart?.price.original.currency || '',
      value: cart?.price.total.amount || 0,
      discount,
      coupon: cart?.cartDiscount?.byDiscountCode?.code || '',
      cart_discounts: cartDiscounts1.concat(cartDiscounts2).join('|') || '',
      ...additonalEcommerceData,
      cart_type: cart?.normalizedCartType,
      items: mapLineItems(cart?.lineItems || []),
    },
  }

  deleteUndefined(res)

  return res
}

const mapMagicLinkEvent = ({ items, coupon }: GtmV4MagicLinkEvent) => ({
  event: 'magic_link',
  event_id: generateId(),
  ecommerce: {
    currency: items?.length ? items[0].price?.currency : '',
    coupon: coupon || '',
    items: items?.map(item => mapNormalizedProduct(item)),
  },
})

const mapCustomBundlerEvent = ({
  step,
  selection,
}: GtmV4CustomBundlerEvent) => {
  return {
    event: 'custom_bundler',
    event_id: generateId(),
    step,
    ecommerce: {
      value: selection.template.price.amount, // price of selected bundle, persisted through all steps
      currency: selection.template.price.currency,
      items: [
        ...selection.tonieboxes,
        ...selection.tonies,
        ...selection.creativeTonies,
        selection.bipocTonie,
      ],
    },
  }
}

const mapCouponEvent = ({ event, coupon }: GtmV4CouponEvent) => ({
  event: `${event}_coupon`,
  event_id: generateId(),
  ecommerce: {
    coupon: coupon,
  },
})

const mapReferralEvent = ({ step }: GtmV4ReferralEvent) => ({
  event: 'referral',
  event_id: generateId(),
  step,
})

const mapAudioSampleEvent = ({ step, item }: GtmV4AudioSampleEvent) => ({
  event: 'audio_sample',
  event_id: generateId(),
  step,
  ecommerce: {
    items: [mapNormalizedProduct(item)],
  },
})

const mapAudioContentEvent = ({ step, item }: GtmV4AudioContentEvent) => {
  return {
    event: 'audio_content',
    event_id: generateId(),
    step,
    ecommerce: {
      items: [mapNormalizedProduct(item)],
    },
    tonie_type: item.assignableToAllCreativeTonies
      ? 'creative_tonie'
      : 'content_tonie',
  }
}

const mapGenerateLeadEvent = ({
  leadType,
  formLocation,
  email,
}: GtmV4GenerateLeadEvent) => ({
  event: 'generate_lead',
  event_id: generateId(),
  lead_type: leadType,
  form_location: formLocation,
  user_data: {
    user_email: email,
    user_email_sha256: sha256(email),
  },
})

const mapSelectItemEvent = ({ item }: GtmV4SelectItemEvent) => ({
  event: 'select_item',
  event_id: generateId(),
  ecommerce: {
    items: [mapNormalizedProduct(item)],
  },
})

const mapSelectContentEvent = ({
  ctaLabel,
  ctaLink,
  type,
}: GtmV4SelectContentEvent) => ({
  event: 'select_content',
  event_id: generateId(),
  cta_label: ctaLabel,
  cta_link: ctaLink,
  type,
})

const mapSelectVariantEvent = ({ item, step }: GtmV4SelectVariantEvent) => ({
  event: 'select_variant',
  event_id: generateId(),
  step,
  ecommerce: {
    items: [mapNormalizedProduct(item)],
  },
})

const mapSearchEvent = ({ filters, searchTerm }: GtmV4SearchEvent) => {
  const filtersReduced = filters.reduce(
    (obj, filter) =>
      Object.assign(obj, {
        ['filter_' + filter.filterKey]: filter.activeFilters,
      }),
    {}
  )
  const filterActive = filters.some(filter => filter.activeFilters.length > 0)

  return {
    event: 'search',
    event_id: generateId(),
    search_term: searchTerm,
    filter_active: filterActive,
    ...filtersReduced,
  }
}

const mapExperimentImpressionEvent = ({
  key,
  value,
}: GtmV4ExperimentImpression) => {
  return {
    event: 'experiment_impression',
    event_id: generateId(),
    gaExperiments: {
      gaExpId: key,
      gaExpVar: typeof value === 'boolean' ? (value ? '1' : '0') : value,
    },
  }
}

const mapClickEvent = ({ label, link, action }: GtmV4ClickEvent) => {
  const res = {
    event: 'click',
    event_id: generateId(),
    label,
    link,
    action,
  }

  deleteUndefined(res)

  return res
}

export const mapToDataLayerEvent = (
  params: GtmV4Event
): Record<string, unknown> & { ecommerce?: Record<string, unknown> } => {
  switch (params.eventType) {
    case 'addToCart':
      return mapAddToCartEvent(params)
    case 'removeFromCart':
      return mapRemoveFromCartEvent(params)
    case 'viewWishlist':
      return mapViewWishlistEvent(params)
    case 'wishlist':
      return mapWishlistEvent(params)
    case 'addToWishlist':
      return mapAddToWishlistEvent(params)
    case 'removeFromWishlist':
      return mapRemoveFromWishlistEvent(params)
    case 'checkout':
      return mapCheckoutEvent(params)
    case 'viewItem':
      return mapViewItemEvent(params)
    case 'viewItemList':
      return mapViewItemListEvent(params)
    case 'magicLink':
      return mapMagicLinkEvent(params)
    case 'customBundler':
      return mapCustomBundlerEvent(params)
    case 'coupon':
      return mapCouponEvent(params)
    case 'referral':
      return mapReferralEvent(params)
    case 'audioSample':
      return mapAudioSampleEvent(params)
    case 'audioContent':
      return mapAudioContentEvent(params)
    case 'generateLead':
      return mapGenerateLeadEvent(params)
    case 'selectItem':
      return mapSelectItemEvent(params)
    case 'selectContent':
      return mapSelectContentEvent(params)
    case 'selectVariant':
      return mapSelectVariantEvent(params)
    case 'search':
      return mapSearchEvent(params)
    case 'experimentImpression':
      return mapExperimentImpressionEvent(params)
    case 'click':
      return mapClickEvent(params)
  }
}
