import { EcomLocale } from '../../../../config/shopAPI/types'
import { fetch } from '../../../../lib/shopApi/util/fetch'
import { BxWishlist } from '../bxTypes'
import { normalizeWishlist } from '../../../../lib/shopApi/normalizers/normalizeWishlist'

export const createWishlist = async (
  lcCC: EcomLocale
): Promise<NormalizedWishlist> => {
  if (process.env.NEXT_PUBLIC_FEATUREFLAG_HAS_WISHLIST !== 'true') {
    return Promise.reject()
  }

  return fetch<BxWishlist>({
    query: `/wishlist`,
    method: 'post',
    lcCC,
  }).then(res => {
    if (res.result === 'successful') {
      return normalizeWishlist(lcCC, res.data)
    } else {
      throw res
    }
  })
}
