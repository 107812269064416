import Keycloak, { KeycloakConfig } from 'keycloak-js'
import { useEffect, useState, useCallback } from 'react'
import { useLocalStorage } from '@boxine/tonies-ui'
import useCheckThirdPartyCookies from './useCheckThirdPartyCookies'
import { sentryWithExtras } from './useSentry'
import { normalizeErrorMessage } from '../utils/normalizeError'

export type KeycloakStatus =
  | 'UNKNOWN'
  | 'INITIALIZATION_STARTED'
  | 'INITIALIZATION_FAILED'
  | 'AUTHENTICATED'
  | 'NOT_AUTHENTICATED'

type UseKeycloakReturnType = {
  status: KeycloakStatus
  setNotAuthenticated: () => void
  accessToken: string | undefined
  refreshToken: string | undefined
  idToken: string | undefined
  keycloak: Keycloak | undefined
}
/**
 * TODO: TOC-2795 - Refactoring after nonSSO-Code has been removed
 * Initializing Keycloak
 * Previously KeycloakProvider from @react-keycloak/web was used
 * but it did not return any information about initialization errors.
 * To intercept the status correctly, we have implemented a state machine.
 * The statuses 'UNKNOWN', 'INITIALIZATION_FAILED', 'AUTHENTICATED', 'NOT_AUTHENTICATED' are defined here.
 */

let count = 0
let timeout: NodeJS.Timeout

// eslint-disable-next-line sonarjs/cognitive-complexity
const useKeycloak = (defaultKeycloak?: Keycloak): UseKeycloakReturnType => {
  const [status, setStatus] = useState<KeycloakStatus>('UNKNOWN')
  const [keycloak, setKeycloak] = useState<Keycloak | undefined>()
  const [accessToken, setAccessToken] = useLocalStorage<string | undefined>(
    'kc_access_token'
  )

  const [refreshToken, setRefreshToken] = useLocalStorage<string | undefined>(
    'kc_refresh_token'
  )

  const [idToken, setIdToken] = useLocalStorage<string | undefined>(
    'kc_id_token'
  )

  useEffect(() => {
    if (typeof window !== 'undefined' || defaultKeycloak) {
      if (defaultKeycloak) {
        if (count === 0) {
          count++
          setKeycloak(defaultKeycloak)
        }
      } else if (!keycloak) {
        import('keycloak-js').then(({ default: Keycloak }) => {
          const newKeycloak = new Keycloak({
            realm: 'tonies',
            'ssl-required': 'external',
            'public-client': true,
            'confidential-port': 0,
            clientId: 'shop-uk-frontend',
            url: `${process.env.NEXT_PUBLIC_SSO_URL}/auth/`,
          } as KeycloakConfig)
          setKeycloak(newKeycloak)
        })
      }
    }
  }, [keycloak, defaultKeycloak])

  const updateTokens = useCallback(() => {
    if (keycloak) {
      setAccessToken(keycloak.token)
      setRefreshToken(keycloak.refreshToken)
      setIdToken(keycloak.idToken)
    }
  }, [keycloak, setAccessToken, setRefreshToken, setIdToken])

  const setNotAuthenticated = () => setStatus('NOT_AUTHENTICATED')
  const thirdPartyEnabled = useCheckThirdPartyCookies()

  useEffect(() => {
    if (status === 'UNKNOWN' && keycloak) {
      timeout = setTimeout(() => {
        // We assume that if Keycloak doesn't respond within 5000 ms
        // then Keycloak isn't accessible.
        setStatus(status => {
          return ['INITIALIZATION_STARTED', 'UNKNOWN'].includes(status)
            ? 'INITIALIZATION_FAILED'
            : status
        })
      }, 5000)

      if (thirdPartyEnabled !== null) {
        setStatus('INITIALIZATION_STARTED')

        keycloak
          .init({
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: `${window.origin}/_assets/silent-sso.html`,
            token: accessToken,
            refreshToken,
            idToken,
            enableLogging:
              process.env.NEXT_PUBLIC_ENVIRONMENT === 'local' ||
              process.env.NEXT_PUBLIC_ENVIRONMENT === 'preview' ||
              process.env.NEXT_PUBLIC_ENVIRONMENT === 'dev',
            checkLoginIframe: thirdPartyEnabled,
          })
          .then((isAuthenticated: boolean) => {
            keycloak.onAuthLogout = () => {
              setNotAuthenticated()
            }

            if (isAuthenticated) {
              // Necessary for Keycloak-Magic
              // if localStorage validation is used during initialization
              keycloak
                .updateToken(0)
                .then(() => {
                  updateTokens()
                  setStatus('AUTHENTICATED')
                })
                .catch(() => {
                  // User has no valid session
                  // Token can't be updated
                  // Furthermore the caught error is always 'true'
                  setNotAuthenticated()
                })
            } else {
              setNotAuthenticated()
            }

            // Clear the timeout once keycloak sent a response
            clearTimeout(timeout)
          })
          .catch((error: Error) => {
            // Keycloak throws custom plain objects instead of proper
            // error instances with stack traces ¯\_(ツ)_/¯
            //
            // This leads to the shape of those looks like this:
            // ```
            // { error: string, error_description: string | undefined }
            // ```
            if (error && 'error' in error && 'error_description' in error) {
              error = new Error(`${error.error}: ${error.error_description}`)
            }
            const sentryError =
              error instanceof Error
                ? error
                : new Error(normalizeErrorMessage(error))
            sentryWithExtras('keycloak', sentryError)

            setStatus('INITIALIZATION_FAILED')
            // Clear the timeout once keycloak sent a response
            clearTimeout(timeout)
          })
      }
    }
  }, [
    keycloak,
    accessToken,
    idToken,
    refreshToken,
    status,
    thirdPartyEnabled,
    updateTokens,
  ])

  if (keycloak) {
    keycloak.onTokenExpired = () => {
      keycloak
        .updateToken(0)
        .then(() => updateTokens())
        .catch(() => {
          // User has no valid session
          // Token can't be updated
          // Furthermore the caught error is always 'true'
          setNotAuthenticated()
        })
    }
  }

  return {
    status,
    setNotAuthenticated,
    accessToken,
    refreshToken,
    idToken,
    keycloak,
  }
}

export default useKeycloak
