import { createContext, Dispatch, SetStateAction, useContext } from 'react'

export type DiscountCodeInputContextProps = {
  discountCodeInput: string | undefined
  setDiscountCodeInput: Dispatch<SetStateAction<string | undefined>>
}

export const DiscountCodeInputContext =
  createContext<DiscountCodeInputContextProps>({
    discountCodeInput: undefined,
    setDiscountCodeInput: () => undefined,
  })

export const useDiscountCodeInput = () => useContext(DiscountCodeInputContext)
