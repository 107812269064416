import { useCallback } from 'react'
import { EcomLocale } from '../../../../config/shopAPI/types'
import { useAuth } from '../../../../providers/auth'
import { useEcomLocale } from '../../../../providers/locale'
import { fetch } from '../../util/fetch'
import { useShopLocaleConfig } from '../../../../hooks/useShopLocaleConfig'
import { isNotUndefined } from '../../../../utils/isNotUndefined'

export type TunesItemCheckData = {
  boughtTunes: string[]
  compatibleTunes: string[]
}

export type CompatibleTonie = {
  id: string
  salesId: string
  tonieType: string
  title: string
  imageUrl: string
  householdId: string
  tune?: {
    id: string
    item: { title: string }
  }
}

export type TuneItemNode = {
  node: {
    id: string
    title: string
    allCreativeTonies: boolean
    compatibleTonies: CompatibleTonie[]
    myTune?: {
      id: string
      assignCountCurrent: number
      assignedTonies: Array<{ id: string; title: string }>
    }
  }
}
export type SingleTunesItemCheckData = {
  tunesItems: {
    edges: TuneItemNode[]
  }
}

export type TunesItemCheckRequest = {
  additionalTonieSalesIdsInCart?: string[]
} & (
  | {
      tunesItemIds: string[]
      tunesItemSalesIds?: never
    }
  | {
      tunesItemIds?: never
      tunesItemSalesIds: string[]
    }
)
export type TunesStatus = 'invalid' | 'bought' | 'compatible' | undefined

export type GetCheckTunesStatus = TunesItemCheckRequest & {
  lcCC: EcomLocale
}

/**
 * Returns a list of
 *  - `boughtTunes` as Array of `UUID` and
 *  - `compatibleTunes` as Array of `salesId`
 * based on the given `tunesItemIds` or `tunesItemSalesIds`.
 *
 * The check for compatible Tunes includes Content Tonies
 * already in Household as well as `additionalTonieSalesIdsInCart`
 *
 * @param {EcomLocale}lcCC
 * @param {string[]}tunesItemIds Array of `UUID` of Tunes
 * @param {string[]}tunesItemSalesIds Array of `salesId` of Tunes
 * @param {string[]}additionalTonieSalesIdsInCart Array of `salesId` of ContentTonies in Cart
 * @returns
 */
export const getCheckTunesStatus = ({
  lcCC,
  tunesItemIds,
  tunesItemSalesIds,
  additionalTonieSalesIdsInCart,
}: GetCheckTunesStatus) => {
  const request: TunesItemCheckRequest = {
    additionalTonieSalesIdsInCart:
      additionalTonieSalesIdsInCart?.length === 0
        ? undefined
        : [...new Set(additionalTonieSalesIdsInCart)],
    ...(tunesItemSalesIds
      ? { tunesItemSalesIds: [...new Set(tunesItemSalesIds)] }
      : { tunesItemIds: [...new Set(tunesItemIds)] }),
  }
  return fetch<TunesItemCheckData, TunesItemCheckRequest>({
    query: '/check-tune-status',
    lcCC,
    method: 'post',
    request,
  })
}

const getIds = (additionalProducts: NormalizedProduct[]) =>
  additionalProducts.map(p => p.audioLibraryId).filter(isNotUndefined)

// use fully cart-lineItems + enrich response with useful shop-frontent data
export const getCheckTunesStatusExtended = async (
  lcCC: EcomLocale,
  additionalProducts: NormalizedProduct[],
  products: NormalizedProduct[] = []
) => {
  // get all Creative-Tonies from the cart
  const creativeToniesLineItems = products?.filter(
    p => p.shopCategory === 'creative-tonies'
  )

  // filter all Tonies ids from the cart
  const tonieLineItemsSalesIds =
    (products
      ?.filter(p => p.shopCategory === 'tonies')
      .map(p => p.salesId)
      .filter(id => typeof id !== undefined) as string[]) || []

  // filter all tunes ids from the cart
  const tuneLineItemsUUIDs =
    (products
      ?.filter(p => p.shopCategory === 'tunes')
      .map(p => p.audioLibraryId)
      .filter(id => typeof id !== undefined) as string[]) || []

  // combine cart-tunes + manual id
  const tunesItemIds = [
    ...new Set([...getIds(additionalProducts), ...tuneLineItemsUUIDs]),
  ]

  const isCompatibleByCTinCart = (p: NormalizedProduct | undefined) =>
    Boolean(creativeToniesLineItems?.length && p?.assignableToAllCreativeTonies)

  if (tunesItemIds && tunesItemIds?.length !== 0) {
    const { result, data } = await getCheckTunesStatus({
      lcCC,
      tunesItemIds,
      additionalTonieSalesIdsInCart: tonieLineItemsSalesIds,
    })

    if (result === 'successful') {
      const newObj = tunesItemIds.map(id => {
        // find NormalizedProduct in passed products (Cart LineItems + additionalProducts) to get information about assignableToAllCreativeTonies
        const np = [...products, ...additionalProducts]?.find(
          p => p.audioLibraryId === id
        )

        return {
          id,
          isCompatible: Boolean(
            data.compatibleTunes.includes(id) || isCompatibleByCTinCart(np)
          ),
          isBought: data.boughtTunes.includes(id),
        }
      })

      const getTuneStatus = (product: NormalizedProduct): TunesStatus => {
        const tune =
          product.shopCategory === 'tunes' &&
          newObj.find(i => i.id === product.audioLibraryId)

        if (!tune) return undefined

        return tune.isBought
          ? 'bought'
          : tune.isCompatible
          ? 'compatible'
          : 'invalid'
      }

      /**
       * enrich return with useful infos
       * @returns {
       *  items: {
       *    id: string,
       *    valid: boolean,
       *  }[],
       *  allValid: boolean,
       *  isTuneValid: (id) => boolean
       * }
       */
      return {
        items: newObj,
        allValid: newObj?.every(i => i.isCompatible || i.isBought),
        getTuneStatus,
      }
    }

    return undefined
  }
}

export interface TunesItemStatus {
  id: string
  isCompatible: boolean
  isBought: boolean
}
export interface CheckTunesStatus {
  items: TunesItemStatus[]
  allValid: boolean
  getTuneStatus: (product: NormalizedProduct) => TunesStatus
}

// use hooks to prevent manual setting
export const useCheckTunesStatus = (
  products: NormalizedProduct[] | undefined
) => {
  const lcCC = useEcomLocale()
  const { authenticated } = useAuth()
  const shopGlobalLocaleConfig = useShopLocaleConfig()

  return useCallback(
    async (
      additionalProducts: NormalizedProduct[] = []
    ): Promise<CheckTunesStatus | undefined> => {
      return shopGlobalLocaleConfig?.showTunes && authenticated
        ? await getCheckTunesStatusExtended(lcCC, additionalProducts, products)
        : undefined
    },
    [shopGlobalLocaleConfig, products, authenticated, lcCC]
  )
}
