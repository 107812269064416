import React from 'react'
import { DefaultMotionSpanElement } from '@/tonies-ui/motions/constants'

export const ZoomIt: NormalizedMotionComponent = ({ children, ...rest }) => {
  return (
    <DefaultMotionSpanElement
      data-testid="zoom-it-motion"
      variants={{
        zoomOut: {
          bottom: '-5%',
          scale: 0.9,
          opacity: 0.2,
        },
        zoomIn: {
          scale: 1.1,
          opacity: 0.25,
        },
        reset: {
          bottom: 0,
          scale: 1,
          opacity: 1,
          transition: {
            duration: 0.75,
          },
        },
      }}
      initial="zoomOut"
      whileInView="reset"
      {...rest}
    >
      {children}
    </DefaultMotionSpanElement>
  )
}
