import React, {
  FunctionComponent,
  useState,
  useRef,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
  PropsWithChildren,
  useMemo,
} from 'react'
import makeDebug from 'debug'
import { useAuth } from '../auth'
import { usePayment } from '../../hooks/usePayment'
import { useAddresses } from '../addresses'
import { useShipping } from '../../hooks/useShipping'
import { CheckoutContext } from './CheckoutContext'
import { useCartActions } from '../../hooks/useCartActions'
import { useSyncCartStateWithCookies } from '../../hooks/useSyncCartStateWithCookies'
import { useSyncCartStateWithAuth } from '../../hooks/useSyncCartStateWithAuth'
import { useCartState } from '../../hooks/useCartState'
import { Message } from '../../layouts/LoadingLayout'
import { useTranslation } from 'next-i18next'
import { useUrlQuery } from '../urlQuery'
import {
  getCheckoutConfig,
  defaultConfirmedSteps,
  defaultIsChecked,
} from './config'
import {
  CheckboxVariant,
  CheckoutIndicatorStep,
  CheckoutStep,
  CheckoutStepRefProps,
  ConfirmedStepsState,
  IsCheckedCollection,
} from './config/types'
import { useIsViewportDesktop } from '@boxine/tonies-ui'
import { useShopAPIErrorHandling } from '../../hooks/useShopAPIErrorHandling'

export const useCheckoutConfig = () => {
  const { cart } = useCartState()
  return {
    ...getCheckoutConfig({
      normalizedCartType: cart?.normalizedCartType || null,
      bxLineItemTypes: cart?.bxLineItemTypes || [],
    }),
  }
}

export const CheckoutContextProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const debug = makeDebug('CheckoutContext')
  const { t } = useTranslation()
  const { cartViolations } = useShopAPIErrorHandling()
  const { resetError: resetCartActionsError } = useCartActions()
  const isDesktop = useIsViewportDesktop()
  const [currentStep, setCurrentStep] = useState<CheckoutStep>('login')
  const prevCurrentStep = useRef<CheckoutStep | undefined>()
  const config = useCheckoutConfig()
  const { user } = useAuth()
  const urlQuery = useUrlQuery()
  const paymentRedirectResult =
    urlQuery.getUrlParamAsSingleton('redirectResult')
  const {
    paymentStatus,
    submitAdditionalPaymentDetails,
    pendingOperations,
    initializePayment,
  } = usePayment()
  const {
    isReadOnly: isReadOnlyAddresses,
    isContainerValid: isContainerValidAddresses,
    setIsReadOnly: setIsReadOnlyAddresses,
    isPending: isPendingAddresses,
    hasCartAddresses,
  } = useAddresses()
  const hasSelectedAddress = isReadOnlyAddresses && hasCartAddresses
  const { hasCartShipping: hasShipping, setIsReadOnly: setIsReadOnlyShipping } =
    useShipping()
  const { state: cartActionsState } = useCartActions()
  const { state: cookieSyncState } = useSyncCartStateWithCookies()
  const { state: authSyncState } = useSyncCartStateWithAuth()
  const [orderId, setOrderId] = useState<string | undefined>()
  const [orderNumber, setOrderNumber] = useState<string | undefined>()
  const [orderError, setOrderError] = useState<string | undefined>()
  const [focusOn, setFocusOn] =
    useState<React.RefObject<HTMLDivElement> | null>(null)
  const [isGuestCheckout, setIsGuestCheckout] = useState(false)
  const [counterDebug, setCounterDebug] = useState(0)
  const [isReadOnlyOrderReview, setIsReadOnlyOrderReview] = useState(false)
  const [, setIsReadOnlyLogin] = useState(false)
  const [, setIsReadOnlyPayment] = useState(false)
  const [editOrderReview, setEditOrderReview] = useState(false)
  const { cart } = useCartState()
  const cartId = cart?.id

  // explicit checkout Step handling
  const [confirmedSteps, setConfirmedSteps] = useState<ConfirmedStepsState>(
    () => ({ ...defaultConfirmedSteps, addresses: hasSelectedAddress })
  )

  type IsReadOnlySetterCollection = {
    // FIXME: use partial key typing for missing `login` and `payment` setters
    [key in CheckoutStep]: Dispatch<SetStateAction<boolean>>
  }
  const isReadOnly: IsReadOnlySetterCollection = useMemo(
    () => ({
      addresses: setIsReadOnlyAddresses,
      shipping: setIsReadOnlyShipping,
      review: setIsReadOnlyOrderReview,
      login: setIsReadOnlyLogin,
      payment: setIsReadOnlyPayment,
    }),
    [
      setIsReadOnlyAddresses,
      setIsReadOnlyShipping,
      setIsReadOnlyOrderReview,
      setIsReadOnlyLogin,
      setIsReadOnlyPayment,
    ]
  )

  const [stepIndicatorReadOnly, setStepIndicatorReadOnly] = useState(false)
  const indicatorSteps: CheckoutIndicatorStep[] = config.indicatorSteps.map(
    ({ key, order }) => ({
      key,
      label: t(`checkout:stepIndicator:${key}Label`),
      order,
      state: confirmedSteps[`${key as CheckoutStep}`] ? 'done' : undefined,
    })
  )

  const currentStepIndicatorStep =
    config.indicatorSteps
      .filter(({ key }) => key === currentStep)
      .map(({ order }) => order)[0] || 0
  /**
   * handler to set a checkout step as confirmed by the user
   */
  const confirmStep = useCallback(
    (step: CheckoutStep) => {
      resetCartActionsError()
      isReadOnly[step](true)
      setConfirmedSteps(currentSteps => ({ ...currentSteps, [step]: true }))
    },
    [isReadOnly, resetCartActionsError]
  )

  const editStep = useCallback(
    (step: CheckoutStep) => {
      setCurrentStep(step)
      isReadOnly[step](false)
      setConfirmedSteps(currentSteps => ({ ...currentSteps, [step]: false }))
    },
    [isReadOnly]
  )

  /**
   * checkbox state
   */
  const [isChecked, setIsChecked] = useState<IsCheckedCollection>(
    () => defaultIsChecked
  )

  const toggleCheckbox = (box: CheckboxVariant) => {
    setIsChecked(currentBoxes => ({
      ...currentBoxes,
      [box]: !currentBoxes[box],
    }))
  }

  const tickMandatoryCheckboxes = useCallback(() => {
    setIsChecked(currentBoxes => {
      for (const [key] of Object.entries(currentBoxes)) {
        if (
          config.orderReview.checkboxes[key as CheckboxVariant]
            .isMandatoryForCheckout
        )
          currentBoxes[key as CheckboxVariant] = true
      }
      return currentBoxes
    })
  }, [config.orderReview.checkboxes])

  // Ready to render payment component
  const isReadyForPayment =
    confirmedSteps.addresses && confirmedSteps.shipping && confirmedSteps.review

  // payment status
  const hasAuthorizedPayment = paymentStatus === 'payment-authorized'
  const noPaymentNeeded = paymentStatus === 'payment-not-necessary'

  /**
   * if the payment is not finalized and we have paymentRedirectResult
   * we want to directly go back to stepPayment
   */
  const presentPaymentRedirectResult =
    (paymentStatus === 'payment-redirect-shopper' && !!paymentRedirectResult) ||
    paymentStatus === 'error'

  // place order state,
  // set manually if `payment-not-necessary` and user has clicked `place order` in PaymentInfoTeaser
  const [hasPlacedOrder, setHasPlacedOrder] = useState(false)
  const paymentDone =
    hasAuthorizedPayment || (noPaymentNeeded && hasPlacedOrder)
  const isOrderReady = isReadyForPayment && paymentDone
  const isLoggedIn = Boolean(isGuestCheckout || user)

  const [isPending, setIsPending] = useState(true)
  const [isPendingMessage, setIsPendingMessage] = useState<Message | undefined>(
    undefined
  )

  useEffect(() => {
    if (!isPending) {
      setIsPendingMessage(undefined)
    } else if (cartActionsState !== 'idle') {
      setIsPendingMessage({
        text: t('checkoutPage:state:fetchingCart'),
        type: 'progress',
      })
    } else if (cookieSyncState !== 'finished') {
      setIsPendingMessage({
        text: t('checkoutPage:state:fetchingCookie'),
        type: 'progress',
      })
    } else if (authSyncState !== 'finished') {
      setIsPendingMessage({
        text: t('checkoutPage:state:checkingAuthentication'),
        type: 'progress',
      })
    } else if (paymentRedirectResult) {
      setIsPendingMessage({
        text: t('checkoutPage:state:fetchingPaymentResults'),
        type: 'progress',
      })
    }
    if (
      cartActionsState === 'idle' &&
      cookieSyncState === 'finished' &&
      authSyncState === 'finished' &&
      !isPendingAddresses &&
      !paymentRedirectResult &&
      isPending
    ) {
      setIsPending(false)
    }
  }, [
    cartActionsState,
    cookieSyncState,
    authSyncState,
    isPending,
    t,
    paymentRedirectResult,
    isPendingAddresses,
  ])

  const [pageRef, setPageRef] = useState(useRef<HTMLDivElement>(null))
  const [orderSummaryRef, setOrderSummaryRef] = useState(
    useRef<HTMLDivElement>(null)
  )
  const [loginRef, setLoginRef] = useState(useRef<HTMLDivElement>(null))
  const [addressesRef, setAddressesRef] = useState(useRef<HTMLDivElement>(null))
  const [shippingRef, setShippingRef] = useState(useRef<HTMLDivElement>(null))
  const [paymentRef, setPaymentRef] = useState(useRef<HTMLDivElement>(null))
  const [orderReviewRef, setOrderReviewRef] = useState(
    useRef<HTMLDivElement>(null)
  )

  const checkoutStepRef: CheckoutStepRefProps = useMemo(
    () => ({
      login: {
        ref: loginRef,
        nextRefName: 'addresses',
      },
      addresses: {
        ref: addressesRef,
        nextRefName: confirmedSteps.addresses
          ? hasShipping
            ? confirmedSteps.shipping
              ? confirmedSteps.review
                ? 'payment'
                : 'review'
              : 'shipping'
            : confirmedSteps.review
            ? 'payment'
            : 'review'
          : 'addresses',
      },
      shipping: {
        ref: shippingRef,
        nextRefName: confirmedSteps.shipping
          ? hasSelectedAddress
            ? confirmedSteps.review
              ? 'payment'
              : 'review'
            : 'addresses'
          : 'shipping',
      },
      review: {
        ref: orderReviewRef,
        nextRefName: confirmedSteps.review
          ? hasSelectedAddress
            ? confirmedSteps.shipping
              ? 'payment'
              : 'shipping'
            : 'addresses'
          : 'review',
      },
      payment: {
        ref: paymentRef,
        nextRefName: 'payment',
      },
    }),
    [
      loginRef,
      paymentRef,
      shippingRef,
      orderReviewRef,
      addressesRef,
      hasShipping,
      hasSelectedAddress,
      confirmedSteps.review,
      confirmedSteps.shipping,
      confirmedSteps.addresses,
    ]
  )
  const goToNextStep = useCallback(() => {
    // payment is the last step
    if (currentStep === 'payment') return
    setCurrentStep(current => {
      return checkoutStepRef[current].nextRefName
    })
  }, [checkoutStepRef, currentStep])

  useEffect(() => {
    debug('new CheckoutProvider State:', {
      currentStep,
      nextStep: checkoutStepRef[currentStep].nextRefName,
      confirmedSteps,
      isChecked,
    })
  }, [checkoutStepRef, confirmedSteps, currentStep, isChecked, debug])
  useEffect(() => {
    /**
     * if the cart does not have shipping (i.e. tunes) we assume
     * the step shipping has been confirmed
     */
    if (!hasShipping && !confirmedSteps.shipping) {
      confirmStep('shipping')
    }
  }, [confirmStep, confirmedSteps.shipping, currentStep, hasShipping])
  useEffect(() => {
    /**
     * if the cart does have valid addresses on firstRender we assume
     * the step addresses has been confirmed
     */
    if (
      isContainerValidAddresses &&
      isReadOnlyAddresses &&
      !confirmedSteps.addresses
    ) {
      confirmStep('addresses')
    }
  }, [
    confirmStep,
    confirmedSteps.addresses,
    currentStep,
    isContainerValidAddresses,
    isReadOnlyAddresses,
  ])
  useEffect(() => {
    if (confirmedSteps[currentStep] && currentStep !== 'payment') {
      debug(
        'goToNextStep from: ' + currentStep + ' because it is already confirmed'
      )
      goToNextStep()
    }
    if (isGuestCheckout) {
      setCurrentStep(
        !confirmedSteps.addresses
          ? 'addresses'
          : confirmedSteps.addresses && !confirmedSteps.shipping
          ? 'shipping'
          : confirmedSteps.shipping && !confirmedSteps.review
          ? 'review'
          : 'payment'
      )
    }
  }, [
    confirmedSteps.login,
    confirmedSteps.addresses,
    confirmedSteps.shipping,
    confirmedSteps.review,
    currentStep,
    goToNextStep,
    confirmedSteps,
    debug,
    isGuestCheckout,
  ])

  useEffect(() => {
    if (user && currentStep === 'login') {
      confirmStep('login')
      debug(
        'confirm Step login: CheckoutProvider user && currentStep === login'
      )
    }
    if (!cartId || cartActionsState === 'processing') return
    if (presentPaymentRedirectResult) {
      debug('presentRedirectResult', {
        paymentStatus,
        isReadyForPayment,
      })
      if (!user) setIsGuestCheckout(true)
      if (!confirmedSteps.addresses) confirmStep('addresses')
      if (!confirmedSteps.shipping) confirmStep('shipping')
      tickMandatoryCheckboxes()
      if (!confirmedSteps.review) confirmStep('review')
      setCurrentStep('payment')
      paymentRef.current &&
        paymentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
    }
    if (
      paymentStatus === 'error' ||
      paymentStatus === 'payment-challenge-shopper' ||
      paymentStatus === 'payment-identify-shopper'
    ) {
      paymentRef.current &&
        paymentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
    }

    const handlePaymentRedirectResult = async () => {
      setIsPending(true)
      try {
        urlQuery.setUrlParam({ redirectResult: undefined })
        await submitAdditionalPaymentDetails({
          details: { redirectResult: paymentRedirectResult },
        })
      } catch (error) {
        console.error(error)
      }
    }

    if (paymentRedirectResult?.length && !pendingOperations) {
      handlePaymentRedirectResult()
    }
  }, [
    cartActionsState,
    cartId,
    confirmStep,
    confirmedSteps.addresses,
    confirmedSteps.review,
    confirmedSteps.shipping,
    currentStep,
    goToNextStep,
    debug,
    isReadyForPayment,
    paymentRedirectResult,
    paymentRef,
    paymentStatus,
    pendingOperations,
    presentPaymentRedirectResult,
    submitAdditionalPaymentDetails,
    tickMandatoryCheckboxes,
    urlQuery,
    user,
  ])

  useEffect(() => {
    const onlyAddressViolations =
      cartViolations?.filter(v => !v.propertyPath.indexOf('address')).length ===
      0

    if (currentStep === 'addresses' && onlyAddressViolations) {
      return
    }
    if (currentStep !== 'review' && cartViolations) {
      editStep('review')
    }
  }, [cartViolations, currentStep, editStep])

  type ScrollOptions = {
    delay: number
    behavior: ScrollBehavior
  }
  const scrollOptions: ScrollOptions = { delay: 500, behavior: 'smooth' }

  const scrollToPageStart = useCallback(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        left: 50,
        behavior: scrollOptions.behavior,
      })
    }
  }, [scrollOptions.behavior])

  const setFocusStep = useCallback(
    ({
      toTop = false,
      stepKey = 'addresses',
    }: {
      toTop: boolean
      stepKey: CheckoutStep
    }) => {
      const currentRef = checkoutStepRef[stepKey].ref.current
      if (typeof window !== 'undefined' && toTop) {
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: scrollOptions.behavior,
          })
        }, scrollOptions.delay)
      }

      if (currentRef) {
        if (isDesktop && currentRef === addressesRef?.current) {
          setTimeout(() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: scrollOptions.behavior,
            })
          }, scrollOptions.delay)
        } else {
          const nextRef = currentRef
          setTimeout(() => {
            nextRef &&
              nextRef.scrollIntoView({
                behavior: scrollOptions.behavior,
                block: 'center',
              })
          }, scrollOptions.delay)
        }
      }
    },
    [
      checkoutStepRef,
      scrollOptions.delay,
      scrollOptions.behavior,
      isDesktop,
      addressesRef,
    ]
  )

  useEffect(() => {
    if (!prevCurrentStep) {
      scrollToPageStart()
    }
    if (currentStep === 'login' || currentStep === 'addresses') {
      scrollToPageStart()
    }
  }, [currentStep, isGuestCheckout, scrollToPageStart])

  useEffect(() => {
    // If there is no payment yet: initiate one
    if (
      paymentStatus === 'payment-missing' &&
      isReadyForPayment &&
      !pendingOperations
    ) {
      /**
       * if there is no valid payment currently available on the cart,
       * we have to initialize a new Payment
       */
      initializePayment()
    }
  }, [isReadyForPayment, paymentStatus, initializePayment, pendingOperations])

  return (
    <CheckoutContext.Provider
      value={{
        hasSelectedAddress,
        confirmedSteps,
        confirmStep,
        editStep,
        hasShipping,
        hasPayment: hasAuthorizedPayment,
        noPaymentNeeded,
        hasPlacedOrder,
        setHasPlacedOrder,
        isLoggedIn,
        isReadyForPayment,
        isOrderReady,
        isPending,
        isPendingMessage,
        useCounterDebug: [counterDebug, setCounterDebug],
        useIsGuestCheckout: [isGuestCheckout, setIsGuestCheckout],
        orderId,
        orderNumber,
        setOrderId,
        setOrderNumber,
        orderError,
        setOrderError,
        isChecked,
        toggleCheckbox,
        focusOn,
        setFocusOn,
        pageRef,
        setPageRef,
        orderSummaryRef,
        setOrderSummaryRef,
        loginRef,
        setLoginRef,
        addressesRef,
        setAddressesRef,
        shippingRef,
        setShippingRef,
        paymentRef,
        setPaymentRef,
        orderReviewRef,
        setOrderReviewRef,
        isReadOnlyOrderReview,
        setIsReadOnlyOrderReview,
        editOrderReview,
        setEditOrderReview,
        config,
        paymentRedirectResult,
        currentStep,
        setCurrentStep,
        checkoutStepRef,
        scrollToPageStart,
        cartViolations,
        indicatorSteps,
        stepIndicatorReadOnly,
        setStepIndicatorReadOnly,
        currentStepIndicatorStep,
        setFocusStep,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  )
}
