import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useStateRef } from 'use-state-ref'
import { useTranslation } from 'next-i18next'
import { toast, toastify } from '@/tonies-ui/atoms/Toast'
import { useCartState } from '../../hooks/useCartState'
import { sentryWithExtras } from '../../hooks/useSentry'
import {
  PaymentContext,
  PaymentStatus,
  ErrorMessage,
  PaymentMethodsResponseObject,
  PaymentResults,
  PaymentType,
} from './types'
import {
  isRedirectShopper,
  isChallengeShopper,
  isIdentifyShopper,
  isAuthorized,
  isAuthorizing,
  isPaymentError,
  isPaymentMethods,
  getCurrentPaymentResponse,
  hasPayment,
  parsePaymentType,
  getPaymentErrorMessage,
  getPaymentOriginError,
  parseGetPaymentCountryCode,
  getMakePaymentResultCode,
  getSubmitAdditionalPaymentDetailsResultCode,
  parseAvailablePaymentMethods,
} from './lib'
import { makePaymentForCartAction } from '../cartActions/actions/makePaymentForCartAction'
import { submitAdditionalPaymentDetailsForCartAction } from '../cartActions/actions/submitAdditionalPaymentDetailsForCartAction'
import { useCartActions } from '../../hooks/useCartActions'
import { emptyPaymentMethodResponseObject, paymentContext } from '.'
import { usePaymentTypes } from '../../hooks/usePaymentTypes'
import { createPaymentForCartAction } from '../cartActions/actions/createPaymentForCartAction'
import { Payment } from '@commercetools/platform-sdk'
import { PaymentResponse } from '../../lib/commercetools/requests/payments/types'
import { FetchResult } from '../../lib/commercetools/util/fetch'
import { useShopAPIErrorHandling } from '../../hooks/useShopAPIErrorHandling'
import { useAuth } from '../../providers/auth'
import { useCheckout } from '../../hooks/useCheckout'

export const PaymentProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  // Error texts are translated per locale
  const { t } = useTranslation()
  const { region: geoIpRegion } = useAuth()
  // get blocked PaymentTypes from configCat
  const { getBlockedPaymentTypes } = usePaymentTypes()
  const blockedPaymentTypes = getBlockedPaymentTypes('checkout')
  const { error: shopAPIError } = useShopAPIErrorHandling()

  // store toast id in state to be able to dismiss toast when the error resolves
  const [errorToastId, setErrorToastId] = useState<string | number>('')
  // Determine payment status
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>('pending')

  const [pendingOperations, setPendingOperations] = useState(0)

  // Error handling for refusals or network errors
  //  error shown in toasts and handle payment error state
  //  origin errors are currently only pushed to sentry
  const [error, setError] = useState<Error>()

  const prevError = useRef<Error>()
  // errorMessage State
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>(undefined)
  const prevErrorMessage = useRef<ErrorMessage>()

  //
  // Determine cart status
  //
  const { cart } = useCartState()
  const currentPaymentResponse = useMemo(
    () => cart?.currentPaymentResponse,
    [cart?.currentPaymentResponse]
  )
  const cartCurrentPayment: Payment | undefined = useMemo(
    () => cart?.currentPayment || undefined,
    [cart?.currentPayment]
  )
  const cartCurrentPaymentType = parsePaymentType({
    fields: cart?.currentPaymentResponse?.custom?.fields || undefined,
    blockedPaymentTypes: blockedPaymentTypes || [],
  })
  const cartCountryCode: string | undefined = parseGetPaymentCountryCode(
    cartCurrentPayment?.custom?.fields || undefined
  )
  const cartAvailablePaymentMethods: PaymentType[] | undefined =
    parseAvailablePaymentMethods(
      cartCurrentPayment?.custom?.fields || undefined
    )
  const [countryCode, setCountryCode] = useState<string | undefined>(
    cartCountryCode
  )
  const { push: queue, state: cartActionState } = useCartActions()
  const { orderId } = useCheckout()
  const cartCurrency = cart?.price.total?.currency

  const cartCentAmount = useMemo(
    () => cart?.price.total?.centAmount || 0,
    [cart?.price.total?.centAmount]
  )
  const cartCurrentPaymentCentAmount: number | undefined = useMemo(
    () => cart?.currentPayment?.amountPlanned.centAmount,
    [cart?.currentPayment?.amountPlanned.centAmount]
  )

  // As a non-React component, the dropin (and its handlers) cannot process
  // payment changes through state; any change would result in a re-render
  // and data loss inside the Dropin; `useStateRef` gives us an additional,
  // self-updating reference (as in useRef) which we can safely pass to the
  // Dropin. We can't rely on `useRef` alone because everything else is built
  // in React and hence absolutely relies on state updates / notifications.
  const paymentRef = useStateRef(currentPaymentResponse)

  // Indicate a Order that don't need a payment
  const readyForOrder = cart?.flags.isReadyForOrder === true

  const isCartComplete = cartCentAmount > 0
  // Handle carts with a amount of 0 (for example: 100% vouchers are applied)
  const cartPaymentNotNecessary = cartCentAmount === 0 && readyForOrder

  const [makePaymentResultCode, setMakePaymentResultCode] = useState<
    PaymentResults['resultCode'] | undefined
  >(
    () =>
      currentPaymentResponse && getMakePaymentResultCode(currentPaymentResponse)
  )

  const [
    submitAdditionalPaymentDetailsResultCode,
    setSubmitAdditionalPaymentDetailsResultCode,
  ] = useState<PaymentResults['resultCode'] | undefined>(
    () =>
      currentPaymentResponse &&
      getSubmitAdditionalPaymentDetailsResultCode(currentPaymentResponse)
  )

  /**
   * update paymentResponses when currentPayment changes
   */
  useEffect(() => {
    if (currentPaymentResponse) {
      setMakePaymentResultCode(getMakePaymentResultCode(currentPaymentResponse))
      setSubmitAdditionalPaymentDetailsResultCode(
        getSubmitAdditionalPaymentDetailsResultCode(currentPaymentResponse)
      )
    }
  }, [currentPaymentResponse])

  const originError = useRef<PaymentResults | undefined>()
  /**
   * Update current payment when cart total changes
   */
  const newestPayment = useMemo(
    () =>
      cart && hasPayment(cart) ? getCurrentPaymentResponse(cart) : undefined,
    [cart]
  )
  const needToSetCurrentPaymentResponse = useMemo(
    () => newestPayment !== paymentRef.current || !paymentRef.current,
    [newestPayment, paymentRef]
  )

  // localize errorMessage
  const localizeErrorMessage = useCallback(
    (message: ErrorMessage) => {
      if (message) {
        if (message.includes('Network Error')) {
          return t('checkout:payment:errorNetwork')
        } else if (message.includes('No payment methods')) {
          return t('checkout:payment:errorPaymentType')
        } else if (message.includes('Unable to determine variant')) {
          return t('checkout:payment:errorVariant')
        } else if (message.includes('Request failed with status code 409')) {
          return t('checkout:payment:errorDefault')
        } else if (message.includes('Request failed with status code 400')) {
          return t('checkout:payment:errorDefault')
        } else {
          return message
        }
      }
    },
    [t]
  )

  useEffect(() => {
    if (error && error !== prevError.current) {
      setErrorMessage(localizeErrorMessage(error.message))
      sentryWithExtras('payment', error, originError.current)
      prevError.current = error
      originError.current = undefined
    }
  }, [error, originError, t, localizeErrorMessage])

  // handle error Toast
  useEffect(() => {
    if (errorMessage && errorMessage !== prevErrorMessage.current) {
      if (errorToastId) toastify.dismiss(errorToastId)
      setErrorToastId(
        toast(t('checkout:payment:errorContext') + ': ' + errorMessage, 'error')
      )
      prevErrorMessage.current = errorMessage
    }
    if (!errorMessage && errorToastId) {
      toastify.dismiss(errorToastId)
      setErrorToastId('')
    }
  }, [errorMessage, errorToastId, error, t])

  const cartReadyForPaymentInit =
    cartCurrency && cartCentAmount && countryCode && !orderId

  /**
   * Callback to initialize a new payment in CommerceTools
   * with a list of available Payment Methods in Adyen
   */
  const initializePayment = useCallback<
    PaymentContext['initializePayment']
  >(async () => {
    if (!cartReadyForPaymentInit) return

    // Unset an error if the user likes to "try again"
    if (error) {
      setError(undefined)
      if (errorToastId) {
        toastify.dismiss(errorToastId)
        setErrorToastId('')
      }
    }
    setPendingOperations(n => n + 1)

    let newError: Error | undefined

    try {
      await queue(
        createPaymentForCartAction({
          blockedPaymentMethods: blockedPaymentTypes,
          geoIpRegion,
        })
      )
    } catch (e) {
      newError = e instanceof Error ? e : new Error('Cart Error: ' + e)
    }

    setPendingOperations(n => n - 1)

    if (newError) {
      setError(newError)
      console.error(newError)
    }
  }, [
    cartReadyForPaymentInit,
    error,
    errorToastId,
    queue,
    blockedPaymentTypes,
    geoIpRegion,
  ])

  /**
   * useEffect to re-init Payment only if:
   * - there is a cart & it has a currentPayment &
   * - there is a countryCode mismatch between billingAddress and currentPayment
   * - we are not already fetching new payments (!pendingOperations)
   * - the cartCentAmount changes (triggered by dependency Array of initializePayment() callback)
   */
  const countryCodeMatchesPaymentRequest = cartCountryCode === countryCode
  useEffect(() => {
    if (
      cart &&
      hasPayment(cart) &&
      cartCountryCode &&
      !countryCodeMatchesPaymentRequest &&
      !pendingOperations &&
      paymentStatus !== 'pending'
    ) {
      initializePayment()
    }
  }, [
    paymentStatus,
    cart,
    cartCountryCode,
    countryCode,
    countryCodeMatchesPaymentRequest,
    initializePayment,
    pendingOperations,
  ])
  useEffect(() => {
    /**
     * if the `cartCentAmount` is not 0 and `cartCurrentPaymentCentAmount`
     * is not undefined (so we do have a currentPayment) and both values do not
     * match then initialize a new payment if we are not already initializing
     * a payment indicated by the various `pending` states
     */
    if (
      cartCentAmount &&
      cartCurrentPaymentCentAmount !== undefined &&
      cartCentAmount !== cartCurrentPaymentCentAmount &&
      !pendingOperations &&
      paymentStatus !== 'pending' &&
      !shopAPIError
    ) {
      initializePayment()
    }
  }, [
    paymentStatus,
    cart,
    initializePayment,
    pendingOperations,
    shopAPIError,
    cartCentAmount,
    cartCurrentPaymentCentAmount,
    error,
  ])

  /**
   * Callback to authorize a previously initialized payment with Adyen
   */
  const authorizePayment = useCallback<PaymentContext['authorizePayment']>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (data: any) => {
      if (
        !paymentRef.current ||
        !paymentRef.current.amountPlanned?.centAmount ||
        !paymentRef.current.amountPlanned?.currencyCode
      ) {
        // No payment initialized
        return
      }

      setPendingOperations(n => n + 1)
      let newError: Error | undefined
      let paymentResponse: FetchResult<PaymentResponse>
      try {
        paymentResponse = await queue(
          makePaymentForCartAction({
            paymentId: paymentRef.current.id,
            paymentVersion: paymentRef.current.version,
            centAmount: paymentRef.current.amountPlanned.centAmount,
            currency: paymentRef.current.amountPlanned.currencyCode,
            data,
          })
        )
        if (paymentResponse.result !== 'successful') {
          setError(paymentResponse.error)
          setPendingOperations(n => n - 1)
          return
        }
        const makePaymentResponse = JSON.parse(
          paymentResponse.data.custom?.fields?.makePaymentResponse || ''
        )

        if (isPaymentError(makePaymentResponse)) {
          originError.current = getPaymentOriginError(makePaymentResponse)
          setError(new Error(getPaymentErrorMessage(makePaymentResponse)))
          setPendingOperations(n => n - 1)
          return makePaymentResponse
        }
        setPendingOperations(n => n - 1)
        return makePaymentResponse
      } catch (e) {
        newError = e instanceof Error ? e : new Error('Payment Error: ' + e)
      }
      if (newError) {
        setError(newError)
        console.error(newError)
        setPendingOperations(n => n - 1)
      }
    },
    [paymentRef, queue]
  )

  /**
   * Callback to attach additional information to a previously
   * authorized payment (e.g. Klarna redirection, PayPal modal response, 3D Secure token, ...)
   */
  const submitAdditionalPaymentDetails = useCallback<
    PaymentContext['submitAdditionalPaymentDetails']
  >(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (additionalPaymentDetails: any) => {
      if (
        !paymentRef.current ||
        cartCurrentPayment?.id !== paymentRef.current.id
      ) {
        // No payment initialized or mounted
        return
      }

      setPendingOperations(n => n + 1)

      let newError: Error | undefined
      let paymentResponse: FetchResult<PaymentResponse> | undefined
      try {
        paymentResponse = await queue(
          submitAdditionalPaymentDetailsForCartAction({
            additionalPaymentDetails,
          })
        )

        if (!paymentResponse) {
          const error = new Error('no submitAdditionalPaymentDetails response')
          setError(error)
          setPendingOperations(n => n - 1)
          return
        }
        if (paymentResponse.result !== 'successful') {
          setError(paymentResponse.error)
          setPendingOperations(n => n - 1)
          return
        }
        /**
         * unexpected error but seen once (see TWAS-4716)
         * if we did not get back a submitAdditionalPaymentDetailsResponse from
         * the current request, we force creating a new payment
         */
        if (
          !paymentResponse.data.custom?.fields
            ?.submitAdditionalPaymentDetailsResponse
        ) {
          setError(new Error('submitAdditionalPaymentDetailsResponse missing'))
          setPendingOperations(n => n - 1)
          return
        }

        const submitAdditionalPaymentDetailsResponse = JSON.parse(
          paymentResponse.data.custom.fields
            .submitAdditionalPaymentDetailsResponse
        )

        if (isPaymentError(submitAdditionalPaymentDetailsResponse)) {
          originError.current = getPaymentOriginError(
            submitAdditionalPaymentDetailsResponse
          )
          setError(
            new Error(
              getPaymentErrorMessage(submitAdditionalPaymentDetailsResponse)
            )
          )
        }
      } catch (e) {
        newError = e instanceof Error ? e : new Error('Payment Error: ' + e)
      }
      if (newError) {
        setError(newError)
        console.error(newError)
        setPendingOperations(n => n - 1)
      }

      setPendingOperations(n => n - 1)
    },

    [cartCurrentPayment, paymentRef, queue]
  )

  const stillPending = !cart || pendingOperations > 0

  useEffect(() => {
    if (stillPending) {
      setPaymentStatus('pending')
    } else {
      if (error) {
        setPaymentStatus('error')
      } else if (cartActionState === 'processing') {
        setPaymentStatus('pending')
      } else if (cartPaymentNotNecessary) {
        setPaymentStatus('payment-not-necessary')
      } else if (!isCartComplete) {
        setPaymentStatus('cart-incomplete')
      } else if (!currentPaymentResponse) {
        if (needToSetCurrentPaymentResponse) {
          setPaymentStatus('payment-missing')
        }
      } else if (isRedirectShopper(currentPaymentResponse)) {
        setPaymentStatus('payment-redirect-shopper')
      } else if (isChallengeShopper(currentPaymentResponse)) {
        setPaymentStatus('payment-challenge-shopper')
      } else if (isIdentifyShopper(currentPaymentResponse)) {
        setPaymentStatus('payment-identify-shopper')
      } else if (isAuthorized(currentPaymentResponse)) {
        setPaymentStatus('payment-authorized')
      } else if (isAuthorizing(currentPaymentResponse)) {
        setPaymentStatus('payment-authorizing')
      } else {
        setPaymentStatus('payment-initialized')
      }
    }
  }, [
    error,
    cartActionState,
    cartPaymentNotNecessary,
    pendingOperations,
    currentPaymentResponse,
    isCartComplete,
    stillPending,
    needToSetCurrentPaymentResponse,
  ])

  //
  // Generate the context value
  //  get initial payment methods
  //

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const paymentMethodsResponse = (currentPaymentResponse?.custom as any)?.fields
    ?.getPaymentMethodsResponse

  const paymentMethodsTmp =
    typeof paymentMethodsResponse === 'string'
      ? JSON.parse(paymentMethodsResponse)
      : undefined

  const paymentMethodsResponseObject: PaymentMethodsResponseObject =
    isPaymentMethods(paymentMethodsTmp)
      ? paymentMethodsTmp
      : emptyPaymentMethodResponseObject

  // Show an error if no payment methods are configured
  if (
    paymentStatus === 'payment-initialized' &&
    paymentMethodsResponseObject.paymentMethods.length === 0
  ) {
    originError.current = paymentMethodsResponse
    setError(new Error('No payment methods'))
  }

  return (
    <paymentContext.Provider
      value={{
        paymentStatus,
        errorMessage,
        countryCode,
        currentPaymentResponse,
        cartCurrentPaymentType,
        paymentMethodsResponseObject,
        pendingOperations,
        submitAdditionalPaymentDetailsResultCode,
        makePaymentResultCode,
        cartAvailablePaymentMethods,
        initializePayment,
        authorizePayment,
        submitAdditionalPaymentDetails,
        setCountryCode,
      }}
    >
      {children}
    </paymentContext.Provider>
  )
}
