import React from 'react'
import { ToniesColor, isToniesColor } from '@/tonies-ui/themes/colors'
import { useTheme } from 'styled-components'

/**
 * Renders the Toni Head.
 *
 * @component
 * @param props - The SVG props for the component.
 * @param [dark] - Determines if the TonieHead should be rendered in dark mode.
 * @returns The rendered TonieHead component.
 */
export default function TonieHead({
  fill = '#3c1e5f',
  ...props
}: Omit<React.SVGProps<SVGSVGElement>, 'fill'> & {
  fill: ToniesColor | (string & Record<never, never>)
}) {
  const { colors } = useTheme()

  return (
    <svg
      viewBox="0 0 1330 1676"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1285.48 0.00011238C1229.33 0.000107471 968.293 267.931 785.076 463.953L477.744 463.953C370.374 320.137 234.439 147.756 195.038 147.756C144.801 147.756 107.369 424.552 90.6235 570.337C38.4165 622.544 -6.13137e-05 701.347 -7.15614e-05 818.567L-0.00011548 1320.94C-0.00011548 1320.94 -0.000146481 1675.55 354.614 1675.55L974.203 1675.55C974.203 1675.55 1328.82 1675.55 1328.82 1320.94L1328.82 829.402C1328.82 829.402 1328.82 278.766 1328.82 273.841C1332.76 132.98 1323.89 0.000115738 1285.48 0.00011238Z"
        fill={isToniesColor(fill) ? colors[fill] : fill}
      />
    </svg>
  )
}
