import { LocaleInclExternal } from '../config/shopAPI/types'
import { shopConfig } from '../config/shop'

/**
 * Returns the base url
 * - Optionally remove trailing slash
 */
export function baseUrl(removeTrailingSlash = false) {
  let url = process.env.NEXT_PUBLIC_BASE_URL || 'https://tonies.com/'

  if (removeTrailingSlash) {
    url = url.replace(/\/+$/, '')
  }

  return url
}

/**
 * Returns the full URL
 * - Prepend base url if needed
 * - Optionally remove query params
 * - Removes double slashes
 */
export function fullUrl(url: string, removeQueries = false) {
  if (
    process.env.NEXT_PUBLIC_BASE_URL &&
    !url.startsWith(process.env.NEXT_PUBLIC_BASE_URL)
  ) {
    url = process.env.NEXT_PUBLIC_BASE_URL + url
  }

  if (removeQueries) {
    url = url.split('?')[0]
  }

  url = url.replace(/(https?:\/\/)|(\/)+/g, '$1$2')

  return url
}

/**
 * Returns the full URL for the homepage of the given Locale
 */
export function homepageUrl(lcCC: LocaleInclExternal) {
  return (
    shopConfig.locales[lcCC]?.commonPageUrls?.mainPage ||
    fullUrl(`/${lcCC.toLowerCase()}/`)
  )
}
