import React from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'
import { useRef } from 'react'
import { ToniesColor } from '@/tonies-ui/themes/colors'
import { Curve } from '@/tonies-ui/atoms/Curve'
import { AnimatedToniehead } from '@/tonies-ui/atoms/TonieHead/AnimatedToniehead'
import { useIsViewportMobile } from '@boxine/tonies-ui'
import { useToniesSpring } from '@/tonies-ui/motions/constants'

export const ScrollingTonieHeads: NormalizedMotionComponent<{
  firstColor: ToniesColor | (string & Record<never, never>)
  secondColor: ToniesColor | (string & Record<never, never>)
  hasCurve?: boolean
}> = ({
  firstColor = 'lightergrey',
  secondColor = 'primary',
  hasCurve = false,
  ...rest
}) => {
  const isMobile = useIsViewportMobile()
  const sectionRef = useRef<HTMLDivElement>(null)
  const introRef = useRef<HTMLDivElement>(null)

  const { scrollYProgress } = useScroll({
    target: introRef,
    offset: ['start center', 'end center'],
  })
  const progress = useTransform(
    useToniesSpring(scrollYProgress),
    [0, 0.1, 0.9, 1],
    [0, 0.2, 0.8, 1]
  )

  return (
    <motion.div
      ref={sectionRef}
      data-testid="scrolling-tonie-heads-motion"
      aria-hidden
      style={{
        position: 'relative',
      }}
      {...rest}
    >
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          height: '100vh',
          inset: 0,
          isolation: 'isolate',
          pointerEvents: 'none',
          top: '-50vh',
          zIndex: 10,
          overflow: 'hidden',
        }}
        ref={introRef}
      >
        <AnimatedToniehead
          style={{
            y: useTransform(progress, [0, 1], ['80vh', '-40vh']),
            rotate: useTransform(progress, [0, 1], [0, 50]),
            width: isMobile ? '2.5rem' : '4rem',
            willChange: 'auto',
          }}
          fill={secondColor}
        />
        <AnimatedToniehead
          style={{
            y: useTransform(progress, [0, 1], ['80vh', '-40vh']),
            rotate: useTransform(progress, [0, 1], [-130, 110]),
            left: '20%',
            width: isMobile ? '4rem' : '7rem',
            willChange: 'auto',
          }}
          fill={firstColor}
        />
        <AnimatedToniehead
          style={{
            y: useTransform(progress, [0, 1], ['155vh', '-140vh']),
            rotate: useTransform(progress, [0, 1], [20, 130]),
            left: '15%',
            width: isMobile ? '5rem' : '10rem',
            willChange: 'auto',
          }}
          fill={firstColor}
        />
        <AnimatedToniehead
          style={{
            y: useTransform(progress, [0, 1], ['90vh', '-100vh']),
            rotate: useTransform(progress, [0, 1], [10, 50]),
            left: '53%',
            width: isMobile ? '4rem' : '8rem',
            willChange: 'auto',
          }}
          fill={secondColor}
        />
        <AnimatedToniehead
          style={{
            y: useTransform(progress, [0, 1], ['125vh', '-75vh']),
            rotate: useTransform(progress, [0, 1], [50, -100]),
            right: '15%',
            width: isMobile ? '2rem' : '3rem',
            willChange: 'auto',
          }}
          fill={firstColor}
        />
        <AnimatedToniehead
          style={{
            y: useTransform(progress, [0, 1], ['130vh', '-180vh']),
            rotate: useTransform(progress, [0, 1], [-50, 100]),
            right: '30%',
            width: isMobile ? '2.5rem' : '4rem',
            willChange: 'auto',
          }}
          fill={firstColor}
        />
        <AnimatedToniehead
          style={{
            y: useTransform(progress, [0, 1], ['80vh', '-50vh']),
            rotate: useTransform(progress, [0, 1], [-70, 50]),
            right: 0,
            width: isMobile ? '5rem' : '10rem',
            willChange: 'auto',
          }}
          fill={secondColor}
        />
      </div>
      {hasCurve && <Curve color={secondColor} layout="topRight" />}
    </motion.div>
  )
}
