import { BxCart, BxLineItemUpdate } from '../bxTypes'
import { fetch } from '../../util/fetch'
import { EcomLocale } from '../../../../config/shopAPI/types'

export const changeLineItemQuantity = async (
  lcCC: EcomLocale,
  lineItemId: string,
  cartId: string,
  cartVersion: number,
  quantity: number
) =>
  fetch<BxLineItemUpdate, BxCart>({
    query: `/carts/${cartId}`,
    lcCC,
    method: 'post',
    request: {
      version: cartVersion,
      actions: [
        {
          action: 'changeLineItemQuantity',
          lineItemId,
          quantity,
        },
      ],
    },
  })
