/**
 * Root base for gloabl font size in px
 * used for rem and em
 */
export const ROOT_BASE = 16
/**
 * Grid basis (8pt grid design system)
 * used for margins, paddings, sizings etc.
 */
export const GRID_BASE = 8
/**
 * Font family for serif font
 */
export const FONT_FAMILY_SERIF = 'Museo, Helvetica, Arial, sans-serif'
/**
 * Font family for sans-serif font
 */
export const FONT_FAMILY_SANS_SERIF = 'MuseoSans, Helvetica, Arial, sans-serif'
/**
 * Font family for accent font
 */
export const FONT_FAMILY_ACCENT = 'EllaTonies-Script, serif'
/**
 * Maximum safe Z-Index
 */
export const MAX_SAFE_Z_INDEX = 16777271
