import { shade } from 'color-helpers'
import { mediaQuery, rem } from './themeHelperFunctions'
import { colors } from './colors'
import {
  FONT_FAMILY_ACCENT,
  FONT_FAMILY_SANS_SERIF,
  FONT_FAMILY_SERIF,
  MAX_SAFE_Z_INDEX,
  ROOT_BASE,
} from './constants'
import { spacings } from './spacings'
import { variables } from './variables'

export const darken = (factor: number, color: string) =>
  '#' + shade(color, -1 * factor, { convertHSL: true })
export const lighten = (factor: number, color: string) =>
  '#' + shade(color, factor)

const themeHelperFactory = () => {
  /* Use these template strings when you want to target a media query with javascript */
  const mediaQueryTemplateStrings = {
    mobileS: `(min-width: ${rem(variables.screenMobileS)})`,
    mobileSM: `(min-width: ${rem(variables.screenMobileSM)})`,
    mobileM: `(min-width: ${rem(variables.screenMobileM)})`,
    mobileL: `(min-width: ${rem(variables.screenMobileL)})`,
    mobileXL: `(min-width: ${rem(variables.screenMobileXL)})`,
    tablet: `(min-width: ${rem(variables.screenTablet)})`,
    tabletL: `(min-width: ${rem(variables.screenTabletL)})`,
    laptop: `(min-width: ${rem(variables.screenLaptop)})`,
    laptopL: `(min-width: ${rem(variables.screenLaptopL)})`,
    screen4k: `(min-width: ${rem(variables.screen4k)})`,
  }

  const media = {
    mobileS: mediaQuery(mediaQueryTemplateStrings.mobileS),
    mobileSM: mediaQuery(mediaQueryTemplateStrings.mobileSM),
    mobileM: mediaQuery(mediaQueryTemplateStrings.mobileM),
    mobileL: mediaQuery(mediaQueryTemplateStrings.mobileL),
    mobileXL: mediaQuery(mediaQueryTemplateStrings.mobileXL),
    tablet: mediaQuery(mediaQueryTemplateStrings.tablet),
    tabletL: mediaQuery(mediaQueryTemplateStrings.tabletL),
    laptop: mediaQuery(mediaQueryTemplateStrings.laptop),
    laptopL: mediaQuery(mediaQueryTemplateStrings.laptopL),
    screen4k: mediaQuery(mediaQueryTemplateStrings.screen4k),
  }

  return {
    mediaQueryTemplateStrings,
    media,
  }
}

const themeFactory = () => {
  return {
    rootBase: ROOT_BASE,
    ...variables,
    colors,
    spacings,
    zIndex: {
      toast: MAX_SAFE_Z_INDEX - 1,
      modal: MAX_SAFE_Z_INDEX - 2,
    },
    application: {
      backgroundColor: colors.lightergrey,
      color: colors.darkergrey,
      maxContentWidth: '1200px',
    },
    fonts: {
      accent: FONT_FAMILY_ACCENT,
      serif: FONT_FAMILY_SERIF,
      sansSerif: FONT_FAMILY_SANS_SERIF,
    },
    /** @deprecated typography definitions
     * TODO: Remove this block after migration
     */
    h1: {
      fontSizeSmall: `${rem(variables.h1FontSizeSmall, variables.FontSize)}`,
      fontSizeMedium: `${rem(variables.h1FontSizeMedium, variables.FontSize)}`,
      fontSizeLarge: `${rem(variables.h1FontSizeLarge, variables.FontSize)}`,
      lineHeight: `${variables.LineHeight}`,
      font: FONT_FAMILY_SERIF,
      highlightPadding: `${variables.Padding * 1.5}px`,
    },
    h2: {
      fontSizeSmall: `${rem(variables.h2FontSizeSmall, variables.FontSize)}`,
      fontSizeMedium: `${rem(variables.h2FontSizeMedium, variables.FontSize)}`,
      fontSizeLarge: `${rem(variables.h2FontSizeLarge, variables.FontSize)}`,
      lineHeight: `${variables.LineHeight}`,
      font: FONT_FAMILY_SERIF,
      highlightPadding: `${variables.Padding}px`,
    },
    h3: {
      fontSizeSmall: `${rem(variables.h3FontSizeSmall, variables.FontSize)}`,
      fontSizeMedium: `${rem(variables.h3FontSizeMedium, variables.FontSize)}`,
      fontSizeLarge: `${rem(variables.h3FontSizeLarge, variables.FontSize)}`,
      lineHeight: `${variables.LineHeight}`,
      font: FONT_FAMILY_SERIF,
      highlightPadding: `${variables.Padding * 0.75}px`,
    },
    h4: {
      fontSize: `${rem(variables.h4FontSize, variables.FontSize)}`,
      lineHeight: `${variables.LineHeight}`,
      font: FONT_FAMILY_SERIF,
      highlightPadding: `${variables.Padding * 0.6125}px`,
    },
    text: {
      default: {
        fontSize: `1rem`,
        lineHeight: `${variables.LineHeight}`,
      },
      lead: {
        fontSize: `${rem(20, variables.FontSize)}`,
        lineHeight: `${variables.LineHeight}`,
      },
      intro: {
        fontSize: `${rem(28, variables.FontSize)}`,
        lineHeight: `${variables.LineHeight}`,
      },
    },
    /* @deprecated typography definitions end */
    typography: {
      accent1: {
        fontSize: {
          normal: '2rem',
          small: '1.5rem',
        },
      },
      accent2: {
        fontSize: {
          normal: '1.75rem',
          small: '1.375rem',
        },
      },
      headline1: {
        fontSize: {
          normal: '2rem',
          small: '1.5rem',
        },
      },
      headline2: {
        fontSize: {
          normal: '1.75rem',
          small: '1.25rem',
        },
      },
      headline3: {
        fontSize: {
          normal: '1.5rem',
          small: '1.125rem',
        },
      },
      headline4: {
        fontSize: {
          normal: '1.25rem',
          small: '1.125rem',
        },
      },
      headline5: {
        fontSize: {
          normal: '1rem',
          small: '0.875rem',
        },
      },
      copy1: {
        fontSize: {
          normal: '1.25rem',
          small: '1rem',
        },
      },
      copy2: {
        fontSize: {
          normal: '1rem',
          small: '0.875rem',
        },
      },
      copy3: {
        fontSize: {
          normal: '0.875rem',
          small: '0.75rem',
        },
      },
      copy4: {
        fontSize: {
          normal: '0.75rem',
          small: '0.625rem',
        },
      },
      copy5: {
        fontSize: {
          normal: '0.625rem',
          small: '0.625rem',
        },
      },
    },
    colorContext: 'light' as 'light' | 'dark',
  } as const
}

const theme = themeFactory()
const themeHelpers = themeHelperFactory()

export type ThemeType = typeof theme

export const media = themeHelpers.media
export { theme, themeHelpers, themeFactory, themeHelperFactory, variables }
