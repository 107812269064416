import isEqual from 'lodash/isEqual'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useGtmV4 } from '../../providers/gtmV4'
import { useTranslation } from 'next-i18next'
import { useLocalStorage } from '@boxine/tonies-ui'
import { toast } from '@/tonies-ui/atoms/Toast'
import { useAsyncEffect } from '../../hooks/useAsyncEffect'
import useKeycloak from '../../hooks/useKeycloak'
import { useLocale } from '../locale'
import { http } from '../../lib/http.service'
import { generateId, generateSha256 } from '../../utils'
import { removeItem as storageRemoveItem } from '../../hooks/useStorage'
import { useCurrentUserInSentry } from '../../hooks/useSentry'
import { getCustomer } from '../../lib/commercetools/requests/me/getCustomer'
import { AuthContext, AuthContextInterface, RegisterTypes } from '.'
import { Address } from '@commercetools/platform-sdk'
import { isEcomLocale } from '../../config/shopAPI'
import { changeBrazeUser, brazeApiKey, brazeBaseUrl } from '../../lib/braze'
import {
  getHouseholdData,
  getHouseholdSingleTunesItemData,
} from '../../lib/cloudservices/requests/graphql/getHouseholdData'
import { useGeoIp } from '@/tonies-ui/hooks/useGeoIp'
import {
  TunesItemCheckData,
  getCheckTunesStatus,
} from '../../lib/cloudservices/requests/rest/checkTuneStatus'
import { FetchResult } from '../../lib/cloudservices/util/fetch'
import { EcomLocale } from '../../config/shopAPI/types'
import { getWishlists } from '../../lib/shopApi/requests/wishlist/getWishlists'
import { useToastErrorMessage } from '../../hooks/useToastErrorMessage'
import { isRequestFailed } from '../../lib/shopApi/util/fetch'
import { useToniesCookies } from '../../hooks/useToniesCookies'

const getExemptUrl = (url: string, exempt: boolean) => {
  return exempt ? url.replace('?', '?exempt=1&') : url
}

// Quick fix for keycloak reload incorrect language settings
// FIXME: need to be refactored to a hormonize local-string in all DEV-Teams (My-Tonies, Keycloak, Cloudservices, etc)
const getKeycloakLanguage = (lang: string) => {
  return lang !== 'fr-FR' ? lang : 'fr'
}

export const AuthProvider: NormalizedFunctionComponent = ({ children }) => {
  const { i18n, t } = useTranslation()
  const lcCC = useLocale()
  const { setCartId, setCartItemCount, setCartUserId } = useToniesCookies()
  const showErrorToastMessage = useToastErrorMessage()
  const [user, setUser] = useState<AuthContextInterface['user']>(null)
  const [localStorageToken, setLocalStorageToken] = useLocalStorage<
    string | null
  >('kc_access_token')

  const [, setLocalStorageTokenRefresh] = useLocalStorage<string | null>(
    'kc_refresh_token'
  )

  const [, setLocalStorageTokenId] = useLocalStorage<string | null>(
    'kc_id_token'
  )

  const [hasAuthorizationFinished, setHasAuthorizationFinished] =
    useState(false)

  const { keycloak, accessToken, status } = useKeycloak()

  const [isBrazePending, setIsBrazePending] = useState(true)

  /**
   * FIXME
   * 1. why this useMemo() here at all?
   * 2. why not keycloakUser.id?
   */
  const uuid = useMemo(
    () => keycloak?.tokenParsed?.sub,
    [keycloak?.tokenParsed?.sub]
  )

  // This should be the only place to use this hook to avoid multiple calls
  const { geoIpRegion, geoIpCountry = 'GB', geoIpLcCC } = useGeoIp()

  useCurrentUserInSentry(user)

  const resetLocalStorageTokens = () => {
    setLocalStorageToken(null)
    setLocalStorageTokenRefresh(null)
    setLocalStorageTokenId(null)
  }

  // remove classic specific sessionStorage items
  // this can be removed after we have fully migrated to next
  storageRemoveItem('bxAuthenticated_de-DE', 'sessionStorage')
  storageRemoveItem('bxAuthenticated_en-GB', 'sessionStorage')

  useEffect(() => {
    // show TOAST-Message when the status is 'INITIALIZATION_FAILED' (Keycloak-Outage)
    if (status === 'INITIALIZATION_FAILED') {
      toast(
        t('default:TOASTSomethingWentWrongWithErrorCode', {
          errorCode: 403,
        }),
        'error',
        {
          autoClose: false,
          toastId: 'keycloak-outage',
        }
      )
    }
  }, [status, t])

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useAsyncEffect(async () => {
    if (
      status === 'AUTHENTICATED' &&
      accessToken &&
      keycloak?.loadUserProfile
    ) {
      http.defaults.headers.Authorization = `Bearer ${accessToken}`

      // get user-data from CloudServices with localStorage keycloak token
      try {
        /**
         * keycloakUser.attributes is not a standard Keycloak field
         * but appended by us. We therefor need to expand the type here
         * for the auth code.
         */
        const keycloakUser =
          await (keycloak.loadUserProfile() as Keycloak.KeycloakPromise<
            Keycloak.KeycloakProfile & {
              attributes?: { auth_code?: string[] }
            },
            void
          >)

        const authCode =
          keycloakUser?.attributes?.auth_code instanceof Array
            ? keycloakUser.attributes.auth_code[0]
            : undefined

        if (!isEcomLocale(lcCC)) {
          if (brazeBaseUrl && brazeApiKey !== undefined) {
            changeBrazeUser(uuid)
          }
          // Locale
          // create user with data from keycloak only
          setUser({
            id: keycloakUser.id,
            email: keycloakUser.email,
            firstName: keycloakUser.firstName,
            lastName: keycloakUser.lastName,
            isEmailVerified: Boolean(keycloakUser.emailVerified),
            bxFilteredAddresses: [],
            authCode: authCode,
            wishlists: [],

            /**
             * beware, we are casting an incomplete UserObject (non Ecom-User) here and ignore all required properties
             * this should not be currently a problem because a non-Ecom should not have access to any Ecom functionality
             * if this becomes a problem we should introduce an explict type for this non ecom user
             */
          } as unknown as AuthContextInterface['user'])
        } else {
          // EcomLocale
          // enrich data from keycloak with shipping and billing address
          // or default addresses from commercetools
          const commercetoolsData = await getCustomer(lcCC)

          if (commercetoolsData.result === 'successful') {
            // START hotfix TWAS-3283 + fix TWAS-3333
            if (commercetoolsData.data.bxFilteredAddresses.length) {
              const trimmedAddresses: Address[] =
                commercetoolsData.data.bxFilteredAddresses.map(adr => {
                  const returnAdr = { ...adr }
                  const entries = Object.entries(adr)
                  entries.forEach(([key, value]) => {
                    returnAdr[key as keyof Address] = value
                      ? value.trim()
                      : value
                  })
                  return returnAdr
                })
              commercetoolsData.data.bxFilteredAddresses = trimmedAddresses
            }
            // END hotfix TWAS-3283 + fix TWAS-3333

            // get cloudservice data
            const cloudservicesData = await getHouseholdData(lcCC)

            let checkTunesStatus: FetchResult<TunesItemCheckData> | undefined

            if (
              !isEqual(user, commercetoolsData.data) &&
              cloudservicesData.result === 'successful'
            ) {
              /**
               * get checkTunesStatus for all tunes in household at once
               */
              const { myTunes } = cloudservicesData.data.data

              if (myTunes.length) {
                checkTunesStatus = await getCheckTunesStatus({
                  lcCC,
                  tunesItemSalesIds: myTunes.map(t => t.item.salesId),
                })
              }

              const tunesStatus =
                checkTunesStatus?.result === 'successful'
                  ? checkTunesStatus.data
                  : undefined

              if (brazeBaseUrl && brazeApiKey !== undefined) {
                changeBrazeUser(uuid)
              }

              const wishlists = await getWishlists(lcCC).catch(error => {
                if (isRequestFailed(error)) {
                  showErrorToastMessage(
                    'get-user-wishlists-outage',
                    error.httpStatus
                  )
                }
                return []
              })
              setUser({
                ...commercetoolsData.data,
                email: keycloakUser.email || commercetoolsData.data.email,
                firstName: keycloakUser.firstName,
                lastName: keycloakUser.lastName,
                isEmailVerified: Boolean(keycloakUser.emailVerified),
                authCode: authCode,
                household: {
                  data: cloudservicesData.data.data,
                  ownTunes: cloudservicesData.data.data.myTunes,
                  tunesStatus,
                },
                wishlists,
              })
            }
          }
        }
      } catch (error) {
        if (isRequestFailed(error)) {
          showErrorToastMessage('auth-provider-outage', error.httpStatus)
        }
      } finally {
        setHasAuthorizationFinished(true)
      }
    } else if (status === 'NOT_AUTHENTICATED') {
      // cleanup
      setUser(null)
      resetLocalStorageTokens()
      setHasAuthorizationFinished(true)
      http.defaults.headers.Authorization = undefined
    }
  }, [status, accessToken])

  const login: AuthContextInterface['login'] = ({
    redirectUri = window.location.href,
    shouldReplaceHistory = true,
    exempt = false,
  } = {}) => {
    // Users may click login before keycloak is initialized in which case the
    // internal adapter is not present and calling `keycloak.login()` will
    // therefore throw. We can detect the adapter being initialized when
    // the status is "NOT_AUTHENTICATED" or "AUTHENTICATED". Check out
    // our "useKeycloak" hook for more details. (TOC-3687)
    if (
      keycloak?.createLoginUrl &&
      (status === 'NOT_AUTHENTICATED' || status === 'AUTHENTICATED')
    ) {
      const loginUrl = getExemptUrl(
        keycloak.createLoginUrl({
          redirectUri,
          locale: getKeycloakLanguage(i18n.language),
        }),
        exempt
      )

      // Inlined variant of `keycloak.login()`. It does nothing more
      // than doing the navigation to the login URL anyway.
      if (shouldReplaceHistory) {
        window.location.replace(loginUrl)
      } else {
        window.location.href = loginUrl
      }
    }
  }

  const register: AuthContextInterface['register'] = ({
    redirectUri = window.location.href,
    shouldReplaceHistory = true,
    exempt = false,
  }: RegisterTypes = {}) => {
    // Keycloak may not finished initializing here. Check out
    // our "useKeycloak" hook for more details. (TOC-3600, related to TOC-3687)
    if (
      keycloak?.createRegisterUrl &&
      (status === 'NOT_AUTHENTICATED' || status === 'AUTHENTICATED')
    ) {
      const registerUrl = getExemptUrl(
        keycloak.createRegisterUrl({
          redirectUri,
          locale: getKeycloakLanguage(i18n.language),
        }),
        exempt
      )

      // Inlined variant of `keycloak.register()`. It does nothing more
      // than doing the navigation to the register URL anyway.
      if (shouldReplaceHistory) {
        window.location.replace(registerUrl)
      } else {
        window.location.href = registerUrl
      }
    }
  }

  const resetPassword = () => {
    if (keycloak?.authServerUrl) {
      window.location.href = `${
        keycloak.authServerUrl
      }realms/tonies/login-actions/reset-credentials?client_id=${
        keycloak.clientId
      }&kc_locale=${getKeycloakLanguage(i18n.language)}`
    }
  }

  const updatePassword = () => {
    if (keycloak?.createAccountUrl) {
      const accountUrl = new URL(keycloak.createAccountUrl())
      const passwordUrl = `${accountUrl.origin}${accountUrl.pathname}/password${
        accountUrl.search
      }&kc_locale=${getKeycloakLanguage(i18n.language)}`

      window.location.href = passwordUrl
    }
  }

  const deleteAccount = () => {
    if (keycloak?.authServerUrl) {
      const deleteAccountUrl = `${
        keycloak.authServerUrl
      }realms/tonies/tonie-account/account/delete?referrer=${
        keycloak.clientId
      }&referrer_uri=${window.location.href}&kc_locale=${getKeycloakLanguage(
        i18n.language
      )}`

      window.location.href = deleteAccountUrl
    }
  }

  const updateEmail = () => {
    if (keycloak?.createAccountUrl) {
      const accountUrl = keycloak.createAccountUrl()
      const updateEmailUrl = `${accountUrl}&kc_locale=${getKeycloakLanguage(
        i18n.language
      )}`

      window.location.href = updateEmailUrl
    }
  }

  const logout: AuthContextInterface['logout'] = options => {
    if (!keycloak?.logout) return

    setCartUserId('guest')
    setCartId(undefined)
    setCartItemCount(0)

    keycloak.logout({
      redirectUri: window.location.href,
      ...options,
    })
  }

  const updateUserContext: AuthContextInterface['updateUserContext'] =
    useCallback(
      data => {
        if (!isEqual(user, data)) {
          if (brazeBaseUrl && brazeApiKey !== undefined) {
            changeBrazeUser(uuid)
          }
          setUser(data)
        }
      },
      [user, uuid]
    )

  /**
   * To be able to display the pending state of the ProtectedRoutes,
   * we assume that if there is a localStorageToken for authentication,
   * this JWT is most likely valid and the user is probably authenticated
   *
   * Therefore, the ProtectedRoutes can still be displayed without
   * routing to the login page. An error message is displayed there.
   */
  const isTokenPresent = useMemo<boolean>((): boolean => {
    return (
      ['AUTHENTICATED', 'INITIALIZATION_STARTED', 'UNKNOWN'].includes(status) &&
      Boolean(localStorageToken)
    )
  }, [localStorageToken, status])

  const isPending =
    !hasAuthorizationFinished ||
    status === 'UNKNOWN' ||
    status === 'INITIALIZATION_STARTED'

  // Set user ID for Tracking
  const { pushDataLayer } = useGtmV4()
  useEffect(() => {
    if (uuid && !isPending) {
      pushDataLayer({
        event: 'user_data',
        event_id: generateId(),
        user_email: user?.email || '',
        user_email_sha256: user?.email ? generateSha256(user.email) : '',
        userId: uuid,
      })
    }
  }, [isPending, pushDataLayer, user?.email, uuid])

  /**
   * dynamically import braze SDK elements as suggested
   * here: {@link https://www.braze.com/docs/developer_guide/platform_integration_guides/web/initial_sdk_setup/#alternative-integration-methods}
   */
  useEffect(() => {
    if (brazeBaseUrl && brazeApiKey !== undefined) {
      import(
        /* webpackExports: ["initialize", "openSession", "getUser", "logCustomEvent", "automaticallyShowInAppMessages", "getCachedContentCards"] */
        '../../lib/braze/exports'
      ).then(
        ({
          initialize,
          openSession,
          logCustomEvent,
          getUser,
          changeUser,
          automaticallyShowInAppMessages,
          getCachedContentCards,
        }) => {
          if (brazeApiKey !== undefined) {
            initialize(brazeApiKey, {
              baseUrl: brazeBaseUrl,
              enableLogging: true,
              noCookies: true,
              allowUserSuppliedJavascript: true, // also sets `enableHtmlInAppMessages` to true
            })

            if (window) {
              window.__BRAZE__ = {
                logCustomEvent,
                getUser,
                changeUser,
                getCachedContentCards,
              }
            }
            /**
             * subscribes to automatically show in-app messages {@link https://js.appboycdn.com/web-sdk/latest/doc/modules/braze.html#automaticallyshowinappmessages}
             * TODO: do we have to remove the subscription again {@link https://js.appboycdn.com/web-sdk/latest/doc/modules/braze.html#removesubscription}
             */
            automaticallyShowInAppMessages()
            openSession()
            setIsBrazePending(false)
          }
        }
      )
    }
  }, [])

  /**
   * check if user has compatible Content Tonies in their household
   * @param {string}salesId salesId of already bought tunesItem
   */
  const getIsMyCompatibleTune = async (salesId: string) => {
    const checkTuneStatus = await getHouseholdSingleTunesItemData(
      lcCC as EcomLocale,
      salesId
    )
    if (
      checkTuneStatus.result === 'successful' &&
      checkTuneStatus.data.data.tunesItems.edges.length
    ) {
      return Boolean(
        checkTuneStatus.data.data.tunesItems.edges[0].node.compatibleTonies
          .length
      )
    }
    return false
  }
  return (
    <AuthContext.Provider
      value={{
        authenticated:
          status === 'AUTHENTICATED' && Boolean(user && user.isEmailVerified),
        isPending,
        isBrazePending,
        isTokenPresent,
        login,
        logout,
        register,
        deleteAccount,
        updateEmail,
        updatePassword,
        updateUserContext,
        getIsMyCompatibleTune,
        user,
        resetPassword,
        region: geoIpRegion,
        geoIpLcCC,
        geoIpCountry:
          typeof geoIpCountry === 'string'
            ? (geoIpCountry as string).toLocaleUpperCase()
            : 'GB',
        uuid,
        hasBearerToken:
          http.defaults?.headers?.Authorization?.substring(0, 6) === 'Bearer' ||
          false,
        keycloakStatus: status,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
