import axios, { AxiosResponse } from 'axios'
import * as Yup from 'yup'
import { EcomLocale } from '../../../config/shopAPI/types'
import { ShopApiViolation } from '../../../utils'
import { isAxiosError } from '../../../utils/isAxiosError'
import { http } from '../../http.service'

const baseURL = process.env.NEXT_PUBLIC_API_URL

export type FetchResult<SuccessDataType extends Record<string, unknown>> =
  | {
      result: 'successful'
      data: SuccessDataType
    }
  | {
      result: 'request-failed'
      data?: {
        type?: string
        title?: string
        detail?: string
        violations?: ShopApiViolation[]
      }
      error: Error
      httpStatus?: number
    }
  | {
      result: 'unexpected-response-format'
      error: Error
      data: unknown
    }

/**
 * Our standard implementation for CommerceTools API calls.
 *
 * This function supersedes the original `fetch()` in `./client.js`:
 *
 * - it takes Typescript types for the request and response
 *   payloads to improve type safety at build time.
 * - it takes an optional Yup schema for the response type to
 *   validate the response and improve type safety at runtime.
 *
 * The return value has a `result` field which indicates if the
 * operation succeeded. Depending on the `result`, additional
 * parameters may be present. In case of a successful request,
 * the response payload is in the type-safe field `data` of the
 * return value.
 *
 * Convenience hint: this function does NOT raise or pass-through
 * exceptions; callers need not implement try/catch clauses.
 */
export const fetch = async <
  RequestType extends Record<string, unknown>,
  SuccessDataType extends Record<string, unknown>
>({
  query,
  lcCC,
  method = 'get',
  request,
  responseSchema,
}: {
  query: string
  lcCC: EcomLocale
  method?: 'get' | 'post' | 'put'
  request: RequestType
  responseSchema?: Yup.Schema<SuccessDataType>
  // eslint-disable-next-line sonarjs/cognitive-complexity
}): Promise<FetchResult<SuccessDataType>> => {
  let response: AxiosResponse<SuccessDataType> | undefined

  const params: Record<string, unknown> = method === 'get' ? { ...request } : {}

  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'local') {
    params.XDEBUG_SESSION = 'yes' // Backend flag, see TWAS-3190
  }

  try {
    response = await axios.request<SuccessDataType>({
      headers: {
        ...http.defaults.headers,
        Accept: 'application/json',
        'Accept-Language': lcCC,
        'Content-Type': 'application/json',
      },
      data: method === 'get' ? undefined : request,
      params,
      method,
      baseURL,
      url: query,
    })
  } catch (error) {
    if (isAxiosError(error)) {
      const { data, status } = error.response || {}
      const { type, title, detail, violations } = data || {}

      return {
        result: 'request-failed',
        data: {
          type: typeof type === 'string' ? type : undefined,
          title: typeof title === 'string' ? title : undefined,
          detail: typeof detail === 'string' ? detail : undefined,
          violations: Array.isArray(violations) ? violations : undefined,
        },
        error,
        httpStatus: typeof status === 'number' ? status : undefined,
      }
    } else {
      return {
        result: 'request-failed',
        error:
          error instanceof Error ? error : new Error('fetch failed: ' + error),
      }
    }
  }

  if (responseSchema) {
    try {
      responseSchema.validateSync(response.data)
    } catch (error) {
      return {
        result: 'unexpected-response-format',
        data: response.data,
        error:
          error instanceof Error ? error : new Error('fetch failed: ' + error),
      }
    }
  }

  /**
   * Log a warning when fetch has more than 500 results, which will mean that we
   * then for sure need a paginated fetch or should split up the request if possible
   * for our legacy API because of a fixed limit by commercetools.
   *
   * https://docs.commercetools.com/api/limits#queries
   *
   * As we'll replace the API with OpenSearch - let's kick this down the road
   * as long as we can.
   */
  if (typeof response.data.total === 'number' && response.data.total > 500) {
    console.error(
      `🔥 fetch() has more than 500 results: ${response.data.total}`,
      request
    )
  }

  return {
    result: 'successful',
    data: response.data,
  }
}
