import { createGlobalStyle } from 'styled-components'

export const ToniesFontStyles = createGlobalStyle`
  body {
    /**
    * Without these rules both the Museo and Ella font look somewhat messy.
    */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @font-face {
    font-family: 'EllaTonies-Script';
    src: url('https://cdn.tonies.de/fonts/EllaTonies-Script.woff2') format('woff2'),
      url('https://cdn.tonies.de/fonts/EllaTonies-Script.woff') format('woff');
    font-weight: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Museo';
    src: url('https://cdn.tonies.de/fonts/museo-300.woff2') format('woff2'),
      url('https://cdn.tonies.de/fonts/museo-300.woff') format('woff');
    font-weight: 300;
    font-display: swap;
  }

  @font-face {
    font-family: 'Museo';
    src: url('https://cdn.tonies.de/fonts/museo-500.woff2') format('woff2'),
      url('https://cdn.tonies.de/fonts/museo-500.woff') format('woff');
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: 'Museo';
    src: url('https://cdn.tonies.de/fonts/museo-700.woff2') format('woff2'),
      url('https://cdn.tonies.de/fonts/museo-700.woff') format('woff');
    font-weight: 700;
    font-display: swap;
  }
  @font-face {
    font-family: 'Museo';
    src: url('https://cdn.tonies.de/fonts/museo-900.woff2') format('woff2'),
      url('https://cdn.tonies.de/fonts/museo-900.woff') format('woff');
    font-weight: 900;
    font-display: swap;
  }
  @font-face {
    font-family: 'MuseoSans';
    src: url('https://cdn.tonies.de/fonts/museo-sans-300.woff2') format('woff2'),
      url('https://cdn.tonies.de/fonts/museo-sans-300.woff') format('woff');
    font-weight: 300;
    font-display: swap;
  }

  @font-face {
    font-family: 'MuseoSans';
    src: url('https://cdn.tonies.de/fonts/museo-sans-500.woff2') format('woff2'),
      url('https://cdn.tonies.de/fonts/museo-sans-500.woff') format('woff');
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: 'MuseoSans';
    src: url('https://cdn.tonies.de/fonts/museo-sans-700.woff2') format('woff2'),
      url('https://cdn.tonies.de/fonts/museo-sans-700.woff') format('woff');
    font-weight: 700;
    font-display: swap;
  }
`
