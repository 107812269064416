import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { AxiosError } from 'axios'
import { toast } from '@/tonies-ui/atoms/Toast'
import { useCartActions } from '../../hooks/useCartActions'
import { ErrorLayoutType } from '../../layouts/ErrorLayout'
import {
  normalizeGeoIpViolations,
  normalizeLineItemViolations,
  ShopApiViolation,
} from '../../utils'
import { isAxiosError } from '../../utils/isAxiosError'
import { isJestRunning } from '../../utils/isJestRunning'
import { isStorybookRunning } from '../../utils/isStorybookRunning'
import { ShopAPIErrorHandlingContext } from './ShopAPIErrorHandlingContext'
import { usePayment } from '../../hooks/usePayment'

/**
 * This provider ensures that a Toast message will be displayed
 * when an error is returned in response to a requested cart action.
 */
export const ShopAPIErrorHandlingProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [errorPageLayout, setErrorPageLayout] = useState<
    ErrorLayoutType | undefined
  >()
  const [pendingOperations, setPendingOperations] = useState(0)
  const { error: cartError, resetError } = useCartActions()
  const { errorMessage, initializePayment } = usePayment()
  const { t } = useTranslation()

  const error = useMemo(
    () => (isAxiosError(cartError) ? cartError : undefined),
    [cartError]
  )

  const normalizedLineItemViolations = error?.response?.data.violations
    ? normalizeLineItemViolations(error?.response?.data.violations)
    : undefined
  const normalizedGeoIpViolations = error?.response?.data.violations
    ? normalizeGeoIpViolations(error?.response?.data.violations)
    : undefined
  const isUnexpectedPaymentError = Boolean(
    errorMessage === 'makePaymentResponse missing' ||
      errorMessage === 'submitAdditionalPaymentDetailsResponse missing'
  )

  const handleResetError = () => {
    if (isUnexpectedPaymentError) {
      /**
       * if we encountered an unexpected payment Error we need to
       * initialize a new payment to be able to start over
       */
      initializePayment()
    }
    resetError()
  }

  const isPlaceOrderError = Boolean(
    error?.config.url?.substring(0, 7) === '/orders' &&
      error.config.method === 'post'
  )
  const isAddPaymentError = Boolean(
    error?.config.url?.match(
      /\/bx\/carts\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\/payments/
    )
  )
  const hasLineItemViolations = Boolean(normalizedLineItemViolations?.length)
  const hasGeoIpViolations = Boolean(normalizedGeoIpViolations?.length)
  const cartViolations =
    (error?.response?.data?.errorType === 'validation' &&
      error.response.data.violations) ||
    undefined

  const showCartViolationModal =
    (isPlaceOrderError || isAddPaymentError) &&
    (hasLineItemViolations || hasGeoIpViolations)

  /**
   * checks if the request is syntactically correct but not processable (HTTP 422)
   * and acts as TypeGuard to detect AxiosError
   * @param {Error}error
   * @returns {boolean}
   */
  const isUnprocessable = (error: AxiosError) => {
    return error?.response?.status === 422
  }

  useEffect(() => {
    if (!error && errorPageLayout) {
      setErrorPageLayout(undefined)
      return
    }
    if (error) {
      if (isJestRunning() || isStorybookRunning()) {
        return
      }
      if (showCartViolationModal) {
        // no toasts when we show the CartViolationModal
        return
      }
      if (isPlaceOrderError || isUnexpectedPaymentError) {
        // no toasts when we show the ErrorPage
        setErrorPageLayout('orderError')
        return
      }

      // Display toast message
      let message
      if (error.message.includes('Network Error')) {
        // 'Network Error' is implemented by Axios and hence cross-browser-safe
        message = t('cart:error:network')
      } else if (
        isUnprocessable(error) &&
        error.response?.data?.violations?.find(
          (violation: ShopApiViolation) => violation.message === 'invalid'
        )
      ) {
        // user input was marked as invalid by CommerceTools validation
        // (i.e. an invalid postcode for the current locale)
        message = t('checkoutAddressForm:invalidInputError')
      } else if (
        isUnprocessable(error) &&
        error.response?.data?.violations?.find((violation: ShopApiViolation) =>
          violation.message.includes(
            'product_variant.maximum_quantity_exceeded'
          )
        )
      ) {
        message = t('cart:error:maxQuantityReached')
      } else if (
        isUnprocessable(error) &&
        error.response?.data.violations.find((violation: ShopApiViolation) =>
          violation.message.includes('line_item.product_is_out_of_stock')
        )
      ) {
        message = t('cart:error:outOfStock')
      } else if (error.message.includes('quantity')) {
        /** @deprecated: should be handled by check for `product_variant.maximum_quantity_exceeded` */
        message = t('cart:error:maxQuantityReached')
      } else if (
        error.message.includes('discount_code.does_not_exist') ||
        error.message.includes('discount_code.is_not_active') ||
        error.message.includes('discount_code.DoesNotMatchCart')
      ) {
        // show *no* Toast, inline error is preferred
      } else if (error.response?.data?.errorType === 'validation') {
        // show *no* Toast, handled by modal CartViolationModal
      } else if (error.message.includes('getPaymentMethodsRequest:')) {
        // show *no* Toast, handled by `createPaymentForCartAction`
      } else {
        message = t('cart:error:generic')
      }
      message && toast(message, 'error')
    }
  }, [
    error,
    errorPageLayout,
    isPlaceOrderError,
    isUnexpectedPaymentError,
    showCartViolationModal,
    t,
  ])

  const value = {
    error,
    errorPageLayout,
    resetError: handleResetError,
    cartViolations,
    showCartViolationModal,
    normalizedLineItemViolations,
    normalizedGeoIpViolations,
    isPlaceOrderError,
    pendingOperations,
    setPendingOperations,
  }

  return (
    <ShopAPIErrorHandlingContext.Provider value={value}>
      {children}
    </ShopAPIErrorHandlingContext.Provider>
  )
}
