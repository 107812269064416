/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback } from 'react'

function serialize(value: any): string {
  return typeof value === 'string' ? value : JSON.stringify(value)
}

/**
 * Try to parse Session and LocalStorage value otherwise return value
 * e.g. sessionStorage, localStorage values
 * "123" (stringified Number) => 123 (number)
 * "false" (stringified Boolean) => false (boolean)
 * ey73n3ngj3udnfm (non-stringified string)=> ey73n3ngj3udnfm
 */
function deserialize(value: string | null): any {
  if (!value) return value

  try {
    return JSON.parse(value)
  } catch {
    return value
  }
}

export function setItem(
  key: string,
  value: any,
  type: Storage = 'localStorage'
) {
  if (typeof window === 'undefined') return
  window[type].setItem(key, serialize(value))
}

export function getItem(
  key: string,
  type: Storage = 'localStorage'
): any | undefined {
  if (typeof window === 'undefined') return
  return deserialize(window[type].getItem(key))
}

export function removeItem(key: string, type: Storage = 'localStorage') {
  if (typeof window === 'undefined') return
  window[type].removeItem(key)
}

type Storage = 'localStorage' | 'sessionStorage'

function useStorage<ValueType>(
  key: string,
  initialValue?: ValueType,
  type?: Storage
): [ValueType, (value?: ValueType) => void] {
  const item = getItem(key, type)
  const [, setCounter] = useState(0)

  const rerender = useCallback(() => {
    setCounter(counter => counter + 1)
  }, [])

  const setItemCallback = useCallback(
    (value: ValueType | undefined) => {
      if (typeof window === 'undefined') return // fix endless rerender loop
      const serializedValue = serialize(value)

      if (value !== null && value !== undefined) {
        if (serializedValue !== item) {
          setItem(key, serializedValue, type)

          rerender()
        }
      } else {
        removeItem(key, type)

        rerender()
      }
    },
    //  type should never change in between
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item, key, rerender]
  )

  if (initialValue !== null && initialValue !== undefined && item === null) {
    setItemCallback(initialValue)
  }

  return [deserialize(getItem(key, type)), setItemCallback]
}

export default useStorage
