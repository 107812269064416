import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { sentryWithExtras } from '../../hooks/useSentry'
import { getRecommendations } from '../../lib/shopApi/requests/recommendations/getRecommendations'
import {
  addLastSeenSku,
  getRecommendations as getRecommendationsFromStorage,
  getRecommendationsAge,
  getRecommendationsSource,
  getLastSeenSku,
  setRecommendedProducts,
  type RecommendationsSource,
} from '../../lib/shopApi/requests/recommendations/utils'
import { useEcomLocale } from '../../providers/locale'
import { normalizeErrorMessage } from '../../utils/normalizeError'
import { useAuth } from '../../providers/auth'

type RecommendationsProviderProps = {
  recommendations: NormalizedProduct[]
  handleSetLastSeenSku: (sku: string) => void
}

export const recommendationsContext =
  createContext<RecommendationsProviderProps>({
    recommendations: [],
    handleSetLastSeenSku: () => null,
  })

export const RecommendationsProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const lcCC = useEcomLocale()
  const { isPending: isAuthPending, authenticated } = useAuth()
  const isPending = isAuthPending

  /** no need to use useState and re-evaluate the age because we only
   * use it once to check if the recommendations in LocalStorage are older then 24h */
  const age = getRecommendationsAge(lcCC)

  const [recommendations, setRecommendations] = useState<NormalizedProduct[]>(
    () => getRecommendationsFromStorage(lcCC)
  )
  const [source, setSource] = useState<RecommendationsSource>(() =>
    getRecommendationsSource(lcCC)
  )

  const updateState = useCallback(
    (products: NormalizedProduct[], source: RecommendationsSource) => {
      setRecommendations(products)
      setSource(source)
    },
    []
  )

  const handleSetLastSeenSku = (sku: string) => {
    const lastSeenSku = getLastSeenSku(lcCC)
    if (isPending || lastSeenSku === sku) {
      return
    }
    addLastSeenSku(sku, lcCC)
    if (!authenticated) {
      handleSetRecommendations(sku)
    }
  }
  /**
   * if called with an sku, `sku-based` recommendations are fetched
   * if called without an sku, `personal` recommendations are fetched
   * @param {string} [sku] - optional
   */
  const handleSetRecommendations = useCallback(
    async (sku?: string) => {
      try {
        const res = await getRecommendations({
          lcCC,
          sku,
        })
        if (res.result === 'successful') {
          const { products } = res.data
          const source = sku ? 'sku-based' : 'personal'
          setRecommendedProducts(products, source, lcCC)
          updateState(products, source)
        }
        return undefined
      } catch (error) {
        const sentryError =
          error instanceof Error
            ? error
            : new Error(normalizeErrorMessage(error))
        sentryWithExtras('recommendations', sentryError, {
          scope: sku ? `product-based (sku: ${sku})` : 'personal',
        })
        return undefined
      }
    },
    [lcCC, updateState]
  )
  useEffect(() => {
    if (isPending) return

    const lastSeenSku = getLastSeenSku(lcCC)
    /**
     * fetch personal recommendations
     * - when logged in and
     * - current recommendations are not `personal` or older than 24 hours
     */
    if (authenticated && (source !== 'personal' || age > 1000 * 60 * 60 * 24)) {
      handleSetRecommendations()
    }
    /**
     * discard personal recommendations when logged out and
     * fetch sku-based as an alternative
     */
    if (
      !authenticated &&
      lastSeenSku &&
      (source === 'personal' || !recommendations.length)
    ) {
      handleSetRecommendations(lastSeenSku)
    }
  }, [
    authenticated,
    recommendations.length,
    age,
    source,
    handleSetRecommendations,
    isPending,
    lcCC,
  ])

  return (
    <recommendationsContext.Provider
      value={{
        recommendations,
        handleSetLastSeenSku,
      }}
    >
      {children}
    </recommendationsContext.Provider>
  )
}

export const useRecommendations = () => useContext(recommendationsContext)
