import React, { useContext } from 'react'
import { GeoIpRegion } from '@/tonies-ui/hooks/useGeoIp'
import { LocaleInclExternal } from '../../config/shopAPI/types'
import { KeycloakStatus } from 'hooks/useKeycloak'

export type LoginTypes = {
  redirectUri?: string
  shouldReplaceHistory?: boolean
  exempt?: boolean
}

export type RegisterTypes = {
  redirectUri?: string
  shouldReplaceHistory?: boolean
  exempt?: boolean
}

export type LogoutTypes = {
  redirectUri?: string
}

export type AuthContextInterface = {
  authenticated: boolean
  isPending: boolean
  isBrazePending: boolean
  isTokenPresent: boolean
  login: (options?: LoginTypes) => void
  register: (options?: RegisterTypes) => void
  logout: (options?: LogoutTypes) => void
  deleteAccount: () => void
  updateEmail: () => void
  updatePassword: () => void
  updateUserContext: React.Dispatch<React.SetStateAction<NormalizedUser | null>>
  getIsMyCompatibleTune: (salesId: string) => Promise<boolean>
  user: NormalizedUser | null
  resetPassword: () => void
  region: GeoIpRegion
  geoIpCountry: string
  geoIpLcCC: LocaleInclExternal
  uuid: string | undefined
  hasBearerToken: boolean
  keycloakStatus: KeycloakStatus
}

export const authContextDefaultProps: AuthContextInterface = {
  authenticated: false,
  isPending: true,
  isBrazePending: true,
  isTokenPresent: false,
  login: () => null,
  logout: () => null,
  register: () => null,
  deleteAccount: () => null,
  updateEmail: () => null,
  updatePassword: () => null,
  updateUserContext: () => null,
  getIsMyCompatibleTune: () => Promise.reject(),
  user: null,
  resetPassword: () => null,
  region: 'UKI' as GeoIpRegion,
  geoIpCountry: 'GB',
  geoIpLcCC: 'en-GB',
  uuid: undefined,
  hasBearerToken: false,
  keycloakStatus: 'UNKNOWN',
}

export const AuthContext = React.createContext<AuthContextInterface>(
  authContextDefaultProps
)

export const AuthConsumer = AuthContext.Consumer

export const useAuth = () => useContext(AuthContext)
export const useHouseholdData = () => {
  const { user, isPending, getIsMyCompatibleTune } = useAuth()
  const household = user?.household.data
  const householdTunesStatus = user?.household.tunesStatus

  return {
    ...household,
    /**
     * check if user has already bought the tune
     * @param {string}salesId salesId of the tunesItem
     */
    getIsMyTune: (salesId: string) =>
      household?.myTunes.some(mt => mt.item.salesId === salesId),

    /** all compatible Tunes in my household */
    compatibleTunes: householdTunesStatus?.compatibleTunes,
    isPending,
    getIsMyCompatibleTune,
  }
}

export const useHouseholdTunesData = (salesId: string) => {
  const { getIsMyTune } = useHouseholdData()

  return {
    isMyTune: getIsMyTune(salesId),
  }
}
